import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromChat from './lex-chat.selectors';
import { LexSession } from '@domgen/dgx-components';
import * as chatActions from './lex-chat.actions';

@Injectable({
  providedIn: 'root',
})
export class LexChatFacadeService {
  currentLexChatMessages$: Observable<
    LexSession['messages'] | null | undefined
  > = this._store.pipe(select(fromChat.currentLexChatSessionMessages));

  currentLexChatSession$: Observable<LexSession | null | undefined> =
    this._store.pipe(select(fromChat.currentLexChatSession));

  history$ = this._store.pipe(select(fromChat.getLexHistory));

  isLoading$ = this._store.pipe(select(fromChat.isLoading));

  errorMessage$ = this._store.pipe(select(fromChat.errorMessage));
  errorCount$ = this._store.pipe(select(fromChat.errorCount));

  constructor(private _store: Store) {}

  updateHistory(payload: LexSession) {
    this._store.dispatch(chatActions.UpdateLexHistory({ payload }));
  }

  startLexSession(payload: string) {
    this._store.dispatch(chatActions.StartLexSessionLoading({ payload }));
  }

  sendLexRespond(payload: chatActions.LexRespond) {
    this._store.dispatch(chatActions.SendLexRespondLoading({ payload }));
  }
}
