import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import type { Claim } from '@domgen/dgx-components';

@Component({
  selector: 'claims-claim-type-selection-notice',
  templateUrl: './claim-type-selection-notice.component.html',
  styleUrls: ['./claim-type-selection-notice.component.scss'],
})
export class ClaimTypeSelectionNoticeComponent implements OnInit {
  @Input() claim?: Claim;
  @Input() showInWarrantyNotice = false;
  @Input() showplanInWaitPeriodNotice = false;
  @Input() isIWEnabled = false;

  InWarrantyContactTel?: string;
  InWarrantyContactName?: string;

  @Output() continueClicked = new EventEmitter();

  @Output() routeToPlanDetails = new EventEmitter();

  ngOnInit(): void {
    this.InWarrantyContactName = this.claim?.getData?.InWarrantyContactName
      ? this.claim.getData.InWarrantyContactName
      : this.claim?.reflect?.manufacturer;
    this.InWarrantyContactTel = this.claim?.getData?.InWarrantyContactTel
      ? this.claim.getData.InWarrantyContactTel
      : 'their direct line.';
  }
}
