import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CalendarDay, CalendarSlot } from '../../../interfaces';
import { BookingCalendarDateFormats } from '../booking-calendar-constants';
import { Api } from './../../../interfaces/api.interface';
import { AnalyticsService } from '../../../services/analytics.service';

export enum SlotType {
  ALLDAY = 'AllDay',
  SPECIFIC = 'Specific',
  AMPM = 'AMPM',
}

@Component({
  selector: 'dg-booking-calendar-confirmation',
  templateUrl: './booking-calendar-confirmation.component.html',
  styleUrls: ['./booking-calendar-confirmation.component.scss'],
})
export class BookingCalendarConfirmationComponent
  implements OnInit, AfterViewInit
{
  @Input() day?: CalendarDay;
  @Input() slotSelected: CalendarSlot | undefined = undefined;
  @Input() slotType?: SlotType;
  @Input() isRebooking = false;
  @Input() gaData: { planNumber: string; productName: string } | undefined;
  @Input() currentAppointment?: Api.CurrentAppointment;

  @Output() confirmBooking: EventEmitter<boolean> = new EventEmitter();

  bookingCalendarDateFormats = BookingCalendarDateFormats;

  slotTypes = SlotType;

  get confirmationHeading(): string {
    if (this.isRebooking) return 'Shall we change your booking?';

    if (this.slotType === SlotType.ALLDAY) return 'Shall we book this date?';

    return 'Shall we book this slot?';
  }

  get toSlotTimes(): string {
    if (this.slotType === SlotType.SPECIFIC) {
      return `${this.slotSelected?.startTime} - ${this.slotSelected?.endTime}`;
    }

    let startTime = '8am';
    let endTime = '1pm';

    if (this.slotSelected?.slotType === 'PM') {
      startTime = '12pm';
      endTime = '6pm';
    }
    return `${startTime} - ${endTime}`;
  }
  constructor(private readonly analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.trackRebookingAttempt();
  }

  trackRebookingAttempt(): void {
    if (!this.isRebooking) return;

    this.analyticsService.trigger('genericGAEvent', {
      eventAction: 'plan_details',
      eventCategory: 'current_booking',
      eventLabel: 'attempt_rebooking_change_date',
      ...this.gaData,
    });
  }

  ngAfterViewInit() {
    this.focusButton();
  }

  focusButton() {
    (
      document.querySelector(
        '.dialog dgx-components-button button'
      ) as HTMLButtonElement
    )?.focus();
  }

  decline() {
    if (this.isRebooking) {
      this.trackRebookAttempt(false);
    }
    this.confirmBooking.emit(false);
  }

  confirm() {
    if (this.isRebooking) {
      this.trackRebookAttempt(true);
    }
    this.confirmBooking.emit(true);
  }

  trackRebookAttempt(confirm: boolean): void {
    this.analyticsService.trigger('genericGAEvent', {
      pagename: 'claims-manage-my-repairs',
      eventAction: 'plan_details',
      eventCategory: 'current_booking',
      eventLabel: `rebooking_change_date_${confirm ? 'yes' : 'no'}_clicked`,
      ...this.gaData,
    });
  }
}
