import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CalendarDay } from '../../../interfaces';
import { BookingCalendarDateFormats } from '../booking-calendar-constants';
import { SlotType } from '../booking-calendar-day/booking-calendar-day.component';

@Component({
  selector: 'dg-booking-calendar-header',
  templateUrl: './booking-calendar-header.component.html',
  styleUrls: ['./booking-calendar-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingCalendarHeaderComponent {
  @Input() firstDate?: CalendarDay;
  @Input() isRebook = false;
  @Output() selectFirstDate = new EventEmitter();
  @Input() slotType?: SlotType;
  bookingCalendarDateFormats = BookingCalendarDateFormats;
  slotTypes = SlotType;
}
