<div [formGroup]="form">
  <div *ngFor="let group of callbackInWarranty">
    <div *ngIf="group?.date" #controls>
      <div
        *ngIf="group?.date"
        id="repairCallbackDate"
        #controls
        [ngClass]="{ 'p-calendar--open': open }"
      >
        <dg-information-notice
          [title]="group?.date?.heading"
          [description]="group.date?.subheading"
        >
        </dg-information-notice>
        <div
          class="form-input legacy-form-builder"
          [ngClass]="{
            'is-invalid':
              form.controls[group.date?.control]?.invalid &&
              form.controls[group.date?.control]?.touched,
            'is-valid': form.controls[group.date?.control]?.valid
          }"
        >
          <p class="typog-body-large-bold form-label">Fault date</p>
          <div class="form-control form-control--calendar">
            <i class="icon validation-icon icon-tick-circle"></i>
            <i class="icon validation-icon icon-error-circle"></i>
            <p-calendar
              class="form-control form-control--calendar"
              inputId="{{ group.date?.control }}"
              [showIcon]="true"
              dateFormat="dd/mm/yy"
              dataType="string"
              icon="icon icon-calendar"
              placeholder="DD/MM/YYYY"
              [showWeek]="true"
              [readonlyInput]="true"
              [showOtherMonths]="false"
              [selectOtherMonths]="false"
              [minDate]="group?.date?.calendarDays[0].date"
              [maxDate]="
                group?.date?.calendarDays[group?.date?.calendarDays.length - 1]
                  .date
              "
              [required]="true"
              [formControlName]="group.date?.control"
              (onClose)="open = false"
              (onShow)="onOpen()"
              (onSelect)="selectDate($event)"
            >
            </p-calendar>
          </div>
          <p
            class="form--error"
            *ngIf="form?.value?.FaultDate === null && validate"
          >
            Select date
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="group?.textarea" #controls id="repairCallbackText">
      <div class="form-label">
        <p class="typog-body-large-bold">{{ group.textarea?.heading }}</p>
        <p>{{ group.textarea?.subheading }}</p>
      </div>

      <dg-fb-textarea
        [classes]="'dg-textarea'"
        [validators]="group.textarea?.validators"
        [chrLimit]="group.textarea?.chrLimit"
        [group]="form"
        [showValidation]="validate"
        [control]="group.textarea?.control"
        [placeholder]="group.textarea?.placeholder"
        [label]="group.textarea?.label"
        [labelFor]="group.textarea?.control"
      >
      </dg-fb-textarea>
    </div>

    <div>
      <dg-fb-input
        *ngIf="group?.input"
        [item]="group?.input"
        [attr.data-automation]="group?.input?.control"
        [maxlength]="group?.input?.maxLength"
        [validators]="group?.input?.validators"
        [showValidation]="validate"
        [group]="form"
        [label]="group?.input?.label"
        [labelFor]="group?.input?.control"
      ></dg-fb-input>
    </div>
  </div>

  <dgx-components-button
    type="submit"
    variant="primary"
    [ngClass]="{
      'btn--disabled': form.invalid
    }"
    iconName="arrow-right"
    (click)="submitForm()"
    [attr.data-automation]="'Continue-qa-callback'"
  >
    Continue
  </dgx-components-button>
</div>
