import { ServiceCollectionData } from '@domgen/dgx-components';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { fadeInAnimation, fadeInOut, slideInOut } from '@domgen/dgx-components';
import { Title } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { ProductDetails } from '@domgen/dgx-components';
import { Observable } from 'rxjs';
import { DropoffStateService } from './book-dropoff-data-access/book-dropoff-state.service';
import { ContactDetailsFormValues } from '@domgen/dgx-components';

@Component({
  selector: 'claims-book-dropoff',
  templateUrl: './book-dropoff.component.html',
  styleUrls: ['./book-dropoff.component.scss'],
  providers: [DropoffStateService],
  animations: [fadeInAnimation, fadeInOut, slideInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookDropOffComponent implements OnInit {
  addressNoticeMessage =
    'This address will only be used for the purpose of this claim. It won’t be updated on your plan.';
  ServiceCollectionData = ServiceCollectionData;
  vm$ = this._state.vm$;
  submitCtaText = 'Confirm and book';
  isReturnAddressValid = true;

  product$ = this._state.product$.pipe(
    map((product: ProductDetails) => ({
      ...product,
      title: 'Arrange a drop-off repair',
      subtitle: `Find your nearest drop-off point`,
    }))
  ) as Observable<ProductDetails>;

  showDeliveryAddress$ = this._state.getDeliveryAddress$;
  collectionData$ = this._state.collectionData$;

  constructor(private _title: Title, private _state: DropoffStateService) {}

  ngOnInit() {
    this._title.setTitle('Arrange a drop-off repair | Domestic & General');
  }

  onSubmit() {
    this._state.setSubmitLoading(true);
  }

  saveAddress(form: ContactDetailsFormValues, type: ServiceCollectionData) {
    this._state.onSetCollectionData(form, type);
  }

  isSaved(valid: boolean) {
    this.isReturnAddressValid = valid;
  }
}
