import { Inject, Injectable } from '@angular/core';

import { ErrorHandlerService } from '@domgen/data-access-claims';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, catchError, take } from 'rxjs/operators';
import {
  AppConfig,
  ErrorPageContent,
  ErrorTypes,
} from '@domgen/dgx-components';
import { CONFIG } from '@domgen/dgx-fe-config';

@Injectable({
  providedIn: 'root',
})
export class ErrorGuard {
  constructor(
    @Inject(CONFIG) private readonly config: AppConfig,
    private handler: ErrorHandlerService
  ) {}

  canActivate(): Observable<boolean> {
    return this.checkClaim().pipe(
      switchMap(() => of(true)),
      catchError(() => {
        window.location.href = this.config?.plansHomePage;
        return of(false);
      })
    );
  }

  checkClaim(): Observable<boolean> {
    return this.handler.errorPageContent().pipe(
      take(1),
      switchMap((errorObject: ErrorPageContent) => {
        return errorObject?.errorType === ErrorTypes.Technical ||
          errorObject?.active
          ? of(true)
          : throwError(false);
      })
    );
  }
}
