<div
  class="printed"
  [ngClass]="{
    show: state === 'show',
    hide: state === 'hide',
    minimised: state === 'minimised'
  }"
>
  <a class="link" (click)="print($event)" data-automation="print">
    <div>
      <svg class="svg-icon icon-printer">
        <use xlink:href="#icon-printer"></use>
      </svg>
    </div>
    <span>Print</span>
  </a>
</div>
