import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  LexChatActions,
  LoadLexHistory,
  SendLexRespondError,
  SendLexRespondLoading,
  SendLexRespondLoadingSuccess,
  StartLexSessionError,
  StartLexSessionLoading,
  StartLexSessionSuccess,
} from './lex-chat.actions';
import { HistoryMessage, LexSession } from '@domgen/dgx-components';

export const componentFeatureKey = 'lex-boiler-chat';

export interface State extends EntityState<LexSession> {
  isLoading: boolean;
  history: HistoryMessage[];
  error: string | null;
  currentLexChatSession: LexSession;
  sessionId: string | null;
  errorCount: number;
}

export const adapter: EntityAdapter<LexSession> =
  createEntityAdapter<LexSession>({
    selectId: (c: LexSession) => c.sessionId as unknown as string,
  });

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  history: [],
  error: null,
  currentLexChatSession: {} as LexSession,
  sessionId: null,
  errorCount: 0,
});

export const lexChatReducer = createReducer(
  initialState,
  on(StartLexSessionLoading, (state) => {
    return { ...state, isLoading: true };
  }),

  on(StartLexSessionError, (state, { error }) => {
    return {
      ...state,
      isLoading: false,
      error: error,
      errorCount: state.errorCount + 1,
    };
  }),

  on(StartLexSessionSuccess, (state, { payload }) => {
    return adapter.upsertOne(
      {
        ...payload,
      },
      {
        ...state,
        isLoading: false,
        error: null,
        sessionId: payload.sessionId,
        currentLexChatSession: { ...payload },
      }
    );
  }),
  on(LoadLexHistory, (state) => {
    return { ...state, history: state.history };
  }),
  on(SendLexRespondLoading, (state, { payload }) => {
    const newHistoryMessage: HistoryMessage[] =
      state.currentLexChatSession.messages.map((message, index) => {
        if (index === state.currentLexChatSession.messages.length - 1) {
          return {
            ...message,
            response: payload.text,
            date: new Date(),
          };
        }
        return {
          ...message,
          date: new Date(),
        };
      });

    const updatedHistory = state.history.concat(newHistoryMessage);

    return {
      ...state,
      isLoading: true,
      error: null,
      history: [...updatedHistory],
    };
  }),

  on(SendLexRespondLoadingSuccess, (state, { payload }) => {
    return adapter.upsertOne(
      {
        ...payload,
      },
      {
        ...state,
        isLoading: false,
        error: null,
        sessionId: payload.sessionId,
        currentLexChatSession: { ...payload },
      }
    );
  }),

  on(SendLexRespondError, (state, { error }) => {
    return {
      ...state,
      isLoading: false,
      error: error,
      errorCount: state.errorCount + 1,
    };
  })
);

export function reducer(state: State | undefined, action: LexChatActions) {
  return lexChatReducer(state, action);
}
