import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { RadioGroupDefinition } from '../../interfaces/form-controls-definition.interface';
import { ValidationError } from '../../interfaces/validation-error.interface';

@UntilDestroy()
@Component({
  selector: 'dg-fb-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: [
    './radiobutton.component.scss',
    './radiobutton-light.component.scss',
  ],
})
export class RadiobuttonComponent implements AfterViewInit {
  @Input() group?: UntypedFormGroup;
  @Input() idPrefix: string | undefined = 'rb';
  @Input() name = 'rb';
  @Input() items?: RadioGroupDefinition[];
  @Input() classes?: string;
  @Input() control?: string;
  @Input() showValidation = false;
  @Input() validators?: ValidationError[];
  @Input() elementsPerPage: number | undefined = 2;
  @Input() align = 'justify--center';
  @Input() includeResetOption = false;

  @Output() resetFormValue: EventEmitter<void> = new EventEmitter<void>();

  Xpos = 0;
  step: number | undefined;
  slides: RadioGroupDefinition[][] = [];
  active = 0;
  focused: number | undefined;

  public rambOn: null | number = null; // set mousedown / touchstart (user interaction - enter)
  public rambOff: null | number = null; // set mouseup / touchend (user interaction - exit)
  public radioHint: boolean | string = false;

  constructor(public renderer: Renderer2, private cdr: ChangeDetectorRef) {
    // listen for resize and check checkIfMobile
    // debounce resize, wait for resize to finish before doing stuff - delay of 100
    this.resize$.pipe(untilDestroyed(this), debounceTime(100)).subscribe(() => {
      this.checkIfMobile(this.slider?.nativeElement);
    });
  }

  @ViewChild('page') page?: ElementRef;
  @ViewChild('slider') slider?: ElementRef;

  resize$ = new Subject<void>();

  @HostListener('window:resize')
  onWindowResize() {
    // trigger resize subscription
    this.resize$.next();
  }

  setFocus(item: number | undefined): void {
    this.focused = item;
  }

  resetSelectedValue(dateValue: string | number) {
    const groupValue = this.group?.get(this.control as string)?.value;
    if (groupValue !== dateValue || !this.includeResetOption) return;
    this.resetFormValue.emit();
  }

  /**
   * ngAfterViewInit
   * Checking if it is mobile device
   */
  ngAfterViewInit() {
    this.checkIfMobile(this.slider?.nativeElement);
    this.cdr.detectChanges();
  }

  /**
   * setActiveSlide
   * @param slide
   * Removing all previews selections
   * Getting active slide number and applying class 'active' to active slide
   */
  setActiveSlide(slide: number) {
    this.cdr.detectChanges();
    const slideBullets = this.page?.nativeElement.querySelectorAll('li');
    for (const el of slideBullets) {
      this.renderer.removeClass(el, 'active');
    }
    if (slideBullets[slide] !== null) {
      this.renderer.addClass(slideBullets[slide], 'active');
    }
  }

  /**
   * createSlider
   * Initialising Slider if mobile device detected
   * Splitting data by slides based on elementsPerPage variable
   */
  createSlider() {
    let pages =
      (this.items as RadioGroupDefinition[]).length /
      (this.elementsPerPage as number);
    if (
      (this.items as RadioGroupDefinition[]).length %
        (this.elementsPerPage as number) ===
      0
    ) {
      pages--;
    } else {
      pages = Math.floor(pages);
    }
    for (let page = 0; page <= pages; page++) {
      this.makeSlide(page);
    }
    return true;
  }

  /**
   * makeSlide
   * @param page
   * Creating slide with number of items and pushing to slides array to render
   */
  makeSlide(page: number) {
    const indexMin = page * (this.elementsPerPage as number);
    const indexMax = indexMin + (this.elementsPerPage as number);

    const slides = this.items?.filter(
      (x, index) => index >= indexMin && index < indexMax
    );

    if (slides) {
      this.slides.push(slides);
    }
  }

  /**
   * resetSlider
   * @param tag
   * Disabling and Resetting slider to desktop view
   */
  resetSlider(tag: Element) {
    this.slides.length = 0;
    this.slides[0] = this.items as RadioGroupDefinition[];
    tag.removeAttribute('style');
  }

  /**
   * checkIfMobile
   * @param tag
   * Reading 'content' css property which define mobile or desktop device type
   * Detection based on CSS media query breakpoint
   * Initialise Mobile or Desktop view
   */
  checkIfMobile(tag: Element | null): string | void {
    this.slides = [];

    if (tag !== null) {
      const style = window.getComputedStyle(tag);
      const content = style.getPropertyValue('content');
      const state = content.replace(/["']/g, ''); // removing all quotes,
      if (state === 'mobile') {
        // If items less than 1 just show one radio button in the middle

        if ((this.items as RadioGroupDefinition[]).length <= 1) {
          this.resetSlider(tag);
        } else {
          this.createSlider();
          this.setCarouselWidth();
          this.setActiveSlide(this.active);
        }
      } else {
        // Reset data to desktop mode if switched from mobile back to desktop

        this.resetSlider(tag);
      }
      return state;
    }
  }

  /**
   * setCarouselWidth
   * Setting Slider width based on number of slides calculated in percent
   * Setting step to scroll in percent
   */
  setCarouselWidth() {
    (this.slider as ElementRef).nativeElement.style.width =
      100 * this.slides.length + '%';
    this.step = 100 / this.slides.length;
  }

  /**
   * unify the touch and click cases - conditional returns different object
   * @param event
   */
  public unify(event: any) {
    return event.changedTouches ? event.changedTouches[0] : event;
  }

  /**
   * lock
   * @param event
   */
  public lock(event: MouseEvent | TouchEvent) {
    this.rambOn = this.unify(event).clientX;
  }

  /**
   * updates the count conditionally on the value of 'step'
   * 1. check to see if lock (user interaction has been fired)
   * 2. conditionally set direction to 1 (Left) or -1 (Right) depending on direction of swipe / interaction
   * 3. if there is an item to switch to, run handleSelectDate
   * @param event
   */
  public move(event: any) {
    // 1.
    if (this.rambOn || this.rambOn === 0) {
      // 2.
      this.rambOff = this.unify(event).clientX - this.rambOn;

      const tolerance = 30;
      const swipe = !!(Math.abs(this.rambOff) > tolerance);
      const direction = Math.sign(this.rambOff);

      if (swipe) {
        if (direction < 0) {
          // 3. Scroll Left
          this.active++;
          if (this.active >= this.slides.length) {
            this.active = this.slides.length - 1;
          }
          this.Xpos = direction * ((this.step as number) * this.active);
          this.setActiveSlide(this.active);
          this.renderer.setStyle(
            this.slider?.nativeElement,
            'transform',
            `translate3d(${this.Xpos}%, 0, 0)`
          );
        }
        if (direction > 0) {
          // 3. Scroll Right
          this.active--;
          if (this.active <= 0) {
            this.active = 0;
          }
          this.Xpos = -1 * (this.step as number) * this.active;
          this.setActiveSlide(this.active);
          this.renderer.setStyle(
            this.slider?.nativeElement,
            'transform',
            `translate3d(${this.Xpos}%, 0, 0)`
          );
        }
      }

      this.rambOn = null;
    }
  }
}
