import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'dg-information-notice',
  templateUrl: './information-notice.component.html',
  styleUrls: ['./information-notice.component.scss'],
})
export class InformationNoticeComponent implements OnInit {
  @Input() title = 'title';
  @Input() description = '';
  @Input() icon = 'arrow-right';
  @Input() theme = '';
  @Input() cta = '';
  @Output() changeCTA = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
