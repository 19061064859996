import { Component, Input } from '@angular/core';

@Component({
  selector: 'smart-fix-chatbot-disclaimer-option',
  templateUrl: './chatbot-disclaimer-option.component.html',
  styleUrls: ['./chatbot-disclaimer-option.component.scss'],
})
export class ChatbotDisclaimerOptionComponent {
  @Input() description?: string;
  @Input() closed = false;
  @Input() icon?: string;
}
