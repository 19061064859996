export interface CalendarDay {
  date: Date;
  slotsAvailable?: boolean;
  slots?: CalendarSlot[];
  newMonth?: boolean;
  text?: string;
  selected?: boolean | null;
}

export interface CalendarSlot {
  identifier: string | null;
  slotType: string;
  startTime: string;
  endTime: string;
  available: boolean;
}
