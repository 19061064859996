import { slideInOut } from './../../animations/animations';
import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

export enum ToggleState {
  open = 'open',
  closed = 'closed',
}
@Component({
  selector: 'dg-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInOut],
})
export class AccordionComponent {
  /**
   * Emits toggled event when accordion is clicked
   */
  @Output() toggled: EventEmitter<string> = new EventEmitter();

  /**
   * Tracks accordion open/closed state
   */
  @Input() isOpen = false;

  /**
   * icon state
   */
  @Input() icon = 'icon-arrow-down';

  /**
   * Triggered when the user clicks the accordion header
   */
  onToggleClick() {
    this.isOpen = !this.isOpen;
    this.toggled.emit(this.isOpen ? ToggleState.open : ToggleState.closed);
  }
}
