import { LexSession } from '@domgen/dgx-components';
import { createAction, props } from '@ngrx/store';

export type LexRespond = {
  sessionId: string;
  text: string;
};

export enum LexChatActionTypes {
  StartLexSession = '[Lex-Smart-fix] StartLexSession',
  StartLexSessionLoading = '[Lex-Smart-fix] StartLexSession',
  StartLexSessionError = '[Lex-Smart-fix] StartLexSessionError',
}

export const StartLexSessionLoading = createAction(
  '[Lex-Smart-fix] StartLexSessionLoading',
  props<{ payload: string }>()
);

export const StartLexSessionSuccess = createAction(
  '[Lex-Smart-fix] StartLexSession',
  props<{ payload: LexSession }>()
);

export const StartLexSessionError = createAction(
  '[Lex-Smart-fix] StartLexSessionError',
  props<{ error: string | null }>()
);

export const LoadLexHistory = createAction(
  '[Lex-Smart-fix] Load History',
  props<{ history: Array<LexSession> }>()
);

export const UpdateLexHistory = createAction(
  '[Smart-fix] Update History',
  props<{ payload: LexSession }>()
);

export const SendLexRespondLoading = createAction(
  '[Lex-Smart-fix] SendLex',
  props<{ payload: LexRespond }>()
);

export const SendLexRespondLoadingSuccess = createAction(
  '[Lex-Smart-fix] SendLexRespondLoadingSuccess',
  props<{ payload: LexSession }>()
);

export const SendLexRespondError = createAction(
  '[Lex-Smart-fix] SendLexSessionError',
  props<{ error: string | null }>()
);

export type LexChatActions =
  | typeof StartLexSessionSuccess
  | typeof StartLexSessionLoading
  | typeof LoadLexHistory
  | typeof UpdateLexHistory
  | typeof StartLexSessionError
  | typeof SendLexRespondLoading
  | typeof SendLexRespondLoadingSuccess
  | typeof SendLexRespondError;
