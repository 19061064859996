import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

/**
 * This component displays a "Did you know" banner" that allows user to trigger the chat-bot.
 *
 * @example
 * <smart-fix-pre-chat-banner-boiler
 *  (openChatbot)="callback1()"
 *  (continueBooking)="callback2()"
 *  (bannerVisible)="callback3()"
 * ></smart-fix-pre-chat-banner-boiler>
 */
@Component({
  selector: 'smart-fix-pre-chat-banner-boiler',
  templateUrl: './pre-chat-banner.component.html',
  styleUrls: ['./pre-chat-banner.component.scss'],
})
export class PreChatBannerBoilerComponent implements OnInit {
  @Output() openChatbot = new EventEmitter<void>();
  @Output() continueBooking = new EventEmitter<void>();
  @Output() bannerVisible = new EventEmitter<void>();

  ngOnInit(): void {
    this.bannerVisible.emit();
  }

  handleOpenChatbot() {
    this.openChatbot.emit();
  }

  handleContinueBooking() {
    this.continueBooking.emit();
  }
}
