<dgx-components-panel rounded="md">
  <p class="typog-h4 mb-1">
    <dgx-components-icon
      name="fa-light fa-lightbulb-on"
      size="md"
      class="mr-2"
    ></dgx-components-icon>
    Did you know?
  </p>
  <p class="typog-body-large mb-1">
    When boilers stop working, it’s often down to issues like low or high
    pressure.
  </p>
  <p class="typog-body-large mb-1">
    You can try some quick fixes yourself to get these problems sorted.
  </p>
  <p class="typog-body-large mb-4">
    And you could get your boiler back up and running straight away.
  </p>
  <p class="typog-caption-regular mb-3">
    <strong class="typog-caption-bold mr-3">Want to give it a go?</strong>
    <span class="inline-flex mt-2"
      ><dgx-components-icon
        name="timer"
        size="sm"
        class="mr-1"
      ></dgx-components-icon
      >Only takes a few mins</span
    >
  </p>
  <div class="button-group">
    <dgx-components-button
      [fill]="true"
      data-testid="yes-button"
      (click)="handleOpenChatbot()"
      >Yes</dgx-components-button
    >
    <dgx-components-button
      [fill]="true"
      (click)="handleContinueBooking()"
      data-testid="no-button"
      variant="secondary"
      >No, continue booking</dgx-components-button
    >
  </div>
</dgx-components-panel>
