import { Config } from '@domgen/dgx-fe-config';
import { AppEnvironment } from '../environment/app-environment.type';
import { AppRuntimeConfig } from '../runtime/app-runtime-config.interface';
import { AppDomainConfig } from '../domain/app-domain-config.interface';

export type AppConfig = Config<
  AppEnvironment,
  AppRuntimeConfig,
  AppDomainConfig
>;
