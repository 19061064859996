<div
  class="history mb-5"
  *ngFor="let lexMessage of lexHistory | async"
  data-testid="history-item"
>
  <div>
    <ng-container [ngSwitch]="lexMessage.contentType">
      <ng-container *ngSwitchCase="'PlainText'">
        <smart-fix-lex-plain-text [plainTextDescription]="lexMessage.content" />
        <div class="mb-5">
          <smart-fix-human-readable-date
            *ngIf="lexMessage.imageResponseCard?.subtitle"
            [date]="lexMessage.date"
            [alignLeft]="false"
            data-testid="date"
          />
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'CustomPayload'">
        <smart-fix-lex-custom-payload [content]="lexMessage.content" />
        <div class="mb-5">
          <smart-fix-human-readable-date
            *ngIf="lexMessage"
            [date]="lexMessage.date"
            [alignLeft]="false"
            data-testid="date"
          />
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'ImageResponseCard'">
        <ng-container
          *ngIf="lexMessage.imageResponseCard?.imageUrl; else plainMessage"
        >
          <ng-container
            *ngIf="
              isYoutubeUrl(lexMessage.imageResponseCard?.imageUrl);
              else imageCard
            "
          >
            <div>
              <smart-fix-video-player
                [videoUrl]="lexMessage.imageResponseCard?.imageUrl"
                [description]="lexMessage.imageResponseCard?.title"
                data-testid="embedded-video"
              />
            </div>
            <div class="mb-5">
              <smart-fix-human-readable-date
                [date]="lexMessage.date"
                [alignLeft]="false"
                data-testid="date"
              />
            </div>
          </ng-container>

          <ng-template #imageCard>
            <div>
              <smart-fix-lex-response-card
                [title]="lexMessage.imageResponseCard?.title"
                [imageUrl]="lexMessage.imageResponseCard?.imageUrl"
              ></smart-fix-lex-response-card>
            </div>
            <div class="mb-5">
              <smart-fix-human-readable-date
                [date]="lexMessage.date"
                [alignLeft]="false"
                data-testid="date"
              />
            </div>
          </ng-template>
        </ng-container>
        <ng-template #plainMessage>
          <smart-fix-lex-plain-text
            [plainTextDescription]="lexMessage.imageResponseCard?.title"
          />
          <smart-fix-lex-plain-text
            [plainTextDescription]="lexMessage.imageResponseCard?.subtitle"
          />
          <div class="mb-5">
            <smart-fix-human-readable-date
              [date]="lexMessage.date"
              [alignLeft]="false"
              data-testid="date"
            />
          </div>
        </ng-template>
        <smart-fix-lex-response [lexResponse]="lexMessage.response" />
        <div class="mb-5">
          <smart-fix-human-readable-date
            *ngIf="lexMessage"
            [date]="lexMessage.date"
            [alignLeft]="true"
            data-testid="date"
          />
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
