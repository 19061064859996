import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatContainerComponent } from './components/chat-container/chat-container.component';
import { DgxComponentsButtonModule } from '@domgen/dgx-fe-components/button';
import { DgxComponentsCardModule } from '@domgen/dgx-fe-components/card';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { ChatHistoryComponent } from './components/chat-history/chat-history.component';
import { SmartFixDataAccessModule } from '@domgen/smart-fix/data-access-smart-fix';
import { ChatbotHeaderComponent } from './components/chatbot-header/chatbot-header.component';
import { BoilerChatContainerComponent } from './components/lex-chat/boiler-chat-container/boiler-chat-container.component';
import { ChatbotResponseComponent } from './components/chatbot-response/chatbot-response.component';
import { ChatbotDisclaimerComponent } from './components/chatbot-disclaimer/chatbot-disclaimer.component';
import { ChatbotDisclaimerOptionComponent } from './components/chatbot-disclaimer-option/chatbot-disclaimer-option.component';
import { DgxComponentsIconModule } from '@domgen/dgx-fe-components/icon';
import {
  FormBuilderModule,
  SharedComponentsUiModule,
} from '@domgen/dgx-components';
import { ChatbotWelcomeMessageComponent } from './components/chatbot-welcome-message/chatbot-welcome-message.component';
import { HumanReadableDateComponent } from './components/human-readable-date/human-readable-date.component';
import { PreChatBannerComponent } from './components/pre-chat-banner/pre-chat-banner.component';
import { DgxComponentsGeneralModule } from '@domgen/dgx-fe-components/general';
import { DgxComponentsPanelModule } from '@domgen/dgx-fe-components/panel';
import { CustomDatePipe } from './pipes/custom-date/custom-date.pipe';
import { ExitDialogComponentComponent } from './exit-dialog-component/exit-dialog-component.component';
import { DateAgoPipe } from './pipes/date-ago/date-ago.pipe';
import { SmartFixAnalyticsService } from './services/smart-fix-analytics/smart-fix-analytics.service';
import { LexCustomPayloadComponent } from './components/lex-chat/lex-custom-payload/lex-custom-payload.component';
import { LexHistoryComponent } from './components/lex-chat/lex-history/lex-history.component';
import { LexPlainTextComponent } from './components/lex-chat/lex-plain-text/lex-plain-text.component';
import { LexResponseCardComponent } from './components/lex-chat/lex-response-card/lex-response-card.component';
import { LexResponseComponent } from './components/lex-chat/lex-response/lex-response.component';
import { ErrorDialogComponent } from './components/lex-chat/error-dialog/error-dialog.component';
import { ErrorFallbackComponent } from './components/lex-chat/error-fallback/error-fallback.component';
import { PreChatBannerBoilerComponent } from './components/pre-chat-banner-boiler/pre-chat-banner.component';
import { BedrockChatContainerComponent } from './components/bedrock-chat/bedrock-chat-container/bedrock-chat-container.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DgxFormsInputModule } from '@domgen/dgx-fe-forms/input';
import { BedrockChatHeaderComponent } from './components/bedrock-chat/bedrock-chat-header/bedrock-chat-header.component';
import { BedrockChatWelcomeMessageComponent } from './components/bedrock-chat/bedrock-chat-welcome-message/bedrock-chat-welcome-message.component';
import { ModelResponseCharacterTypingComponent } from './components/bedrock-chat/model-response-character-typing/model-response-character-typing.component';
import { MarkdownModule } from 'ngx-markdown';
import { VideoPreviewComponent } from './components/video-preview/video-preview.component';
import { InstructionDisplayComponentComponent } from './components/bedrock-chat/instruction-display-component/instruction-display-component.component';
import { BedrockFeedbackComponent } from './components/bedrock-chat/bedrock-feedback/bedrock-feedback.component';
import { BedrockFeedbackFormComponent } from './components/bedrock-chat/bedrock-feedback-form/bedrock-feedback-form.component';
@NgModule({
  imports: [
    CommonModule,
    DgxComponentsButtonModule,
    DgxComponentsIconModule,
    DgxComponentsCardModule,
    SmartFixDataAccessModule,
    FormBuilderModule,
    FormsModule,
    SharedComponentsUiModule,
    DgxComponentsCardModule,
    DgxComponentsGeneralModule,
    DgxComponentsPanelModule,
    ReactiveFormsModule,
    DgxFormsInputModule,
    MarkdownModule.forRoot(),
  ],
  declarations: [
    ChatContainerComponent,
    VideoPlayerComponent,
    VideoPreviewComponent,
    ChatHistoryComponent,
    ChatbotHeaderComponent,
    InstructionDisplayComponentComponent,
    ChatbotResponseComponent,
    ChatbotDisclaimerComponent,
    ChatbotDisclaimerOptionComponent,
    ChatbotWelcomeMessageComponent,
    HumanReadableDateComponent,
    BoilerChatContainerComponent,
    LexHistoryComponent,
    LexPlainTextComponent,
    LexResponseCardComponent,
    LexResponseComponent,
    LexCustomPayloadComponent,
    PreChatBannerComponent,
    PreChatBannerBoilerComponent,
    CustomDatePipe,
    ExitDialogComponentComponent,
    DateAgoPipe,
    ErrorDialogComponent,
    ErrorFallbackComponent,
    BedrockChatContainerComponent,
    BedrockChatHeaderComponent,
    BedrockChatWelcomeMessageComponent,
    BedrockFeedbackComponent,
    BedrockFeedbackFormComponent,
    ModelResponseCharacterTypingComponent,
  ],
  providers: [SmartFixAnalyticsService],
  exports: [
    ChatContainerComponent,
    PreChatBannerComponent,
    PreChatBannerBoilerComponent,
    BoilerChatContainerComponent,
    BedrockChatContainerComponent,
    InstructionDisplayComponentComponent,
  ],
})
export class SmartFixFeatureChatModule {}
