import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface ColumnsItem {
  description: string;
  heading: string;
  iconName: string;
  iconColor: string;
}

@Component({
  selector: 'dg-columns-banner',
  templateUrl: './columns-banner.component.html',
  styleUrls: ['./columns-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnsBannerComponent {
  @Input() heading?: string;
  @Input() items?: ColumnsItem[] = undefined;
}
