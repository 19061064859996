import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { CustomWindow } from '../../interfaces/custom-window.interface';

interface Link {
  link: string;
  url: string;
}

declare let window: CustomWindow;

@Component({
  selector: 'dg-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() primaryLinks: Link[] = [
    {
      link: 'About us',
      url: 'https://www.domesticandgeneral.com/corporate/about-us',
    },
    {
      link: 'Help and advice',
      url: 'https://www.domesticandgeneral.com/content/help-advice-section/faq',
    },
    {
      link: 'Contact us',
      url: 'https://www.domesticandgeneral.com/content/contact-domestic-general',
    },
  ];

  @Input() secondaryLinks: Link[] = [
    {
      link: 'Website Terms and Conditions',
      url: 'https://www.domesticandgeneral.com/web-terms-conditions',
    },
    {
      link: 'Privacy and Cookies policy',
      url: 'https://www.domesticandgeneral.com/privacy-cookies-policy',
    },
    {
      link: 'Accessibility policy',
      url: 'https://www.domesticandgeneral.com/accessibility',
    },
  ];

  @ViewChild('trustPiliot', { static: true }) trustPiliot?: ElementRef;

  public date = Date.now();

  constructor() {}

  ngOnInit() {
    if (window['Trustpilot']) {
      window.Trustpilot.loadFromElement(this.trustPiliot?.nativeElement);
    }
  }
}
