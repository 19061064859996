import { Inject, Injectable } from '@angular/core';
import { CONFIG } from '@domgen/dgx-fe-config';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { AppConfig } from '../models/config/app-config.type';
import posthog from 'posthog-js';

export type FeatureFlagKey = 'whp-confirmation-banner' | 'MMR_IN_CLAIMS';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private featureFlagsSubject = new BehaviorSubject<any>(null);

  constructor(
    @Inject(CONFIG) private readonly config: AppConfig,
    private readonly http: HttpClient
  ) {
    this.loadFeatureFlags();
  }

  loadFeatureFlags(): void {
    this.getPostHog()
      .pipe(
        catchError((err) => {
          console.error('Error fetching feature flags', err);
          return of(null);
        })
      )
      .subscribe((flags) => {
        this.featureFlagsSubject.next(flags?.featureFlags || {});
      });
  }

  getPostHog(): Observable<any> {
    const url = this.config.POSTHOG_URL;
    const body = {
      api_key: this.config.POSTHOG_KEY,
      distinct_id: this.config.POSTHOG_ACCOUNT,
    };

    return this.http.post<any>(url, body);
  }

  isFeatureFlagEnabled(featureFlagKey: FeatureFlagKey): Observable<boolean> {
    return this.featureFlagsSubject.asObservable().pipe(
      filter((flag) => !!flag),
      map((flags) => flags?.[featureFlagKey] ?? false)
    );
  }
}
