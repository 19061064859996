import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'smart-fix-model-response-character-typing',
  templateUrl: './model-response-character-typing.component.html',
  styleUrls: [
    './model-response-character-typing.component.scss',
    '../bedrock-markdown.scss',
  ],
  animations: [],
})
export class ModelResponseCharacterTypingComponent implements OnChanges {
  @Input() content: string = '';
  @Input() isLoading: boolean = false;
  blocks: string[] = [];
  reconstitutedText: string = '';
  visible: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['content']) {
      this.typeContent(changes.content.currentValue);
    }
  }

  splitByWord(text: string): string[] {
    return text.split(' ');
  }

  showPreview = false;

  typeContent(content: string) {
    let BlocksIndex = 0;

    const allBlocks = this.splitByWord(content);

    const typingInterval = setInterval(() => {
      if (BlocksIndex < allBlocks.length) {
        this.blocks.push(allBlocks[BlocksIndex]);
        if (this.blocks.length > 1) {
          this.reconstitutedText += ' ' + this.blocks.shift();
        }
        BlocksIndex++;
      } else {
        if (this.blocks.length === 1) {
          this.reconstitutedText += ' ' + this.blocks.shift();
          this.showPreview = true;
        }
        clearInterval(typingInterval);
      }
    }, 50);
  }
}
