import { Component, Input } from '@angular/core';

@Component({
  selector: 'smart-fix-instruction-display-component',
  templateUrl: './instruction-display-component.component.html',
  styleUrls: ['./instruction-display-component.component.scss'],
})
export class InstructionDisplayComponentComponent {
  @Input() description!: string;
  @Input() icon?: string;
}
