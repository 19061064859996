<dg-booking-calendar-confirmation
  *ngIf="showConfirmationModal"
  [day]="day"
  [slotSelected]="slotSelected"
  [slotType]="slotTypes.AMPM"
  [currentAppointment]="currentAppointment"
  [isRebooking]="isRebook"
  [gaData]="gaData"
  (confirmBooking)="handleBookingConfirmation($event)"
></dg-booking-calendar-confirmation>

<form *ngIf="form" [formGroup]="form" (change)="selectSlot()">
  <h5 class="typog-h5 mb-3">Time slots available</h5>
  <div class="slots">
    <div
      *ngFor="
        let option of formData?.groups[0].radios?.radioGroup;
        let index = index
      "
      class="slot-holder"
    >
      <p>{{ option.slotType === 'AM' ? 'Morning' : 'Afternoon' }}</p>

      <label *ngIf="formData?.groups[0] as group" [for]="option.value">
        <input
          type="radio"
          name="slotIdentifier"
          [formControlName]="group.radios?.control"
          [value]="option.value"
          [id]="option.value"
        />
        <p>
          {{ option.text }}
        </p>
        <button
          *ngIf="form.value?.slotIdentifier === option.value"
          (click)="clearSlot($event)"
        >
          <dgx-components-icon name="arrow-rotate-left" size="md">
          </dgx-components-icon>
        </button>
      </label>
    </div>
  </div>
</form>

<section class="engineer-arrival mt-6 mb-8 flex flex-column">
  <p class="label typog-body-bold mb-1">When will the engineer come?</p>
  <p class="typog-body">
    They'll contact you with their arrival time as soon as possible.
  </p>
</section>

<div class="slot-confirm" [@fadeInAnimation]>
  <dgx-components-button
    (clicked)="showConfirmationModal = true"
    data-automation="'ConfirmAD'"
    [disabled]="form?.value?.slotIdentifier === null"
    [fill]="true"
    type="submit"
    variant="primary"
  >
    Book
  </dgx-components-button>

  <dgx-components-button
    class="mt-3"
    (clicked)="seeOtherDates()"
    data-automation="'SeeOtherDates'"
    [fill]="true"
    variant="secondary"
  >
    See other dates
  </dgx-components-button>
</div>
