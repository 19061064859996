import { Component, Input } from '@angular/core';

@Component({
  selector: 'claims-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss'],
})
export class SectionTitleComponent {
  @Input() active = false;
  @Input() completed = false;
  @Input() number = '';
  @Input() title = '';
}
