import { Component, Input } from '@angular/core';

@Component({
  selector: 'dg-info-message',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.scss'],
})
export class InfoMessageComponent {
  @Input() lightTheme = false;
}
