import { Inject, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  AnalyticsService,
  Api,
  AppConfig,
  Claim,
  ClaimBundleType,
  ClaimStage,
  FormControlsDefinition,
  FormDataSchema,
  FormsService,
  PartialUpdateClaimPayload,
} from '@domgen/dgx-components';
import { flatMap, first } from 'lodash-es';
import { RepairDetailsService } from '../../../services/repair-details.service';
import { SelectionSummary } from './claim-fault-type.store';
import { CONFIG } from '@domgen/dgx-fe-config';

export enum FormTypes {
  CLAIMTYPEFORM = 'claimType',
  FAULTAREAFORM = 'faultArea',
  FAULTTYPEFORM = 'faultType',
}

@Injectable({
  providedIn: 'root',
})
export class ClaimFaultTypeFormHelperService {
  constructor(
    @Inject(CONFIG) private readonly config: AppConfig,
    public _service: RepairDetailsService,
    private _fs: FormsService,
    private analyticsService: AnalyticsService
  ) {}

  useFallbackSelection = false;

  flagFallback(): void {
    this.useFallbackSelection = true;
  }

  createForm(formData: FormControlsDefinition[]) {
    return new UntypedFormGroup(this._fs.defineformControls(formData, {}));
  }

  getDefaults(claim: Claim): FormDataSchema['meta'] {
    return this._claimTypeRequired(claim)
      ? this.getClaimTypeData(claim)
      : this.getFaultAreaTypeData(claim);
  }

  isRepairWithService(claim: Claim) {
    const hasASVType = claim?.getData?.ClaimTypes.find(
      (c: Api.ClaimType) => c.ClaimTypeCode === 'ASV'
    );
    const isRepairClaim = claim.reflect.claimType === ClaimBundleType.REPAIR;
    const ASVClaimNotOffered = claim.reflect.asvOffered === 'false';
    return isRepairClaim && ASVClaimNotOffered && hasASVType;
  }

  getClaimTypeData(claim: Claim) {
    const isRepairWithService = this.isRepairWithService(claim);

    return {
      header: isRepairWithService
        ? 'What do you need to book?'
        : 'Firstly, what caused the fault?',
      description: isRepairWithService
        ? 'Whether you need a repair or an annual service, we’re here to help.'
        : 'Whether it was a breakdown or an accident, we’ll help you get it fixed.',
      groups: [
        {
          radios: {
            control: FormTypes.CLAIMTYPEFORM,
            elementsPerPage: 4,
            classes: 'flex radio--button radio--with-icon',
            validators: [],
            radioGroup: this.filterClaimTypes(claim).map((t: Api.ClaimType) => {
              return {
                value: t.ClaimTypeID,
                ...this.getTextIcon(
                  t.ClaimTypeName,
                  t.ClaimTypeCode,
                  this._service.hasASVrepairClaim(claim)
                ),
                svgClasses: 'svg-new',
                classes: 'xs-12 sm-4 md-4',
                hiddenAccessibilityText: '',
              };
            }),
          },
        },
      ],
    };
  }

  filterClaimTypes(claim: Claim) {
    return claim.getData.ClaimTypes.filter((c: Api.ClaimType) =>
      ['BD', 'AD', 'ASV', 'BDBOCO', 'BDBOSY'].includes(c.ClaimTypeCode)
    );
  }

  getTextIcon(
    claimTypeName: string,
    claimTypeCode: string,
    asv = false
  ): { text: string; icon: string } {
    switch (claimTypeCode) {
      case 'BD':
      case 'BDBOSY':
      case 'BDBOCO':
        return {
          text: asv ? 'Book a repair' : 'It stopped working by itself',
          icon: asv ? 'repair' : 'multiproducts',
        };

      case 'AD':
        return {
          text: asv ? 'Book a repair' : 'It got damaged by accident',
          icon: asv ? 'repair' : 'accidental-damage',
        };
      case 'ASV':
        return {
          text: asv ? 'Book a service' : 'Annual Service',
          icon: asv ? 'diagnosis' : 'accidental-damage',
        };

      default:
        return {
          text: claimTypeName,
          icon: 'glass',
        };
    }
  }

  isSingleCatagoryFault(faults: Api.FaultData | Api.FaultData[]): {
    faultTypeQuestions: Api.FaultData[];
    singleFaultAvailable: boolean;
  } {
    let faultTypeQuestions = faults;
    let singleFaultAvailable = false;

    if (!Array.isArray(faultTypeQuestions)) {
      faultTypeQuestions = [faults as Api.FaultData];
      singleFaultAvailable = true;
    } else if ((faultTypeQuestions as Api.FaultData[]).length === 1) {
      singleFaultAvailable = true;
    }

    return {
      faultTypeQuestions,
      singleFaultAvailable,
    };
  }

  isUsingFreeTextSearch(claim: Claim): boolean {
    const hasProductGroupCode =
      claim.getData.OEMProductGroupCode !== '' &&
      !!claim.getData.OEMProductGroupCode;

    if (!hasProductGroupCode) {
      this.analyticsService.trigger('FTS.NoProductLineId', {
        planNumber: claim.getData.PlanNumber,
        productType: claim.getData.ProductType,
        modelNumber: claim.getData.ModelNumber,
      });
    }

    return (
      this.config.runtimeFeatures.freeTextSearch &&
      claim.getData.BookingOEM === 'Whirlpool' &&
      !this.useFallbackSelection &&
      hasProductGroupCode
    );
  }

  getFaultAreaTypeData(claim: Claim): FormDataSchema['meta'] {
    const { faultTypeQuestions, singleFaultAvailable } =
      this.isSingleCatagoryFault(claim.getData.FaultData);

    const hiddenClaimTypes = this.isClaimTypeHidden(claim);

    const freeTextSearch = {
      header: "Now, tell us what's wrong",
      description: undefined,
    };

    const oldFlowText = {
      header: hiddenClaimTypes
        ? 'Firstly, say which part is broken.'
        : 'Now, say which part is broken.',
      description: `<p>If you can’t see the exact part here, don’t worry. Choose the closest thing and write in the <strong>comments box</strong> further on.</p>`,
    };

    const useFreeTextSearch = this.isUsingFreeTextSearch(claim);
    const chosenFaultData = useFreeTextSearch ? freeTextSearch : oldFlowText;

    const faultData = {
      ...chosenFaultData,
      freeTextSearch: useFreeTextSearch,
      groups: [
        {
          radios: {
            control: FormTypes.FAULTAREAFORM,
            elementsPerPage: 4,
            classes: 'flex radio--button radio--with-text-box',
            validators: [
              {
                type: 'required',
                message: 'Please select option',
              },
            ],
            radioGroup: (faultTypeQuestions as Api.FaultData[]).map((q) => {
              return {
                value: q.FaultCategoryID,
                text: `<span class="typog-body-bold">${q.FaultCategoryLabel}</span>`,
                classes: 'xs-12 md-4',
                hiddenAccessibilityText: '',
              };
            }),
          },
        },
      ],
    };

    const singleFaultCat =
      faultData.groups.length === 1 &&
      singleFaultAvailable &&
      !useFreeTextSearch;

    return singleFaultCat
      ? this.getFaultTypeDataSingleFault(faultData, faultTypeQuestions, claim)
      : faultData;
  }

  isClaimTypeHidden(claim: Claim) {
    const { claimTypeOptions } =
      this._service.initSectionSchemaAndOptions(claim);
    const hiddenClaimTypes =
      (claimTypeOptions as Api.ClaimType[]).length <= 1 &&
      !claim?.claimSelection?.request?.ClaimTypeID;

    return hiddenClaimTypes;
  }

  getFaultTypeDataSingleFault(
    faultData: FormDataSchema['meta'],
    faultTypeQuestions: Api.FaultData[],
    claim: Claim
  ) {
    const radio = first(
      flatMap(faultData.groups, (groups) => first(groups.radios?.radioGroup))
    );

    const faultAnswer = faultTypeQuestions.find(
      (f) => Number(f.FaultCategoryID) === Number(radio.value)
    ) as Api.FaultData;

    const hiddenClaimTypes = this.isClaimTypeHidden(claim);

    return this.getFaultTypeData(faultAnswer, true, hiddenClaimTypes);
  }

  getHeaderCopy(isSingleFault, hiddenClaimTypes) {
    if (hiddenClaimTypes && !isSingleFault) {
      return 'Now, tell us what’s gone wrong.';
    }

    if (!hiddenClaimTypes && !isSingleFault) {
      return 'Lastly, tell us what’s gone wrong.';
    }

    if (isSingleFault && hiddenClaimTypes) {
      return 'Firstly, tell us what’s gone wrong.';
    }

    if (!hiddenClaimTypes && isSingleFault) {
      return 'Now, tell us what’s gone wrong.';
    }
  }

  getFaultTypeData(
    faultAnswer: Api.FaultData,
    isSingleFault = false,
    hiddenClaimTypes = false
  ): FormDataSchema['meta'] {
    return {
      header: this.getHeaderCopy(isSingleFault, hiddenClaimTypes),
      description:
        '<p>If your appliance has multiple faults, select the main one. You can always give more detail in the <strong>comments box</strong> later on.</p>',
      groups: [
        {
          radios: {
            control: FormTypes.FAULTTYPEFORM,
            elementsPerPage: 4,
            classes: 'flex radio--button radio--with-text-box',
            validators: [
              {
                type: 'required',
                message: 'Please select option',
              },
            ],
            radioGroup: (faultAnswer?.PossibleAnswers as Api.PossibleAnswer[])
              .filter(
                (t) => !t?.FaultName.toLowerCase()?.includes('annual service')
              )
              .map((t) => {
                const isGas = t.FaultLabel?.toLowerCase()?.includes('gas leak');

                return {
                  value: t.FaultID,
                  text: `<span class="typog-body-bold">${t.FaultLabel}</span>`,
                  icon: `${isGas ? 'alerts' : ''}`,
                  classes: `xs-12 md-4 ${isGas ? 'radio--warning' : ''}`,
                  hiddenAccessibilityText: '',
                };
              }),
          },
        },
      ],
    };
  }

  getFormFaultDefaults(claim: Claim) {
    let claimTypeData;
    const { claimTypeOptions } =
      this._service.initSectionSchemaAndOptions(claim);

    if ((claimTypeOptions as Api.ClaimType[]).length <= 1) {
      if (!claim?.claimSelection?.request?.ClaimTypeID) {
        // bypass claim type and ffwd to fault type
        const isSingleFaultCatagory = !Array.isArray(claim.getData.FaultData);
        claimTypeData = isSingleFaultCatagory
          ? this.getFaultTypeData(
              claim.getData.FaultData as Api.FaultData,
              isSingleFaultCatagory,
              true
            )
          : this.getFaultAreaTypeData(claim);
      }
    } else {
      claimTypeData = this.getDefaults(claim);
    }

    return claimTypeData;
  }

  getClaimTypeIDForSubmit(
    selectionSummary: SelectionSummary[],
    claim: Claim
  ): string {
    const { claimTypeOptions } =
      this._service.initSectionSchemaAndOptions(claim);
    const claimTypeID =
      selectionSummary.find((s) => s?.formName === 'claimType')?.id ||
      claim.claimSelection?.request?.ClaimTypeID ||
      (claimTypeOptions.length === 1 ? claimTypeOptions[0].ClaimTypeID : null);

    if (!claimTypeID) {
      throw new Error(
        'ClaimTypeID not found on form, state selection, or single item in list.'
      );
    }
    return claimTypeID;
  }

  getPayload(
    claim: Claim,
    selectionSummary: SelectionSummary[]
  ): PartialUpdateClaimPayload {
    const areaExists = selectionSummary.find(
      (s) => s.formName === FormTypes.FAULTAREAFORM
    );
    let faultArea;

    if (areaExists) {
      faultArea = areaExists.id;
    } else {
      faultArea = Array.isArray(claim.getData.FaultData)
        ? claim.getData.FaultData[0]?.FaultCategoryID
        : claim.getData.FaultData?.FaultCategoryID;
    }

    const faultType = selectionSummary.find(
      (s) => s.formName === FormTypes.FAULTTYPEFORM
    );

    return {
      request: {
        ClaimTypeID: this.getClaimTypeIDForSubmit(
          selectionSummary,
          claim as Claim
        ),
        FaultCategoryID: faultArea?.id || faultArea,
        FaultID: faultType.id,
      },
      selectionState: {
        claimStage: ClaimStage.CreateClaim,
        faultType: {
          id: faultArea?.id || faultArea,
          description: faultArea?.description || '',
        },
        faultTypeAnswer: {
          id: faultType?.id,
          description: faultType?.description,
        },
      },
    };
  }

  private _claimTypeRequired(claim: Claim): boolean {
    return claim?.claimSelection?.request?.ClaimTypeID === undefined;
  }
}
