import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { BookingEffects } from './+state/booking.effects';
import { ClaimEffects } from './+state/claim.effects';
import { localStorageSync } from 'ngrx-store-localstorage';
import { ROOT_REDUCERS } from './+state';
import {
  BookingAnalyticsService,
  BookingMapService,
} from '@domgen/dgx-components';
import { ApiService } from './services/api.service';
import { CallbackEffects } from './+state/callback.effects';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({ keys: [{ claim: ['entities'] }], rehydrate: true })(
    reducer
  );
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      /** TODO: enable strictActionTypeUniqueness after DGX-2601
       * runtimeChecks: {
       *  // strictStateImmutability and strictActionImmutability are enabled by default
       *  strictActionTypeUniqueness: true
       * }
       */
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: false,
      },
    }),
    EffectsModule.forRoot([ClaimEffects, BookingEffects, CallbackEffects]),
  ],
  providers: [BookingMapService, ApiService, BookingAnalyticsService],
})
export class ClaimsDataAccessClaimsModule {}
