import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { format } from 'date-fns';
import set from 'date-fns/set';
import { fadeInAnimation } from '../../../animations/animations';
import { Api, CalendarDay, CalendarSlot } from '../../../interfaces';
import {
  FormDataSchema,
  RadioGroupDefinition,
} from '../../../modules/formbuilder/interfaces/form-controls-definition.interface';
import { FormsService } from '../../../modules/formbuilder/services/forms.service';
import { SlotType } from '../booking-calendar-day/booking-calendar-day.component';

@Component({
  selector: 'dg-booking-calendar-day-ampm-slot',
  templateUrl: './booking-calendar-day-ampm-slot.component.html',
  styleUrls: ['./booking-calendar-day-ampm-slot.component.scss'],
  animations: [fadeInAnimation],
})
export class BookingCalendarDayAMPMSlotComponent implements OnInit, OnChanges {
  @Input() day?: CalendarDay;
  @Input() isRebook = false;
  @Input() bookingError = false;
  @Input() currentAppointment?: Api.CurrentAppointment;
  @Input() selectedItem: CalendarSlot | undefined;
  @Input() gaData: { planNumber: string; productName: string } | undefined;
  @Output() selected: EventEmitter<CalendarSlot> = new EventEmitter();
  @Output() temporarySelection: EventEmitter<CalendarSlot> =
    new EventEmitter<CalendarSlot>();

  formData?: FormDataSchema['meta'];
  form?: UntypedFormGroup;
  slotSelected: CalendarSlot | undefined = undefined;
  controlName = 'slotIdentifier';
  elementsPerPage = 4;

  showConfirmationModal = false;
  slotTypes = SlotType;

  constructor(private formsService: FormsService) {}

  ngOnInit(): void {
    this.formData = {
      groups: [
        {
          radios: {
            control: this.controlName,
            elementsPerPage: 4,
            classes: `d-flex radio--button`,
            validators: [],
            radioGroup: this.day?.slots?.map(
              (type: CalendarSlot, index: number) => {
                return {
                  value: type.identifier,
                  slotType: type.slotType,
                  text: type.slotType === 'AM' ? '8am - 1pm' : '12pm - 6pm',
                  classes: 'xs-12',
                  hiddenAccessibilityText: '',
                };
              }
            ) as RadioGroupDefinition[],
          },
        },
      ],
    };

    this.form = new UntypedFormGroup(
      this.formsService.defineformControls(this.formData.groups, {
        slotIdentifier: this.selectedItem?.identifier as string,
      })
    );

    if (this.selectedItem) {
      this.slotSelected = this.selectedItem;
      this.form
        .get('groups')
        ?.get('radios')
        ?.setValue(this.selectedItem.identifier);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bookingError) {
      if (this.bookingError) {
        this.showConfirmationModal = false;
      }
    }
  }

  selectSlot() {
    const slot = this.day?.slots?.find(
      (s, index) => s.identifier === this.form?.value?.slotIdentifier
    ) as CalendarSlot;

    let startTime = '8am';
    let endTime = '1pm';

    if (slot.slotType === 'PM') {
      startTime = '12pm';
      endTime = '6pm';
    }
    this.slotSelected = {
      ...slot,
      startTime,
      endTime,
    };

    console.log(this.slotSelected);
    this.temporarySelection.emit(this.slotSelected);
  }

  handleBookingConfirmation(confirm: boolean) {
    this.showConfirmationModal = confirm;
    if (confirm) {
      this.selected.emit(this.slotSelected);
    }
  }

  clearSlot(event?: MouseEvent) {
    event?.preventDefault();
    this.form?.controls[this.controlName].patchValue(null);
    this.temporarySelection.emit(undefined);
  }

  seeOtherDates() {
    if (!this.day) return;

    this.day.selected = false;
  }
}
