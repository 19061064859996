<div class="mt-4" *ngIf="showInWarrantyNotice && !isIWEnabled">
  <div class="notice-box mt-4">
    <h4>
      Your appliance is still under warranty and breakdowns are covered by your
      manufacturer’s guarantee.
    </h4>

    <p>
      You can request a repair from
      {{ InWarrantyContactName }} by calling them on
      <a
        class="link"
        *ngIf="InWarrantyContactTel"
        href="tel:{{ InWarrantyContactTel }}"
        >{{ InWarrantyContactTel }}</a
      >
      <span *ngIf="!InWarrantyContactTel">their direct line.</span>
    </p>

    <dgx-components-button
      class="mt-4"
      type="submit"
      variant="primary"
      iconName="arrow-right"
      data-automation="'backToPlanDetails'"
      (click)="routeToPlanDetails.emit()"
    >
      Back to Plan details
    </dgx-components-button>
  </div>
</div>

<div *ngIf="showplanInWaitPeriodNotice && !isIWEnabled" class="mt-4">
  <div class="notice-box">
    <h4>
      You can’t book a repair because you’re still within the wait period of
      your plan.
    </h4>

    <p>
      Please check your terms and conditions to find out when you’ll be able to
      request a repair.
    </p>

    <dgx-components-button
      class="mt-4"
      type="submit"
      variant="primary"
      iconName="arrow-right"
      data-automation="'backToPlanDetails'"
      (click)="routeToPlanDetails.emit()"
    >
      Back to Plan details
    </dgx-components-button>
  </div>
</div>

<div *ngIf="isIWEnabled" class="mt-4">
  <div class="notice-box iwEnabled">
    <h4>A faster way to get it fixed</h4>

    <p>
      To fix your appliance quickly and conveniently, we now offer repair
      callbacks for some faults.
    </p>

    <ul class="mb-6">
      <li>
        If your fault qualifies, you can book a callback with an expert before
        booking an engineer visit.
      </li>
      <li>
        On the call, an expert will guide you step by step to make a simple fix.
      </li>
      <li>
        If it isn't fixed, you'll get an engineer booking right away - so you've
        always got the help you need.
      </li>
    </ul>

    <dgx-components-button
      *ngIf="isIWEnabled"
      type="submit"
      variant="primary"
      iconName="arrow-right"
      data-automation="'ConfirmAD'"
      (click)="continueClicked.emit()"
    >
      Got it, let's continue
    </dgx-components-button>
  </div>
</div>
