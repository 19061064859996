import { Injectable, Inject, Optional } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DebugLoggerService {
  distinctCash: string[] = [];

  constructor(
    @Optional()
    @Inject('isDevelopmentEnvironment')
    private isDevelopmentEnvironment: boolean
  ) {}

  log(...args: any[]) {
    if (this.isDevelopmentEnvironment) {
      console.log('DEBUG LOG: ', ...args);
    }
  }

  /**
   * Throws a dummy error to get the TS stack trace.
   * Use carefully: may stop program flow.
   * @param args
   */
  logAndThrow(...args: any[]) {
    if (this.isDevelopmentEnvironment) {
      if (args?.length > 0) {
        this.log(...args);
      }
      throw new Error('DEBUG ERROR THROWN FOR STACK TRACE');
    }
  }

  /**
   * Caches unique id so that if this id is logged again
   * it won't appear in the console
   * @param id - unique id (not displayed in console)
   * @param rest - ...rest of parameters to be logged to the console
   */
  logOnce(id: string, ...rest: any[]) {
    if (this.isDevelopmentEnvironment) {
      if (this.isDistinct(id)) this.log('(Log Once)', id, ...rest);
    }
  }

  private isDistinct(id: string): boolean {
    if (this.distinctCash.includes(id)) {
      // this event has fired already
      return false;
    }

    // is distinct
    this.distinctCash.push(id);
    return true;
  }
}
