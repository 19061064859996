<dg-svg></dg-svg>
<div class="full-page">
  <dgx-components-header [clientLogo]="clientLogo" [navigation]="headerLinks">
  </dgx-components-header>

  <dg-loader
    *ngIf="(loadingState$ | async)?.isLoading"
    [message]="(loadingState$ | async)?.message"
  ></dg-loader>
  <router-outlet></router-outlet>
</div>

<dg-footer></dg-footer>
