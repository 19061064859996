import { Claim } from './claim-state.interface';
import { ProductDetails } from './product-details';

export enum ErrorTypes {
  Hard = 'Hard',
  Soft = 'Soft',
  Technical = 'Technical',
}

export interface BasicErrorKeys {
  name: string;
  message: string;
  errorCode?: string;
  status?: number;
  ManualReferralAllowed?: boolean;
  ManualReferralServiceOptionID?: number;
  errorDetail?: {
    Status?: string;
    StatusCode?: string;
  };
}

export type ErrorCache =
  | {
      values: BasicErrorKeys;
      timeStamp: number;
    }
  | undefined;

export interface ErrorPageContent {
  claim: Claim;
  active: boolean;
  title: string;
  description: string;
  ctaText: string;
  errorType: ErrorTypes;
  shortErrorDescription: string;
  contactDetails: ContactDetails;
  product: ProductDetails | null;
  errorCache: BasicErrorKeys | undefined;
  descriptionLine2?: string;
}

export interface ContactDetails {
  BookingOEM: string;
  GetInTouchText: string | null;
  SpCallText: string;
  SpPhoneNumber: string;
  SpOpeningHours?: string;
  SpCallCosts: string;
}

export enum ErrorMessages {
  NoEngineerAvailable = 'PutServiceOption: No Availability',
  NoRecognisedServiceOptionTypes = 'GetServiceOption: No recognised types',
  NoAnnualCodeFound = 'Update Transaction: No annual code found',
  InvalidQuesionType = 'Invalid question type',
  NotEligibleForASV = '503 Orbit code: NC003',
}

export interface ErrorContent {
  heading?: string;
  body?: string;
  cta?: string;
}
