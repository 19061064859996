import { ElementRef, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ClaimFacade,
  ClaimHelperService,
  SpecialRoutingService,
} from '@domgen/data-access-claims';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { filter, switchMapTo, take } from 'rxjs/operators';
import { RepairDetailsService } from './repair-details.service';
import { claimActions } from '@domgen/data-access-claims';
import { ClaimTypeSelected, PaymentType } from '@domgen/dgx-components';

import {
  Api,
  Claim,
  ClaimsAnalyticsService,
  ClaimStage,
  ContactDetailsFormValues,
  ContactDetailsFormValuesContainer,
  FormControlsDefinition,
  PartialUpdateClaimPayload,
  ProductDetails,
  ServiceOption,
  PageSection,
  PutAnswer,
} from '@domgen/dgx-components';
import { QAFormCallbackHelper } from '@domgen/data-access-repair-details';
import { FormTypes } from '../repair-details/components/claim-fault-type-form/claim-fault-type-form-helper.service';
import { ContactDetailsLookupService } from '../repair-details/components/contact-information/contact-details-lookup.service';

export interface RepairState {
  claim: Claim;
  sectionSchema: PageSection[];
  contactDetails: ContactDetailsFormValuesContainer;
  contactFormValue: ContactDetailsFormValues;
  claimTypeOptions: Api.ClaimType[];
  callbackInWarranty: null | FormControlsDefinition[];
  excess: {
    required: boolean;
    cost: string;
    type: PaymentType;
  };
}

@Injectable({
  providedIn: 'root',
})
export class RepairDetailsComponentService {
  vm$ = this._claimFacade.claimLoaded$.pipe(
    filter(Boolean),
    switchMapTo(this._claimFacade.activeClaim$.pipe(take(1))),
    map((claim: Claim) => {
      this.claim = claim;
      this.contactDetails = {
        formValues: {
          email: this.claim.reflect?.email,
          mobile: this.claim.reflect?.mobilePhone,
          landline: this.claim.reflect?.homeTelephone,
          address: '',
          addressLine1: this.claim.reflect?.addressLine1 as string,
          addressLine2: '',
          city: this.claim.reflect?.addressLine2 as string,
          county: this.claim.reflect?.addressLine4 as string,
          postcode: this.claim.reflect?.postCode as string,
        },
      };
      this.contactFormValue = this.contactDetails?.formValues;

      this.pageViewGA();

      return {
        claim: this.claim,
        sectionSchema: this.setPageSectionSchema(claim),
        claimTypeOptions: this.claimTypeOptions,
        contactDetails: this.contactDetails,
        contactFormValue: this.contactFormValue,
        callbackInWarranty: this.getCallbackQA(claim),
        excess: this.claim.excess,
      };
    })
  ) as Observable<RepairState>;

  readonly repairOptions$ = this._claimFacade.serviceOptions$;

  readonly product$ = new BehaviorSubject(
    null
  ) as BehaviorSubject<ProductDetails | null>;

  readonly bannerText$ = new BehaviorSubject(null) as BehaviorSubject<string>;

  constructor(
    private _claimFacade: ClaimFacade,
    private _repairDetails: RepairDetailsService,
    private claimHelper: ClaimHelperService,
    private callbackHelper: QAFormCallbackHelper,
    private title: Title,
    private analytics: ClaimsAnalyticsService,
    private routing: SpecialRoutingService,
    private contactDetailsLookup: ContactDetailsLookupService
  ) {}

  sectionSchemaInitialised = false;
  claimTypeOptions?: Api.ClaimType[];
  sectionSchema?: PageSection[];
  claim?: Claim;
  contactDetails?: ContactDetailsFormValuesContainer;
  contactFormValue?: ContactDetailsFormValues;
  faultFormLogGA: string[] = [];

  setPageSectionSchema(claim: Claim): PageSection[] {
    const { title, sectionSchema, isAnnualService, claimTypeOptions } =
      this._repairDetails.initSectionSchemaAndOptions(claim);
    this.title.setTitle(`${title} | Domestic & General`);

    if (!this.sectionSchemaInitialised || claim?.getData?.IWCallbackRequired) {
      this.sectionSchema = sectionSchema;
      this.claimTypeOptions = claimTypeOptions;
      this.setHeader(title, claim);
      this.sectionSchemaInitialised = true;
      this.setBannerNotice(isAnnualService);
    }

    // set completed and active flags
    return this._repairDetails.getSectionSchemaFlags(
      this.sectionSchema as PageSection[],
      claim
    );
  }

  setBannerNotice(isAnnualService: boolean) {
    this.bannerText$.next(
      isAnnualService
        ? `These details are usually all we need to book your service. But in the rare
      case that we need to know more, we’ll let you know before booking.`
        : `These details are usually all we need to book a repair. But in the rare case that we need to know more, we’ll let you know before booking.`
    );
  }

  setHeader(title: string, claim: Claim) {
    this.product$.next({
      title,
      brand: claim.reflect?.manufacturer,
      applianceName: claim.reflect?.productType,
      planNumber: claim.reflect?.planNumber,
      modelNumber: this.claimHelper.getModelNumberHeading(claim) as string,
    });
  }

  claimTypeFormSelect(request: ClaimTypeSelected) {
    this._claimFacade.dispatch(
      claimActions.UpdateClaim({
        payload: {
          request: {
            ...request,
            Manufacturer: this.claim?.reflect?.manufacturer,
            ProductType: this.claimHelper.getProductTypeForUpdateClaim(
              this.claim as Claim
            ),
          },
          selectionState: {
            claimStage: ClaimStage.ClaimTypeSelect,
          },
          route: false,
        },
      })
    );
    this.triggerClaimTypeGA(request, this?.claim);
  }

  claimTypeFormCreateClaim(partialPayload: PartialUpdateClaimPayload) {
    this._claimFacade.dispatch(
      claimActions.CreateClaim({ payload: partialPayload })
    );
  }

  qAFormPutAnswer(answer: PutAnswer) {
    this._claimFacade.dispatch(
      claimActions.PutAnswer({
        payload: {
          ClaimID: this.claim?.claimID as string,
          QuestionID: answer.QuestionID,
          AnswerID: answer.AnswerID,
          AnswerValue: answer.AnswerValue,
        },
      })
    );
  }

  contactDetailsSubmitted(contactFormValue: ContactDetailsFormValues) {
    this.contactFormValue = contactFormValue;
  }

  onCompleteContactDetails() {
    const customer = {
      ClaimID: this.claim?.claimID as string,
      CustomerTitle: this.claim?.reflect?.customerTitle as string,
      CustomerFirstName: this.claim?.reflect?.firstName || '', // firstName can be undefined
      CustomerLastName: this.claim?.reflect?.surname as string,
      CustomersEmail: this.contactFormValue?.email as string,
      CustomersMobile: this.contactFormValue?.mobile as string,
      CustomersLandLine: this.contactFormValue?.landline as string,
      CustomersHouseStreetName: `${this.contactFormValue?.addressLine1}${
        this.contactFormValue?.addressLine2
          ? ', ' + this.contactFormValue?.addressLine2
          : ''
      }`,
      CustomersTownCity: this.contactFormValue?.city as string,
      CustomersLocalArea: this.contactFormValue?.county as string,
      CustomersPostCode: this.contactDetailsLookup.formatPostCode(
        this.contactFormValue.postcode
      ),
    };

    if (this.claim?.getData?.IWCallbackRequired) {
      this._claimFacade.dispatch(
        claimActions.GetCallbackWidget({ payload: customer })
      );
      return;
    }

    this.onCompleteContactDetailsGA();
    this._claimFacade.dispatch(
      claimActions.GetServiceOptions({
        payload: customer,
      })
    );
  }

  onCompleteClaimDetails(selectedServiceOption: ServiceOption) {
    this.onCompleteServiceOptionGA(selectedServiceOption.ServiceOptionLabel);
    this._claimFacade.onCompleteClaimDetails(selectedServiceOption, this.claim);
  }

  onCompleteCallbackQA(e: { [key: string]: string }) {
    this._claimFacade.dispatch(
      claimActions.ContactDetailsCallbackClaim({ payload: e })
    );
  }

  onCompleteServiceOptionGA(label: string) {
    this.analytics.claimEventExtended({
      eventClaims: 'repair-options-selected',
      eventAction: 'RepairOptions-dgx',
      eventLabel: `${label}`, // collects the type of option selected (Drop off, Self send, Courier, Pay & Claim)
      claim: this.claim as Claim,
    });
  }

  goBack() {
    this.routing.routeBack(this.claim as Claim);
  }

  onCompleteContactDetailsGA() {
    this.analytics.claimEventExtended({
      eventClaims: 'contact-details-confirm-repair-options',
      eventAction: 'confirmPersonalDetails-dgx',
      eventLabel: 'detailsConfirm and repair option',
      claim: this.claim as Claim,
    });
  }

  onResetClaimSelectGA(formType: string) {
    this.analytics.claimEventExtended({
      eventClaims: 'claimsRequest-dgx',
      eventAction: 'type-of-fault-change-selection',
      eventLabel: `${this.getSummaryLabel(formType)}`,
      claim: this.claim as Claim,
    });
  }

  getSummaryLabel(formType: string) {
    if (FormTypes.CLAIMTYPEFORM === formType) {
      return 'Cause of fault';
    }

    if (FormTypes.FAULTTYPEFORM === formType) {
      return 'Fault description';
    }

    if (FormTypes.FAULTAREAFORM === formType) {
      return 'Broken part';
    }
  }

  triggerClaimTypeGA(claimType: ClaimTypeSelected, claim: Claim) {
    this.analytics.pageViewEvent({
      eventClaims: 'what-happened-selected',
      claim: null,
    });

    const claimTypeSelected = this.claim?.getData?.ClaimTypes?.find(
      (c) => String(c.ClaimTypeID) === String(claimType.ClaimTypeID)
    );

    const label = this.getTextIcon(
      claimTypeSelected?.ClaimTypeName,
      claimTypeSelected?.ClaimTypeCode,
      this._repairDetails.hasASVrepairClaim(claim)
    );

    this.analytics.claimEventExtended({
      eventClaims: 'what-happened-selected',
      claimGroup: claimType?.ClaimTypeCode === 'ASV' ? 'asv' : 'dgx',
      claim: this.claim,
      checkClaimStarted: true,
      questionNumber: 1,
      eventAction: 'faultType', // Short form of question asked (eg 'TypeofFault','TypeofDamage','Location of Damage')
      eventLabel: label.text,
    });
  }

  getTextIcon(
    claimTypeName: string,
    claimTypeCode: string,
    asv = false
  ): { text: string; icon: string } {
    switch (claimTypeCode) {
      case 'BD':
      case 'BDBOSY':
      case 'BDBOCO':
        return {
          text: asv ? 'Book a repair' : 'It stopped working by itself',
          icon: asv ? 'repair' : 'multiproducts',
        };

      case 'AD':
        return {
          text: asv ? 'Book a repair' : 'It got damaged by accident',
          icon: asv ? 'repair' : 'accidental-damage',
        };
      case 'ASV':
        return {
          text: asv ? 'Book a service' : 'Annual Service',
          icon: asv ? 'diagnosis' : 'accidental-damage',
        };

      default:
        return {
          text: claimTypeName,
          icon: 'glass',
        };
    }
  }

  triggerFaultResponseGA(
    freeTextSearch: boolean,
    area: string,
    description: string
  ) {
    this.analytics.claimEventExtended({
      eventClaims: 'fault-details-completed',
      eventAction: 'type-of-fault',
      eventLabel: description,
      area,
      freeTextSearch,
      claim: this.claim as Claim,
    });
  }

  triggerFaultFormViewGA(name: string) {
    this.analytics.pageViewEvent({
      eventClaims: 'what-question-asked',
      claim: null,
    });
    this.analytics.claimEventExtended({
      eventClaims: 'what-question-asked',
      eventAction: name,
      eventLabel: 'question-loaded',
      claim: this.claim as Claim,
      questionNumber: this.questionNumberGA(name),
    });
  }

  private questionNumberGA(name: string): number {
    const idx = this.faultFormLogGA.findIndex((n) => n === name);
    let questionNumber = 1; // claim type answered aleady
    if (idx > -1) {
      questionNumber += idx + 1;
    } else {
      this.faultFormLogGA.push(name);
      questionNumber += this.faultFormLogGA.length;
    }
    return questionNumber;
  }

  getCallbackQA(claim: Claim) {
    const fields = claim.getData.IWCallbackData?.Fields;
    return fields ? this.callbackHelper.generateQuestionFormData(fields) : null;
  }

  pageViewGA() {
    if (!this.claim?.accepted) {
      this.analytics.pageViewEvent({
        eventClaims: 'what-happened',
        claim: this.claim as Claim,
      });
    }

    if (this.claim?.serviceOptions) {
      this.analytics.pageViewEvent({
        eventClaims: 'repair-options',
        pagename: 'repair-options-dgx',
        claim: this.claim as Claim,
      });
    }

    if (this.claim?.accepted && !this.claim?.serviceOptions) {
      this.analytics.pageViewEvent({
        pagename: 'confirm-details-dgx',
        eventClaims: 'contact-details',
        claim: this.claim,
      });
    }
  }
  scrollToContinue(completeScroll: ElementRef) {
    setTimeout(() => {
      // timeout for user experience
      if (completeScroll && !this.claim?.serviceOption?.ServiceOptionID) {
        completeScroll.nativeElement.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
        });
      }
    }, 300);
  }
}
