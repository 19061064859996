import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ChatComponent, HistoryType } from '../_shared/chat-types';
import {
  ChatActions,
  LoadDecisionTree,
  LoadDecisionTreeFailure,
  LoadDecisionTreeSuccess,
  LoadHistory,
  LoadNextComponent,
  UpdateHistory,
  UpdateRootComponent,
} from './chat.actions';

export const componentFeatureKey = 'chat';

export interface State extends EntityState<ChatComponent> {
  isLoading: boolean;
  history: HistoryType[];
  error: string | null;
  nextComponent: ChatComponent | null;
  selectedId: number;
}

export const adapter: EntityAdapter<ChatComponent> =
  createEntityAdapter<ChatComponent>({
    selectId: (c: ChatComponent) => c.id as unknown as string,
  });

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  history: [],
  error: null,
  nextComponent: null,
  selectedId: 0,
});

export const chatReducer = createReducer(
  initialState,
  on(LoadDecisionTreeSuccess, (state, { payload }) => {
    return adapter.upsertOne(
      {
        ...payload,
      },
      {
        ...state,
        isLoading: false,
        selectedId: payload.id,
        nextComponent: { ...payload },
      }
    );
  }),
  on(LoadDecisionTree, (state) => {
    return { ...state, isLoading: true };
  }),
  on(LoadDecisionTreeFailure, (state) => {
    return { ...state, isLoading: false, error: state.error };
  }),
  on(LoadHistory, (state) => {
    return { ...state, history: state.history };
  }),
  on(UpdateHistory, (state, { payload }) => {
    return {
      ...state,
      history: [...state.history, payload],
    };
  }),
  on(UpdateRootComponent, (state, { update }) => {
    return adapter.updateOne(update, {
      ...state,
      selectedId: update.changes.id as number,
    });
  }),
  on(LoadNextComponent, (state, { payload }) => {
    return { ...state, nextComponent: { ...payload } };
  })
);

export function reducer(state: State | undefined, action: ChatActions) {
  return chatReducer(state, action);
}
