import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
} from '@angular/platform-browser';

@Component({
  selector: 'smart-fix-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
  @Input() videoUrl!: string | undefined;
  @Input() description!: string | undefined;
  @Input() offset: number = 0;
  @Input() closed = false;
  @Output() watchedVideo: EventEmitter<boolean> = new EventEmitter();
  safeVideoUrl!: SafeResourceUrl;
  safeDescription!: SafeHtml;

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit() {
    if (this.videoUrl) {
      this.videoUrl = this.videoUrl.replace('watch?v=', 'embed/');
      this.safeVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
        this.videoUrl + '?start=' + this.offset
      );
    }
    if (this.description) {
      this.safeDescription = this.domSanitizer.bypassSecurityTrustHtml(
        this.description
      );
    }
  }

  submitVideoWatched() {
    this.watchedVideo.emit(true);
  }
}
