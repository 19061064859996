import { Injectable, Inject } from '@angular/core';
import {
  Claim,
  ClaimBundleType,
  UserJourneyTypeEnum,
} from '../interfaces/claim-state.interface';
import { LocalStorageService } from './localstorage.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CookieTokenService } from './cookie-token.service';
import { Router } from '@angular/router';
import { AnalyticsService } from './analytics.service';
import { GAClientConfig } from '../interfaces';
import { GA_CLIENT } from '../tokens';
import { ValidationService } from '../modules/formbuilder/services/validation.service';
import { ValidationError } from '../modules/formbuilder/interfaces/validation-error.interface';

export interface CustomWindow extends Window {
  dataLayer: any;
  optimizely: any;
  Trustpilot: any;
  CXBus: any;
  _genesys: any;
}

interface GaTrack {
  eventClaims?: string;
  eventCategory?: string;
  DigitalID: string;
  customerID: string;
  planNumber?: string;
  productName: string;
  UnderManufacturerGuarantee: string;
  claimGroup: string;
  claimedModelNumber?: string;
  claimedPNC?: string;
  claimedSerialNumber?: string;
  claimedAccountBrand?: string | undefined | null;
  claimedManufacturer?: string;
  claimedBookingReference?: string;
  startClaim?: string;
  optionType?: string;
  QuestionNumber?: number;
  event?: string;
  eventAction?: string;
  eventLabel?: string;
  boilerType?: string;
  claimedClientName?: string;
  claimsFirstAvailableDate?: string;
  eventValue?: string;
  excessClaimsCost?: number;
  excessClaimsMessage?: string;
  worldPayReferenceNumber?: string;
  claimsLogin?: string;
  area?: string;
  freeTextSearch?: boolean;
}

interface ClaimEvent {
  pagename?: string;
  eventClaims?: string;
  claim: Claim | null;
  checkClaimStarted?: boolean;
  questionNumber?: number;
  fireDistinct?: boolean;
  claimGroup?: string;
  repairer?: string;
}

type ClaimEventExtended = ClaimEvent & {
  eventCategory?: string;
  eventAction: string;
  eventLabel: string;
  claimGroup?: string;
  confirmation?: Confirmation;
  eventValue?: string;
  claimsFirstAvailableDate?: string;
  area?: string;
  freeTextSearch?: boolean;
  repairer?: string;
};

export interface Confirmation {
  pagename?: string;
  repairMethod: string;
  claimType: string;
  excessAmount: number;
  claimedTimeSlot?: string;
  RepairCentreDetails?: string;
  claimedPNC?: string;
  claimsBooked?: number;
  claimedBookingReference?: string;
  claimsFirstAvailableDate?: string;
  claimsDateBooked?: string;
  excessClaimsCost?: number;
  excessClaimsMessage?: string;
  worldPayReferenceNumber?: string;
  repairer?: string;
}

type GaTrackConfimation = GaTrack & Confirmation;

interface GenericEventBody {
  eventCategory: 'claimsRequest-dgx';
  eventClaims?: string;
  eventAction: string;
  eventLabel?: string;
  claimGroup: string;
  fireDistinct?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ClaimsAnalyticsService extends AnalyticsService {
  private previousRoute = '';
  private eventClaimsArr: string[] = [];
  private claimGroup = 'dgx';

  constructor(
    @Inject(GA_CLIENT) protected gaClient: GAClientConfig,
    private validationService: ValidationService,
    private ls: LocalStorageService,
    private cookieToken: CookieTokenService,
    private router: Router
  ) {
    super(gaClient);

    this.validationService.errors.subscribe((error: ValidationError) => {
      this.trigger('ClaimsGenericGAEvent', {
        eventClaims: 'validation-error',
        eventCategory: 'claimsRequest-dgx',
        eventAction: 'validation-error-dgx',
        eventLabel: `${error.message}`,
        eventValue: `${error.value}`,
      });
    });
  }

  pageViewEvent({
    eventClaims,
    claim,
    checkClaimStarted = false,
    pagename,
    fireDistinct = true, // this event only is distinct by default
    claimGroup,
    repairer,
  }: ClaimEvent) {
    if (fireDistinct && !this.isDistinct(eventClaims as string)) {
      return;
    }

    this.trigger('app.pageview', {
      pagename: `claims-${pagename || eventClaims}`,
      genericpagename: 'create-claim',
      category: 'claims-dgx',
      repairer: repairer,
    });

    if (claim) {
      this.claimGroup = claimGroup
        ? claimGroup
        : this.isASV(claim)
        ? 'asv'
        : 'dgx';

      this.claimEvent({
        eventClaims,
        claim,
        checkClaimStarted,
      });
    }
  }

  claimEvent(
    {
      eventClaims,
      claim,
      checkClaimStarted = false,
      questionNumber,
      fireDistinct = false,
    }: ClaimEvent,
    triggerGeneric: boolean = true
  ): GaTrack | void {
    if (fireDistinct && !this.isDistinct(eventClaims as string)) {
      return;
    }

    const gaTrack: GaTrack = {
      eventClaims: eventClaims?.replace(/-dgx$/, '') as string,
      DigitalID: claim?.reflect?.user || 'DigitalID not found',
      customerID: this.cookieToken.getCustomerID(),
      planNumber: claim?.reflect?.planNumber,
      productName: `${claim?.reflect?.manufacturer} ${claim?.reflect?.productType}`, // eg Hoover Condenser Dryer
      UnderManufacturerGuarantee: claim?.getData?.PlanInWarrantyPeriod
        ? 'Yes'
        : 'No',
      claimGroup: this.claimGroup,
      claimedModelNumber: claim?.getData?.ModelNumber,
      claimedSerialNumber: claim?.getData?.SerialNumber,
      claimedAccountBrand: claim?.getData?.BookingOEM,
      claimedManufacturer: claim?.reflect?.manufacturer,
      claimedClientName: claim?.claimedClientName,
      excessClaimsMessage: claim?.excess?.type
        ? `${claim?.excess?.type === 'CALLOUT' ? 'CALLOUT' : 'EXCESS'}`
        : 'NO',
      claimsLogin:
        claim?.reflect?.journey === UserJourneyTypeEnum.QUICKBOOKING
          ? 'quick booking'
          : 'my account',
    };

    if (questionNumber) {
      gaTrack.QuestionNumber = questionNumber;
    }

    if (claim?.claimSelection?.selectionState?.PNCNumber) {
      gaTrack.claimedPNC = claim?.claimSelection?.selectionState?.PNCNumber;
    }

    if ((claim?.claimID && claim?.bookingAvailable) || claim?.claimReference) {
      gaTrack.claimedBookingReference = claim?.bookingAvailable
        ? claim?.claimID
        : claim?.claimReference;
    }

    if (claim?.serviceOption) {
      gaTrack.optionType = `${claim.serviceOption.ServiceOptionType}`;
    }

    if (claim?.isGasAppliance) {
      gaTrack.boilerType = `${claim?.reflect?.productType}`;
    }

    if (claim?.excess?.cost) {
      gaTrack.excessClaimsCost = Number(claim?.excess?.cost);
    }

    if (claim?.worldpay?.orderNumber) {
      gaTrack.worldPayReferenceNumber = `${claim?.worldpay?.orderNumber}`;
    }

    if (triggerGeneric) {
      this.triggerGenericGaTrack(gaTrack, checkClaimStarted);
    } else {
      return gaTrack;
    }
  }

  claimEventExtended(params: ClaimEventExtended) {
    if (params.fireDistinct && !this.isDistinct(params.eventClaims as string)) {
      return;
    }

    let gaTrack: GaTrack | GaTrackConfimation = this.claimEvent(
      { ...params, fireDistinct: false },
      false
    ) as GaTrack | GaTrackConfimation;

    const {
      eventAction,
      eventLabel,
      checkClaimStarted,
      confirmation,
      claimGroup,
      claimsFirstAvailableDate,
      eventValue,
    } = params;

    gaTrack = {
      ...gaTrack,
      event: 'ClaimsGenericGAEvent',
      eventCategory: params?.eventCategory
        ? params?.eventCategory
        : 'claimsRequest-dgx',
      eventAction,
      eventLabel,
      area: params.area,
      freeTextSearch: params.freeTextSearch,
    };

    if (claimGroup) {
      gaTrack = { ...gaTrack, claimGroup };
    }

    if (confirmation) {
      gaTrack = { ...gaTrack, ...confirmation };
    }

    if (claimsFirstAvailableDate) {
      gaTrack = { ...gaTrack, claimsFirstAvailableDate };
    }

    if (eventValue) {
      gaTrack = { ...gaTrack, eventValue };
    }

    this.triggerGenericGaTrack(gaTrack, checkClaimStarted as boolean);
  }

  genericEvent({
    eventClaims,
    eventAction,
    eventLabel,
    fireDistinct = false,
  }: Omit<GenericEventBody, 'eventCategory' | 'claimGroup'>) {
    if (fireDistinct && !this.isDistinct(eventClaims as string)) {
      return;
    }

    const eventBody: GenericEventBody = {
      eventCategory: 'claimsRequest-dgx',
      eventClaims,
      eventAction,
      claimGroup: this.claimGroup,
    };

    if (eventLabel) {
      eventBody.eventLabel = eventLabel;
    }

    if (!eventClaims) {
      delete eventBody.eventClaims;
    }

    this.trigger('ClaimsGenericGAEvent', eventBody);
  }

  isASV(claim: Claim): boolean {
    return claim?.reflect?.claimType === ClaimBundleType.ANNUAL_SERVICE;
  }

  isASVinRepairJourney(claim: Claim): boolean {
    return (
      claim.getData?.ClaimTypes.find(
        (ct) => ct.ClaimTypeID === claim.claimSelection?.request?.ClaimTypeID
      )?.ClaimTypeCode !== 'ASV' &&
      !this.isASV(claim) &&
      !!claim?.isGasAppliance
    );
  }
  private triggerGenericGaTrack(gaTrack: GaTrack, checkClaimStarted: boolean) {
    if (checkClaimStarted) {
      this.ls
        .getItem('gaClaimStarted')
        .pipe(
          switchMap((res) => {
            if (res === null) {
              return this.ls.setItem('gaClaimStarted', true);
            }
            return of(res);
          })
        )
        .subscribe((isNew) => {
          if (isNew === null) {
            gaTrack.startClaim = '1';
          }

          this.trigger('ClaimsGenericGAEvent', gaTrack);
        });
    } else {
      this.trigger('ClaimsGenericGAEvent', gaTrack);
    }
  }

  private isDistinct(eventClaims: string): boolean {
    if (this.previousRoute !== this.router.url) {
      // all new; reset cached checking vals
      this.previousRoute = this.router.url;
      this.eventClaimsArr = [eventClaims];
      return true;
    }

    if (this.eventClaimsArr.includes(eventClaims)) {
      // this event has fired already
      return false;
    }

    // is distinct
    this.eventClaimsArr.push(eventClaims);
    return true;
  }
}
