import { debounceTime } from 'rxjs/operators';
import {
  Component,
  AfterViewInit,
  Input,
  Renderer2,
  ViewChild,
  ElementRef,
  OnInit,
  HostListener,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';

// interface
import { CalendarDay } from '../../interfaces/calendar.interface';
import { CalendarHelperService } from './services/calendar-helper.service';

@UntilDestroy()
@Component({
  selector: 'dg-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})

/**
 * This module has the following requirements
 * - Centers a selected item on click or swipe
 * - Smooth transition between selections
 * - Swipe / touch enabled navigation between days - (might make this mobile only)
 * - Skip x num of days ahead - tablet & above only. X is the number of days in the current view
 */
export class CalendarComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() displayConfiguration: {
    greyBackground: boolean;
    showUIRows: boolean;
    showMonthBelowDay: boolean;
    showDayOfWeek: boolean;
    showNewMonth: boolean;
    showAdditionalText: boolean;
  } = {
    greyBackground: true,
    showMonthBelowDay: false,
    showUIRows: false,
    showNewMonth: true,
    showDayOfWeek: true,
    showAdditionalText: false,
  };

  @Input() startPosition: number | null = null; // 0 or length of calendarDays
  @Input() startDirection = true; // true = forward, false = backwards
  @Input() extraAvailability = false; // for each api request this will change once no more slots are left to request
  @Input() canRequestExtraAvailability = false; // this indicates the calendar to be used
  @Input() APICalendarDays: CalendarDay[] | null = null;
  @Input() startDate: string | undefined = undefined;
  @Input() maxVisibleDays = 7;
  @Input() endDate: string | undefined = undefined;
  @Input() showUIRows = false; // to show the UI Rows; Dgx - displays the grey bar & next availabe date; Repair & Care doesnt
  @Output() slots: EventEmitter<any> = new EventEmitter();
  @Output() daySelected: EventEmitter<CalendarDay | null> = new EventEmitter();
  @Output() requestExtraSlots: EventEmitter<any> = new EventEmitter();
  @Output() silentRequestExtraSlots: EventEmitter<any> = new EventEmitter();

  @Input() setActiveDate?: number | undefined | null = undefined;
  @ViewChild('picker') picker: ElementRef | null = null;
  @ViewChild('pickerWrapper') pickerWrapper: ElementRef | null = null;
  @ViewChild('pickerContainer') pickerContainer: ElementRef | null = null;
  @ViewChild('daysWrapper') daysWrapper: ElementRef | null = null;
  @ViewChild('days') days: ElementRef | null = null;
  @ViewChild('buttonRight') buttonRight: ElementRef | null = null;
  @ViewChild('buttonLeft') buttonLeft: ElementRef | null = null;
  @ViewChild('buttonSpacer') buttonSpacer: ElementRef | null = null;

  private _daysWidth: number | null = null;
  private _singleDayWidth: number | null = null;
  public firstAvailableDateId: number | null = null;
  public nextAvailableDate: Date | null = null;
  public currentDate: Date | null = null;
  public count = 0;
  private readonly swipeThreshold = 50; //required min distance traveled to be considered swipe
  public swipeStartX: number | null = 0; // set mousedown / touchstart (user interation - enter)
  public swipeLength = 0; // set mouseup / touchend (user interation - exit)
  public step: number | null = null;
  public daysInView: number | null = null;
  public halfDaysInView: number | null = null;
  public showButtonRight = false;
  public showButtonLeft = false;
  public viewportWidth: number | null = null;
  public selectedDayId: number | null = null;
  public calendarDays: CalendarDay[] = [];
  private maxDaysLength = 7;
  public initalized = false;
  public groupID: string;
  public silentCall = false;
  public silentCallInProgress = false;
  resize$ = new Subject<void>();

  @HostListener('window:resize')
  onWindowResize() {
    // trigger resize subscription
    this.resize$.next();
  }

  constructor(
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef,
    private _calendarHelper: CalendarHelperService
  ) {
    this.groupID = Math.random().toString(36).substr(2, 9);

    // listen for resize and set the daysInView
    // debounce resize, wait for resize to finish before doing stuff - delay of 500
    this.resize$.pipe(untilDestroyed(this), debounceTime(500)).subscribe(() => {
      if (this.canRequestExtraAvailability) {
        this.setContainerWidth();
      }
      this.calculateDaysToSkip();
      this.handleButtonVisibility();
      this.setLeftProperty();
    });
  }

  ngAfterViewInit() {
    // set default values
    this.count = this.startPosition as number;
    this._daysWidth = this.days?.nativeElement.offsetWidth;
    this._singleDayWidth =
      (this._daysWidth as number) / this.calendarDays.length;
    this.viewportWidth = this.pickerWrapper?.nativeElement.offsetWidth;

    this.setUpCalendarDefault();
    setTimeout(() => this.setAnimation(true)); // dom ready
  }

  setUpCalendarDefault() {
    if (this.canRequestExtraAvailability) {
      this.setContainerWidth();
    }
    // set the dynamic values for left and right buttons
    this.calculateDaysToSkip();
    // set the starting point of the days-wrapper in %
    this.setLeftProperty();
    // build CTA
    this.constructDateCTA();
    // set starting position
    this.setPosition(this.startPosition as number);
    // this.handleButtonVisibility();
    if (this.setActiveDate) {
      this.handleSelectDate(this.setActiveDate);
    }

    // center the calendar
    if (this.canRequestExtraAvailability) {
      this.handleSelectDate(this.getMiddleIndex());
      this.currentDate = this.calendarDays[this.getMiddleIndex()]?.date;
    }
  }

  getMiddleIndex(): number {
    return Math.floor(this.maxDaysLength / 2);
  }

  ngOnChanges() {
    this.setUpCalendar();
  }

  // this happens first (lifecycle hooks)
  ngOnInit() {
    this.showButtonRight = this.startDirection;
    this.showButtonLeft = !this.startDirection;
  }

  setUpCalendar() {
    // TODO - remove this once the CR (ticket number has been comppleted - ETA 2025)
    // build the local object from the API response

    this.calendarDays = this.startDirection
      ? this._calendarHelper.buildUIDaysForward(
          this.APICalendarDays as CalendarDay[],
          this.startDate ? new Date(this.startDate) : new Date(),
          this.endDate ? new Date(this.endDate) : new Date()
        )
      : (this.APICalendarDays as CalendarDay[]);

    this.setMonth();

    if (this.canRequestExtraAvailability && this.initalized) {
      this.currentDate = this.calendarDays[this.count].date;
      this.calculateDaysToSkip();

      if (this.silentCall) {
        this.silentCallInProgress = false;
        this.setAnimation(false);
        this.handleSelectDate(this.selectedDayId as number);
      } else {
        this.handleSelectDate(
          this.calendarDays.length - (this.halfDaysInView as number) - 1
        );
      }

      this.handleButtonVisibility();
    }

    this.initalized = true;
  }

  /**
   * constructDateCTA
   * builds the date CTA
   */
  public constructDateCTA() {
    for (let i = 0; i < this.calendarDays.length; i++) {
      const element = this.calendarDays[i];
      if (element.slotsAvailable === true) {
        this.nextAvailableDate = element.date;
        this.firstAvailableDateId = i;
        break;
      }
    }

    this.cdRef.detectChanges();
  }
  /**
   * setMonth
   * 1. set the current month title
   * 2. calculate month change and set newMonth property on that date: true
   * The UI (month title) will then reflect this when switching to the 1st of a month
   */
  public setMonth() {
    // 1.
    this.currentDate = this.calendarDays[this.startPosition as number].date;

    // 2.
    for (let i = 0; i < this.calendarDays.length - 1; i++) {
      const datestamp = this.calendarDays[i];
      const nextdatestamp = this.calendarDays[i + 1];
      const datestampmonth = datestamp.date.getMonth();
      const nextdatestampmonth = nextdatestamp.date.getMonth();
      if (datestampmonth !== nextdatestampmonth) {
        this.calendarDays[i + 1].newMonth = true;
      }
    }
  }

  public setContainerWidth() {
    this.maxDaysLength = Math.floor(
      (this.picker?.nativeElement.offsetWidth -
        this.buttonSpacer?.nativeElement.offsetWidth * 2) /
        (this._singleDayWidth as number)
    );

    if (this.maxDaysLength > this.maxVisibleDays) {
      this.maxDaysLength = this.maxVisibleDays;
    }

    this.maxDaysLength =
      this.maxDaysLength % 2 ? this.maxDaysLength : this.maxDaysLength - 1;

    this.renderer.setStyle(
      this.pickerWrapper?.nativeElement,
      'max-width',
      `${(this._singleDayWidth as number) * this.maxDaysLength}px`
    );
  }

  /**
   * setStartDate
   * brings the selected date with matching id to the center and update the the count to hold reference of the new item id - (this makes sure the swiping action continues from the newly updated item)
   * @param id { Number }
   */
  public setPosition(id: number) {
    this.renderer.setStyle(
      this.days?.nativeElement,
      'left',
      (id / this.calendarDays.length) * -100 + '%'
    );
    this.count = id;
  }

  //

  /**
   * calculateDaysToSkip
   * In the following we want to:
   * Sets the number of days to bring into view when clicking left / right buttons.
   * This is calculate by the number of days in the current view
   * 1. Calculate the number of days to skip, -1 off the number, due to left and right buttons covering a day either side
   */
  public calculateDaysToSkip() {
    // 1.
    this.daysInView =
      (Math.round(
        this.pickerContainer?.nativeElement.offsetWidth /
          (this._singleDayWidth as number)
      ) /
        10) *
        10 -
      1;

    this.halfDaysInView = Math.round(this.daysInView / 2);
  }

  /**
   * handleButtonVisibility
   * Hide / show buttons if no more dates available to show
   * 1. logic for ButtonRight
   * 2. logic for buttonLeft
   */
  public handleButtonVisibility() {
    const limitRight =
      this.calendarDays.length - (this.halfDaysInView as number) - 1;
    const limitLeft = this.halfDaysInView;

    this.showButtonRight = this.extraAvailability || this.count < limitRight;
    this.showButtonLeft = this.count > (limitLeft as number);
  }

  /**
   * getClosestDay
   * Searches each object within calendarDays array (direction based on 'forward' param) for disable && available value of true
   * 1. Set the current id incase there's no more available days
   * 2. Search for a value of true on the property 'Available' in either fwd or bck direction (depening on 'forward' param)
   * 3. Returns the id if there is one or the id of the currently selected date (count) if none found
   * @param id {number} id to go to
   * @param forward {Boolean} direction of search
   * @param jump {Number} steps to jump
   * @returns {Number}
   */
  public getClosestDay(id: number = 0, forward: boolean = true) {
    // 1.
    let newDayId = this.count;

    // 2.
    for (let i = id; i < this.calendarDays.length; forward ? i++ : i--) {
      if (this.calendarDays[i] === undefined) {
        break;
      }
      if (this.calendarDays[i].slotsAvailable) {
        newDayId = i;
        break;
      }
    }
    // 3.
    return newDayId;
  }

  /**
   * Handles the click and swipe action on each item and brings the item to the center
   * 1. Prevent propagation and default behaviour
   * 2. If slot is avaialble then set as active
   * 3. Set the current month
   * 4. Emit the current slot back to the parent component
   * @param id {Number}
   * @param event {Event} event is optional as it is not passed in when swiping
   */
  public handleSelectDate(id: number, event: any = null) {
    const clickEvt = event !== null && event.type === 'click';

    // 1.
    if (clickEvt) {
      event.preventDefault();
    }

    // 2.
    this.calendarDays = this.calendarDays.map((c: CalendarDay, i: number) => {
      return {
        ...c,
        selected: id === i ? true : null,
      };
    });

    this.setPosition(id);
    this.handleButtonVisibility();
    this.calculateDaysToSkip();

    // 3.
    this.currentDate = this.calendarDays[id]?.date;

    // 4.
    this.daySelected.emit(this.calendarDays[id]);
    this.selectedDayId = id;
    this.cdRef.detectChanges();

    if (this.silentCallInProgress) {
      return;
    }

    if (this.silentCall) {
      this.setAnimation(true);
      this.silentCall = false;
      return;
    }

    this.silentCall =
      this.selectedDayId + (this.halfDaysInView as number) >=
      this.calendarDays.length;

    if (this.silentCall && this.extraAvailability) {
      this.silentCallInProgress = true;
      this.silentRequestExtraSlots.emit(true);
    }
  }

  setAnimation(enabled = true) {
    this.renderer[enabled ? 'addClass' : 'removeClass'](
      this.days?.nativeElement,
      'add-animation'
    );
  }

  /**
   * jumpCount
   * @param id {Number}
   * @param forward {Number}
   * @param jump {Number}
   * This is called when the right and left buttons are clicked
   * Default number of days to shift is half those in the view
   * 1. Add logic for right and left buttons being clicked and there are not enough dates to bring into view. Calculate days remaining and only bring them into view.
   * 2. Pass in the day to search for / go to
   */
  public jumpCount(id: number, forward: boolean) {
    const length = this.calendarDays.length - 1;
    this.halfDaysInView = Math.round((this.daysInView as number) / 2);

    // 1.
    if (forward && id + this.halfDaysInView > length) {
      const daysRemaining = length - Number(this.count);
      const newDaysToSkip = daysRemaining - this.halfDaysInView;
      id = Number(this.count) + newDaysToSkip + 1;
    }
    if (!forward && id < this.halfDaysInView) {
      id = this.halfDaysInView - 1;
    }

    if (forward && this.requestMoreDays()) {
      this.silentCall = false;
      this.setAnimation(true);
      this.requestExtraSlots.emit(true);
      return;
    }

    const slotId = this.canRequestExtraAvailability
      ? this.getNextSlots(forward)
      : this.getClosestDay(id, forward);

    // 2.
    this.handleSelectDate(slotId);
  }

  requestMoreDays(): boolean {
    const limitRight =
      this.count >=
      this.calendarDays.length - (this.halfDaysInView as number) - 1;
    return limitRight && this.extraAvailability;
  }

  getNextSlots(forward: boolean): number {
    this.count = forward
      ? this.count + this.maxDaysLength
      : this.count - this.maxDaysLength;

    if (this.calendarDays.length <= this.count) {
      this.count =
        this.calendarDays.length - (this.halfDaysInView as number) - 1;
    }

    if (Math.sign(this.count) === -1) {
      this.count = 0;
    }

    return this.count;
  }

  /**
   * lock
   * @param event
   */
  public lock(event: MouseEvent) {
    this.swipeStartX = this.unify(event).clientX;
  }

  /**
   * updates the count conditionally on the value of 'step'
   * 1. check to see if lock (user interation has been fired)
   * 2. conditionally set step to 1 (fwd) or -1 (bk) depending on direction of swipe / interaction
   * 3. if there is an item to switch to, run handleSelectDate
   * @param event
   */
  public move(event: MouseEvent) {
    // 1.
    if (this.swipeStartX || this.swipeStartX === 0) {
      // 2.
      this.swipeLength = this.unify(event).clientX - this.swipeStartX;
      this.step = Math.sign(this.swipeLength);

      if (
        Math.abs(this.swipeLength) > this.swipeThreshold &&
        (this.count > 0 || this.step < 0) &&
        (this.count < this.calendarDays.length - 1 || this.step > 0)
      ) {
        // 3.
        this.handleSelectDate(
          this.canRequestExtraAvailability
            ? this.getNextSlots(this.step < 0)
            : this.getClosestDay(
                this.count - this.step,
                this.step < 0 ? true : false
              )
        );
      }

      this.swipeStartX = null;
    }
  }

  /**
   * unify the touch and click cases - conditional returns different object
   * @param event
   */
  public unify(event: any) {
    return event.changedTouches ? event.changedTouches[0] : event;
  }

  /**
   * findFirstAvailableDate
   * Handles the click event on the constructDateCTA
   * Passes in the starting position and direction and uses the default
   * seachTerm of 'Available'
   */
  public findFirstAvailableDate() {
    this.handleSelectDate(
      this.getClosestDay(this.startPosition as number, this.startDirection)
    );
  }

  /**
   * setLeftProperty
   * calculate the viewport and set left property
   */
  private setLeftProperty() {
    this.viewportWidth = this.pickerWrapper?.nativeElement.offsetWidth;

    this.renderer.setStyle(
      this.daysWrapper?.nativeElement,
      'left',
      (((this.viewportWidth as number) / 2 -
        (this._singleDayWidth as number) / 2) /
        (this.viewportWidth as number)) *
        100 +
        '%'
    );
  }
}
