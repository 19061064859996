export enum ClaimErrorCodeTypes {
  NoAvailabilityExtraFalse = 'PS001', // Case A
  NoAvailabilityExtraTrue = 'PS002', // Case B
  CannotConnectToOEM = 'PS006', // Case C
  OEMTimeout = 'PS007',
  SlotUnavailable = 'RD001',
}

export interface ErrorType {
  bookingApiTimeout?: boolean;
  slotUnavailable?: boolean;
}
