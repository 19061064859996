<ng-container
  *ngIf="
    excess?.cost && excess?.required && newStyleRepairOptions;
    else currentStyleRepairOptions
  "
>
  <div class="excess-card">
    <dgx-components-icon
      [name]="'info-circle'"
      [size]="'sm'"
    ></dgx-components-icon>
    <ng-container *ngIf="excess.type === PaymentType.CALLOUT">
      <span class="typog-bold"
        >Once you've paid the £{{ excess.cost }} call-out fee, you can continue
        with your booking.</span
      >
    </ng-container>
    <ng-container *ngIf="excess.type === PaymentType.EXCESS">
      <span class="typog-bold"
        >Once you've paid the £{{ excess.cost }} excess, you can continue with
        your booking.</span
      >
    </ng-container>
  </div>
</ng-container>

<ng-template #currentStyleRepairOptions>
  <div *ngIf="excess?.cost && excess?.required" @slideInOut class="wrapper">
    <div class="wrapper__box">
      <div class="content-box__header">
        <div class="icon-wrapper">
          <svg class="svg-icon icon-credit-card">
            <use xlink:href="#icon-credit-card"></use>
          </svg>
        </div>
        <ng-container *ngIf="excess.type === PaymentType.CALLOUT">
          <h4>There’s a £{{ excess.cost }} call-out fee to pay</h4>
        </ng-container>
        <ng-container *ngIf="excess.type === PaymentType.EXCESS">
          <h4>There’s a £{{ excess.cost }} excess to pay</h4>
        </ng-container>
        <p>
          The payment’s coming up next, so it’ll help to have your card details
          ready.
        </p>
      </div>

      <div class="accordion-plus-minus" *ngIf="excess?.required">
        <dg-accordion icon="icon-plus-minus" (toggled)="toggle($event)">
          <ng-container header>
            <h4 class="margin--none">What is this payment?</h4>
          </ng-container>
          <ng-container content>
            <div class="content" *ngIf="excess.type === PaymentType.EXCESS">
              <p>
                Paying the excess is the last step before you can book your
                repair. It’s a simple online payment using your card details.
              </p>
              <a
                tabindex="0"
                *ngIf="!showMore"
                href="#"
                class="link d-block"
                (click)="showMoreToggle($event)"
                >Show more</a
              >
              <div *ngIf="showMore" @slideInOut>
                <p>
                  In insurance, ‘excess’ means an amount the customer has to pay
                  towards the overall cost of a claim. This amount is agreed
                  when the customer purchases the policy.
                </p>
                <p>
                  Your policy documents have more details of your excess if you
                  need. You can find these documents in the email or letter you
                  received from us after purchase, and also in My Account.
                </p>
              </div>
            </div>
            <div class="content" *ngIf="excess.type === PaymentType.CALLOUT">
              <p>
                Paying the call-out fee is the last step before you can book
                your repair. It’s a simple online payment using your card
                details.
              </p>
              <a
                tabindex="0"
                *ngIf="!showMore"
                href="#"
                class="link d-block"
                (click)="showMoreToggle($event)"
                >Show more</a
              >
              <div *ngIf="showMore" @slideInOut>
                <p>
                  Your plan documents have more details of your call-out fee if
                  you need. You can find these documents in the email or letter
                  you received from us after purchase, and also in My Account.
                </p>
              </div>
            </div>
          </ng-container>
        </dg-accordion>
      </div>
    </div>
  </div>
</ng-template>

<div
  class="wrapper wrapper--paid"
  *ngIf="excess?.cost && !excess?.required"
  @slideInOut
>
  <div class="wrapper__box flex align--center">
    <svg class="svg-icon icon-check-circle">
      <use xlink:href="#icon-check-circle"></use>
    </svg>
    <ng-container *ngIf="excess.type === PaymentType.CALLOUT">
      <h5 class="margin--none">
        You’ve already paid your call-out fee for this repair request
      </h5>
    </ng-container>
    <ng-container *ngIf="excess.type === PaymentType.EXCESS">
      <h5 class="margin--none">
        You’ve already paid your excess for this repair request
      </h5>
    </ng-container>
  </div>
  <p *ngIf="!excess.required">
    The payment’s all done, so you can continue to book your repair.
  </p>
</div>
