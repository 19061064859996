import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromChat from './chat.reducer';

export const getChatState = createFeatureSelector<fromChat.State>(
  fromChat.componentFeatureKey
);

export const { selectEntities, selectIds } =
  fromChat.adapter.getSelectors(getChatState);

export const getChatHistory = createSelector(
  getChatState,
  (state) => state.history
);

export const getChatComponentId = createSelector(
  getChatState,
  (state) => state.selectedId
);

export const selectRootComponentChildren = createSelector(
  selectEntities,
  getChatComponentId,
  (chatEntities, id) => chatEntities[id]?.children
);

export const selectChatCurrentComponentChildren = createSelector(
  getChatState,
  (state) => state.nextComponent?.children
);

export const selectChatCurrentComponent = createSelector(
  getChatState,
  (state) => state.nextComponent
);

export const getDecisionTree = createSelector(getChatState, (chat) => {
  return chat;
});
