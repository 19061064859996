<div class="header-container">
  <div class="header">
    <div class="header-left">
      <dgx-components-icon
        class="message-bot-icon"
        name="message-bot"
        size="lg"
      ></dgx-components-icon>
      <h2 class="title">Smart-Fix support</h2>
    </div>
    <dgx-components-icon
      (click)="toggleMenu()"
      class="functionality-icon"
      name="ellipsis"
      size="lg"
    ></dgx-components-icon>
  </div>
</div>
<div class="dropdown-container">
  <div class="dropdown-menu" *ngIf="isOpen">
    <div class="dropdown-icon" (click)="onMinimize()">
      <dgx-components-icon
        class="functionality-icon"
        name="arrow-down-left-and-arrow-up-right-to-center"
      ></dgx-components-icon
      >Minimise
    </div>
    <div class="dropdown-icon" (click)="onRefresh()">
      <dgx-components-icon
        (click)="onRefresh()"
        class="functionality-icon"
        name="arrows-rotate"
      ></dgx-components-icon
      >Refresh
    </div>
    <div class="dropdown-icon" (click)="onRepairBooking()">
      <dgx-components-icon
        (click)="onRepairBooking()"
        class="functionality-icon"
        name="screwdriver-wrench"
      ></dgx-components-icon
      >Go to repair booking
    </div>
    <div class="dropdown-icon" (click)="onEndChat()">
      <dgx-components-icon
        (click)="onEndChat()"
        class="functionality-icon"
        name="close"
      ></dgx-components-icon>
      End chat
    </div>
  </div>
</div>
