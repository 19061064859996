import { Claim, ServiceOptionTypes } from '../interfaces/claim-state.interface';
import { claim } from './claim-state';

export const ClaimMock: Claim = {
  ...claim,
  bookedServiceOption: ServiceOptionTypes.Home,
  claimConfirmed: {
    Status: 'OK',
    StatusCode: 'RD000',
    StatusDebugging: [],
    ClaimID: '32475',
    ClaimReference: '',
    PlanNumber: 'VU40028569',
    ManufacturerName: 'Candy',
    ProductDescription: 'WASHING MACHINE',
    ModelNumber: 'LB C30J ARG',
    SerialNumber: null,
    FaultCategorySelected: 'Fault Codes',
    FaultSelected: 'Doesnt drain',
    ServiceOption: 'Home Repair',
    AppointmentDate: '2020-01-15',
    AppointmentSlot: 'ANY',
    ServiceProviderID: '1064',
    ServiceProviderCompanyName: 'HOOVER CANDY GROUP',
    ServiceProviderBuildingName: '',
    ServiceProviderHouseStreetName: 'Breightmet Industrial Estate',
    ServiceProviderLocalArea: 'Bury Road',
    ServiceProviderTownCity: 'Bolton',
    ServiceProviderPostCode: 'BL2 6PU',
    RepairSlot: '',
    ProductGroup: '',
    ProductType: '',
    ServiceProviderTelephone: '0344 499 5599',
    ServiceProviderEmail: 'hoover@candy.com',
    ServiceProviderWebsite: 'www.service.hoover.co.uk/',
    ServiceProviderHours: {
      Monday: {
        OpeningTime: '08: 30: 00',
        ClosingTime: '17: 00: 00',
      },
      Tuesday: {
        OpeningTime: '08:30:00',
        ClosingTime: '17: 00:00',
      },
      Wednesday: {
        OpeningTime: '08: 30:00',
        ClosingTime: '17:  00: 00',
      },
      Thursday: {
        OpeningTime: '08: 30:  00',
        ClosingTime: '17: 00: 00',
      },
      Friday: {
        OpeningTime: '08: 30: 00',
        ClosingTime: '17: 00: 00',
      },
      Saturday: {
        OpeningTime: '00:00:  00',
        ClosingTime: '00:  00:00',
      },
      Sunday: {
        OpeningTime: '10: 00: 00',
        ClosingTime: '16: 00: 00',
      },
    },
    ContactName: 'Ben Dikson',
    ContactAddressType: '',
    ContactBuilding: '23A',
    ContactHouseStreetName: 'Some Street',
    ContactLocalArea: 'Bexley',
    ContactTownCity: 'London',
    ContactPostCode: 'E4 13FS',
    ContactMobile: '07565432123',
    ContactEmail: 'some@email.com',
  },
  isServiceProviderCallback: false,
};

export const ClaimMockforBoiler: Claim = {
  ...claim,
  bookedServiceOption: ServiceOptionTypes.Home,
  claimConfirmed: {
    Status: 'OK',
    StatusCode: 'RD000',
    StatusDebugging: [],
    ClaimID: '32475',
    ClaimReference: '',
    PlanNumber: 'VU40028569',
    ManufacturerName: 'Candy',
    ProductDescription: 'BOILER',
    ModelNumber: 'LB C30J ARG',
    SerialNumber: null,
    FaultCategorySelected: 'Fault Codes',
    FaultSelected: 'Doesnt drain',
    ServiceOption: 'Home Repair',
    AppointmentDate: '2020-01-15',
    AppointmentSlot: 'ANY',
    ServiceProviderID: '1064',
    ServiceProviderCompanyName: 'HOOVER CANDY GROUP',
    ServiceProviderBuildingName: '',
    ServiceProviderHouseStreetName: 'Breightmet Industrial Estate',
    ServiceProviderLocalArea: 'Bury Road',
    ServiceProviderTownCity: 'Bolton',
    ServiceProviderPostCode: 'BL2 6PU',
    RepairSlot: '',
    ProductGroup: '',
    ProductType: '',
    ServiceProviderTelephone: '0344 499 5599',
    ServiceProviderEmail: 'hoover@candy.com',
    ServiceProviderWebsite: 'www.service.hoover.co.uk/',
    ServiceProviderHours: {
      Monday: {
        OpeningTime: '08: 30: 00',
        ClosingTime: '17: 00: 00',
      },
      Tuesday: {
        OpeningTime: '08:30:00',
        ClosingTime: '17: 00:00',
      },
      Wednesday: {
        OpeningTime: '08: 30:00',
        ClosingTime: '17:  00: 00',
      },
      Thursday: {
        OpeningTime: '08: 30:  00',
        ClosingTime: '17: 00: 00',
      },
      Friday: {
        OpeningTime: '08: 30: 00',
        ClosingTime: '17: 00: 00',
      },
      Saturday: {
        OpeningTime: '00:00:  00',
        ClosingTime: '00:  00:00',
      },
      Sunday: {
        OpeningTime: '10: 00: 00',
        ClosingTime: '16: 00: 00',
      },
    },
    ContactName: 'Ben Dikson',
    ContactAddressType: '',
    ContactBuilding: '23A',
    ContactHouseStreetName: 'Some Street',
    ContactLocalArea: 'Bexley',
    ContactTownCity: 'London',
    ContactPostCode: 'E4 13FS',
    ContactMobile: '07565432123',
    ContactEmail: 'some@email.com',
  },
};

export const claimComplete = {
  bundleToken: 'ELECTROLUX',
  reflect: {
    asvOffered: 'false',
    countryCode: 'GBR',
    type: 'Claim:Start',
    applianceCode: 'AW',
    ttl: '3600',
    domain: 'https://www.vm.domesticandgeneral.com',
    homeTelephone: '07707654556',
    addressLine1: '28 ROSEBERY GARDENS',
    productType: 'Washing Machine',
    addressLine2: 'BELFAST',
    companyCode: 'D',
    addressLine3: '',
    mobilePhone: '',
    addressLine4: '',
    postCode: 'BT6 8JG',
    initials: 'M',
    user: '',
    claimId: '',
    claimType: 'Repair',
    journeyState: 'Start',
    manufacturer: 'ELECTROLUX',
    planNumber: 'VU40036338',
    status: 'N',
    catalogId: '10053',
    customerTitle: 'MR',
    surname: 'MALAK',
    firstName: 'MORHYS',
    email: 'hello@morhys.com',
    storeId: '10151',
    workPhone: '',
    rootURL: 'https://www.domesticandgeneral.com',
    myAccountURL: 'https://www.myaccount.domesticandgeneral.com',
    logoutPath: '/logout',
    personalDetailsPath: '/personaldetails',
    dashboardPath: '/dashboard',
    langId: '44',
    journey: 'quickbooking',
    userState: 'COMPLETE',
  },
  claimedClientName: 'ELECTROLUX',
  excess: {
    required: false,
    cost: '',
  },
  isGasAppliance: false,
  getData: {
    Status: 'OK',
    StatusCode: 'GM000',
    StatusDebugging: [],
    BookingOEM: 'Electrolux',
    ProductType: 'WASHING MACHINE',
    PlanInWarrantyPeriod: true,
    PlanInWaitPeriod: false,
    InWarrantyContactName: null,
    InWarrantyContactTel: null,
    OEMModelDataRequired: true,
    UniqueApplianceID: '1234',
    ModelNumber: '1234',
    SerialNumber: '',
    FaultData: [],
    OEMApplianceData: [
      {
        FieldIdentifier: 'UniqueApplianceID',
        FieldLabel: 'PNC Number',
        FieldType: 'String',
        FieldRegex: '^[0-9]*$',
        FieldLookupRegex: null,
        FieldValues: [],
        DataCategory: 'OEMAppliances',
        DataType: 'PNCNumber',
        UseGetData: true,
        DisplayPriority: 0,
      },
      {
        FieldIdentifier: 'MLCode',
        FieldLabel: 'ML Code',
        FieldType: 'String',
        FieldRegex: '^[0][0-9]$',
        FieldLookupRegex: null,
        FieldValues: [],
        DataCategory: null,
        DataType: null,
        UseGetData: false,
        DisplayPriority: 0,
      },
      {
        FieldIdentifier: 'UniqueApplianceID',
        FieldLabel: 'Model',
        FieldType: 'String',
        FieldRegex: null,
        FieldLookupRegex: null,
        FieldValues: [],
        DataCategory: 'OEMAppliances',
        DataType: 'ModelNumber',
        UseGetData: true,
        DisplayPriority: 1,
      },
      {
        FieldIdentifier: 'SerialNumber',
        FieldLabel: 'Serial Number',
        FieldType: 'String',
        FieldRegex: '^([A-Za-z]{3})([0-9]{2})([0-9]{2})([0-9]{5}[A-Za-z]{2})$',
        FieldLookupRegex: null,
        FieldValues: [],
        DataCategory: null,
        DataType: null,
        UseGetData: false,
        DisplayPriority: 2,
      },
    ],
    ClaimTypes: [
      {
        ClaimTypeID: '3',
        ClaimTypeName: 'Breakdown',
        ClaimTypeLabel: 'Breakdown',
        ClaimTypeCode: 'BD',
        ClaimTypeAvailable: false,
        IWEnabled: true,
      },
      {
        ClaimTypeID: '4',
        ClaimTypeName: 'Damage Caused by Accident',
        ClaimTypeLabel: 'Damage Caused by Accident',
        ClaimTypeCode: 'AD',
        ClaimTypeAvailable: true,
      },
    ],
  },
  claimSelection: {
    request: {
      Manufacturer: 'ELECTROLUX',
      ProductType: 'Washing Machine',
      ClaimTypeID: '4',
      FaultCategoryID: 214,
      FaultID: '29131',
    },
    selectionState: {
      modelNumberAlreadyValidated: true,
      claimStage: '6-CreateClaim',
      faultType: {
        id: 214,
        description: '',
      },
      faultTypeAnswer: {
        id: '29131',
        description: 'Program control knob sticks on program',
      },
    },
  },
  bookingApiTimeout: false,
  slotUnavailable: false,
  bookingOption: null,
  bookingAvailable: false,
  preClaimStartPageViewed: 1,
  accepted: true,
  created: true,
  claimID: '15566',
  questions: [
    {
      question: {
        Status: 'OK',
        StatusCode: 'GQ000',
        StatusDebugging: [],
        QuestionID: '43',
        QuestionLabel:
          'Please ask the customer for a detailed description of the fault. \r\n',
        QuestionHelp:
          'Please capture any error codes or messages displayed on the product.',
        AnswerType: 'DATE_PICKER',
        AnswerData: [
          {
            AnswerID: '100',
            AnswerLabel: '',
            AnswerHint:
              'Please enter as much detail about the problem being reported.',
            AnswerMinimumCharacters: '3',
            AnswerMaximumCharacters: '0',
          },
        ],
      },
      answer: {
        AnswerID: '100',
        AnswerValue: 'Tue Aug 01 2023 00:00:00 GMT+0100 (British Summer Time)',
      },
    },
    {
      question: {
        Status: 'OK',
        StatusCode: 'GQ000',
        StatusDebugging: [],
        QuestionID: '401',
        QuestionLabel: 'Tell us about the fault',
        QuestionHelp:
          'Please give us any information that might help the engineer to fix your product',
        AnswerType: 'RESPONSE_FIELD',
        AnswerData: [
          {
            AnswerID: '1033',
            AnswerLabel: 'Display Response Field Only',
            AnswerHelp: '',
            AnswerHint: 'Please provide as much information as possible',
            AnswerMinimumCharacters: '3',
            AnswerMaximumCharacters: null,
          },
        ],
      },
      answer: {
        AnswerID: '1033',
        AnswerValue: 'sdsds',
      },
    },
  ],
  customer: {
    ClaimID: '15566',
    CustomerTitle: 'MR',
    CustomerFirstName: 'MORHYS',
    CustomerLastName: 'MALAK',
    CustomersEmail: 'hello@morhys.com',
    CustomersMobile: '07707654556',
    CustomersLandLine: '',
    CustomersHouseStreetName: '28 ROSEBERY GARDENS',
    CustomersTownCity: 'BELFAST',
    CustomersLocalArea: '',
    CustomersPostCode: 'BT6 8JG',
  },
  serviceOptions: {
    Status: 'OK',
    StatusCode: 'GQ000',
    StatusDebugging: [],
    ServiceOptions: [
      {
        ServiceOptionID: '6',
        ServiceOptionLabel: 'Home Visit (Normal Working Hours)',
        ServiceOptionMessage:
          'If the Service Provider has exposed their booking diary an option will be displayed to allow you to create an appointment. If no diary is displayed the Service Provider will contact the customer to arrange the appointment.',
        ServiceOptionType: 'Home Repair',
        ServiceOptionCost: '0.00',
        ServiceOptionRequiredFields: [],
        PaymentRequired: true,
        PaymentData: {
          Type: 'CALLOUT',
          Label: 'Call Out Charge',
          Amount: '100.00',
        },
      },
      {
        ServiceOptionID: '11',
        ServiceOptionLabel:
          'Customer to send by post (own postage & packaging)',
        ServiceOptionMessage:
          'The customer can send their product direct to the service provider by post at their own cost using their own packaging.\r\n\r\nThe customer must be aware that it is their responsibility to ensure the product is correctly labelled and securely packed.',
        ServiceOptionType: 'Self Send',
        ServiceOptionCost: '0.00',
        ServiceOptionRequiredFields: ['DELIVERY_RETURN_ADDRESS'],
      },
      {
        ServiceOptionID: '28',
        ServiceOptionLabel:
          'Central Repair (Courier Collection with Packaging)',
        ServiceOptionMessage:
          'The courier will arrive with a packing crate for the device to be placed into for secure transportation to the Service Centre.   ',
        ServiceOptionType: 'Courier',
        ServiceOptionCost: '0.00',
        ServiceOptionRequiredFields: [
          'COLLECTION_ADDRESS',
          'DELIVERY_RETURN_ADDRESS',
        ],
      },
      {
        ServiceOptionID: '30',
        ServiceOptionLabel: 'Drop off ',
        ServiceOptionMessage: 'The package will be dropped off ',
        ServiceOptionType: 'Drop off',
        ServiceOptionCost: '0.00',
        ServiceOptionRequiredFields: [
          'COLLECTION_ADDRESS',
          'DELIVERY_RETURN_ADDRESS',
        ],
      },
      {
        ServiceOptionID: '88',
        ServiceOptionLabel: 'Pay and Claim',
        ServiceOptionMessage: 'Pay and Claim',
        ServiceOptionType: 'Pay and Claim',
        ServiceOptionCost: '0.00',
        ServiceOptionRequiredFields: [
          'COLLECTION_ADDRESS',
          'DELIVERY_RETURN_ADDRESS',
        ],
      },
      {
        ServiceOptionID: '37',
        ServiceOptionLabel: 'Manual Referral (TEST)',
        ServiceOptionMessage: 'Manual Referral (TEST)',
        ServiceOptionType: 'Manual Referral',
        ServiceOptionCost: '0.00',
        ServiceOptionRequiredFields: [],
      },
    ],
  },
};
