<dg-information-notice
  [title]="
    'Please check the contact details we’ll use to notify you about the repair.'
  "
>
</dg-information-notice>
<dg-information-notice
  *ngIf="isEdit && isLandLordOwner"
  [theme]="'theme-grey'"
  [icon]="'information'"
  [title]="
    'We’ll use the email address you enter here to contact you about this repair. This won’t change the email you use to log in to My Account, though. Editing the other contact details here will update them in My Account.'
  "
>
</dg-information-notice>

<div *ngIf="!isEdit" [@fadeInAnimation]>
  <a
    class="link link-underline edit-cta d-block"
    (click)="edit()"
    data-automation="editAddress"
    >Edit your details</a
  >
</div>

<ng-container *ngIf="!isEdit; else editForm">
  <div [@fadeInAnimation]>
    <ng-container *ngFor="let input of formTemplateBuilder">
      <div
        class="customer-info"
        *ngIf="!input.addressGroup && (addressForm?.value)[input.name]"
      >
        <h4 class="typog-body-large-bold margin--none">{{ input.title }}</h4>

        <p
          [dgxQmSanitise]="
            ['Email', 'Mobile', 'Landline'].includes(input.title)
              ? 'block'
              : 'encrypt'
          "
        >
          {{ (addressForm?.value)[input.name] }}
        </p>
      </div>
    </ng-container>

    <div class="customer-info">
      <h4 class="typog-body-large-bold margin--none">Address</h4>
      <p>
        <span class="xs--block" [dgxQmSanitise]="'block'"
          >{{ contactDetails?.formValues?.addressLine1 }}
          {{ contactDetails?.formValues?.addressLine2 }}</span
        >
        <span class="xs--block" [dgxQmSanitise]="'block'">{{
          contactDetails?.formValues?.county
        }}</span>
        <span
          *ngIf="contactDetails?.formValues?.city"
          [dgxQmSanitise]="'block'"
          class="xs--block"
          >{{ contactDetails?.formValues?.city }}, UK</span
        >
        <span *ngIf="!contactDetails?.formValues?.city" class="xs--block"
          >UK</span
        >
        <span class="xs--block" [dgxQmSanitise]="'block'">{{
          contactDetails?.formValues?.postcode?.toUpperCase()
        }}</span>
      </p>
    </div>
  </div>
</ng-container>

<ng-template #editForm>
  <div class="mt-4" #editContactDetailsForm [@fadeInAnimation]>
    <form [formGroup]="$any(addressForm)">
      <div class="edit-contact-details-form">
        <ng-container *ngFor="let input of formTemplateBuilder">
          <div
            [@fadeInAnimation]
            *ngIf="
              (input.addressGroup && isManualAddress) || !input.addressGroup
            "
          >
            <div class="pb-4" *ngIf="input.name === 'addressLine1'">
              <a
                class="link link-underline"
                (click)="toggleAddress()"
                data-automation="searchAdressEnter"
                >Search address</a
              >
            </div>
            <div
              *ngIf="$any(f)[input.name]?.touched && $any(f)[input.name].errors"
              #error
            ></div>
            <div
              class="form-input no-padding legacy-form-builder"
              [ngClass]="{
                'is-invalid':
                  !$any(f)[input.name].valid && $any(f)[input.name]?.touched,
                'is-valid':
                  $any(f)[input.name]?.valid && $any(f)[input.name]?.touched
              }"
            >
              <label class="form-label">{{ input.title }}</label>
              <div
                class="form-control"
                [dgxQmSanitise]="
                  ['Email', 'Mobile', 'Landline'].includes(input.title)
                    ? 'block'
                    : 'encrypt'
                "
              >
                <input
                  (input)="formatCase(input.name, $any(f)[input.name])"
                  type="text"
                  [formControlName]="input.name"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': $any(f)[input.name].errors }"
                  [attr.data-automation]="input.automation"
                />
                <i class="icon validation-icon icon-tick-circle"></i>
                <i class="icon validation-icon icon-error-circle"></i>
              </div>
              <ng-container
                *ngIf="
                  $any(f)[input.name]?.touched && $any(f)[input.name].errors
                "
                class="invalid-feedback"
              >
                <span
                  class="form-input-feedback"
                  *ngIf="$any(f)[input.name].errors.required"
                  >{{ input.requiredMessage }}</span
                >
                <span
                  class="form-input-feedback"
                  *ngIf="$any(f)[input.name].errors.pattern"
                  >{{ input.patternMessage }}</span
                >
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!isManualAddress">
          <div
            class="form-input no-padding legacy-form-builder form-input--address"
            [@fadeInAnimation]
            [ngClass]="{
              'is-invalid':
                addressList.length === 1 && addressList[0].Error && !focused
            }"
          >
            <label class="form-label">{{ searchLabel }}</label>
            <div class="form-control">
              <input
                #contactAddressInput
                type="text"
                class="form-control"
                formControlName="address"
                data-automation="addressLookup"
                autocomplete="chrome-off"
                [dgxQmSanitise]="'block'"
                (keydown)="onKeydown($event, selected, addressList)"
                (blur)="onBlur($event)"
                (focus)="focused = true"
              />

              <i
                *ngIf="
                  addressList.length === 1 && addressList[0].Error && !focused
                "
                class="icon validation-icon icon-error-circle"
              ></i>

              <i
                class="icon icon--lg trigger-icon icon-search"
                [ngClass]="{
                  'icon-close': addressValue.length > 0,
                  'icon-search': addressValue.length === 0
                }"
                (mousedown)="clearInput()"
              ></i>

              <ul
                class="address-look-up"
                [@slideInOut]
                tabindex="0"
                #scrollList
                *ngIf="addressList.length > 0 && focused"
                (keydown)="onKeydown($event, selected, addressList)"
              >
                <li
                  *ngFor="let address of addressList; index as i"
                  class="address-look-up__item"
                  (click)="handleAddressClick(address)"
                  (keydown)="onKeydown($event, selected, addressList)"
                  [class.active]="i === selected && !address.Error"
                  [class.error]="address.Error"
                  [attr.tabindex]="'-1'"
                  [dgxQmSanitise]="'block'"
                  [innerHTML]="address.Description"
                >
                  <span
                    class="glyphicon glyphicon-chevron-right"
                    [ngClass]="{
                      hidden: handleExpandable(address)
                    }"
                    aria-hidden="true"
                  ></span>
                </li>
              </ul>
            </div>
            <span
              *ngIf="
                addressList.length === 1 && addressList[0].Error && !focused
              "
              class="form-input-feedback"
              >Address not found. Check and enter again.</span
            >
          </div>

          <a
            class="link link-underline md-8"
            (click)="toggleAddress()"
            data-automation="searchAdressEnter"
            >Enter address manually</a
          >
        </ng-container>

        <div class="cta">
          <dgx-components-button
            *ngIf="isEdit && initFormDataValid"
            variant="secondary"
            (click)="cancel()"
            class="pb-4"
          >
            Make no changes
          </dgx-components-button>
        </div>

        <div class="pb-6">
          <dgx-components-button
            iconName="arrow-right"
            variant="primary"
            (click)="updateForm()"
          >
            Update your details
          </dgx-components-button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<dgx-components-button
  *ngIf="!isEdit"
  type="submit"
  variant="primary"
  iconName="arrow-right"
  (click)="submitted.emit()"
  data-automation="ConfirmAndContinueRepairDetails"
>
  Yes, let’s continue
</dgx-components-button>
