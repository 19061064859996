import { Injectable } from '@angular/core';
import {
  Api,
  Claim,
  CustomerDetails,
  QuestionTypes,
} from '@domgen/dgx-components';
import { format, isValid } from 'date-fns';

enum CallbackKey {
  CallbackSlot = 'CallbackSlot',
  FaultDate = 'FaultDate',
}

@Injectable({ providedIn: 'root' })
export class CallbackHelperService {
  getCallbackPayload(claim: Claim, desiredTime: string): Api.CallbackRequest {
    return {
      Fields: [
        {
          Identifier: CallbackKey.CallbackSlot,
          Value: `${format(new Date(desiredTime), 'dd/MM/yyyy HH:mm:ss')}`,
        },
        ...this.getKeysAndValue(claim?.callbackQA as { [key: string]: string }),
      ],
    };
  }

  getKeysAndValue(
    data: { [key: string]: string } | CustomerDetails = {}
  ): Api.CallbackRequestField[] {
    return Object.entries(data)
      .filter(([key, value]) => value.length > 1)
      .map(([key, value]) => {
        const isFaultDate = key === CallbackKey.FaultDate;

        return {
          Identifier: `${key}`,
          Value: `${
            isFaultDate && isValid(value)
              ? format(new Date(value), 'dd/MM/yyyy')
              : value
          }`,
        };
      });
  }

  getConfirmationData(
    claim: Claim,
    callbackReference: string
  ): Api.PutRepairDataResponse {
    return {
      Status: 'OK',
      StatusCode: '',
      StatusDebugging: [],
      ClaimID: '',
      ProductGroup: '',
      ProductType: '',
      ClaimReference: callbackReference,
      PlanNumber: claim?.reflect?.planNumber as string,
      ManufacturerName: claim?.reflect?.manufacturer as string,
      ProductDescription: claim?.reflect?.productType,
      ModelNumber: claim?.getData?.ModelNumber as string,
      SerialNumber: null,
      FaultCategorySelected: claim?.claimSelection?.selectionState
        ?.faultTypeAnswer?.description as string,
      FaultSelected: claim?.claimSelection?.selectionState?.faultType
        ?.description as string,
      ClaimQuestionsArray: [
        {
          QuestionID: '',
          QuestionLabel: '',
          AnswerID: '',
          AnswerLabel: '',
          AnswerType: QuestionTypes.RESPONSE_FIELD,
          AnswerHelp: '',
          AnswerValue: claim?.callbackQA?.Description as string,
        },
        {
          QuestionID: '',
          QuestionLabel: '',
          AnswerID: '',
          AnswerLabel: '',
          AnswerType: QuestionTypes.DATE,
          AnswerHelp: '',
          AnswerValue: claim?.callbackQA?.FaultDate as string,
        },
      ],
      ServiceOption: claim.bookedServiceOption as string,
      ExcessAmount: 0,
      AuthorityLimit: '',
      ServiceProviderID: '',
      ServiceProviderCompanyName: claim.getData?.InWarrantyContactName,
      ServiceProviderBuildingName: '',
      ServiceProviderHouseStreetName: '',
      ServiceProviderLocalArea: '',
      ServiceProviderTownCity: '',
      ServiceProviderPostCode: '',
      ServiceProviderTelephone: claim.getData?.InWarrantyContactTel,
      ServiceProviderEmail: '',
      ServiceProviderWebsite: '',
      ServiceProviderHours: {},
      ContactName: '',
      ContactAddressType: '',
      ContactBuilding: '',
      ContactHouseStreetName: '',
      ContactLocalArea: '',
      ContactTownCity: '',
      ContactPostCode: '',
      ContactMobile: '',
      ContactEmail: '',
    };
  }
}
