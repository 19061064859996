import { Injectable } from '@angular/core';
import {
  AnalyticsService,
  Claim,
  CookieTokenService,
} from '@domgen/dgx-components';

@Injectable({
  providedIn: 'root',
})
export class SmartFixAnalyticsService {
  constructor(
    private readonly analyticsService: AnalyticsService,
    private cookieToken: CookieTokenService
  ) {}

  triggerSmartFixEvent(
    eventLabel: string,
    eventClaims: string,
    claim: Claim,
    eventAction: string,
    eventCategory?: string
  ): void {
    const gaTrack = {
      eventClaims: eventClaims?.replace(/-dgx$/, '') as string,
      eventCategory: eventCategory || 'claimsRequest-dgx',
      DigitalID: claim?.reflect?.user || 'DigitalID not found',
      customerID: this.cookieToken.getCustomerID(),
      planNumber: claim?.reflect?.planNumber,
      productName: `${claim?.reflect?.manufacturer} ${claim?.reflect?.productType}`,
      UnderManufacturerGuarantee: claim?.getData?.PlanInWarrantyPeriod
        ? 'Yes'
        : 'No',
      claimGroup: 'dgx',
      claimedModelNumber: claim?.getData?.ModelNumber,
      claimedSerialNumber: claim?.getData?.SerialNumber,
      claimedAccountBrand: claim?.getData?.BookingOEM,
      claimedManufacturer: claim?.reflect?.manufacturer,
      claimedClientName: claim?.claimedClientName,
      excessClaimsMessage: claim?.excess?.type
        ? `${claim?.excess?.type === 'CALLOUT' ? 'CALLOUT' : 'EXCESS'}`
        : 'NO',
      companyCode: claim?.reflect?.companyCode,
      countryCode: claim?.reflect?.countryCode,
      ...(claim?.isGasAppliance && { boilerType: claim?.reflect?.productType }),
    };

    this.analyticsService.trigger('genericGAEvent', {
      ...gaTrack,
      eventLabel,
      eventAction,
    });
  }
}
