<h1 class="typog-h4">
  <button
    *ngIf="currentAppointment.SlotType !== 'Specific'"
    class="show-tooltip-button"
    (mouseenter)="isMobile ? null : showTooltip = true"
    (mouseleave)="isMobile ? null : showTooltip = false"
    (click)="showTooltip = !showTooltip"
  >
    <dgx-components-icon name="info-circle" class="mr-1"> </dgx-components-icon>
  </button>

  <section class="tooltip" [class.show-tooltip]="showTooltip">
    <p class="typog-caption">
      If you want to keep this booking, you can leave this page.
    </p>
  </section>

  Your current booking
</h1>

<section class="current-booking-data">
  <section class="date-information">
    <dgx-components-icon name="calendar"></dgx-components-icon>
    <p class="typog-body-bold">
      {{ currentAppointment.Date | date :
      bookingCalendarDateFormats.DayDateMonthYear }}
    </p>
  </section>

  <section
    *ngIf="currentAppointment.SlotType === 'Specific'"
    class="date-information"
  >
    <dgx-components-icon name="clock"></dgx-components-icon>
    <p class="typog-body-bold">
      {{ currentAppointment.StartTime | timeFormat }} - {{
      currentAppointment.EndTime | timeFormat }}
    </p>
  </section>

  <p
    *ngIf="currentAppointment.SlotType !== 'Specific'; else SpecificMessage"
    class="mb-0 typog-body"
  >
    Your engineer will be in touch with their estimated arrival time.
  </p>

  <ng-template #SpecificMessage>
    <p class="mb-0 typog-body">
      If you want to keep this booking, you can leave this page.
    </p>
  </ng-template>
</section>
