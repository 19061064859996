<section class="modal" cdkTrapFocus>
  <section class="background"></section>
  <section class="dialog">
    <section class="title">
      <div>
        <dgx-components-icon name="circle-exclamation" class="mr-2">
        </dgx-components-icon>
        <span class="typog-h4 margin--none">{{ confirmationHeading }}</span>
      </div>
      <dgx-components-icon name="xmark" size="xl" (click)="decline()">
      </dgx-components-icon>
    </section>

    <ng-container *ngIf="isRebooking && currentAppointment">
      <p class="typog-body-bold mt-4">From:</p>
      <section class="details mt-2">
        <section class="info-breakdown">
          <dgx-components-icon name="calendar"></dgx-components-icon>
          <p class="typog-body-bold">
            {{
              currentAppointment.Date
                | date : bookingCalendarDateFormats.DayDateMonthYear
            }}
          </p>
        </section>

        <section
          *ngIf="slotType === slotTypes.SPECIFIC || slotType === slotTypes.AMPM"
          class="info-breakdown"
        >
          <dgx-components-icon name="clock"></dgx-components-icon>
          <p class="typog-body-bold">
            {{ currentAppointment.StartTime | timeFormat }} -
            {{ currentAppointment.EndTime | timeFormat }}
          </p>
        </section>
      </section>

      <p class="typog-body-bold mt-4">To:</p>
    </ng-container>

    <section class="details mt-2">
      <section class="info-breakdown">
        <dgx-components-icon name="calendar"></dgx-components-icon>
        <p class="typog-body-bold">
          {{ day?.date | date : bookingCalendarDateFormats.DayDateMonthYear }}
        </p>
      </section>

      <section
        *ngIf="slotType === slotTypes.SPECIFIC || slotType === slotTypes.AMPM"
        class="info-breakdown"
      >
        <dgx-components-icon name="clock"></dgx-components-icon>
        <p class="typog-body-bold">
          {{ toSlotTimes }}
        </p>
      </section>
    </section>

    <section class="actions mt-6">
      <dgx-components-button
        [fill]="true"
        variant="secondary"
        (clicked)="confirm()"
      >
        Yes
      </dgx-components-button>
      <dgx-components-button
        [fill]="true"
        variant="secondary"
        (clicked)="decline()"
      >
        No
      </dgx-components-button>
    </section>
  </section>
</section>
