import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  LoadDecisionTree,
  LoadDecisionTreeFailure,
  LoadDecisionTreeSuccess,
} from './chat.actions';
import { catchError, map, mergeMap, of } from 'rxjs';
import { ChatService } from '../services/chat.service';

@Injectable()
export class ChatEffects {
  loadInitialDecisionTree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDecisionTree),
      mergeMap((action) =>
        this.chatService.loadJSONDecisionTree(action.payload).pipe(
          map((chat) => LoadDecisionTreeSuccess({ payload: chat })),
          catchError((error) => of(LoadDecisionTreeFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private chatService: ChatService) {}
}
