import { Component, Input } from '@angular/core';

export interface Hint {
  text?: string;
  markup?: {
    link?: string;
    href?: string;
  };
  classes?: string[];
}

@Component({
  selector: 'dg-fb-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
})
export class HintComponent {
  @Input() hint?: Hint;
}
