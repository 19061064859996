<div
  *ngIf="group && item"
  [formGroup]="group"
  class="form-input legacy-form-builder"
  [ngClass]="{
    'is-invalid':
      !item.readOnly &&
      !group.controls[item.control].valid &&
      group.controls[item.control]?.touched,
    'is-valid':
      !item.readOnly &&
      group.controls[item.control]?.valid &&
      group.controls[item.control]?.touched
  }"
>
  <dg-fb-label
    *ngIf="label"
    [label]="label.text"
    [class]="label?.classes"
    [forElement]="labelFor"
    >{{ label?.text }}
  </dg-fb-label>
  <div *ngIf="tooltip">
    <div class="tooltip"></div>
    <p [class]="tooltip.classes">{{ tooltip.text }}</p>
  </div>
  <dg-fb-hint *ngIf="hint" [hint]="hint"></dg-fb-hint>
  <div class="form-control">
    <input
      [class]="item.classes"
      [formControlName]="item.control"
      [placeholder]="item.placeholder"
      [readonly]="item.readOnly"
      [id]="item.control"
      [name]="item.control"
      [attr.maxlength]="item?.maxLength"
      type="text"
      (blur)="refreshValidationMessages()"
    />
    <i
      class="icon validation-icon icon-tick-circle"
      *ngIf="group.controls[item.control]?.value"
    ></i>
    <i class="icon validation-icon icon-error-circle"></i>
  </div>
  <ng-container *ngIf="validators && showValidation">
    <dg-fb-validation-error-formcontrol
      [group]="group"
      [validators]="validators"
      [control]="item.control"
      [refresh$]="refreshValidation$"
    ></dg-fb-validation-error-formcontrol>
  </ng-container>
</div>
