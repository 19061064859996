<h3 #addressSearch>Pick a drop-off point</h3>

<div class="origin__container" *ngIf="vm$ | async as vm">
  <h2>
    <img src="./assets/svg/Icon-Location.svg" alt="Your address" />Your address
  </h2>

  <div *ngIf="!vm?.isEdit" [@fadeInAnimation]>
    <div class="address">
      {{ origin?.BuildingName ? origin?.BuildingName + ', ' : '' }}
      {{
        origin?.CustomersHouseStreetName
          ? origin?.CustomersHouseStreetName + ', '
          : ''
      }}
      {{ origin?.CustomersTownCity ? origin?.CustomersTownCity + ', ' : '' }}

      {{ origin?.CustomersLocalArea ? origin?.CustomersLocalArea + ', ' : '' }}
      {{ origin?.CustomersPostCode }}
    </div>
    <br />
    <dg-fb-button
      text="Choose a different address"
      classes="btn btn-quaternary-outline cta__focusarea"
      (click)="toggleEdit(true)"
    ></dg-fb-button>
  </div>

  <div class="edit" [class.invisible]="!vm?.isEdit" [@fadeInAnimation]>
    <div class="form-input legacy-form-builder" [formGroup]="addressSearchForm">
      <label class="form-label">Start typing your address or postcode</label>

      <div
        class="form-control edit__focusarea"
        *ngIf="vm?.addressList as addressList"
      >
        <input
          type="text"
          #addressInput
          placeholder="Address"
          class="form-control"
          [dgxQmSanitise]="'block'"
          formControlName="input"
          data-automation="addressLookup"
          autocomplete="chrome-off"
          (keydown)="onKeydown($event, selected, addressList)"
        />
        <ul
          class="address-look-up"
          [@slideInOut]
          *ngIf="addressList?.length"
          tabindex="0"
          #scrollList
          (keydown)="onKeydown($event, selected, addressList)"
        >
          <li
            *ngFor="let address of addressList; index as i"
            (click)="handleAddressClick(address)"
            (keydown)="onKeydown($event, i, addressList)"
            [dgxQmSanitise]="'block'"
            [class.active]="i === selected"
          >
            {{ address.Text }}, {{ address.Description }}
            <span
              class="glyphicon glyphicon-chevron-right"
              [ngClass]="{
                hidden: handleExpandable(address)
              }"
              aria-hidden="true"
            ></span>
          </li>
        </ul>
      </div>
    </div>

    <dg-fb-button
      text="Cancel"
      classes="btn btn-quaternary-outline cta__focusarea"
      (click)="cancel()"
    ></dg-fb-button>
  </div>
</div>
