import { Component, Input } from '@angular/core';
import { fadeInAnimation, HistoryMessage } from '@domgen/dgx-components';
import { Observable } from 'rxjs';
import { LexChatHelperService } from '../../../services/lex-chat-helper/lex-chat-helper.service';

@Component({
  selector: 'smart-fix-lex-history',
  templateUrl: './lex-history.component.html',
  styleUrls: ['./lex-history.component.scss'],
  animations: [fadeInAnimation],
})
export class LexHistoryComponent {
  @Input() lexHistory!: Observable<HistoryMessage[]>;

  constructor(private lexChatHelperService: LexChatHelperService) {}

  isYoutubeUrl(url: string | undefined) {
    return this.lexChatHelperService.isYoutubeUrl(url);
  }
}
