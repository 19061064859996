import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromClaims from '../+state';
import * as claimActions from '../+state/claim.actions';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '@domgen/dgx-components';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CONFIG } from '@domgen/dgx-fe-config';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    @Inject(CONFIG) private readonly config: AppConfig,
    private store: Store<fromClaims.State>,
    private http: HttpClient
  ) {}

  public correlationID?: string | null = null;

  private readonly defaultHomepageURL: string =
    'https://www.domesticandgeneral.com/';

  logout(
    routeToLogin: boolean = false,
    homepageURL?: string,
    callSignoutApi = true
  ) {
    return this.callSignout(callSignoutApi).pipe(
      tap(() => {
        routeToLogin
          ? this.redirectToLogin()
          : this.redirectToHomepage(homepageURL);

        this.clearClaim();
      }),
      catchError((err) => of(this.store.dispatch(claimActions.Error(err))))
    );
  }

  clearClaim() {
    this.store.dispatch(claimActions.ClearCurrentClaim());
  }

  redirectToHomepage(homepageURL?: string) {
    window.location.href = homepageURL ? homepageURL : this.defaultHomepageURL;
  }

  redirectToLogin() {
    window.location.href = this.config.myAccountLogoutPage ?? '';
  }

  private callSignout(callSignoutApi: boolean) {
    return callSignoutApi
      ? this.http
          .post(`${this.config.myAccountApi}/v1/signout`, {})
          .pipe(catchError(() => of(null)))
      : of(null);
  }
}
