import { Component, Input } from '@angular/core';
import { ChatBotEventType } from '@domgen/smart-fix/data-access-smart-fix';

@Component({
  selector: 'smart-fix-chatbot-welcome-message',
  templateUrl: './chatbot-welcome-message.component.html',
  styleUrls: ['./chatbot-welcome-message.component.scss'],
})
export class ChatbotWelcomeMessageComponent {
  @Input() event!: ChatBotEventType;
  @Input() closed = false;
}
