import { Component, Input } from '@angular/core';

@Component({
  selector: 'smart-fix-lex-response-card',
  templateUrl: './lex-response-card.component.html',
  styleUrls: [
    './../../chatbot-welcome-message/chatbot-welcome-message.component.scss',
    './lex-response-card.component.scss',
  ],
})
export class LexResponseCardComponent {
  @Input() title!: string | undefined;
  @Input() imageUrl!: string | undefined;
}
