import { CommonModule, LOCATION_INITIALIZED } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  Injector,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/* MODULES */
import {
  AppProvidersConfig,
  AppProvidersConfigToken,
  ENVIRONMENT,
  GA_CLIENT,
  SharedComponentsUiModule,
  commonConfigFactory,
  lightstepConfigFactory,
} from '@domgen/dgx-components';
/* COMPONENTS */
import {
  ClaimsDataAccessClaimsModule,
  HttpInterceptorService,
} from '@domgen/data-access-claims';
import { BookingMapService } from '@domgen/dgx-components';
import { DgxComponentsLayoutModule } from '@domgen/dgx-fe-components/layout';
import { GAService, GA_CONFIG } from '@domgen/dgx-fe-ga';
import { DgxI18nModule } from '@domgen/dgx-fe-i18n';
import { SharedUiSvgModule } from '@domgen/ui-svg';
import { environment } from '@domgen/util-environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CookieService } from 'ngx-cookie-service';
import { ClaimsAppComponent } from './claims-app.component';
import { ClaimsFeatureShellRoutingModule } from './claims-feature-shell-routing.module';
import { DgxLightstepModule, LIGHTSTEP_CONFIG } from '@domgen/dgx-fe-lightstep';
import { CONFIG, DgxConfigModule } from '@domgen/dgx-fe-config';
import { COMMON_CONFIG } from '@domgen/dgx-fe-common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';

const storeDevToolsModule = !environment.production
  ? [
      StoreDevtoolsModule.instrument({
        name: 'Domestic & General',
        maxAge: 25,
      }),
    ]
  : [];

const gaTagsTokenFactory = (injector: Injector) => {
  return injector.get(AppProvidersConfigToken).gaTagsConfig;
};

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(
    httpClient,
    'assets/i18n/',
    `.json?v=${new Date().getTime()}`
  );
}

export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {
        // TODO here we can detect user browser language and load correct translations in the future
        const langToSet = 'en';
        translate.setDefaultLang('en');
        translate.use(langToSet).subscribe(
          () => {
            // eslint-disable-next-line no-restricted-syntax
            console.info(`Successfully initialized '${langToSet}' language.'`);
          },
          (err) => {
            console.error(
              `Problem with '${langToSet}' language initialization.'`
            );
          },
          () => {
            resolve(null);
          }
        );
      });
    });
}

@NgModule({
  declarations: [ClaimsAppComponent],
  exports: [ClaimsAppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    ClaimsFeatureShellRoutingModule,
    SharedComponentsUiModule,
    SharedUiSvgModule,
    HttpClientModule,
    ClaimsDataAccessClaimsModule,
    DgxI18nModule,
    ...storeDevToolsModule,
    DgxComponentsLayoutModule,
    DgxConfigModule.forRoot(),
    DgxLightstepModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [GAService],
})
export class ClaimsFeatureShellModule {
  static forRoot(
    appConfig: AppProvidersConfig
  ): ModuleWithProviders<ClaimsFeatureShellModule> {
    return {
      ngModule: ClaimsFeatureShellModule,
      providers: [
        { provide: AppProvidersConfigToken, useValue: appConfig },
        { provide: ENVIRONMENT, useValue: environment },
        {
          provide: GA_CONFIG,
          useValue: {
            debug: true,
          },
        },
        {
          provide: APP_INITIALIZER,
          useFactory: appInitializerFactory,
          deps: [TranslateService, Injector],
          multi: true,
        },
        {
          provide: COMMON_CONFIG,
          useFactory: commonConfigFactory,
          deps: [CONFIG],
        },
        {
          provide: LIGHTSTEP_CONFIG,
          useFactory: lightstepConfigFactory,
          deps: [CONFIG],
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpInterceptorService,
          multi: true,
        },
        {
          provide: GA_CLIENT,
          useFactory: gaTagsTokenFactory,
          deps: [Injector],
        },

        BookingMapService,
        CookieService,
        {
          provide: 'isDevelopmentEnvironment',
          useValue: !environment.production,
        },
      ],
    };
  }
}
