import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RepairDetailsComponent } from './repair-details/repair-details.component';
import { ClaimsFeatureRepairDetailsRoutingModule } from './claims-feature-repair-details-routing.module';
import {
  FormBuilderModule,
  SharedComponentsUiModule,
} from '@domgen/dgx-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SectionTitleComponent } from './repair-details/components/section-title/section-title.component';
import { ClaimFaultTypeFormComponent } from './repair-details/components/claim-fault-type-form/claim-fault-type-form.component';
import { DgxComponentsButtonModule } from '@domgen/dgx-fe-components/button';
import { ClaimFaultTypeFormHelperService } from './repair-details/components/claim-fault-type-form/claim-fault-type-form-helper.service';
import { ClaimFaultTypeStore } from './repair-details/components/claim-fault-type-form/claim-fault-type.store';
import { QuestionFormListInputComponent } from './repair-details/components/question-form-list-input/question-form-list-input.component';
import { QuestionFormComponent } from './repair-details/components/question-form/question-form.component';
import { RepairDetailsComponentService } from './services/repair-details-component.service';
import { RepairDetailsService } from './services/repair-details.service';
import { CalendarModule } from 'primeng/calendar';
import { ContactInformationComponent } from './repair-details/components/contact-information/contact-information.component';
import { RepairOptionsComponent } from './repair-details/components/repair-options/repair-options.component';
import { ClaimTypeSelectionNoticeComponent } from './repair-details/components/claim-type-selection-notice/claim-type-selection-notice.component';
import { DgxQmModule } from '@domgen/dgx-fe-qm';
import { ExcessInformationComponent } from './repair-details/components/excess-information/excess-information.component';
import { CallBackWidgetCalendarComponent } from './repair-details/components/call-back-widget-calendar/call-back-widget-calendar.component';
import { CallBackQuestionFormComponent } from './repair-details/components/call-back-question-form/call-back-question-form.component';
import { ContactDetailsLookupService } from './repair-details/components/contact-information/contact-details-lookup.service';
import { DgxFormsInputModule } from '@domgen/dgx-fe-forms/input';
import { DgxComponentsIconModule } from '@domgen/dgx-fe-components/icon';
import { FeatureEnabledDirective } from '@domgen/dgx-fe-config';
import { SmartFixFeatureChatModule } from '@domgen/smart-fix/feature-chat';

const COMPONENTS = [
  RepairDetailsComponent,
  SectionTitleComponent,
  ClaimFaultTypeFormComponent,
  QuestionFormComponent,
  QuestionFormListInputComponent,
  ContactInformationComponent,
  RepairOptionsComponent,
  ClaimTypeSelectionNoticeComponent,
  CallBackWidgetCalendarComponent,
  CallBackQuestionFormComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ClaimsFeatureRepairDetailsRoutingModule,
    SharedComponentsUiModule,
    FormsModule,
    FormBuilderModule,
    ReactiveFormsModule,
    FormBuilderModule,
    DgxComponentsButtonModule,
    ReactiveFormsModule,
    CalendarModule,
    DgxQmModule,
    DgxFormsInputModule,
    DgxComponentsIconModule,
    FeatureEnabledDirective,
    SmartFixFeatureChatModule,
  ],
  declarations: [...COMPONENTS, ExcessInformationComponent],
  exports: [...COMPONENTS],
  providers: [
    RepairDetailsService,
    RepairDetailsComponentService,
    ClaimFaultTypeStore,
    ClaimFaultTypeFormHelperService,
    ContactDetailsLookupService,
  ],
})
export class ClaimsFeatureRepairDetailsModule {}
