<section class="my-account-banner">
  <section class="my-account-banner__content">
    <h5 class="title">{{ heading }}</h5>
    <dgx-components-button-link
      iconAlign="right"
      iconName="arrow-right"
      [dgxGaClick]="{
        event: 'genericGAEvent',
        clientName: 'DG Claims',
        pageName,
        eventLabel: navigationLink.label,
        eventAction: 'booking confirmed - click CTA',
        eventCategory: 'MMR-interactions',
        planNumber,
        productName
      }"
      [navigationLink]="navigationLink"
      variant="secondary"
      [newWindow]="true"
    />
  </section>
  <section class="my-account-banner__image">
    <div class="ellipse-image" id="my-account-banner__image"></div>
  </section>
</section>
