<div class="dropoff__layout" *ngIf="vm$ | async as vm">
  <claims-dropoff-origin
    class="tab-view"
    [origin]="vm.origin"
    [claim]="claim$ | async"
  ></claims-dropoff-origin>
  <claims-map-tabs>
    <claims-map-tab [title]="'List'" class="list">
      <div class="sidebar__container">
        <claims-dropoff-origin
          class="list-view"
          [origin]="vm.origin"
          [claim]="claim$ | async"
        ></claims-dropoff-origin>
        <claims-dropoff-list></claims-dropoff-list>
      </div>
    </claims-map-tab>
    <claims-map-tab [title]="'Map'" class="map">
      <claims-map
        [serviceProviders]="vm.dropOff"
        [origin]="vm.origin"
        [highlighted]="vm.highlighted"
        [selected]="vm.selected"
      ></claims-map>
    </claims-map-tab>
  </claims-map-tabs>
</div>
