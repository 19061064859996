import { Component } from '@angular/core';
import { delayWhen, interval, takeWhile, timer, map, repeat, take } from 'rxjs';

@Component({
  selector: 'smart-fix-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: [
    './error-dialog.component.scss',
    './../error-fallback/error-fallback.component.scss',
  ],
})
export class ErrorDialogComponent {
  countDown = 5;
  countDown$ = interval(500)
    .pipe(
      map((i) => 5 - i),
      takeWhile((value) => value >= 1),
      delayWhen(() => timer(1000)),
      repeat(3)
    )
    .subscribe((val) => (this.countDown = val));
}
