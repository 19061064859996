import { map } from 'rxjs/operators';
import { DropoffStateService } from './../../book-dropoff-data-access/book-dropoff-state.service';
import { Api } from '@domgen/dgx-components';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'claims-dropoff-container',
  templateUrl: './dropoff-container.component.html',
  styleUrls: ['./dropoff-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropoffContainerComponent {
  @Input() serviceProviders?: Api.ServiceProvider[];
  claim$ = this._state.activeClaim$;
  vm$ = this._state.vm$;
  origin$ = this._state.vm$.pipe(map((vm) => vm.origin));

  constructor(private _state: DropoffStateService) {}
}
