import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  BookingReferenceOnlyType,
  FormsService,
  ServiceOption,
  ServiceOptionTypes,
} from '@domgen/dgx-components';
import { ClaimHelperService } from '@domgen/data-access-claims';

export interface ServiceOptionRadio extends ServiceOption {
  label?: { title: string; description: string };
}

@Injectable({
  providedIn: 'root',
})
export class RepairOptionsComponentService {
  constructor(
    private formsService: FormsService,
    private _claimHelper: ClaimHelperService
  ) {}

  modifyRepairOptions(
    claim,
    repairOptions,
    radioClasses,
    radioGroupClasses,
    headingClass,
    svgClasses?
  ) {
    this.scrollToPosition();
    const isFloorcare = this._claimHelper.isFloorcare(claim);
    repairOptions = this.formatOptions(repairOptions, isFloorcare);
    const repairOptionData = {
      groups: [
        {
          radios: {
            control: 'serviceOption',
            elementsPerPage: 6,
            classes: radioClasses,
            validators: [
              {
                type: 'required',
                message: 'Please select a repair option.',
              },
            ],
            radioGroup: repairOptions.map((o) => {
              const type = o.ServiceOptionType.replace(
                /\s+/g,
                ''
              ).toLowerCase();
              return {
                value: o.ServiceOptionType + `_${o.ServiceOptionID}`,
                text: `<div class="${headingClass}">${o?.label?.title}</div><p>${o?.label?.description}</p>`,
                icon: this.getIconType(
                  o.ServiceOptionType,
                  o.ServiceOptionLabel,
                  isFloorcare
                ),
                classes: `${radioGroupClasses} ${type}`,
                hiddenAccessibilityText: '',
                svgClasses: svgClasses,
              };
            }),
          },
        },
      ],
    };

    const defaults = {};

    const repairOptionsForm = new UntypedFormGroup(
      this.formsService.defineformControls(repairOptionData.groups, defaults)
    );

    return {
      repairOptions,
      repairOptionData,
      repairOptionsForm,
    };
  }

  formatOptions(repairOptions, isFloorcare: boolean) {
    const hasGameExchangeOption = repairOptions.find((item) =>
      item.ServiceOptionLabel.includes('Game Exchange')
    );

    return [...repairOptions].map((option) => {
      let label = {} as { title: string; description: string };

      switch (option.ServiceOptionType) {
        case ServiceOptionTypes.Collection:
          label = this.getCollectionLabel(
            option,
            hasGameExchangeOption,
            isFloorcare
          );
          break;
        case ServiceOptionTypes.DropOff:
          label.title = 'Book a drop-off';
          label.description =
            'Take your broken item to one of our drop-off points. It will then be sent for repair.';
          break;
        case ServiceOptionTypes.Home:
          if (
            option.ServiceOptionLabel ===
            BookingReferenceOnlyType.BookingReferenceOnly
          ) {
            label.title =
              'The repairer will<br class="text-break"> contact you';
            label.description =
              'Usually, they’ll be in touch within one working day to arrange your engineer visit.';
            break;
          }
          label.title = 'Engineer visit';
          label.description =
            'Pick a day for an expert engineer to visit and repair your appliance.';
          break;
        case ServiceOptionTypes.Manual:
          label.title = 'Contact the service provider';
          label.description =
            'Get in touch with the service provider directly and arrange for your item to be fixed.';
          break;
        case ServiceOptionTypes.PayClaim:
          label.title = 'Pay and claim';
          label.description =
            'Contact a local repairer for a quote and once approved, get your item fixed. We’ll then fully reimburse you.';
          break;
        case ServiceOptionTypes.SelfSend:
          label.title = 'Arrange a Self-send';
          label.description =
            'We’ll provide you with everything you need so that you can send your item for repair.';
          break;
      }

      return {
        ...option,
        label,
      };
    });
  }

  getCollectionLabel(option, hasGameExchangeOption, isFloorcare) {
    if (option.ServiceOptionLabel.includes('Game Exchange')) {
      return {
        title: 'Book a collection (replacement)',
        description:
          'You’re eligible for a replacement. Choose when you’d like a courier to collect your broken console. We’ll then aim to deliver your replacement in around 3 working days – and your existing plan will end.',
      };
    }

    if (hasGameExchangeOption) {
      return {
        title: 'Book a collection (repair)',
        description:
          'We can repair your console. Choose when you’d like a courier to collect it.',
      };
    }

    if (isFloorcare) {
      return {
        title: 'Book a collection',
        description:
          'The repairer will be in touch to arrange the collection date for your item.',
      };
    }

    return {
      title: 'Book a collection',
      description:
        'Choose a date for when you’d like a courier to collect your broken item for repair.',
    };
  }

  getIconType(optionType: string, optionLabel: string, isFloorcare: boolean) {
    switch (optionType) {
      case ServiceOptionTypes.Collection:
        return isFloorcare ? 'truck' : 'delivery-home';
      case ServiceOptionTypes.DropOff:
        return 'location-pin';
      case ServiceOptionTypes.Home: // TODO confirm correct icon
        if (optionLabel === BookingReferenceOnlyType.BookingReferenceOnly) {
          return 'user-headset';
        }
        return 'engineer-repair';
      case ServiceOptionTypes.Manual:
        return 'contact-centre';
      case ServiceOptionTypes.PayClaim:
        return 'sterling-pound';
      case ServiceOptionTypes.SelfSend:
        return 'post-env';
      default:
        return 'tick';
    }
  }

  scrollToPosition() {
    setTimeout(() => {
      // timeout for user experience
      const region = document.getElementById('RepairOptionSection');
      if (region) {
        region.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300);
  }
}
