import { DgxIconName } from '@domgen/dgx-fe-components/icon';

export type EventTypeKeys = 'chat' | 'embeddedVideo' | 'disclaimer' | 'user';

export type DisclaimerOption = {
  description?: string;
  icon?: string;
};

export type DateType = {
  date: Date;
};

export enum productTypeEnums {
  WashingMachine = 'washing machine',
  WasherDryer = 'washer dryer',
  TumbleDryer = 'tumble dryer',
}

export type HistoryType = ChatBotEventType & DateType;

export type ChatBotEventType = {
  type: string;
  details: {
    header?: string;
    delay?: number;
    description?: string;
    options?: Array<DisclaimerOption>;
    videoUrl?: string;
    videoOffset?: number;
    icon?: string;
    continueToRepairBooking?: boolean;
    continueToMyAccount?: boolean;
    showConfirmationDialog?: boolean;
    trackFeedback?: boolean;
  };
};

export interface ChatComponent {
  id: number;
  autoNext: boolean;
  event: ChatBotEventType;
  textId?: string;
  children: ChatComponent[];
}

export interface Option {
  id: number;
  children: ChatComponent[] | ChatComponent;
  question?: string;
  autoNext: boolean;
  event: ChatBotEventType;
  textId?: string;
}
