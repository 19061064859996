import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'dg-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() message?: string | null;
  @Input() overlayOnTopOfExistingContent = false;
  @ViewChild('lottieElement') lottieElement?: ElementRef;
}
