<div class="full-page" *ngIf="errorObject">
  <dg-sub-header *ngIf="isNoEngineerError" [product]="product"></dg-sub-header>

  <div class="container">
    <div
      class="grid-row text--center row--padding row--spacer-top justify--center"
    >
      <div class="xs-12">
        <h2>{{ errorObject.title }}</h2>
      </div>
      <div class="xs-12 md-9">
        <p>{{ errorObject.description }}</p>
      </div>
    </div>

    <div class="grid-row" *ngIf="errorObject.ctaText">
      <div class="xs-12 row--spacer-bottom row--spacer-top-small">
        <div class="flex justify--center">
          <dg-fb-button
            text="{{ errorObject.ctaText }}"
            (click)="route()"
            classes="btn btn--primary"
          ></dg-fb-button>
        </div>
      </div>
    </div>

    <div class="grid-row row--spacer-bottom">
      <div class="xs-12">
        <hr class="divider-fat row--spacer-bottom-small" />
      </div>
    </div>
    <div class="grid-row row--spacer-top-small row--spacer-bottom">
      <div class="xs-10">
        <strong>{{ errorObject.contactDetails.GetInTouchText }}</strong>
        <h2>
          {{ errorObject.contactDetails.SpCallText }}
          <span class="d-md-inline">
            <a
              class="typog-h2"
              href="tel:{{ errorObject.contactDetails.SpPhoneNumber }}"
            >
              {{ errorObject.contactDetails.SpPhoneNumber }}
            </a>
          </span>
        </h2>
        <p>
          {{ errorObject.contactDetails.SpOpeningHours }}
        </p>
        <br />
        <p>
          {{ errorObject.contactDetails.SpCallCosts }}
        </p>
        <p>
          Got any questions?
          <a
            class="link link-underline"
            href="https://www.domesticandgeneral.com/content/help-advice-section/faq"
            >Visit our Help & Advice</a
          >
        </p>
      </div>
    </div>
  </div>
</div>

<!-- <dg-footer></dg-footer> -->
