<section class="columns-container">
  <h2 class="columns-title">{{ heading }}</h2>
  <section class="columns-container__wrapper">
    <div class="columns-item" *ngFor="let item of items">
      <div class="columns-item__wrapper">
        <dgx-components-icon
          [class]="item.iconColor"
          [name]="item.iconName"
          size="md"
        >
        </dgx-components-icon>
        <h5>
          {{ item.heading }}
        </h5>
      </div>
      <p [innerHTML]="item.description"></p>
    </div>
  </section>
</section>
