import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
} from '@angular/core';

import { MapTabComponent } from './../map-tab/map-tab.component';

@Component({
  selector: 'claims-map-tabs',
  templateUrl: './map-tabs.component.html',
  styleUrls: ['./map-tabs.component.scss'],
})
export class MapTabsComponent implements AfterContentInit {
  /**
   * Get a reference to the tabs
   */
  @ContentChildren(MapTabComponent) tabs?: QueryList<MapTabComponent>;

  /**
   * Set contentChildren active state
   */
  ngAfterContentInit() {
    this.setDefaultTab();
  }

  /**
   * Get all active tabs | if there is no active tab set, activate the first
   */
  setDefaultTab() {
    const activeTabs = this.tabs?.filter((tab) => tab.active);
    if (!activeTabs?.length) {
      this.selectTab((this.tabs as QueryList<MapTabComponent>)?.first);
    }
  }

  /**
   * Deactivate all tabs and activate the tab passed in as a parameter
   */
  selectTab(selectedTab: MapTabComponent) {
    (this.tabs as QueryList<MapTabComponent>)
      .toArray()
      .forEach((tab) => (tab.active = false));
    selectedTab.active = true;
  }
}
