import {
  AddressItem,
  AddressItemDetail,
  PcaService,
} from '@domgen/dgx-components';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  tap,
  filter,
  switchMap,
} from 'rxjs/operators';
import { ComponentStore } from '@ngrx/component-store';
import { Injectable } from '@angular/core';

export interface LocalState {
  isEdit: boolean;
  addressList: AddressItem[];
}

@Injectable()
export class OriginStateService extends ComponentStore<LocalState> {
  addressSearchForm: UntypedFormGroup = this.fb.group({
    input: '',
  });

  readonly addressSearchForm$ = this.addressSearchForm
    .get('input')
    .valueChanges.pipe(
      map((input: string) => input.trim()),
      distinctUntilChanged(),
      debounceTime(200)
    );

  readonly addressList$ = this.select((state) => state.addressList);

  readonly isEdit$ = this.select((state) => state.isEdit);

  readonly vm$ = this.select(
    this.select((state) => state),
    this.addressList$,
    this.isEdit$,
    (state, addressList, isEdit) => ({
      ...state,
      addressList,
      isEdit,
    })
  );

  readonly setAddressList = this.updater(
    (state, addressList: AddressItem[]) => ({
      ...state,
      addressList,
    })
  );

  readonly toggleEdit = this.updater((state, value: boolean) => ({
    ...state,
    isEdit: value,
  }));

  constructor(private fb: UntypedFormBuilder, private pca: PcaService) {
    super({
      isEdit: false,
      addressList: [],
    });
  }

  readonly getAddressList = this.effect(() => {
    return this.addressSearchForm$.pipe(
      map((input: string) => input.trim()),
      distinctUntilChanged(),
      debounceTime(200),
      tap((input: string) => {
        if (!input?.length) {
          this.setAddressList([]);
        }
      }),
      filter(Boolean),
      switchMap((input: string) =>
        this.pca.pcaFind(input).pipe(
          map(({ Items = [] }: { Items: AddressItem[] }) => {
            this.setAddressList([...Items]);
          })
        )
      )
    );
  });
}
