<div *ngIf="$vm | async as vm" class="mt-6 pb-6">
  <dg-information-notice
    *ngFor="let s of vm.selectionSummary"
    [title]="s.title"
    [description]="s.description"
    [theme]="'theme-blue'"
    [icon]="'tick-outlined'"
    [cta]="'Change'"
    (changeCTA)="
      cs.resetForm(vm.selectionSummary, s.formName, vm.claim, freeTextSearch);
      faultDataSearchForm.reset();
      formSubmitted = false
    "
  >
  </dg-information-notice>

  <dg-information-notice
    *ngIf="vm.formData"
    [title]="vm.formData.header"
    [description]="vm.formData.description"
    [ngClass]="{
      fts:
        vm.formData.freeTextSearch &&
        !vm.freeTextSearchState?.fallBackToOldSelect
    }"
  >
    <form
      *ngIf="
        vm.formData.freeTextSearch &&
        !vm.freeTextSearchState?.fallBackToOldSelect
      "
      [formGroup]="faultDataSearchForm"
      class="free-text-search-form xs-12"
      [ngClass]="{
        hasDescription:
          vm.freeTextSearchState.results.length > 0 || showFreeTextSearchError
      }"
      (submit)="searchFaults()"
    >
      <ul>
        <li>
          <p class="typog-body">
            Please enter a keyword most related to the fault you are
            experiencing.
          </p>
        </li>
        <li>
          <p class="typog-body">
            If your appliance has multiple faults, select the main one.
          </p>
        </li>
        <li>
          <p class="typog-body">
            You can always give more details in the
            <strong>comments box</strong> later on.
          </p>
        </li>
      </ul>

      <section class="search">
        <dgx-form-row>
          <dgx-form-field
            label="Fault selection"
            formControlName="search"
            [errorsHidden]="true"
            [optionalHidden]="true"
          >
            <dgx-form-text-input
              name="fault-search"
              placeholder="Enter the fault"
            >
            </dgx-form-text-input>

            <dg-loader *ngIf="vm.freeTextSearchState.running"></dg-loader>

            <dgx-components-icon
              *ngIf="showFreeTextSearchError"
              name="circle-exclamation"
              class="error-icon"
            >
            </dgx-components-icon>
          </dgx-form-field>
          <small
            *ngIf="
              showFreeTextSearchError || vm.freeTextSearchState.results.length
            "
            class="free-text-search-hint typog-caption"
            [ngClass]="{
              error: showFreeTextSearchError
            }"
          >
            <ng-container
              *ngIf="
                vm.freeTextSearchState.run &&
                vm.freeTextSearchState.results.length &&
                !showFreeTextSearchError
              "
            >
              {{ vm.freeTextSearchState.results.length }} search
              {{
                vm.freeTextSearchState.results.length === 1
                  ? 'result'
                  : 'results'
              }}
            </ng-container>

            <ng-container *ngIf="showFreeTextSearchError">
              {{
                faultDataSearchForm.get('search').errors?.required
                  ? 'Enter fault'
                  : "We don't recognise that fault. Check and try again."
              }}
            </ng-container>
          </small>
        </dgx-form-row>

        <dgx-components-button
          iconName="search"
          iconAlign="right"
          type="submit"
          [ngClass]="{
            formRun:
              vm.freeTextSearchState.run &&
              (vm.freeTextSearchState.results.length || showFreeTextSearchError)
          }"
        >
          Search
        </dgx-components-button>
      </section>

      <section
        *ngIf="vm.freeTextSearchState.results.length"
        class="results"
        #optionContainer
      >
        <section
          class="option"
          *ngFor="let result of vm.freeTextSearchState.results"
        >
          <p>
            {{ result.faultId_descr }} -
            <span>{{ result.problemId_descr }}</span>
          </p>

          <dgx-components-button
            [fill]="true"
            variant="secondary"
            (clicked)="selectResult(result, vm.selectionSummary)"
          >
            Select
          </dgx-components-button>
        </section>
      </section>
    </form>
  </dg-information-notice>

  <dg-information-notice
    *ngIf="vm.showContinueCTA"
    [title]="
      vm?.selectionSummary?.length === 1
        ? 'Please check your selection and make any changes if needed.'
        : 'Please check your selections and make any changes if needed.'
    "
    [description]="description"
  >
  </dg-information-notice>

  <form
    *ngIf="
      vm.form &&
      (!vm.formData.freeTextSearch ||
        vm.freeTextSearchState?.fallBackToOldSelect)
    "
    [formGroup]="vm.form"
    (change)="formTypeChange(vm.form, vm.selectionSummary, vm.claim)"
  >
    <section
      *ngIf="vm.freeTextSearchState?.fallBackToOldSelect && showFallbackAlert"
      class="fallback-error"
    >
      <dgx-components-icon name="circle-exclamation"></dgx-components-icon>
      <span>Sorry, something went wrong. Please pick a fault instead.</span>
    </section>
    <div [@fadeInAnimation]>
      <dg-fb-radiobutton
        *ngFor="let group of vm.formData.groups"
        [elementsPerPage]="group.radios?.elementsPerPage"
        [group]="vm.form"
        [align]="'justify--left'"
        [classes]="'dg-radios ' + group.radios?.classes"
        [idPrefix]="group.radios?.control"
        [control]="group.radios?.control"
        [items]="group.radios?.radioGroup"
        [validators]="group.radios?.validators"
      >
      </dg-fb-radiobutton>
    </div>
  </form>

  <claims-claim-type-selection-notice
    *ngIf="showInWarrantyNotice || showplanInWaitPeriodNotice || isIWEnabled"
    [claim]="claim"
    [showInWarrantyNotice]="showInWarrantyNotice"
    [showplanInWaitPeriodNotice]="showplanInWaitPeriodNotice"
    [isIWEnabled]="isIWEnabled"
    (continueClicked)="continue(vm?.form)"
    (routeToPlanDetails)="routeToPlanDetails()"
  ></claims-claim-type-selection-notice>

  <smart-fix-chat-container
    [claim]="vm.claim"
    *ngIf="
      vm.claim &&
      !showBedRockChatContainer &&
      showSmartFixChatBot &&
      mode === 'filter'
    "
    (closedChatNotification)="onChangeSmartFixChat($event)"
  ></smart-fix-chat-container>

  <smart-fix-boiler-chat-container
    [claim]="vm.claim"
    *ngIf="vm.claim && showSmartFixChatBot && mode === 'boiler'"
    (closedChatNotification)="onChangeSmartFixChat($event)"
  ></smart-fix-boiler-chat-container>

  <smart-fix-bedrock-chat-container
    [claim]="vm.claim"
    *ngIf="vm.claim && showBedRockChatContainer"
    (closedChatNotification)="onChangeSmartFixChat($event)"
  >
  </smart-fix-bedrock-chat-container>
  <div
    *ngIf="
      showPreChatBanner &&
        !isGasLeak(vm.selectionSummary) &&
        vm.showContinueCTA;
      else currentCTA
    "
  >
    <div class="block">
      <div *ngIf="smartFixChatState === 'open'" data-testid="showPreChatBanner">
        <smart-fix-pre-chat-banner-boiler
          *ngIf="isProduct(claim, 'boiler'); else washing"
          (openChatbot)="triggerSmartFixChatBot('boiler')"
          (continueBooking)="continueBooking()"
          (bannerVisible)="preChatVisible()"
        ></smart-fix-pre-chat-banner-boiler>
        <ng-template #washing>
          <smart-fix-pre-chat-banner
            class="flex"
            [appliance]="productType"
            (openChatbot)="triggerSmartFixChatBot('filter')"
            (continueBooking)="continueBooking()"
            (bannerVisible)="preChatVisible()"
          ></smart-fix-pre-chat-banner>
        </ng-template>
      </div>

      <div
        *ngIf="vm.showContinueCTA && smartFixChatState === 'closed'"
        class="flex mt-6 pb-6"
      >
        <dgx-components-button
          type="submit"
          variant="primary"
          iconName="arrow-right"
          (click)="continueBookingAfterSmartFixChat()"
          data-automation="'ConfirmAD'"
        >
          {{ cta }}
        </dgx-components-button>
      </div>
    </div>
  </div>

  <ng-template #currentCTA>
    <div *ngIf="vm.showContinueCTA" class="flex mt-6 pb-6">
      <dgx-components-button
        type="submit"
        variant="primary"
        iconName="arrow-right"
        (click)="cs.submitClaim(true)"
        data-automation="'ConfirmAD'"
      >
        {{ cta }}
      </dgx-components-button>
    </div>
  </ng-template>
</div>
