<div>
  <button
    class="chatbot-toggle-button"
    type="submit"
    id="smart-fix-chat-client-container"
    data-testid="toggle-button"
    (click)="toggleMinimize()"
    (keydown.enter)="toggleMinimize()"
  >
    <dgx-components-icon name="comment" size="lg" class="chatbot-icon">
    </dgx-components-icon>
    <span
      class="badge"
      *ngIf="unreadMessages > 0 && !closed"
      data-testid="unread-messages"
      >{{ unreadMessages }}</span
    >
  </button>

  <div
    class="chatbot-container"
    [ngClass]="{ minimized: minimized, closed: closed }"
    data-testid="chatbot-container"
  >
    <div class="chatbot-scroller" #scrollContainer>
      <section [class.exit-dialog]="isExitDialogVisible">
        <smart-fix-exit-dialog-component
          *ngIf="isExitDialogVisible"
          [message]="message"
          (cancel)="onEndChat()"
          (continue)="onContinueChat()"
          data-testid="exit-dialog"
        ></smart-fix-exit-dialog-component>
      </section>

      <smart-fix-chatbot-header
        (closeChatBot)="closeChatBot()"
        (minimizeChatBot)="toggleMinimize()"
        data-testid="chatbot-header"
      ></smart-fix-chatbot-header>

      <div class="chatbot-content">
        <smart-fix-chat-history
          [closed]="closed"
          *ngIf="history$ | async"
          [history]="history$"
          data-testid="chat-history"
        />
        <div
          *ngFor="let component of currentComponentChildren$ | async"
          [@chatBotFadeInAnimation]
          data-testid="chat-component"
        >
          <ng-container [ngSwitch]="component.event.type">
            <ng-container *ngSwitchCase="'disclaimer'">
              <div>
                <smart-fix-chatbot-disclaimer
                  [event]="component.event"
                  data-testid="chatbot-disclaimer"
                ></smart-fix-chatbot-disclaimer>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'welcome-message'">
              <smart-fix-chatbot-welcome-message
                [event]="component.event"
                data-testid="chatbot-welcome-message"
              />
            </ng-container>

            <ng-container *ngSwitchCase="'chat'">
              <dgx-components-button
                class="mb-4 pre-wrap-text fix-width"
                iconAlign="left"
                [iconName]="getIcon(component.event.details.icon)"
                [disabled]="closed"
                variant="secondary"
                [fill]="true"
                (click)="chooseOption(component)"
                (keydown.enter)="chooseOption(component)"
                data-testid="chat-option-button"
              >
                {{ component.event.details.description }}
              </dgx-components-button>
            </ng-container>
            <ng-container *ngSwitchCase="'embeddedVideo'">
              <div>
                <smart-fix-video-player
                  [videoUrl]="component.event.details.videoUrl"
                  [description]="component.event.details.description"
                  [offset]="component.event.details.videoOffset"
                  data-testid="embedded-video"
                />
              </div>
            </ng-container>
          </ng-container>
        </div>

        <span
          class="text--center"
          data-testid="closed-chat-message"
          *ngIf="closedDate"
        >
          <p>This chat is ended</p>
          <p>{{ closedDate | customClosedChatDate }}</p>
        </span>
      </div>
    </div>
  </div>
</div>
