import { Injectable } from '@angular/core';
import { Claim, ClaimBundleType } from '@domgen/dgx-components';
import { claimActions, ClaimFacade } from '@domgen/data-access-claims';
import { Api, PageSection, PageSectionId } from '@domgen/dgx-components';
import { UntypedFormGroup } from '@angular/forms';
import { ClaimHelperService } from '@domgen/data-access-claims';

@Injectable()
export class RepairDetailsService {
  readonly allClaimTypeCodes = ['BD', 'AD', 'ASV', 'BDBOCO', 'BDBOSY'];
  readonly breakdownClaimTypeCodes = ['BD', 'BDBOCO', 'BDBOSY'];
  readonly sectionSchemaDefault: PageSection[] = [
    {
      id: PageSectionId.ClaimTypeAndFaultTypeQuestions,
      title: 'Describe the fault',
      completed: false,
      active: false,
    },
    {
      id: PageSectionId.FaultDetailsQandA,
      title: 'Fault details',
      completed: false,
      active: false,
    },
    {
      id: PageSectionId.ContactDetails,
      title: 'Contact details',
      completed: false,
      active: false,
    },
    {
      id: PageSectionId.RepairOption,
      title: 'Your repair',
      completed: false,
      active: false,
    },
    {
      id: PageSectionId.CallbackBookingWidget,
      title: 'Repair options',
      completed: false,
      active: false,
    },
  ];

  constructor(
    private claimFacade: ClaimFacade,
    private _claimHelper: ClaimHelperService
  ) {}

  showInWarrantyNotice(claim: Claim, selectedClaimTypeCode: string): boolean {
    const isBreakdown = this.breakdownClaimTypeCodes.includes(
      selectedClaimTypeCode
    );
    return isBreakdown && (claim?.getData?.PlanInWarrantyPeriod as boolean);
  }

  showplanInWaitPeriodNotice(
    claim: Claim,
    selectedClaimTypeCode: string
  ): boolean {
    return (
      (claim?.getData?.PlanInWaitPeriod as boolean) &&
      !this.showInWarrantyNotice(claim, selectedClaimTypeCode)
    );
  }

  getSectionSchemaFlags(
    sectionSchema: PageSection[],
    claim: Claim
  ): PageSection[] {
    // set completed and active flags for each section
    return sectionSchema.map((section) => {
      switch (section.id) {
        case PageSectionId.ClaimTypeAndFaultTypeQuestions:
          section.completed = claim.created as boolean;
          section.active = !claim.created;
          break;
        case PageSectionId.FaultDetailsQandA:
          section.completed = claim.accepted as boolean;
          section.active = (claim?.created as boolean) && !claim?.accepted;
          break;
        case PageSectionId.ContactDetails:
          section.completed =
            (claim.accepted as boolean) &&
            (claim?.callbackWidgetEnabled || !!claim?.serviceOptions);
          section.active =
            (claim.accepted as boolean) &&
            !claim?.callbackWidgetEnabled &&
            !claim?.serviceOptions;
          break;
        case PageSectionId.RepairOption:
          section.active =
            (claim.accepted as boolean) && !!claim?.serviceOptions;
          break;
        case PageSectionId.CallbackBookingWidget:
          section.active =
            (claim.accepted as boolean) && claim?.callbackWidgetEnabled;
          break;
      }
      return section;
    });
  }

  initSectionSchemaAndOptions(claim: Claim): {
    title: string;
    claimTypeOptions: Api.ClaimType[] | undefined;
    sectionSchema: PageSection[];
  } {
    let sectionSchema: PageSection[] = this.sectionSchemaDefault;
    let claimTypeOptions = claim.getData?.ClaimTypes.filter((claimType) =>
      this.allClaimTypeCodes.includes(claimType.ClaimTypeCode)
    );
    let title = 'Repair details'; // default
    let isAnnualService = false;

    sectionSchema = sectionSchema.filter((ps: PageSection) => {
      return (
        ps.id !==
        (claim?.getData?.IWCallbackRequired
          ? PageSectionId.RepairOption
          : PageSectionId.CallbackBookingWidget)
      );
    });

    if (this._claimHelper.isASV(claim)) {
      isAnnualService = true;
      title = 'Annual service details';
      claimTypeOptions = claim.getData?.ClaimTypes.filter(
        // filter to only Annual Service option
        (claimType) => claimType.ClaimTypeCode === 'ASV'
      );

      const sectionFaultDetailsQandA = sectionSchema.find(
        (s) => s.id === PageSectionId.FaultDetailsQandA
      );

      if (sectionFaultDetailsQandA) {
        sectionFaultDetailsQandA.title = 'Basic checks';
      }
    }

    // filter out ASV claimType if plan has a separate ASV journey
    if (
      claim?.reflect?.asvOffered === 'true' &&
      claim?.reflect?.claimType === ClaimBundleType.REPAIR
    ) {
      claimTypeOptions = claim.getData?.ClaimTypes.filter(
        (claimType) => !['ASV'].includes(claimType.ClaimTypeCode)
      );
    }

    if (claimTypeOptions?.length === 0) {
      this.claimFacade.dispatch(
        claimActions.Error({
          payload: new Error(
            `No Claim Type options available after filtering for ${title}`
          ),
        })
      );
    }

    if (claimTypeOptions?.length === 1) {
      // only one Claim Type Option...

      const claimTypeAutoSelected = claimTypeOptions[0].ClaimTypeCode;
      const stopNoticeToBeDisplayed =
        this.showInWarrantyNotice(claim, claimTypeAutoSelected) ||
        this.showplanInWaitPeriodNotice(claim, claimTypeAutoSelected);

      const noFaultQuestionsToDisplay =
        !claim.getData?.FaultData ||
        (<Api.FaultData[]>claim.getData.FaultData).length === 0 ||
        ((<Api.FaultData[]>claim.getData.FaultData).length === 1 &&
          (claim?.getData?.FaultData as Api.FaultData[])[0]?.PossibleAnswers
            ?.length === 1) ||
        (<Api.FaultData>claim.getData.FaultData).PossibleAnswers?.length === 1;

      if (
        !stopNoticeToBeDisplayed &&
        (noFaultQuestionsToDisplay || isAnnualService)
      ) {
        sectionSchema = sectionSchema.filter(
          // filter out first page section
          (section) =>
            section.id !== PageSectionId.ClaimTypeAndFaultTypeQuestions
        );
      }
    }

    return { title, claimTypeOptions, sectionSchema };
  }

  getClaimTypeIDForSubmit(
    claimTypeForm: UntypedFormGroup,
    claim: Claim,
    claimTypeOptions: Api.ClaimType[]
  ): string {
    const claimTypeID =
      claimTypeForm?.value?.claimType ||
      claim.claimSelection?.request?.ClaimTypeID ||
      (claimTypeOptions.length === 1 ? claimTypeOptions[0].ClaimTypeID : null);

    if (!claimTypeID) {
      throw new Error(
        'ClaimTypeID not found on form, state selection, or single item in list.'
      );
    }
    return claimTypeID;
  }
}
