import { Injectable, Inject } from '@angular/core';
import { CustomWindow } from '../interfaces/custom-window.interface';
import { AnalyticsService } from './analytics.service';

@Injectable({ providedIn: 'root' })
export class TimingService {
  private trackedEvents: Record<
    string,
    {
      startTime: number;
      endTime: number | undefined;
      data: Record<string, unknown>;
    }
  > = {};

  constructor(private readonly analyticsService: AnalyticsService) {}

  startTracking(id: string, data: Record<string, unknown>): void {
    this.trackedEvents[id] = {
      startTime: new Date().getTime(),
      endTime: undefined,
      data,
    };
  }

  endTracking(id: string, extraData: Record<string, unknown> = {}): void {
    if (!this.trackedEvents[id]) return;

    const endTime = new Date().getTime();
    const timeTaken = endTime - this.trackedEvents[id].startTime;

    this.trackedEvents[id].endTime = endTime;

    this.analyticsService.push({
      event: 'genericGAEvent',
      eventAction: 'page-load-timing',
      eventValue: timeTaken,
      ...this.trackedEvents[id].data,
      ...extraData,
    });
  }
}
