<div
  class="calendar-day"
  [ngClass]="{
    'calendar-day--disabled': !day?.slotsAvailable,
    'calendar-day--active': day?.selected
  }"
  id="{{ day?.date }}"
>
  <button class="calendar-day__row flex" (click)="daySelected.emit(day)">
    <ng-container *ngIf="day?.selected; else SummaryView">
      <div class="calendar-info typog-body-large-bold ml-5">
        {{ day.date | date : bookingCalendarDateFormats.DayDateMonthYear }}
      </div>
    </ng-container>

    <ng-template #SummaryView>
      <div
        class="calendar-date flex-column text--center justify--center align--center"
      >
        <span class="xs--block typog-body-bold">{{
          day?.date | date : bookingCalendarDateFormats.Day
        }}</span
        ><span class="xs--block typog-body-bold">{{
          day?.date | date : bookingCalendarDateFormats.Date
        }}</span>
      </div>
      <div
        *ngIf="slotType === slotTypes.ALLDAY"
        class="calendar-info flex justify--center align--center typog-body-bold"
      >
        Available
      </div>
      <div
        class="calendar-slots flex justify--center align--center typog-body-bold"
        *ngIf="slotType === slotTypes.SPECIFIC && day?.slotsAvailable"
      >
        {{ day?.slots?.length }}
      </div>
      <div
        *ngIf="slotType === slotTypes.SPECIFIC"
        class="calendar-info flex justify--center align--center typog-body-bold"
      >
        {{ day?.slots?.length === 1 ? 'time slot' : 'time slots' }}
      </div>
    </ng-template>

    <dgx-components-icon
      [name]="day?.selected ? 'circle-minus' : 'circle-plus'"
      size="lg"
    >
    </dgx-components-icon>
  </button>

  <dg-booking-calendar-day-all-day-slot
    *ngIf="day?.selected && slotType === slotTypes.ALLDAY"
    [@slideInOut]
    (@slideInOut.start)="animationStart()"
    [day]="day"
    [isRebook]="isRebook"
    [bookingError]="bookingError"
    [currentAppointment]="currentAppointment"
    [gaData]="gaData"
    (selected)="onSlotSelect($event)"
  >
  </dg-booking-calendar-day-all-day-slot>

  <dg-booking-calendar-day-specific-slot
    *ngIf="day?.selected && slotType === slotTypes.SPECIFIC"
    [day]="day"
    [@slideInOut]
    (@slideInOut.start)="animationStart()"
    [isRebook]="isRebook"
    [bookingError]="bookingError"
    [currentAppointment]="currentAppointment"
    [selectedItem]="localSelected"
    [gaData]="gaData"
    (selected)="onSlotSelect($event)"
    (temporarySelection)="setLocalSelected.emit($event)"
  >
  </dg-booking-calendar-day-specific-slot>
</div>
