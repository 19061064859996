import { Component, Input, OnInit } from '@angular/core';

export interface ThreeColumnItem {
  imageName: string;
  heading: string;
  description: string;
}

@Component({
  selector: 'dg-three-column-banner',
  templateUrl: './three-column-banner.component.html',
  styleUrls: ['./three-column-banner.component.scss'],
})
export class ThreeColumnComponent implements OnInit {
  @Input() heading?: string;
  @Input() items?: ThreeColumnItem[] = undefined;
  @Input() bulletPoints = false;
  @Input() stacked = false;

  constructor() {}

  ngOnInit(): void {}
}
