import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NavigationLink } from '@domgen/dgx-fe-common';
import { CONFIG } from '@domgen/dgx-fe-config';
import { AppConfig } from '../../models/config/app-config.type';

const NORTH_WEST_APPLIANCE = 'north west appliance repairs ltd';
@Component({
  selector: 'dg-my-account-banner',
  templateUrl: './my-account-banner.component.html',
  styleUrls: ['./my-account-banner.component.scss'],
})
export class MyAccountBannerComponent implements OnInit, OnChanges {
  @Input() isRepairClaim?: boolean = true;
  @Input() isWhirlpoolBooking = false;
  @Input() pageName = '';
  @Input() planNumber = '';
  @Input() productBrand = '';
  @Input() productType = '';
  @Input() isFloorcareProduct = false;
  @Input() serviceProviderCompanyName = '';

  heading = '';
  navigationLink: NavigationLink = {
    href: '',
    label: '',
  };
  productName = '';

  constructor(@Inject(CONFIG) private readonly config: AppConfig) {}

  ngOnInit(): void {
    this.updateHeading();
    this.updateNavigationLink();
    this.productName =
      `${this.productBrand?.toLowerCase()} ${this.productType?.toLowerCase()}`.trim();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.isRepairClaim ||
      changes.planNumber ||
      this.isWhirlpoolBooking
    ) {
      this.updateNavigationLink();
      this.updateHeading();
    }
  }

  private updateNavigationLink(): void {
    this.navigationLink = {
      href: `${this.config?.plansHomePage}/${this.planNumber}/claims`,
      label: `Manage my ${this.isRepairClaim ? 'repair' : 'service'}`,
    } as NavigationLink;
  }

  private updateHeading(): void {
    if (this.isFloorcareProduct) {
      const providerNameLowerCased =
        this.serviceProviderCompanyName.toLowerCase();

      if (providerNameLowerCased === NORTH_WEST_APPLIANCE) {
        this.heading = 'View your collection and repair details';
      } else {
        this.heading = 'View your repair details';
      }

      return;
    }

    if (
      this.isWhirlpoolBooking &&
      this.config.runtimeFeatures.engineerTrackingBanner
    ) {
      this.heading = 'View details, change booking and track your engineer';
      return;
    }

    this.heading = 'View details, change or cancel your booking';
  }
}
