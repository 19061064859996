<!-- Link -->
<a
  *ngIf="hint?.markup?.link"
  [class]="hint?.classes"
  href="{{ hint?.markup?.href }}"
>
  {{ hint?.text }}
</a>
<!-- Span -->
<span *ngIf="!hint?.markup" [class]="hint?.classes">
  {{ hint?.text }}
</span>
