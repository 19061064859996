import { ChatOptionLeaf } from './chat-option-leaf';
import { ChatBotEventType, ChatComponent, Option } from './chat-types';

export class ChatOptionComposite implements ChatComponent {
  id: number;
  question?: string;
  autoNext: boolean;
  children: ChatComponent[] = [];
  event: ChatBotEventType;

  constructor(option: Option) {
    this.autoNext = option.autoNext;
    this.id = option.id;
    this.event = option.event;

    if (option.question) {
      this.question = option.question;
    }

    if (option.children) {
      if (Array.isArray(option.children)) {
        option.children.forEach((child) => this.addChild(child));
      } else {
        this.addChild(option.children);
      }
    }
  }

  private addChild(child: ChatComponent): void {
    if (child.children?.length > 0) {
      this.children.push(new ChatOptionComposite(child));
    } else {
      this.children.push(new ChatOptionLeaf(child));
    }
  }
}
