<footer class="footer theme-dark">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12 col-lg-6 footer-nav">
        <ul class="footer__list footer__list--large">
          <li class="footer__item" *ngFor="let primary of primaryLinks">
            <a
              href="{{ primary.url }}"
              class="link"
              [attr.data-automation]="primary.link"
              >{{ primary.link }}</a
            >
          </li>
        </ul>
      </div>
      <div class="col-sm-12 col-md-6">
        <div
          #Trustpilot
          class="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="5419b6ffb0d04a076446a9af"
          data-businessunit-id="4cb3113600006400050dfb02"
          data-style-height="40px"
          data-style-width="100%"
          data-theme="dark"
        >
          <a
            href="https://uk.trustpilot.com/review/domesticandgeneral.com"
            target="_blank"
            rel="noopener"
            >Trustpilot</a
          >
        </div>
      </div>
    </div>
    <div class="row terms-and-conditions">
      <div class="col-12">
        <p>
          Service plans, maintenance & support plans are provided by Domestic &
          General Services Limited. Insurance policies are provided by Domestic
          & General Insurance PLC. Domestic & General Insurance PLC is an
          insurance undertaking, not an intermediary. We are the underwriter of
          the insurance policies and do not provide a personal recommendation or
          advice. Domestic & General Insurance PLC is authorised by the
          Prudential Regulation Authority and regulated by the Financial Conduct
          Authority and the Prudential Regulation Authority (Financial Services
          Register Number 202111). Our address and details of our authorisation
          can be checked on the FCA website.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ul class="footer__list">
          <li class="footer__item">
            &copy; {{ date | date : 'yyyy' }} Domestic & General Group Limited
          </li>
          <li class="footer__item" *ngFor="let secondary of secondaryLinks">
            <a
              href="{{ secondary.url }}"
              class="link typog-caption"
              [attr.data-automation]="secondary.link"
              >{{ secondary.link }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
