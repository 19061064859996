import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
  DOMAIN_CONFIG,
  domainIdFromDomain,
  ENVIRONMENT,
  RUNTIME_CONFIG
} from '@domgen/dgx-fe-config';
import { environment } from '@domgen/util-environment';
import { DomainId } from '@domgen/dgx-fe-common';
import { AppDomainConfig, AppRuntimeConfig } from '@domgen/dgx-components';

let configXhr = null;
configXhr = new XMLHttpRequest();
configXhr.open('GET', 'config.json');
configXhr.send();
configXhr.onreadystatechange = function () {
  if (this.status === 200 && this.readyState === 4) {
    Promise.all([loadDomainConfig(), loadRuntimeConfig()]).then(
      ([domainConfig, runtimeConfig]) => {
        if (environment.production) {
          enableProdMode();
        }
        platformBrowserDynamic([
          {
            provide: RUNTIME_CONFIG,
            useValue: runtimeConfig
          },
          {
            provide: ENVIRONMENT,
            useValue: environment
          },
          {
            provide: DOMAIN_CONFIG,
            useValue: domainConfig
          }
        ]).bootstrapModule(AppModule);
      }
    );
  }
};

async function loadDomainConfig(): Promise<AppDomainConfig> {
  const domainId: DomainId = domainIdFromDomain(
    DomainId.DomesticAndGeneral,
    {}
  );

  const response = await fetch(`configs/domains/${domainId}.json`);
  return response.json();
}

async function loadRuntimeConfig(): Promise<AppRuntimeConfig> {
  const response = await fetch(environment.runtimeConfigPath);
  return response.json();
}
