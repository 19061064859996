import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AddressLookupComponent } from './components/address-lookup/address-lookup.component';
import { ButtonComponent } from './components/button/button.component';
import { FieldsetComponent } from './components/fieldset/fieldset.component';
import { FormElementsComponent } from './components/form-elements/form-elements.component';
import { HintComponent } from './components/hint/hint.component';
import { InputAutoCompleteComponent } from './components/input-autocomplete/input-autocomplete.component';
import { InputComponent } from './components/input/input.component';
import { LabelComponent } from './components/label/label.component';
import { LegendComponent } from './components/legend/legend.component';
import { RadiobuttonHintComponent } from './components/radiobutton-hint/radiobutton-hint.component';
import { RadiobuttonComponent } from './components/radiobutton/radiobutton.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { ValidationErrorFormControlComponent } from './components/validation-error-formcontrol/validation-error-formcontrol.component';

import { FormsComponent } from './forms/forms.component';

import { DgxComponentsIconModule } from '@domgen/dgx-fe-components/icon';
import { ForbiddenValidatorDirective } from './directives/forbidden.name.directive';
import { LookupDirective } from './directives/lookup.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AutoCompleteModule,
    DgxComponentsIconModule,
  ],
  declarations: [
    RadiobuttonComponent,
    TextareaComponent,
    LabelComponent,
    FieldsetComponent,
    ButtonComponent,
    LegendComponent,
    HintComponent,
    ValidationErrorFormControlComponent,
    InputComponent,
    FormElementsComponent,
    FormsComponent,
    RadiobuttonHintComponent,
    InputAutoCompleteComponent,
    ForbiddenValidatorDirective,
    AddressLookupComponent,
    ForbiddenValidatorDirective,
    LookupDirective,
  ],
  exports: [
    RadiobuttonComponent,
    InputAutoCompleteComponent,
    TextareaComponent,
    LabelComponent,
    FieldsetComponent,
    ButtonComponent,
    LegendComponent,
    HintComponent,
    ValidationErrorFormControlComponent,
    InputComponent,
    FormElementsComponent,
    FormsComponent,
    ForbiddenValidatorDirective,
    LookupDirective,
  ],
  providers: [DatePipe],
})
export class FormBuilderModule {}
