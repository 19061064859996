import { Injectable } from '@angular/core';
import {} from '@angular/google-maps';
import { Api } from '../interfaces/api.interface';
import { Origin } from '../interfaces/dropoff-origin.interface';

@Injectable()
export class BookingMapService {
  geo: google.maps.Geocoder;
  distance: google.maps.DistanceMatrixService;

  constructor() {
    this.geo = new google.maps.Geocoder();
    this.distance = new google.maps.DistanceMatrixService();
  }
  getData(sProviders: Api.ServiceProvider[]): Promise<Api.ServiceProvider[]> {
    const serviceProviders = this.findLatLng(sProviders);
    return Promise.all(serviceProviders) as Promise<Api.ServiceProvider[]>;
  }

  findLatLng(sProviders: Api.ServiceProvider[]) {
    return sProviders.map((p: Api.ServiceProvider) => {
      return new Promise((resolve) => {
        return this.geo.geocode(
          {
            address: `${p.ServiceProviderBuildingName} ${p.ServiceProviderHouseStreetName} ${p.ServiceProviderTownCity} ${p.ServiceProviderPostCode}`,
          },
          (
            results: google.maps.GeocoderResult[] | null,
            status: google.maps.GeocoderStatus
          ) => {
            if (status === 'OK' && results) {
              const res = results[0];
              resolve({
                Address: res.formatted_address,
                Lat: res.geometry.location.lat(),
                Lng: res.geometry.location.lng(),
                ...p,
              });
            } else {
              resolve({
                Address: null,
                Lat: null,
                Lng: null,
                ...p,
              });
            }
          }
        );
      }).then(
        (x) => new Promise((resolve) => setTimeout(() => resolve(x), 200))
      );
    });
  }

  getOrigin(customer: Api.GetServiceOptionRequest): Promise<Origin> {
    const address = `${customer.CustomersHouseStreetName} ${customer.CustomersLocalArea} ${customer.CustomersTownCity} ${customer.CustomersPostCode}`;
    return new Promise((resolve) => {
      return this.geo.geocode(
        {
          address: address,
        },
        (
          results: google.maps.GeocoderResult[] | null,
          status: google.maps.GeocoderStatus
        ) => {
          if (status === 'OK' && results) {
            const res = results[0];
            resolve({
              Address: res.formatted_address,
              Lat: res.geometry.location.lat(),
              Lng: res.geometry.location.lng(),
              ...customer,
            });
          } else {
            resolve({
              Address: null,
              Lat: null,
              Lng: null,
              ...customer,
            });
          }
        }
      );
    });
  }

  delay(ms: number) {
    return () => new Promise((resolve) => setTimeout(() => resolve(true), ms)); // timeout for promise resolve
  }

  getDistanceMatrix(
    origin: Origin,
    destinations: Api.ServiceProvider[]
  ): Promise<{ origin: Origin; serviceProviders: Api.ServiceProvider[] }> {
    if (!origin?.Lat && !origin?.Lng) {
      return new Promise((resolve) =>
        resolve({
          origin: origin,
          serviceProviders: destinations,
        })
      );
    }

    const d = destinations.map((p: Api.ServiceProvider) => {
      return { lat: p.Lat, lng: p.Lng };
    });

    return new Promise((resolve) => {
      return this.distance.getDistanceMatrix(
        {
          origins: [{ lat: origin.Lat as number, lng: origin.Lng as number }],
          destinations: d as [{ lat: number; lng: number }],
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.IMPERIAL,
          avoidHighways: false,
          avoidTolls: false,
        },
        (
          response: google.maps.DistanceMatrixResponse | null,
          status: google.maps.DistanceMatrixStatus
        ) => {
          if (status === 'OK' && response) {
            return resolve({
              origin: origin,
              serviceProviders: this.sortByDistance(destinations, response),
            });
          } else {
            return resolve({
              origin: origin,
              serviceProviders: destinations,
            });
          }
        }
      );
    });
  }

  sortByDistance(
    destinations: Api.ServiceProvider[],
    response: google.maps.DistanceMatrixResponse
  ): Api.ServiceProvider[] {
    const sortByKey = (array: Api.ServiceProvider[], key: string) => {
      return array.sort((a, b) => {
        const x = (a as never)[key];
        const y = (b as never)[key];
        return Number(x) < Number(y) ? -1 : Number(x) > Number(y) ? 1 : 0;
      });
    };

    const elements = response?.rows[0]?.elements;

    const providers = sortByKey(
      destinations.map((s: Api.ServiceProvider, index: number) => {
        const dis = elements[index]?.distance?.text || '';
        return {
          ...s,
          Distance: dis.slice(0, -3),
        };
      }),
      'Distance'
    )
      .map((p: Api.ServiceProvider) => {
        return {
          ...p,
          Distance: `${p?.Distance}`,
        };
      })
      .slice(0, 5);

    return providers;
  }
}
