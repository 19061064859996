import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ValidationService } from '../../services/validation.service';
import { ValidationError } from '../../interfaces/validation-error.interface';
import { Observable } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import {
  animate,
  group,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@UntilDestroy()
@Component({
  selector: 'dg-fb-validation-error-formcontrol',
  templateUrl: './validation-error-formcontrol.component.html',
  animations: [
    trigger('slideUpDown', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),

        group([
          animate(200, style({ height: 0 })),
          animate('200ms ease-in-out', style({ opacity: '0' })),
        ]),
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),

        group([
          animate(200, style({ height: '*' })),
          animate('200ms ease-in-out', style({ opacity: '1' })),
        ]),
      ]),
    ]),
  ],
})
export class ValidationErrorFormControlComponent implements OnInit, OnChanges {
  @Input() group?: UntypedFormGroup;
  @Input() control = '';
  @Input() validators?: ValidationError[];
  @Input() refresh$?: Observable<unknown>;

  public error?: ValidationError;

  constructor(private _validator: ValidationService) {}

  ngOnInit() {
    if (this.refresh$) {
      this.refresh$.pipe(untilDestroyed(this)).subscribe(() => {
        this.refreshErrorMessage();
      });
    }
  }

  ngOnChanges() {
    this.refreshErrorMessage();
  }

  private refreshErrorMessage() {
    if (this.validators) {
      this.error = this._validator.getErrors(
        this.group as UntypedFormGroup,
        this.control as string,
        this.validators
      );
    }
  }
}
