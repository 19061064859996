import {
  RouteClaimParams,
  ServiceOptionTypes,
  Claim,
  PreClaimStartPageViewed,
  ClaimStage,
  ClaimJourneyStateType,
  ClaimBundleType,
  AdminScheme,
  UserJourneyTypeEnum,
  AppConfig,
} from '@domgen/dgx-components';
import { ClaimFacade } from '../+state/claim.facade';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import * as fromClaims from '../+state';
import { ClaimEffectHelperService } from './claim-effect-helper.service';
import { zip } from 'rxjs';
import * as claimActions from '../+state/claim.actions';
import { Api } from '@domgen/dgx-components';
import { CONFIG } from '@domgen/dgx-fe-config';

@Injectable({
  providedIn: 'root',
})
export class SpecialRoutingService {
  constructor(
    @Inject(CONFIG) private readonly config: AppConfig,
    private router: Router,
    private store: Store<fromClaims.State>,
    private effectsHelper: ClaimEffectHelperService,
    private readonly claimFacade: ClaimFacade
  ) {}

  routeSmartFix() {
    this.router.navigate(['/smart-fix'], { replaceUrl: true });
  }

  routeForward(params: RouteClaimParams = {} as RouteClaimParams) {
    const { claim, manufacturers, productTypes } = params;
    const adminScheme = this.claimFacade.getAdminScheme(
      claim?.reflect as Api.Reflect
    );

    if (!!adminScheme) {
      this.routeToOem(adminScheme, claim, claim?.reflect as Api.Reflect);
      return;
    }

    if (claim?.complete) {
      if (claim?.isServiceProviderCallback === true && params.triggeredBy) {
        if (
          params.triggeredBy ===
          claimActions.ClaimActionTypes.PutRepairDataSuccess
        ) {
          this.router.navigate(['confirmation'], {
            replaceUrl: true,
          });
        } else {
          this.claimFacade.submitCallbackBooking(claim);
        }
        return;
      }

      this.router.navigate(['confirmation'], {
        replaceUrl: true,
      });
      return;
    }

    if (
      params.triggeredBy ===
      claimActions.ClaimActionTypes.LoadCardPaymentRedirectSuccess
    ) {
      this.router.navigate(['payment']);
      return;
    }

    if (claim.created && claim.bookingAvailable === false) {
      this.router.navigate(['repair-details'], {
        replaceUrl: true,
      });
      return;
    }

    if (
      (claim.bookingAvailable === true && claim.bookingOption !== null) ||
      params.triggeredBy ===
        claimActions.ClaimActionTypes.PutServiceOptionSuccess
    ) {
      if (this.isBookingRoute()) {
        return;
      }

      switch (claim.bookedServiceOption) {
        case ServiceOptionTypes.SelfSend:
          this.router.navigate(['book-selfsend']);
          return;

        case ServiceOptionTypes.DropOff:
          this.router.navigate(['book-dropoff']);
          return;

        case ServiceOptionTypes.Collection:
          this.router.navigate(['book-collection']);
          return;

        default:
          this.router.navigate(['book-engineer'], {
            replaceUrl: !!claim?.isRebook,
          });
          return;
      }
    }

    const claimStageBeforeOrInRepairDetails = [
      ClaimStage.ApplianceDetailsSubmit,
      ClaimStage.AutomaticApplianceId,
      ClaimStage.ClaimTypeSelect,
      ClaimStage.CreateClaim,
    ].includes(claim.claimSelection?.selectionState?.claimStage as ClaimStage);

    const autoAndNeedSelectProductType =
      claim.getData &&
      this.effectsHelper.isAutomaticApplianceId(claim.getData) &&
      productTypes &&
      productTypes?.length > 1 &&
      !productTypes?.includes(
        claim.claimSelection?.request?.ProductType as string
      );

    if (claimStageBeforeOrInRepairDetails && !autoAndNeedSelectProductType) {
      this.router.navigate(['repair-details'], {
        replaceUrl: true,
      });
      return;
    }

    if (
      claim.reflect?.journeyState === ClaimJourneyStateType.START ||
      (claim.getData?.UniqueApplianceID === null &&
        (!claim.claimSelection?.selectionState?.claimStage ||
          claim.claimSelection?.selectionState?.claimStage <
            ClaimStage.ApplianceDetailsSubmit))
    ) {
      // if annual service
      if (
        this.isAnnualServiceCheckedGasSafety(claim) ||
        (claim?.preClaimStartPageViewed as PreClaimStartPageViewed) >=
          PreClaimStartPageViewed.GasSafety
      ) {
        if (this.needEnterManufacturer(manufacturers as string[], claim)) {
          this.router.navigate(['enter-manufacturer'], {
            replaceUrl: true,
          });
          return;
        } else {
          if (!claim.getData && productTypes && productTypes?.length > 1) {
            this.router.navigate(['product-type'], {
              replaceUrl: true,
            });
            return;
          }

          if (
            this.effectsHelper.isAutomaticApplianceId(
              claim?.getData as Api.GetMandatoryDataResponse
            )
          ) {
            this.router.navigate(['repair-details'], {
              replaceUrl: true,
            });
            return;
          } else {
            this.router.navigate(['appliance-details'], {
              replaceUrl: true,
            });
            return;
          }
        }
      }

      if (this.needEnterManufacturer(manufacturers as string[], claim)) {
        this.router.navigate(['enter-manufacturer'], {
          replaceUrl: true,
        });
        return;
      }

      //if repair booking and no ProductType selected
      if (claim.reflect?.claimType === ClaimBundleType.REPAIR) {
        if (
          !claim?.claimSelection?.request?.ProductType &&
          this.needToSelectProductType(productTypes)
        ) {
          this.router.navigate(['product-type'], {
            replaceUrl: true,
          });
          return;
        }
      }

      if (claim.isGasAppliance) {
        this.router.navigate(['gas-safety-notice'], {
          replaceUrl: true,
        });
        return;
      } else {
        this.router.navigate(['appliance-details'], {
          replaceUrl: true,
        });
        return;
      }
    }
  }

  routeBack(claim: Claim) {
    const currentPage = this.router.url.replace(/\//g, '');

    // Routing backwards is supposed to skip Gas Safety

    switch (currentPage) {
      case 'product-type':
        this.routeToEnterManufacturerOrProductTypeOrPlanDetails(claim, true);
        break;

      case 'enter-manufacturer':
        this.routeToPlanDetails(claim);
        break;

      case 'appliance-details':
        this.routeToEnterManufacturerOrProductTypeOrPlanDetails(claim);
        break;

      case 'repair-details':
        this.needToEnterApplianceDetails(claim);
        break;

      case 'confirmation':
        this.routeToPlanDetails(claim);
        break;

      default:
        throw new Error('No go back route rule found!');
    }
  }

  needEnterManufacturer(
    manufacturers: string[],
    claim: Claim,
    direction: 'back' | 'forward' = 'forward'
  ): boolean {
    if (direction === 'forward') {
      return !(
        !manufacturers ||
        (manufacturers.includes(claim.reflect?.manufacturer as string) &&
          claim.reflect?.manufacturer)
      );
    } else {
      return manufacturers && manufacturers.length > 0;
    }
  }

  isBookingRoute(): boolean {
    return (
      this.router.url === '/book-engineer' ||
      this.router.url === '/book-dropoff' ||
      this.router.url === '/book-collection'
    );
  }

  isAnnualServiceCheckedGasSafety(claim: Claim): boolean {
    return (
      (claim?.reflect?.claimType as string) ===
        ClaimBundleType.ANNUAL_SERVICE &&
      (claim?.preClaimStartPageViewed as PreClaimStartPageViewed) >=
        PreClaimStartPageViewed.GasSafety &&
      (claim?.isGasAppliance as boolean)
    );
  }

  needToSelectProductType(productTypes: string[] | undefined): boolean {
    return !!productTypes && productTypes?.length > 1;
  }

  routeToPlanDetails(claim: Claim) {
    if (!claim.reflect) {
      throw new Error(
        `Can't route to Plan Details page because no Reflect result yet`
      );
    }

    window.location.href = `/my-plans/${claim.reflect.planNumber}/claims`;
  }

  routeToDashBoard(claim: Claim) {
    if (!claim.reflect) {
      throw new Error(
        `Can't route to Plan Details page because no Reflect result yet`
      );
    }

    const url = `${claim.reflect.rootURL}${claim.reflect.dashboardPath}`;

    window.location.href = url;
  }

  routeToPersonalDetails(claim: Claim) {
    if (!claim.reflect) {
      throw new Error(
        `Can't route to Personal Details page because no Reflect result yet`
      );
    }

    const url = `${claim.reflect.rootURL}${claim.reflect.personalDetailsPath}`;

    window.location.href = url;
  }

  routeToOem(adminScheme: AdminScheme, claim: Claim, reflect: Api.Reflect) {
    const data = { reflect, claim };
    this.router.navigate(['call-service-provider'], {
      replaceUrl: true,
      state: { adminScheme, data },
    });
  }

  isProductTypeValid(claim: Claim, productTypes: string[]) {
    return (
      productTypes?.length === 1 ||
      productTypes === undefined ||
      (productTypes?.length > 0 &&
        productTypes.includes(
          claim?.claimSelection?.request?.ProductType as string
        ))
    );
  }

  private needToEnterApplianceDetails(claim: Claim) {
    const modelNumberAlreadyValidated =
      claim.claimSelection?.selectionState?.modelNumberAlreadyValidated;

    if (modelNumberAlreadyValidated) {
      this.routeToPlanDetails(claim);
      return;
    }

    this.router.navigate(['appliance-details']);
  }

  private routeToEnterManufacturerOrProductTypeOrPlanDetails(
    claim: Claim,
    isProductTypePage: boolean = false
  ) {
    zip(
      this.store.select(fromClaims.getManufacturers).pipe(first()),
      this.claimFacade.productTypes$
    ).subscribe(([manufacturers, productTypes]) => {
      if (this.needToSelectProductType(productTypes) && !isProductTypePage) {
        this.router.navigate(['product-type']);
        return;
      }

      if (
        this.needEnterManufacturer(manufacturers as string[], claim, 'back')
      ) {
        this.router.navigate(['enter-manufacturer']);
      } else {
        this.routeToPlanDetails(claim);
      }
    });
  }
}
