import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterTestingModule } from '@angular/router/testing';
import { SharedUiSvgModule } from '@domgen/ui-svg';
import { StoreModule } from '@ngrx/store';

const mockModules = [
  HttpClientModule,
  BrowserAnimationsModule,
  RouterTestingModule,
  StoreModule,
  SharedUiSvgModule,
];

@NgModule({
  imports: mockModules,
  exports: mockModules,
})
export class AppTestMockModules {}
