import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'smart-fix-exit-dialog-component',
  templateUrl: './exit-dialog-component.component.html',
  styleUrls: ['./exit-dialog-component.component.scss'],
})
export class ExitDialogComponentComponent {
  @Input() message: string = '';
  @Output() cancel = new EventEmitter<void>();
  @Output() continue = new EventEmitter<void>();

  onCancel() {
    this.cancel.emit();
  }

  onContinue() {
    this.continue.emit();
  }
}
