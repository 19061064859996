import { take, takeUntil } from 'rxjs/operators';
import {
  BaseComponent,
  Claim,
  ServiceOptionTypes,
} from '@domgen/dgx-components';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { fadeInAnimation } from '@domgen/dgx-components';
import { Router } from '@angular/router';
import { ErrorMessages, ErrorPageContent } from '@domgen/dgx-components';
import { ErrorHandlerService } from '@domgen/data-access-claims';
import { ClaimsAnalyticsService } from '@domgen/dgx-components';
import { SpecialRoutingService, UserService } from '@domgen/data-access-claims';
import { ProductDetails } from '@domgen/dgx-components';

@Component({
  selector: 'claims-error',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  animations: [fadeInAnimation],
})
export class ErrorPageComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  errorObject?: ErrorPageContent;
  isErrorPage?: boolean;
  errorReject?: string;
  isNoEngineerError?: boolean;
  product?: ProductDetails | null;

  constructor(
    private handler: ErrorHandlerService,
    private title: Title,
    private analytics: ClaimsAnalyticsService,
    private routing: SpecialRoutingService,
    private router: Router,
    private user: UserService
  ) {
    super();
  }

  ngOnInit() {
    this.handler
      .errorPageContent()
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe((errorObject: ErrorPageContent | unknown) => {
        this.errorObject = errorObject as ErrorPageContent;
        this.isNoEngineerError =
          this.errorObject?.shortErrorDescription ===
          ErrorMessages.NoEngineerAvailable;
        this.product = (
          this.isNoEngineerError ? this.errorObject.product : null
        ) as ProductDetails | null;
        this.isErrorPage = this.router.url === '/error';
        this.title.setTitle(`Error | Domestic & General`);

        this.triggerGA();
      });
  }

  route() {
    this.routing.routeToPlanDetails(this.errorObject?.claim as Claim);
  }

  triggerGA() {
    const errorReject = this.isErrorPage ? 'error' : 'reject';
    const manualBookingFailed = this.isManualBookingError();

    this.analytics.pageViewEvent({
      eventClaims: `service-${errorReject}-dgx`,
      ...(manualBookingFailed && {
        pagename: 'error-no-calendar-no-DSP',
      }),
      claim: null,
    });

    this.analytics.claimEventExtended({
      eventClaims: manualBookingFailed
        ? 'error-no-calendar-no-DSP'
        : 'booking-error',
      eventAction: `${errorReject}-dgx`,
      eventLabel: this.errorObject?.shortErrorDescription as string,
      claim: this.errorObject?.claim as Claim,
      fireDistinct: true,
    });
  }

  isManualBookingError(): boolean {
    return (
      (this.errorObject?.claim?.noBookingDatesFound as boolean) &&
      this.errorObject?.claim?.bookedServiceOption === ServiceOptionTypes.Manual
    );
  }

  logout() {
    this.user.logout().pipe(takeUntil(this.destroy$)).subscribe();
  }
}
