<section *ngIf="event" class="flex justify--end">
  <div class="description">
    <dgx-components-icon
      class="ml-3"
      *ngIf="event.details.icon"
      [icon]="event.details.icon"
    />
    {{ event.details.description }}
  </div>
</section>
