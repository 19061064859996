import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'smart-fix-lex-plain-text',
  templateUrl: './lex-plain-text.component.html',
  styleUrls: [
    './lex-plain-text.component.scss',
    './../../chatbot-welcome-message/chatbot-welcome-message.component.scss',
    '../../bedrock-chat/bedrock-markdown.scss',
  ],
})
export class LexPlainTextComponent {
  @Input() plainTextDescription!: string | undefined;
}
