import {
  animate,
  group,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalendarDay, CalendarSlot } from '../../../interfaces';
import { Api } from '../../../interfaces/api.interface';
import { BookingCalendarDateFormats } from '../booking-calendar-constants';

export const slideInOut = trigger('slideInOut', [
  state('in', style({ height: '*', opacity: 0 })),
  transition(':leave', [
    style({ height: '*', opacity: 1 }),

    group([
      animate(200, style({ height: 0 })),
      animate('100ms ease-in-out', style({ opacity: '0' })),
    ]),
  ]),
  transition(':enter', [
    style({ height: '0', opacity: 0 }),

    group([
      animate(200, style({ height: '*' })),
      animate('400ms ease-in-out', style({ opacity: '1' })),
    ]),
  ]),
]);

export enum SlotType {
  ALLDAY = 'AllDay',
  SPECIFIC = 'Specific',
  AMPM = 'AMPM',
}

@Component({
  selector: 'dg-booking-calendar-day',
  templateUrl: './booking-calendar-day.component.html',
  styleUrls: ['./booking-calendar-day.component.scss'],
  animations: [slideInOut],
})
export class BookingCalendarDayComponent {
  @Input() day?: CalendarDay;
  @Input() slotType?: SlotType;
  @Input() isRebook = false;
  @Input() bookingError = false;
  @Input() currentAppointment?: Api.CurrentAppointment;
  @Input() localSelected: CalendarSlot | undefined;
  @Input() gaData: { planNumber: string; productName: string } | undefined;
  @Output() daySelected: EventEmitter<CalendarDay | null> = new EventEmitter();
  @Output() selected: EventEmitter<{ day: CalendarDay; slot: CalendarSlot }> =
    new EventEmitter();

  bookingCalendarDateFormats = BookingCalendarDateFormats;
  slotTypes = SlotType;

  @Output() setLocalSelected: EventEmitter<CalendarSlot | undefined> =
    new EventEmitter<CalendarSlot | undefined>();
  onSlotSelect(slot: CalendarSlot) {
    if (this.day) {
      this.selected.emit({
        day: this.day,
        slot: slot,
      });
    }

    this.setLocalSelected.emit(slot);
  }

  animationStart() {
    const element: HTMLElement | null = document.getElementById(
      this.day?.date?.toString() as string
    );

    if (element?.classList.contains('calendar-day--active')) {
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }
}
