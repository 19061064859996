import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnDestroy,
  Renderer2,
  Inject,
} from '@angular/core';
import { AppConfig, Claim } from '@domgen/dgx-components';
import { DgxIconName } from '@domgen/dgx-fe-components/icon';
import {
  ChatFacadeService,
  HistoryType,
  productTypeEnums,
} from '@domgen/smart-fix/data-access-smart-fix';
import { ChatComponent } from '@domgen/smart-fix/data-access-smart-fix';
import { Observable, take } from 'rxjs';
import { chatBotFadeInAnimation } from './../../animations/chatAnimations';
import { SmartFixAnalyticsService } from '../../services/smart-fix-analytics/smart-fix-analytics.service';
import { CONFIG } from '@domgen/dgx-fe-config';

@Component({
  selector: 'smart-fix-chat-container',
  templateUrl: './chat-container.component.html',
  styleUrls: ['./chat-container.component.scss'],
  animations: [chatBotFadeInAnimation],
})
export class ChatContainerComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @Input() claim!: Claim;
  @Output() closedChatNotification = new EventEmitter<'open' | 'closed'>();
  currentComponentChildren$!: Observable<ChatComponent[] | null | undefined>;
  currentComponent$!: Observable<ChatComponent | null | undefined>;
  history$!: Observable<Array<HistoryType>>;
  closed = false;
  minimized = false;
  disabled = false;
  closedDate: Date | null = null;
  unreadMessages = 0;
  message = 'Are you sure you want to end chat now?';
  isExitDialogVisible = false;
  defaultTimeOutForFirstMessage = 3000;
  defaultTimeOut = 2200;
  // redirectInProgress = true;

  private lastComponentState: ChatComponent | null = null;

  @ViewChild('scrollContainer') private scrollContainer!: ElementRef;

  constructor(
    private chatFacade: ChatFacadeService,
    private readonly smartFixAnalyticsService: SmartFixAnalyticsService,
    @Inject(CONFIG) protected readonly config: AppConfig,
    protected renderer: Renderer2
  ) {
    this.addGlobalClass();
  }

  ngOnInit(): void {
    if (!this.claim) return;
    this.startChatBot(this.claim);
  }

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  ngOnDestroy(): void {
    this.removeGlobalClass();
  }

  removeGlobalClass(className: string = 'chatbot-open') {
    this.renderer.removeClass(document.body, className);
  }

  addGlobalClass(className: string = 'chatbot-open') {
    this.renderer.addClass(document.body, className);
  }

  isProductTypeEnum(productType: string): productType is productTypeEnums {
    return Object.values(productTypeEnums).includes(
      productType as productTypeEnums
    );
  }

  startChatBot(claim: Claim): void {
    if (claim.reflect?.productType == null) return;
    const productType = claim.reflect.productType.toLocaleLowerCase().trim();
    if (!this.isProductTypeEnum(productType)) return;

    this.chatFacade.loadDecisionTree(productType);
    this.history$ = this.chatFacade.history$;
    this.currentComponentChildren$ = this.chatFacade.currentComponentChildren$;
    this.currentComponent$ = this.chatFacade.currentComponent$;

    this.currentComponent$.pipe(take(1)).subscribe(async (res) => {
      if (res?.autoNext) {
        await this.waitForTimeOut(
          res.event.details.delay ?? this.defaultTimeOutForFirstMessage
        );
        this.chooseOption(res.children[0]);
      }
    });
  }

  waitForTimeOut(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async chooseOption(chatComponent: ChatComponent): Promise<void> {
    if (this.minimized) {
      this.unreadMessages++;
    }
    if (chatComponent.event.details.trackFeedback) {
      const eventLabel = chatComponent.event.details.description || '';
      this.trackFeedbackChoice(eventLabel, 'ChatBot - smart fix');
    }

    if (chatComponent.event.details.continueToRepairBooking) {
      this.closeChatBotWithoutConfirmation();
    }

    if (chatComponent.event.details.continueToMyAccount) {
      window.location.href = this.config.myAccountUrl;
    }

    if (chatComponent.event.details.showConfirmationDialog) {
      this.isExitDialogVisible = true;
      return;
    }

    if (!chatComponent.event.details.showConfirmationDialog) {
      this.lastComponentState = chatComponent;
    }

    const date = new Date();
    this.chatFacade.updateHistory({ ...chatComponent.event, date: date });
    this.chatFacade.loadNextComponent(chatComponent);

    if (chatComponent.autoNext) {
      this.triggerAutoNextWithDelay(
        chatComponent,
        chatComponent.event.details.delay ?? this.defaultTimeOut
      );
    }
  }

  async triggerAutoNextWithDelay(
    chatComponent: ChatComponent,
    delay: number
  ): Promise<void> {
    await this.waitForTimeOut(delay);
    this.chooseOption(chatComponent.children[0]);
  }

  getIcon(iconName: string | undefined) {
    return iconName as DgxIconName;
  }

  scrollToBottom() {
    this.scrollContainer.nativeElement.scrollTop =
      this.scrollContainer.nativeElement.scrollHeight;
  }

  closeChatBot(): void {
    if (this.closed) {
      this.minimized = true;
      this.removeGlobalClass();
      return;
    }
    this.addGlobalClass('exit-dialog-open');
    this.isExitDialogVisible = true;
  }

  onCancel(): void {
    this.closeChat();
    this.removeGlobalClass('exit-dialog-open');
    this.isExitDialogVisible = false;
  }

  onEndChat(): void {
    this.closeChat();
    this.removeGlobalClass('exit-dialog-open');
    this.isExitDialogVisible = false;
  }

  onContinueChat(): void {
    this.removeGlobalClass('exit-dialog-open');
    this.isExitDialogVisible = false;
    if (this.lastComponentState) {
      this.chatFacade.loadNextComponent(this.lastComponentState);
    }
  }

  closeChatBotWithoutConfirmation(): void {
    this.closeChat();
  }

  closeChat(): void {
    this.closed = true;
    this.minimized = true;
    this.closedDate = new Date();
    this.closedChatNotification.emit('closed');
    this.removeGlobalClass();
  }

  openChatBot(): void {
    this.closed = false;
    this.minimized = false;
    this.addGlobalClass();
  }

  toggleMinimize(): void {
    this.minimized = !this.minimized;

    if (!this.minimized) {
      this.addGlobalClass();
      this.unreadMessages = 0;
    } else {
      this.removeGlobalClass();
    }
  }

  trackFeedbackChoice(
    eventLabel: string,
    eventAction: string,
    eventCategory?: string
  ) {
    this.smartFixAnalyticsService.triggerSmartFixEvent(
      eventLabel,
      'fault-details-completed',
      this.claim,
      eventAction,
      eventCategory
    );
  }
}
