import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { RepairDetailsCallbackService } from '@domgen/data-access-repair-details';
import { Claim } from '@domgen/dgx-components';

@Component({
  selector: 'claims-call-back-widget-calendar',
  templateUrl: './call-back-widget-calendar.component.html',
  styleUrls: ['./call-back-widget-calendar.component.scss'],
})
export class CallBackWidgetCalendarComponent implements OnInit, AfterViewInit {
  constructor(private service: RepairDetailsCallbackService) {}
  @Input() claim: Claim;

  ngOnInit() {
    this.service.initialize(this.claim);
  }

  ngAfterViewInit() {
    this.scrollToPosition();
  }

  scrollToPosition() {
    setTimeout(() => {
      const region = document.getElementById('RepairOptionSection');
      if (region) {
        region.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }, 300);
  }
}
