<ul>
  <li *ngFor="let item of data">
    <p>
      <small>
        <span class="key">{{ item.key }}</span>
        <span class="value">{{ item.value }}</span>
      </small>
    </p>
  </li>
</ul>
