import { Component, Input } from '@angular/core';
import { ChatBotEventType } from '@domgen/smart-fix/data-access-smart-fix';

@Component({
  selector: 'smart-fix-chatbot-response',
  templateUrl: './chatbot-response.component.html',
  styleUrls: ['./chatbot-response.component.scss'],
})
export class ChatbotResponseComponent {
  @Input() event!: ChatBotEventType;
  @Input() closed = false;
}
