import { AdminScheme } from '@domgen/dgx-components';

export const adminSchemes: AdminScheme[] = [
  {
    CoCode: 'E',
    SchCode: 'X2C',
    SchemeDescr: 'EX2C EDF 6 months Free Admin plan (invoice only)',
    GroupDesc: 'EDF ENERGY',
    CategoryCode: 'ADMN',
    Phone: '0800 497 0707',
    OEM: 'EDF Energy',
  },
  {
    CoCode: 'E',
    SchCode: 'X2R',
    SchemeDescr: 'EX2R EDF Pay On Use',
    GroupDesc: 'EDF ENERGY',
    CategoryCode: 'ADMN',
    Phone: '0800 497 0707',
    OEM: 'EDF Energy',
  },
  {
    CoCode: 'E',
    SchCode: 'AGQ',
    SchemeDescr: 'EAGQ AEG 5 Year Gtee Registration',
    GroupDesc: 'ELECTROLUX GROUP',
    CategoryCode: 'ADMN',
    Phone: '0344 561 3613',
    OEM: 'Electrolux',
  },
  {
    CoCode: 'E',
    SchCode: 'C5E',
    SchemeDescr: 'EC5E Electrolux Group Promo Tel Reg',
    GroupDesc: 'ELECTROLUX GROUP',
    CategoryCode: 'ADMN',
    Phone: '0344 561 3613',
    OEM: 'Electrolux',
  },
  {
    CoCode: 'E',
    SchCode: 'C5M',
    SchemeDescr: 'EC5M Electrolux Group Promo PostReg',
    GroupDesc: 'ELECTROLUX GROUP',
    CategoryCode: 'ADMN',
    Phone: '0344 561 3613',
    OEM: 'Electrolux',
  },
  {
    CoCode: 'E',
    SchCode: 'C6K',
    SchemeDescr: 'EC6K AEG Retailer Prmo-Prem Partners(Basic Admin)',
    GroupDesc: 'ELECTROLUX GROUP',
    CategoryCode: 'ADMN',
    Phone: '0344 561 3613',
    OEM: 'Electrolux',
  },
  {
    CoCode: 'E',
    SchCode: 'C6L',
    SchemeDescr: 'EC6L Lux Group Ret Promo-Oth Prtnrs (Basic Admin)',
    GroupDesc: 'ELECTROLUX GROUP',
    CategoryCode: 'ADMN',
    Phone: '0344 561 3613',
    OEM: 'Electrolux',
  },
  {
    CoCode: 'E',
    SchCode: 'EAC',
    SchemeDescr: 'EEAC Electrolux 5 Yr Promo Adm Only Taylor Wimpey',
    GroupDesc: 'ELECTROLUX GROUP',
    CategoryCode: 'ADMN',
    Phone: '0344 561 3613',
    OEM: 'Electrolux',
  },
  {
    CoCode: 'E',
    SchCode: 'EAE',
    SchemeDescr: 'EEAE Zanussi 2 Year Promo Admin Only',
    GroupDesc: 'ELECTROLUX GROUP',
    CategoryCode: 'ADMN',
    Phone: '0344 561 3613',
    OEM: 'Electrolux',
  },
  {
    CoCode: 'E',
    SchCode: '4ME',
    SchemeDescr: 'E4ME Miele 2+8 Vacs',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: '7TP',
    SchemeDescr: 'E7TP Miele 5+5 Promo Tel Reg',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: '7TR',
    SchemeDescr: 'E7TR Miele 5+5 Promo Upsell',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: '8LG',
    SchemeDescr: 'E8LG Miele Floorcare (Old Rates 30)',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: '9XT',
    SchemeDescr: 'E9XT MIELE 10YR STAND PROM TEL REG (J LEWIS CUST)',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'B4C',
    SchemeDescr: 'EB4C Miele Washing Machine Promo March',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'F6L',
    SchemeDescr: 'EF6L Miele 5 year promo - PLAN DOC scheme',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'F6M',
    SchemeDescr: 'EF6M Miele 10 Yr promo-PLAN DOC Sch',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'F7S',
    SchemeDescr: 'EF7S Miele Out of Gtee Qline Sch',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'H3Z',
    SchemeDescr: 'EH3Z Miele Tel Reg 30 Day Mail',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'H6M',
    SchemeDescr: 'EH6M Miele Web Reg 30 Day Mail',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'J4Z',
    SchemeDescr: 'EJ4Z Miele Floorcare Web Reg mailing Scheme',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MAN',
    SchemeDescr: 'EMAN Miele Service Plan ITB',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MAS',
    SchemeDescr: 'EMAS Miele Protection Plan ITB',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MDY',
    SchemeDescr: 'EMDY Miele Dishwasher 5 yr Postal Reg',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MJN',
    SchemeDescr: 'EMJN Miele Repair+1 Scheme',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MMF',
    SchemeDescr: 'EMMF Miele Telephone Reg',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MMG',
    SchemeDescr: 'EMMG Miele 2+8 Promo Tel Reg',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MMH',
    SchemeDescr: 'EMMH Miele 2+8 Promo Upsell',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MNE',
    SchemeDescr: 'EMNE MIELE FLOORCARE TEL REG',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MNF',
    SchemeDescr: 'EMNF Miele Floorcare Internet Registration',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MNH',
    SchemeDescr: 'EMNH Miele Floorcare 11th Month',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MNP',
    SchemeDescr: 'EMNP Miele ITB',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MNQ',
    SchemeDescr: 'EMNQ Miele Major White Tel Reg',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MNR',
    SchemeDescr: 'EMNR Miele Major White Internet Reg',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MNS',
    SchemeDescr: 'EMNS Miele Major White 30 Day',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MNV',
    SchemeDescr: 'EMNV Miele Major White 11th Month',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MNZ',
    SchemeDescr: 'EMNZ Miele Major White 54th Month',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MOV',
    SchemeDescr: 'EMOV Miele Major White Product Replacement Qline',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MPI',
    SchemeDescr: 'EMPI Miele Kitchen Plan Tel Reg (Header)',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MPJ',
    SchemeDescr: 'EMPJ Miele Kitchen Plan Tel Reg (Baby)',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MPK',
    SchemeDescr: 'EMPK Miele Kitchen Plan Internet (header)',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'MPL',
    SchemeDescr: 'EMPL Miele Kitchen Plan Web Reg 9 App (Baby)',
    GroupDesc: 'MIELE',
    CategoryCode: 'ADMN',
    Phone: '0330 160 6600',
    OEM: 'Miele',
  },
  {
    CoCode: 'E',
    SchCode: 'CTX',
    SchemeDescr: 'ECTX Whirlpool 5 year parts (admin only)',
    GroupDesc: 'WHIRLPOOL',
    CategoryCode: 'ADMN',
    Phone: '0344 822 7227',
    OEM: 'Whirlpool',
  },
  {
    CoCode: 'E',
    SchCode: 'KPV',
    SchemeDescr: 'EKPV Whirlpool 30 Day Parts - Conversion Data',
    GroupDesc: 'WHIRLPOOL',
    CategoryCode: 'ADMN',
    Phone: '0344 822 7227',
    OEM: 'Whirlpool',
  },
  {
    CoCode: 'E',
    SchCode: 'KRT',
    SchemeDescr: 'EKRT Whirlpool tel reg 10yr parts offer',
    GroupDesc: 'WHIRLPOOL',
    CategoryCode: 'ADMN',
    Phone: '0344 822 7227',
    OEM: 'Whirlpool',
  },
  {
    CoCode: 'E',
    SchCode: 'KWE',
    SchemeDescr: 'EKWE Whirlpool 10 Yr Parts (Engineers Scheme)',
    GroupDesc: 'WHIRLPOOL',
    CategoryCode: 'ADMN',
    Phone: '0344 822 7227',
    OEM: 'Whirlpool',
  },
  {
    CoCode: 'E',
    SchCode: 'KWF',
    SchemeDescr: 'EKWF Whirlpool 10 Year Parts (Free Promo)',
    GroupDesc: 'WHIRLPOOL',
    CategoryCode: 'ADMN',
    Phone: '0344 822 7227',
    OEM: 'Whirlpool',
  },
  {
    CoCode: 'E',
    SchCode: 'KXA',
    SchemeDescr: 'EKXA Whirlpool 30 day 8 yr parts ITB offer',
    GroupDesc: 'WHIRLPOOL',
    CategoryCode: 'ADMN',
    Phone: '0344 822 7227',
    OEM: 'Whirlpool',
  },
];
