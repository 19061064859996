import { Component, Input } from '@angular/core';
import { LexChatHelperService } from '../../../services/lex-chat-helper/lex-chat-helper.service';

@Component({
  selector: 'smart-fix-lex-response',
  templateUrl: './lex-response.component.html',
  styleUrls: [
    './../../chatbot-response/chatbot-response.component.scss',
    './lex-response.component.scss',
  ],
})
export class LexResponseComponent {
  @Input() lexResponse: string | undefined;
  constructor(private lexChatHelper: LexChatHelperService) {}

  getFeedbackIcon(text: string) {
    return this.lexChatHelper.getFeedbackIcon(text);
  }
}
