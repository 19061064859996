import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import type { HeaderLinks } from './header-links.interface';

@Component({
  selector: 'dg-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() links?: HeaderLinks | null;
  @Input() user?: { isActive: boolean; isQuickBooking: boolean };
  @Input() themeClass = '';
  @Output() logout = new EventEmitter();
  navOpen = false;

  logoutClick() {
    this.logout.emit(null);
  }

  ngOnInit() {
    this.themeClass = `header ${this.themeClass}`;
  }
}
