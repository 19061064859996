<ng-container *ngFor="let item of data">
  <dg-fb-input
    *ngIf="item.addressOptions?.lookup === undefined"
    [item]="item.input"
    [showValidation]="showValidation"
    [validators]="item.input?.validators"
    [group]="group"
    [label]="item.input?.label"
    [labelFor]="item.input?.control"
    [ngClass]="{
      hidden: item.addressOptions?.hide && hideManualAddress
    }"
  ></dg-fb-input>
  <!-- Address loop up field -->
  <div
    *ngIf="item.addressOptions?.lookup === true && group"
    [formGroup]="group"
    class="form-input legacy-form-builder"
    [ngClass]="{
      'is-invalid':
        !group.controls[$any(item.input?.control)].valid &&
        group.controls[$any(item.input?.control)]?.touched,
      'is-valid':
        group.controls[$any(item.input?.control)]?.valid &&
        group.controls[$any(item.input?.control)]?.touched,
      hidden: !item.addressOptions?.hide && !hideManualAddress
    }"
  >
    <dg-fb-label
      *ngIf="item.input"
      [label]="$any(item.input)"
      [class]="item.input.classes"
      [forElement]="item.input.control"
      >{{ item.input.label.text }}
    </dg-fb-label>

    <div class="form-control">
      <input
        [class]="item.input?.classes"
        [formControlName]="$any(item.input?.control)"
        [placeholder]="item.input?.placeholder"
        [id]="item.input?.control"
        [name]="item.input?.control"
        type="text"
        (blur)="showValidation = true"
        addressLookUp
        (addressList)="handleAddressList($event)"
      />
      <i class="icon validation-icon icon-tick-circle"></i>
      <i class="icon validation-icon icon-error-circle"></i>
      <ul
        id="addressList"
        class="address-look-up"
        [ngClass]="{
          hidden: !addressList?.length
        }"
      >
        <li
          *ngFor="let address of addressList"
          (click)="handleAddressClick(address)"
        >
          {{ address?.Text }}, {{ address?.Description }}
          <span
            class="glyphicon glyphicon-chevron-right"
            [ngClass]="{
              hidden: handleExpandable(address)
            }"
            aria-hidden="true"
          ></span>
        </li>
      </ul>
      <div class="row row--spacer-top-small no-gutters">
        <div class="col">
          <p (click)="toggleManualAddress()" class="link link-underline">
            Enter Address Manually
          </p>
        </div>
      </div>
    </div>
    <ng-container *ngIf="item.input?.validators && showValidation">
      <dg-fb-validation-error-formcontrol
        [group]="group"
        [validators]="item.input?.validators"
        [control]="item.input?.control"
      ></dg-fb-validation-error-formcontrol>
    </ng-container>
  </div>
</ng-container>
