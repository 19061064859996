import { camelCase } from 'lodash-es';
import { Injectable } from '@angular/core';
import { CalendarDay } from '../../../interfaces/calendar.interface';
import { isSameDay, isAfter } from 'date-fns';

@Injectable({ providedIn: 'root' }) // TODO: provide this in the calendar module when it's created
export class CalendarHelperService {
  /**
   * convertToCamelCase
   * @param obj
   * @returns obj
   * converts properties to camelCase
   */
  public convertToCamelCase(obj: Record<string, any>): Record<string, any> {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.convertToCamelCase(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.convertToCamelCase(obj[key]),
        }),
        {}
      );
    }
    return obj;
  }

  /**
   *
   * @param APICalendarDays {CalendarDay}
   * @param newDate {Date}
   * @return UICalendarDays || APICalendarDays
   * This is a temp fix and can be removed once the CR is complete
   * Since the API only returns available days we need to fill in the unavaible ones
   * The below returns a new calendarDays object placing a state of 'slotsAvaibale' : true on matches dates returned in the API
   * This boolean value is then used for the UI to show selectable days
   *
   * 1. Get a reference to the last item in the API responses. (x)
   * 2. Set up array and dateObject - reset hrs to midnight (we need to get yesterdays date for this to work)
   * 3. Build an array day objects with (x) number of items from todays date to the last date (referenceDate). Setting a date object on each item and slotsAvailable: false
   * 4. Loop through the API response and compare the date with the current indexed date. If they match set slotsAvailable to true
   * 5. Now we've built our UI array of dates and can use slotsAvailable to display to the user which days are available
   */
  public buildUIDaysForward(
    APICalendarDays: CalendarDay[],
    newDate: Date = new Date(),
    endDate: Date | null = null
  ): CalendarDay[] {
    // 1.
    const dateEnd = this.getLastDay(APICalendarDays, endDate as Date);

    // 2.
    const UICalendarDays: Array<CalendarDay> = [];
    newDate = new Date(newDate.setDate(newDate.getDate() - 1));
    newDate.setHours(0, 0, 0, 0);

    // 3;
    while (!isSameDay(newDate, dateEnd)) {
      UICalendarDays.push({
        date: newDate,
        slotsAvailable: false,
        selected: false,
      });

      newDate = new Date(newDate.setDate(newDate.getDate() + 1));
    }

    // 4.
    for (let i = 0; i < UICalendarDays.length; i++) {
      APICalendarDays.forEach((day) => {
        if (isSameDay(new Date(day.date), UICalendarDays[i].date)) {
          UICalendarDays[i].slotsAvailable = true;
          UICalendarDays[i].slots = day.slots;
          UICalendarDays[i].selected = false;
        }
      });
    }
    // 5.
    return UICalendarDays;
  }

  /**
   *
   * @param APICalendarDays {CalendarDay}
   * @param endDate {Date}
   * @return Date
   * Returns latest date either from the calendar array or the endDate defined from the last api response
   */
  getLastDay(APICalendarDays: CalendarDay[], endDate: Date): Date {
    let lastDay = new Date(endDate);

    if (
      APICalendarDays?.length > 0 &&
      isAfter(
        new Date(APICalendarDays[APICalendarDays.length - 1].date),
        lastDay
      )
    ) {
      lastDay = new Date(APICalendarDays[APICalendarDays.length - 1].date);
    }

    return lastDay;
  }
}
