import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import type {
  CalendarDay,
  CalendarSlot,
} from '../../../interfaces/calendar.interface';
import set from 'date-fns/set';
import format from 'date-fns/format';
import { sortBy } from 'lodash-es';
import differenceInHours from 'date-fns/differenceInHours';

@Component({
  selector: 'dg-specific-slot',
  templateUrl: './specific-slot.component.html',
  styleUrls: ['./specific-slot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecificSlotComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() slots?: CalendarSlot[];
  @Input() date?: CalendarDay;
  @Output()
  selectedTime: EventEmitter<CalendarSlot | null> = new EventEmitter();

  amSlots: CalendarSlot[] = [];
  pmSlots: CalendarSlot[] = [];

  amAcitve = true;
  activeTime?: CalendarSlot | null;
  maxHeight: Number | null = null;

  @ViewChild('item', { static: false }) item?: ElementRef;

  constructor(private _cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.setSlots();
  }

  ngOnChanges() {
    if (this.maxHeight) {
      this.setSlots();
      this.setHeight();
    }
  }

  ngAfterViewInit() {
    this.setHeight();
  }

  setHeight() {
    const maxSlots = this.getMaxSlots();
    let height = Math.floor(this.item?.nativeElement.offsetHeight);

    if (maxSlots > 2) {
      height = height * Math.round(maxSlots / 2);
    }

    this.maxHeight = height;
    this._cd.detectChanges();
  }

  setSlots() {
    this.activeTime = null;
    this.amSlots = this.getSlots(
      (this.slots as CalendarSlot[]).filter((s) => s.startTime < '12:00')
    );

    this.pmSlots = this.getSlots(
      (this.slots as CalendarSlot[]).filter((s) => s.startTime >= '12:00')
    );

    this.amAcitve = this.amSlots.length > 0 ? true : false;
  }

  public onSelectTimeGroup(time: string) {
    if (
      (time === 'PM' && this.pmSlots.length === 0) ||
      (time === 'AM' && this.amSlots.length === 0)
    ) {
      return;
    }

    this.amAcitve = time === 'AM' ? true : false;
    this.activeTime = null;
    this.selectedTime.emit(this.activeTime);
  }

  public onSelect(time: CalendarSlot) {
    this.activeTime = time;
    this.selectedTime.emit(time);
  }

  private getMaxSlots() {
    return this.amSlots.length > this.pmSlots.length
      ? this.amSlots.length
      : this.pmSlots.length;
  }

  private formatTime(time: string): string {
    const amPm = time >= '12:00' ? 'pm' : 'am';
    return `${format(this.getDate(time), 'h:mm')}${amPm}`;
  }

  private getDate(time: string) {
    const [hours, minutes] = time.split(':');
    return set(this.date?.date as Date, {
      hours: Number(hours),
      minutes: Number(minutes),
      seconds: 0,
    });
  }

  private getSlots(slots: CalendarSlot[]): CalendarSlot[] {
    return sortBy(slots, [
      (slot: CalendarSlot) => {
        return differenceInHours(
          this.getDate(slot.endTime),
          this.getDate(slot.startTime)
        );
      },
    ]).map((slot: CalendarSlot) => {
      return {
        ...slot,
        startTime: this.formatTime(slot.startTime),
        endTime: this.formatTime(slot.endTime),
      };
    });
  }
}
