import { ChatBotEventType, ChatComponent, Option } from './chat-types';

export class ChatOptionLeaf implements ChatComponent {
  id: number;
  autoNext: boolean;
  children: ChatComponent[] = [];
  event: ChatBotEventType;

  constructor(option: Option) {
    this.id = option.id;
    this.autoNext = option.autoNext;
    this.event = option.event;
  }
}
