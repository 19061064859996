<div
  *ngIf="group"
  [attr.validate]="validate"
  [attr.data]="data"
  [attr.group]="group"
>
  <!--JUI FORM builder component -->
  <dg-fb-input
    *ngIf="data?.input"
    [item]="data?.input"
    [attr.data-automation]="data?.input?.control"
    [maxlength]="data?.input?.maxLength"
    [showValidation]="validate"
    [validators]="data?.input?.validators"
    [group]="group"
    [hint]="data?.input?.hint"
    [label]="data?.input?.label"
    [labelFor]="data?.input?.control"
  ></dg-fb-input>
  <dg-fb-radiobutton
    *ngIf="data?.radios"
    [classes]="'dg-radios ' + data?.radios?.classes"
    [elementsPerPage]="data?.radios?.elementsPerPage"
    [items]="data?.radios?.radioGroup"
    [group]="group"
    [control]="data?.radios?.control"
    [attr.data-automation]="data?.radios?.control"
    [idPrefix]="data?.radios?.control"
    [validators]="data?.radios?.validators"
    [showValidation]="validate"
  >
  </dg-fb-radiobutton>
  <dg-fb-textarea
    *ngIf="data?.textarea"
    [classes]="'dg-textarea'"
    [showValidation]="validate"
    [attr.data-automation]="data?.textarea?.control"
    [validators]="data?.textarea?.validators"
    [group]="group"
    [control]="data?.textarea?.control"
    [placeholder]="data?.textarea?.placeholder"
    [label]="data?.textarea?.label"
    [labelFor]="data?.textarea?.control"
    [chrLimit]="data?.textarea?.chrLimit"
    [rows]="5"
  >
  </dg-fb-textarea>
  <!--JUI FORM builder component -->
</div>
