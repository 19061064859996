import { Component, Input } from '@angular/core';
@Component({
  selector: 'smart-fix-human-readable-date',
  templateUrl: './human-readable-date.component.html',
  styleUrls: ['./human-readable-date.component.scss'],
})
export class HumanReadableDateComponent {
  @Input() date!: Date;
  @Input() alignLeft?: boolean;
}
