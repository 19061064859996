<!-- 
  New Repair Options Display:
  - When newStyleRepairOptions=true AND isEngineerVisitOnly=true
-->
<ng-container
  *dgxFeatureEnabled="
    'newStyleRepairOptions';
    and: isEngineerVisitOnly;
    else: currentOptions
  "
>
  <div class="optimizely-test">
    <h4 class="optimizely-test-header">
      Here's how we can arrange your repair
    </h4>
    <div class="card">
      <div class="card-title">
        <dgx-components-icon
          [name]="'screwdriver-wrench'"
          [size]="'sm'"
        ></dgx-components-icon>
        <span class="typog-body-bold">Engineer visit</span>
      </div>
      <div class="card-content">
        <p class="typog-body">Pick a date for an expert engineer to visit.</p>
      </div>
    </div>

    <claims-excess-information
      *ngIf="excess && repairOptionsForm.valid"
      [excess]="excess"
      [newStyleRepairOptions]="true"
    ></claims-excess-information>
  </div>

  <dgx-components-button
    variant="primary"
    iconName="arrow-right"
    [disabled]="repairOptionsForm.invalid"
    (click)="submit()"
    data-automation="'ConfirmAndContinueRepairDetails'"
  >
    {{
      excess?.cost && excess?.required ? 'Continue to payment' : 'Pick a date'
    }}
  </dgx-components-button>
</ng-container>

<!-- 
  Default Repair Options Display:
  - When newStyleRepairOptions=false
  - When newStyleRepairOptions=true AND isEngineerVisitOnly=false
-->
<ng-template #currentOptions>
  <ng-container
    *ngIf="repairOptions.length && repairOptionsForm"
    id="FaultType"
    class="mt-6 mb-6"
  >
    <div class="mt-6 message">
      <h2>Thanks, we’re glad to accept your repair request.</h2>
      <p *ngIf="repairOptions?.length > 1">
        Please select the repair option that suits you best
      </p>
      <p *ngIf="repairOptions?.length === 1">
        We’ve chosen the most suitable repair option for you
      </p>
    </div>

    <form
      [formGroup]="repairOptionsForm"
      (ngSubmit)="(null)"
      class="legacy-form-builder"
    >
      <dg-fb-radiobutton
        *ngFor="let group of repairOptionData?.groups"
        [elementsPerPage]="group.radios?.elementsPerPage"
        [group]="repairOptionsForm"
        [align]="'justify--left'"
        [showValidation]="showValidation"
        [classes]="'dg-radios ' + group.radios?.classes"
        [idPrefix]="group.radios?.control"
        [control]="group.radios?.control"
        [items]="group.radios?.radioGroup"
        [validators]="group.radios?.validators"
      >
      </dg-fb-radiobutton>
    </form>
  </ng-container>

  <claims-excess-information
    *ngIf="excess && repairOptionsForm.valid"
    [excess]="excess"
  ></claims-excess-information>

  <div class="pb-12">
    <dgx-components-button
      variant="primary"
      iconName="arrow-right"
      [disabled]="repairOptionsForm.invalid"
      (click)="submit()"
      data-automation="'ConfirmAndContinueRepairDetails'"
      data-id="proceed-button"
    >
      Confirm and continue
    </dgx-components-button>
  </div>
</ng-template>
