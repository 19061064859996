<div *ngIf="firstDate" class="grid-row booking">
  <h2 *ngIf="isRebook" class="typog-h4 mb-0">Change your booking</h2>
  <div class="xs-12 flex booking-heading">
    <div class="earliest-date">
      <h5 class="typog-body-bold xs--block md--inline mr-2 mb-none">
        Earliest available:
      </h5>
      <p
        class="typog-button first-date xs--block md--inline"
        (click)="selectFirstDate?.emit()"
      >
        {{
          firstDate.date | date : bookingCalendarDateFormats.DayDateMonthYear
        }}
      </p>
    </div>
  </div>
  <div class="xs-12 booking-body">
    <p>This page shows all our slots, so there's no need to call us.</p>
  </div>
</div>
