<div
  class="info-message"
  [ngClass]="{
    'theme--white': lightTheme
  }"
>
  <div class="info-message__inner">
    <ng-content select="h4"></ng-content>
    <ng-content select="section.content"></ng-content>
  </div>
</div>
