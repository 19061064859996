import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'smart-fix-error-fallback',
  templateUrl: './error-fallback.component.html',
  styleUrls: ['./error-fallback.component.scss'],
})
export class ErrorFallbackComponent {
  @Output() continueToRepairBooking = new EventEmitter();

  fireContinueToRepairBooking(event: boolean) {
    this.continueToRepairBooking.emit(event);
  }
}
