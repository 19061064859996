<div class="header mb-10">
  <dgx-components-icon
    name="close"
    size="xl"
    (click)="triggerCloseChatBot()"
  ></dgx-components-icon>
  <h2 class="title">Smart-Fix</h2>
  <dgx-components-icon
    name="window-minimize"
    size="lg"
    (click)="triggerMinimizeChatBot()"
  ></dgx-components-icon>
</div>
