import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  FormControlsDefinition,
  FormControlsDefinitionChildKey,
} from '../../interfaces/form-controls-definition.interface';

@Component({
  selector: 'dg-fb-form-elements',
  templateUrl: './form-elements.component.html',
  styles: ['dg-fb-button { display: inline; }'],
})
export class FormElementsComponent {
  @Input() group?: UntypedFormGroup;
  @Input() data?: FormControlsDefinition;
  @Input() validate = false;
}
