<div
  *ngIf="vm$ | async as vm"
  [@fadeInAnimation]
  [ngClass]="{
    'background-completed': (repairOptions | async) || vm.callbackInWarranty
  }"
>
  <dg-sub-header
    *ngIf="service.product$ | async as product"
    [product]="product"
    [rebrand]="true"
  >
    <span title>{{ product.title }}</span>
  </dg-sub-header>

  <dg-notification-banner *ngIf="bannerText | async as text">
    <p>
      {{ text }}
    </p>
  </dg-notification-banner>

  <div class="section">
    <ng-container *ngFor="let section of vm.sectionSchema; index as i">
      <div
        [class.section--active-background]="section.active || section.completed"
      >
        <div class="section__wrapper" [class.pt-12]="i === 0">
          <claims-section-title
            [completed]="section.completed"
            [active]="section.active"
            [title]="section.title"
            [number]="$any(i + 1)"
            [attr.id]="
              section.id === pageSection.ContactDetails ? 'ContactSection' : ''
            "
            data-testid="claims-section"
          >
          </claims-section-title>

          <ng-container [ngSwitch]="section.id">
            <ng-container
              *ngSwitchCase="pageSection.ClaimTypeAndFaultTypeQuestions"
            >
              <claims-claim-type-fault-type-form
                *ngIf="section.active"
                [claim]="vm.claim"
                (claimTypeSelected)="service.claimTypeFormSelect($event)"
              ></claims-claim-type-fault-type-form>
            </ng-container>

            <ng-container *ngSwitchCase="pageSection.FaultDetailsQandA">
              <!-- claims-question-form || claims-question-call-back-form -->
              <claims-question-form
                *ngIf="section.active && !vm.claim.getData?.IWCallbackRequired"
              >
              </claims-question-form>

              <claims-call-back-question-form
                *ngIf="section.active && vm.claim.getData.IWCallbackRequired"
                [callbackInWarranty]="vm.callbackInWarranty"
                (qaSubmitted)="service.onCompleteCallbackQA($event)"
              >
              </claims-call-back-question-form>
            </ng-container>

            <ng-container *ngSwitchCase="pageSection.ContactDetails">
              <div *ngIf="section.active">
                <claims-contact-information
                  placeholder="Address"
                  searchLabel="Start typing your address or postcode"
                  [contactDetails]="vm.contactDetails"
                  [claim]="vm.claim"
                  (save)="service.contactDetailsSubmitted($event)"
                  (submitted)="service.onCompleteContactDetails()"
                  (valid)="contactFormValid = $event"
                >
                </claims-contact-information>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="pageSection.RepairOption">
              <div *ngIf="section.active">
                <div *ngIf="repairOptions | async">
                  <claims-repair-options
                    [repairOptions]="repairOptions | async"
                    [excess]="vm?.excess"
                    [claim]="vm?.claim"
                    (selected)="onSelectRepairOption($event)"
                    (submitted)="onCompleteClaimDetails()"
                  ></claims-repair-options>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="pageSection.CallbackBookingWidget">
              <div
                *ngIf="section.active"
                [@slideInOut]
                [ngClass]="{ 'overflow--hidden': !section.active }"
              >
                <claims-call-back-widget-calendar
                  [claim]="vm?.claim"
                ></claims-call-back-widget-calendar>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="!vm?.claim?.created" class="flex justify--center mt-12 pb-12">
    <dgx-components-button
      variant="primary"
      iconName="arrow-left"
      iconAlign="left"
      (click)="service.goBack()"
      data-automation="'GoBackRDStep1And2'"
    >
      Go back
    </dgx-components-button>
  </div>
</div>
