import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dg-flip-card',
  templateUrl: './flip-card.component.html',
  styleUrls: ['./flip-card.component.scss'],
})
export class FlipCardComponent implements OnInit {
  //TODO Rename the property names according to API

  @Input() isFlipped = false;

  constructor() {}

  ngOnInit() {}

  flipCard() {
    this.isFlipped = !this.isFlipped;
  }
}
