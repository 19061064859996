import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { isSameDay } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import { fadeInAnimation } from '../../animations/animations';
import { Api, CalendarDay, CalendarSlot } from '../../interfaces';
import { Availability } from '../../interfaces/claim-state.interface';
import { SlotType } from './booking-calendar-day/booking-calendar-day.component';

@Component({
  selector: 'dg-booking-calendar',
  templateUrl: './booking-calendar.component.html',
  styleUrls: ['./booking-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
})
export class BookingCalendarComponent implements OnChanges {
  @Input() availability?: Availability;
  @Input() isRebook = false;
  @Input() bookingError = false;
  @Input() currentAppointment?: Api.CurrentAppointment;
  @Input() gaData: { planNumber: string; productName: string } | undefined;

  @Input() loading = false;

  @Output() selectDateTimeSlot: EventEmitter<{
    day: CalendarDay;
    slot: CalendarSlot;
  }> = new EventEmitter();

  @Output() getMoreDates: EventEmitter<string> = new EventEmitter();

  days?: CalendarDay[] = [];
  slotType?: SlotType;
  weeks = new BehaviorSubject([]) as BehaviorSubject<CalendarDay[] | []>;

  firstDate?: CalendarDay;

  initialized = false;

  showTooltip = false;

  currentPage = 0;
  currentWeek: CalendarDay[] = [];

  localSelected: CalendarSlot | undefined;
  currentDaySelected: CalendarDay | null | undefined;

  get firstWeekWithSlots(): {
    weekData: Api.SlotsByWeek | undefined;
    index: number;
  } {
    const firstWeek = (
      this.availability?.bookingOption?.SlotsByWeek || []
    ).findIndex((i) => i.AvailabilityData.length);
    return {
      weekData: (this.availability?.bookingOption?.SlotsByWeek || [])[
        firstWeek
      ],
      index: firstWeek,
    };
  }
  constructor() {}

  onDaySlotSelected(selected: { day: CalendarDay; slot: CalendarSlot }) {
    this.selectDateTimeSlot.emit(selected);
  }

  onDaySelected(day: CalendarDay | null, forceOpenState = false) {
    this.currentDaySelected = day;
    if (!day?.slotsAvailable) return;
    this.currentWeek = this.currentWeek.map((item) => {
      item.selected = isSameDay(item.date, day.date)
        ? forceOpenState
          ? true
          : !item.selected
        : false;
      return item;
    });

    this.weeks.next(this.currentWeek);
  }

  onSelectFirstDate() {
    this.currentPage = this.firstWeekWithSlots?.index;
    this.setSlotsToShow();
    this.onDaySelected(this.firstDate as CalendarDay, true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.availability?.bookingOption?.SlotsByWeek) {
      this.firstDate = this.formatToCalendarView(
        this.firstWeekWithSlots?.weekData?.AvailabilityData
      )[0];
    }

    if (changes.availability) {
      this.setSlotsToShow();
    }

    this.initialized =
      (this.availability?.bookingOption?.SlotsByWeek?.length || 0) > 0;
  }

  formatToCalendarView(availableDates: Api.BookingDate[] = []): CalendarDay[] {
    const currentAppointment =
      this.availability?.bookingOption?.CurrentAppointment;

    const mapSlotArray = (slots: Api.BookingDateSlot[]) => {
      return slots
        .map((slot) => {
          return {
            slotType: slot.SlotType,
            identifier: slot.Identifier,
            startTime: slot.StartTime,
            endTime: slot.EndTime,
            available: slot.Available,
          };
        })
        .filter((i) => i.available);
    };

    return availableDates
      .map((dateItem, index) => {
        let slots = Array.isArray(dateItem.Slots)
          ? mapSlotArray(dateItem.Slots)
          : mapSlotArray([dateItem.Slots]);

        if (index === 0) {
          this.slotType = slots.find((i) => i.slotType === SlotType.ALLDAY)
            ? SlotType.ALLDAY
            : SlotType.SPECIFIC;
        }

        if (this.slotType === SlotType.SPECIFIC && currentAppointment) {
          slots = slots.filter((slot) => {
            const timeMatches =
              slot.startTime === currentAppointment?.StartTime &&
              slot.endTime === currentAppointment.EndTime;
            const dateMatches = isSameDay(
              new Date(dateItem.Date),
              new Date(currentAppointment.Date)
            );
            return !dateMatches || !timeMatches;
          });
        }

        return {
          date: new Date(dateItem.Date),
          selected: this.currentDaySelected
            ? isSameDay(
                new Date(this.currentDaySelected.date),
                new Date(dateItem.Date)
              )
            : false,
          slots,
          slotsAvailable: slots.length > 0,
        };
      })
      .filter((i) => {
        if (this.slotType === SlotType.ALLDAY && currentAppointment) {
          return (
            i.slotsAvailable &&
            !isSameDay(i.date, new Date(currentAppointment.Date))
          );
        }

        return i.slotsAvailable;
      });
  }

  setSlotsToShow(): void {
    if (!this.availability?.bookingOption?.SlotsByWeek) return;
    const datesToShow =
      this.availability?.bookingOption?.SlotsByWeek[this.currentPage]
        ?.AvailabilityData;
    this.currentWeek = this.formatToCalendarView(datesToShow);
    this.weeks.next(this.currentWeek);
  }

  navigate(value: 'future' | 'past') {
    if (value === 'future') {
      this.currentPage = this.currentPage + 1;
    } else {
      this.currentPage = this.currentPage - 1;
    }

    this.setSlotsToShow();
  }
}
