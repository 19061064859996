<section *ngIf="lexResponse" class="flex justify--end">
  <div class="description">
    <dgx-components-icon
      *ngIf="getFeedbackIcon(lexResponse)"
      class="ml-3"
      [icon]="getFeedbackIcon(lexResponse)"
    />
    {{ lexResponse }}
  </div>
</section>
