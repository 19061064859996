import { Inject, Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { switchMap, catchError, delay, retryWhen } from 'rxjs/operators';

import {
  CookieTokenService,
  ENVIRONMENT,
  LocalStorageService,
} from '@domgen/dgx-components';
import { throwError, zip, Subscription, of } from 'rxjs';
import { UserService } from './user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ErrorPromptHandlerService } from './error-prompt-handler.service';

enum AuthHeaderNames {
  REQUEST_SOURCE = 'request-source',
  REQUEST_ACTION = 'request-action',
  CLIENT = 'wl-client',
}

@UntilDestroy()
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private localStorage: LocalStorageService,
    private userService: UserService,
    private errorPrompt: ErrorPromptHandlerService,
    private cookieService: CookieTokenService,
    @Inject(ENVIRONMENT) private environment: any
  ) {
    const ttl = this.cookieService.getSessionExpireTime();
    if (ttl) {
      this.endAndStartTimer(ttl);
    }
  }

  public static readonly CHANNEL_CODE: string = 'DGX';
  public static readonly COUNTRY_CODE: string = 'GB';

  public static readonly BUNDLE_TOKEN: string = 'athome_aws_bundle';
  public static readonly GUID: string = 'GUID';
  public static readonly TTL: string = 'x-aws-session-ttl';
  public static readonly ID: string = 'x-correlation-id';

  private timeoutSub?: Subscription | null;

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    return zip(
      this.localStorage.getItem(HttpInterceptorService.BUNDLE_TOKEN),
      this.localStorage.getItem(HttpInterceptorService.GUID)
    ).pipe(
      switchMap(([bundle, GUID]: [string, string]) => {
        let httpsReq;

        /* Add headers required for orbit exclude all urls todo with
         - addressy typerhead for address lookup
         - auth for logging out user
         - person service for updating user details
        */
        const { req, httpOptions } = this.addHeaders(request, bundle);

        if (
          !request.url.includes('addressy') &&
          !request.url.includes('/auth/') &&
          !request.url.includes('api.person') &&
          !request.url.includes('callback') &&
          !request.url.includes('api.esb-service.common')
        ) {
          httpsReq = req.clone({
            ...httpOptions,
          });
        } else if (request.url.includes('api.person')) {
          // get unique headers for person service api call
          httpsReq = request.clone(this.getPersonServiceHeaders());
        } else if (request.url.includes('api.esb-service.common')) {
          httpsReq = request.clone(this.getPersonServiceHeaders());
        } else if (request.url.includes('callback')) {
          // get unique headers for callback service api call
          httpsReq = request.clone({
            ...httpOptions,
            body: {
              ...req.body,
              GUID: GUID,
            },
          });
        } else {
          // no headers added
          httpsReq = request.clone();
        }

        if (request.url.includes('api.smart-fix')) {
          return next.handle(httpsReq).pipe(
            catchError((error: HttpErrorResponse) => {
              return throwError(error);
            })
          );
        }

        return next.handle(httpsReq).pipe(
          retryWhen(this.errorPrompt.retryStrategy(httpsReq)),
          catchError((error: HttpErrorResponse) => {
            if (
              error?.status === 403 ||
              (error?.status === 401 && !request.url.includes('api.person'))
            ) {
              return this.userService
                .logout(true, '', false)
                .pipe(switchMap(() => throwError(error)));
            } else {
              return throwError(error);
            }
          })
        );
      })
    );
  }

  private addHeaders(request: HttpRequest<any>, token: string) {
    let bundle = {};
    if (
      token &&
      !request.url.includes('//api.myaccount') &&
      !request.url.includes('api.esb-service.common') &&
      !request.url.includes('api.identity')
    ) {
      bundle = {
        'X-Aws-Bundle': token.replace(/\ /g, '+'),
        ...(this.userService.correlationID && {
          'x-correlation-id': this.userService.correlationID,
        }),
      };
    }

    if (request.url.includes('/config.json')) {
      bundle = {
        ...bundle,
        'Cache-Control': 'no-cache',
      };
    }

    if (request.url.includes('/signout')) {
      bundle = {
        [AuthHeaderNames.REQUEST_SOURCE]:
          this.environment.requestSource || 'DandGUK',
        [AuthHeaderNames.REQUEST_ACTION]:
          this.environment.requestAction || 'MyAccount',
        [AuthHeaderNames.CLIENT]: this.environment.requestSource || 'DandGUK',
      };
    }

    if (
      request.url.includes('api.claims') ||
      request.url.includes('api2.claims') ||
      request.url.includes('api2.my-account') ||
      request.url.includes('api.person') ||
      request.url.includes('api2.my-claims') ||
      request.url.includes('api.identity')
    ) {
      bundle = {
        ...bundle,
        [AuthHeaderNames.REQUEST_SOURCE]:
          this.environment.requestSource || 'DandGUK',
        [AuthHeaderNames.REQUEST_ACTION]:
          this.environment.requestAction || 'QandB',
        [AuthHeaderNames.CLIENT]: this.environment.requestSource || 'DandGUK',
      };
    }

    const httpOptions = {
      headers: new HttpHeaders({
        accept: 'application/json',
        'Content-Type': 'application/json',
        ...bundle,
      }),
      withCredentials: true,
      observe: 'response',
    };

    if (request.url.includes('api.smart-fix')) {
      const authCookies = this.cookieService.getAuthCookies();

      const smartFixHttpOptions = {
        headers: new HttpHeaders({
          accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authCookies.AWS_IDT}`,
        }),
        withCredentials: true,
        observe: 'response',
      };

      return {
        req: request.clone({
          ...smartFixHttpOptions,
        }),
        httpOptions: smartFixHttpOptions,
      };
    }

    return {
      req: request.clone({
        ...httpOptions,
      }),
      httpOptions: httpOptions,
    };
  }

  getPersonServiceHeaders() {
    return {
      headers: new HttpHeaders({
        accept: 'application/json',
        'Content-Type': 'application/json',
        [AuthHeaderNames.REQUEST_SOURCE]:
          this.environment.requestSource || 'DandGUK',
        [AuthHeaderNames.REQUEST_ACTION]:
          this.environment.requestAction || 'QandB',
        [AuthHeaderNames.CLIENT]: this.environment.requestSource || 'DandGUK',
      }),
      withCredentials: true,
      observe: 'response',
    };
  }

  endAndStartTimer(ttl: number) {
    if (this.timeoutSub) {
      this.timeoutSub.unsubscribe();
      this.timeoutSub = null;
    }

    this.timeoutSub = of(true)
      .pipe(
        untilDestroyed(this),
        delay(ttl),
        switchMap(() => this.userService.logout(true))
      )
      .subscribe();
  }
}
