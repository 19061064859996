import { filter, take, tap } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  ClaimFaultTypeState,
  ClaimFaultTypeStore,
  SelectionSummary,
  SummaryHeading,
} from './claim-fault-type.store';
import {
  Api,
  AppConfig,
  Claim,
  ClaimsAnalyticsService,
  ClaimTypeSelected,
  fadeInAnimation,
} from '@domgen/dgx-components';
import { FormTypes } from './claim-fault-type-form-helper.service';
import {
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { RepairDetailsComponentService } from '../../../services/repair-details-component.service';
import { RepairDetailsService } from '../../../services/repair-details.service';
import {
  ChatClientService,
  SpecialRoutingService,
} from '@domgen/data-access-claims';
import {
  FilterFixApplianceType,
  WhiteGoodApplianceNames,
} from './showBannerHelpers';
import { SmartFixAnalyticsService } from '@domgen/smart-fix/feature-chat';
import { isProduct } from './showBannerHelpers';
import { CONFIG } from '@domgen/dgx-fe-config';
@Component({
  selector: 'claims-claim-type-fault-type-form',
  templateUrl: './claim-fault-type-form.component.html',
  styleUrls: ['./claim-fault-type-form.component.scss'],
  animations: [fadeInAnimation],
})
export class ClaimFaultTypeFormComponent implements OnChanges, OnInit {
  @ViewChild('optionContainer', { static: false }) optionsContainer: ElementRef;

  faultDataSearchForm = new FormGroup({
    search: new FormControl('', Validators.compose([Validators.required])),
  });

  freeTextSearch: boolean | undefined;

  constructor(
    public cs: ClaimFaultTypeStore,
    public service: RepairDetailsComponentService,
    public repairDetailsService: RepairDetailsService,
    private routing: SpecialRoutingService,
    private analytics: ClaimsAnalyticsService,
    private chatService: ChatClientService,
    private readonly smartFixAnalyticsService: SmartFixAnalyticsService,
    @Inject(CONFIG) private readonly config: AppConfig
  ) {}

  public showInWarrantyNotice = false;
  public showplanInWaitPeriodNotice = false;
  public isIWEnabled = false;
  public InWarrantyContactName?: string;
  public InWarrantyContactTel?: string;
  public showSmartFixChatBot = false;
  public smartFixChatState: 'open' | 'closed' = 'open';
  public showPreChatBanner = false;
  public showBedRockChatContainer = false;
  public mode: string = '';
  claimType?: ClaimTypeSelected;
  claimSelection?: Api.ClaimType;
  position?: number;
  productType?: string;
  public readonly isProduct = isProduct;

  @Input() claim: Claim;
  @Output() claimTypeSelected: EventEmitter<ClaimTypeSelected> =
    new EventEmitter();

  description = ``;
  cta = `Right, let’s continue`;

  formSubmitted = false;

  fallBackHappened = false;

  showFallbackAlert = false;

  $vm = this.cs.vm$.pipe(
    tap((v) => {
      this.showFallbackAlert = false;

      if (
        v.freeTextSearchState?.fallBackToOldSelect &&
        !this.fallBackHappened
      ) {
        this.fallBackHappened = true;
        this.cs.resetForm(
          v.selectionSummary,
          FormTypes.FAULTTYPEFORM,
          v.claim,
          false
        );
        setTimeout(() => {
          this.showFallbackAlert = true;
        }, 100);
      }

      if (v.formData?.freeTextSearch) {
        this.freeTextSearch = true;
      }

      const faultData = v.claim?.getData?.FaultData || [];
      this.faultOptionsList = Array.isArray(faultData)
        ? faultData
        : [faultData];

      this.setOptionContainerSize();
    })
  ) as Observable<ClaimFaultTypeState>;

  faultOptionsList: Api.FaultData[] = [];

  @HostListener('window:resize')
  onResize() {
    this.setOptionContainerSize();
  }

  ngOnInit(): void {
    if (this.config.runtimeFeatures.standaloneSmartFixPage) {
      window['globalTriggerSmartFixChatBot'] =
        this.triggerSmartFixChatBot.bind(this);

      this.productType = this.claim?.reflect?.productType.toLowerCase();
      window['globalCurrentClaim'] = this.claim;
    }
  }

  get showFreeTextSearchError(): boolean {
    return (
      (this.faultDataSearchForm.touched &&
        this.faultDataSearchForm.get('search').errors?.required) ||
      (this.formSubmitted &&
        this.faultDataSearchForm.get('search').errors?.noMatch)
    );
  }

  get isMobile() {
    return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.claim) {
      this.showPreChatBanner = this.canShowPreChatBannerBasedOnClaim();
      this.cs.setClaim(this.claim);
    }
  }

  calculateOptionContainerHeight(optionSize: number, children: HTMLElement[]) {
    let height = 0;

    for (let i = 0; i < optionSize; i++) {
      height = height + children[i].offsetHeight;
    }

    height = height + 16 * (optionSize - 1) + 4;
    return height;
  }

  isGasLeak(selectionSummary: SelectionSummary[]) {
    return selectionSummary.find(
      (selection) =>
        selection.description.toLocaleLowerCase() ===
        'Gas leak'.toLocaleLowerCase()
    );
  }

  getFaultData(): Observable<Api.FaultData | undefined> {
    return this.cs.faultData$?.pipe(take(1));
  }

  setOptionContainerSize(): void {
    const optionSize = this.isMobile || window.innerWidth < 768 ? 5 : 7;

    setTimeout(() => {
      if (!this.optionsContainer?.nativeElement) return;
      const { children } = this.optionsContainer.nativeElement;

      if (children.length < optionSize) {
        this.optionsContainer.nativeElement.style.height = 'auto';
        return;
      }
      const height = this.calculateOptionContainerHeight(optionSize, children);
      this.optionsContainer.nativeElement.style.height = `${height}px`;
    });
  }

  searchFaults(): void {
    this.formSubmitted = true;
    this.faultDataSearchForm.markAllAsTouched();
    const searchTerm = this.faultDataSearchForm.get('search').value.trim();

    if (searchTerm === '') {
      this.faultDataSearchForm.get('search').setValue('');
    }

    this.cs.searchResults(searchTerm, () => {
      this.faultDataSearchForm.get('search').setErrors({
        noMatch: true,
      });
    });

    this.setOptionContainerSize();
  }

  selectResult(
    result: Api.FaultSearchResponse,
    selectionSummary: SelectionSummary[]
  ): void {
    const faultCategory = this.faultOptionsList.find(
      (i) => i.FaultCategoryName === result.faultId_lv2
    );

    const problemFaultId = faultCategory.PossibleAnswers.find(
      (answer) => `${answer.FaultCode}` === `${result.problemId_lv3}`
    )?.FaultID;
    const newSelectionSummary: SelectionSummary[] = [
      ...selectionSummary,
      {
        formName: FormTypes.FAULTAREAFORM,
        id: faultCategory?.FaultCategoryID as unknown as string,
        title: SummaryHeading.FAULTAREAFORM,
        description: result.faultId_descr,
      },
      {
        formName: FormTypes.FAULTTYPEFORM,
        id: `${problemFaultId}`,
        title: SummaryHeading.FAULTTYPEFORM,
        description: result.problemId_descr,
      },
    ];

    this.cs.updateSelectionSummary(newSelectionSummary);
    this.cs.updateForm({
      formData: null,
      form: null,
    });
    this.cs.showContinueCTA(true);
  }

  continue(form: UntypedFormGroup) {
    this.isIWEnabled = false;
    this.showInWarrantyNotice = false;
    this.showplanInWaitPeriodNotice = false;

    this.claimTypeSelected.emit({
      ClaimTypeID: form.value.claimType,
    });
  }

  formTypeChange(
    form: UntypedFormGroup,
    selectionSummary: SelectionSummary[],
    claim: Claim
  ) {
    if (form.controls[FormTypes.CLAIMTYPEFORM]) {
      const { isIWEnabled, showInWarrantyNotice, showplanInWaitPeriodNotice } =
        this.claimWarrantyStatus(form.value.claimType);

      this.isIWEnabled = isIWEnabled;
      this.showInWarrantyNotice = showInWarrantyNotice;
      this.showplanInWaitPeriodNotice = showplanInWaitPeriodNotice;

      if (
        this.showInWarrantyNotice ||
        this.showplanInWaitPeriodNotice ||
        this.isIWEnabled
      ) {
        this.triggerWarrantyNoticeGA();
        return;
      } else {
        const payload = {
          ClaimTypeID: form.value.claimType,
        };

        this.claimTypeSelected.emit(payload);
      }
    } else {
      this.cs.formTypeChange(form, selectionSummary, claim);
    }
  }

  private triggerWarrantyNoticeGA() {
    this.analytics.pageViewEvent({
      eventClaims: 'rejected-in-warranty-dgx',
      claim: this.claim as Claim,
    });
  }

  routeToPlanDetails() {
    this.routing.routeToPlanDetails(this.claim as Claim);
  }

  claimWarrantyStatus(claimTypeID: string) {
    this.claimSelection = this.claim?.getData?.ClaimTypes.filter(
      (type, index) => {
        if (type.ClaimTypeID === claimTypeID) {
          this.position = index;
          return type.ClaimTypeID;
        }
      }
    )[0];

    const isIWEnabled = this.claimSelection?.IWEnabled || false;

    const showInWarrantyNotice = this.repairDetailsService.showInWarrantyNotice(
      this.claim as Claim,
      this.claimSelection?.ClaimTypeCode as string
    );
    const showplanInWaitPeriodNotice =
      this.repairDetailsService.showplanInWaitPeriodNotice(
        this.claim as Claim,
        this.claimSelection?.ClaimTypeCode as string
      );

    return {
      showInWarrantyNotice,
      showplanInWaitPeriodNotice,
      isIWEnabled,
    };
  }

  canShowPreChatBannerBasedOnClaim(): boolean {
    if (!this.claim || this.config.runtimeFeatures.standaloneSmartFixPage)
      return false;
    this.productType = this.claim?.reflect?.productType.toLowerCase();
    if (this.productType.trim().includes('boiler')) {
      return this.config.runtimeFeatures.boilerLexChat;
    }
    if (
      WhiteGoodApplianceNames.concat(FilterFixApplianceType).includes(
        this.productType
      )
    ) {
      if (this.config.runtimeFeatures.enableSmartFixGenAIPOC) {
        return true;
      } else {
        return FilterFixApplianceType.includes(this.productType);
      }
    }
    return false;
  }

  canShowSmartFixGenAIPOC(): boolean {
    this.productType = this.claim?.reflect?.productType.toLowerCase();
    if (
      WhiteGoodApplianceNames.concat(FilterFixApplianceType).includes(
        this.productType
      )
    ) {
      return this.config.runtimeFeatures.enableSmartFixGenAIPOC;
    }
    return false;
  }

  hideLiveChat(): void {
    this.chatService.hideChat();
  }

  showLiveChat(): void {
    this.chatService.showChat();
  }

  triggerSmartFixChatBot(mode: string): void {
    this.mode = mode;
    this.hideLiveChat();
    this.showSmartFixChatBot = true;
    this.trackSelectedFaultDetails();
    this.showBedRockChatContainer = this.canShowSmartFixGenAIPOC();
    this.trackPreChatBannerChoice('yes', 'banner - smart fix');
  }

  preChatVisible(): void {
    this.trackPreChatBannerChoice('banner presented', 'banner - smart fix');
  }

  private handleSmartFixChat(): void {
    this.showSmartFixChatBot = false;
    this.chatService.hideSmartFixChatBubble();
    this.chatService.showChat();
    this.cs.submitClaim(true);
  }

  continueBooking(): void {
    this.handleSmartFixChat();
    this.trackSelectedFaultDetails();
    this.trackPreChatBannerChoice('No, continue booking', 'banner - smart fix');
  }

  continueBookingAfterSmartFixChat(): void {
    this.handleSmartFixChat();
    this.smartFixAnalyticsService.triggerSmartFixEvent(
      "Right, let's continue", // to check with David!
      'fault-details-completed',
      this.claim,
      'fault-details-completed',
      'claims - smart fix'
    );
  }

  onChangeSmartFixChat(event: 'open' | 'closed'): void {
    this.smartFixChatState = event;
  }

  trackPreChatBannerChoice(eventLabel: string, eventAction: string) {
    this.smartFixAnalyticsService.triggerSmartFixEvent(
      eventLabel,
      'fault-details-completed',
      this.claim,
      eventAction,
      null
    );
  }

  trackSelectedFaultDetails() {
    this.cs.$selectionSummary.pipe(take(1)).subscribe((res) => {
      const faultArea = res?.find((res) => res.formName == 'faultArea');
      const faultType = res?.find((res) => res.formName == 'faultType');

      const formattedFaultDetails =
        [faultArea?.description, faultType?.description]
          .filter((value) => value)
          .join(' | ') || 'No Fault Details Captured';

      this.smartFixAnalyticsService.triggerSmartFixEvent(
        formattedFaultDetails,
        'fault-details-completed',
        this.claim,
        'fault-type-selected',
        'claims - smart fix'
      );
    });
  }
}
