import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const chatBotFadeInAnimation = trigger('chatBotFadeInAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('0.2s', style({ opacity: 1 })),
  ]),
]);

export const chatBotFadeInAnimationSequence = trigger(
  'chatBotFadeInAnimationSequence',
  [
    transition(':enter', [
      query('.sequence-item', [
        style({ opacity: 0 }),
        stagger('1.2s', [animate('0.2s', style({ opacity: 1 }))]),
      ]),
    ]),
  ]
);
