import { Claim } from '@domgen/dgx-components';

export const FilterFixApplianceType = [
  'tumble dryer',
  'washing machine',
  'washer dryer',
];

export const isProduct = (claim: Claim, product: string): boolean => {
  return claim?.reflect?.productType?.toLowerCase().trim().includes(product);
};

export const WhiteGoodApplianceNames = [
  'american fridge freezer',
  'condenser dryer',
  'cooker',
  'cooker hood',
  'dishwasher',
  'double oven',
  'freezer',
  'fridge',
  'fridge Freezer',
  'hob',
  'oven',
  'oven and hob',
  'oven hob and hood',
  'range cooker',
];
