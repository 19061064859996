import { Injectable } from '@angular/core';
import {
  Validators,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { ValidationError } from '../interfaces/validation-error.interface';
import { head } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  private _errors = new Subject<ValidationError>();
  public errors = this._errors.asObservable();

  validators: (ValidationErrors | ValidatorFn)[] = [];
  addControlValidationFn(validators: ValidationError[]) {
    this.validators = [];
    for (const v in validators) {
      if (v) {
        switch (validators[v].type) {
          case 'email':
            this.validators.push(Validators.email);
            break;
          case 'required':
            this.ifCheckbox(validators[v]?.checkbox as boolean);
            break;
          case 'minlength':
            this.validators.push(
              Validators.minLength(validators[v]?.value as number)
            );
            break;
          case 'maxlength':
            this.validators.push(
              Validators.maxLength(validators[v]?.value as number)
            );
            break;
          case 'pattern':
            this.validators.push(
              Validators.pattern(validators[v]?.regexp as string)
            );
            break;
        }
      }
    }
    return this.validators;
  }
  ifCheckbox(checkbox: boolean) {
    if (checkbox) {
      this.validators.push(Validators.requiredTrue);
    } else {
      this.validators.push(Validators.required);
    }
  }

  getErrors(
    group: UntypedFormGroup,
    control: string,
    validators: ValidationError[]
  ): ValidationError {
    const error: ValidationError | undefined = validators.find(
      (v: ValidationError) => {
        const err = group.get(control)?.errors;
        return err ? v.type === head(Object.keys(err)) : null;
      }
    );

    if (error) {
      this._errors.next(error);
    }

    return {
      name: control,
      message: error?.message as string,
      type: error?.type as string,
    };
  }
}
