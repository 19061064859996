import { Claim, ClaimJourneyStateType } from '@domgen/dgx-components';
import { cloneDeep, reduce } from 'lodash-es';
import { State } from '../+state/claim.reducer';

export const claimHelper = {
  getClaim(state: State): Claim {
    return state.entities[state?.planNumber as string] as Claim;
  },
  checkClaimState: (claim: Claim, existingClaim: Claim | undefined): Claim => {
    const newToken =
      claim?.bundleToken && claim?.bundleToken !== existingClaim?.bundleToken;

    const clearClaim =
      newToken && claim.reflect?.journeyState === ClaimJourneyStateType.START
        ? true
        : false;

    return clearClaim ? {} : (existingClaim as Claim);
  },

  updateClaim: (newclaim: Claim, existingClaim: Claim): Claim => {
    const BundleClaimCreated =
      newclaim.reflect?.journeyState === ClaimJourneyStateType.QA
        ? true
        : false;
    const BundleClaimAccepted =
      newclaim.reflect?.journeyState === ClaimJourneyStateType.BOOKING
        ? true
        : false;

    // if claim is in progress and no cached claim available
    if (BundleClaimCreated || BundleClaimAccepted) {
      existingClaim.claimID = newclaim.reflect?.claimId;
      existingClaim.created = true;
    }

    // if claim is accepted and no cached claim available
    if (BundleClaimAccepted) {
      existingClaim.accepted = true;
    }

    return existingClaim;
  },

  updateClaims: (claims: Claim[], activeClaim: Claim): Claim[] => {
    return reduce(
      cloneDeep(claims),
      (c: Claim[], claim: Claim) => {
        c.push(
          claim.reflect?.planNumber === activeClaim.reflect?.planNumber
            ? activeClaim
            : claim
        );
        return c;
      },
      []
    );
  },
};
