import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  ContactDetailsFormValues,
  ContactDetailsFormValuesContainer,
} from '../../interfaces/contact-details.interface';
export interface FormTemplateBuilder {
  name: string;
  title: string;
  automation: string;
  requiredMessage: string;
  patternMessage: string;
  addressGroup: boolean;
  formGroup: {
    [key: string]: any;
  };
}

@Injectable()
export class ContactDetailsLookupService {
  constructor() {}

  getLookupFormGroup(contactDetails: ContactDetailsFormValuesContainer): {
    [key: string]: any;
  } {
    return Object.assign(
      { address: [''] }, // default in Loquate address field
      ...this.getFormBuilderList(contactDetails).map((c) => c.formGroup)
    );
  }

  getFormBuilderElement(
    name: string,
    contactDetails: ContactDetailsFormValuesContainer
  ): FormTemplateBuilder {
    return this.getFormBuilderList(contactDetails).find(
      (e) => e.name === name
    ) as FormTemplateBuilder;
  }

  getFormBuilderList(
    contactDetails: ContactDetailsFormValuesContainer
  ): FormTemplateBuilder[] {
    return this.contactDetailsFormDefList(contactDetails).filter((c) =>
      Object.keys(contactDetails.formValues).includes(c.name)
    );
  }

  checkNumber(f: ContactDetailsFormValues, type: string): string | void {
    if (f.mobile?.length === 0 && type === 'mobile') {
      const isMobile = /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/.test(
        f.landline as string
      );

      if (isMobile && f.landline) {
        return this.formatNumber(f.landline);
      }
      return '';
    }

    if ((f.mobile as string)?.length > 0 && type === 'mobile') {
      return this.formatNumber(f.mobile as string);
    }

    if (type === 'landline') {
      const isMobile = /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/.test(
        f.landline as string
      );

      if (!isMobile && f.landline) {
        return this.formatNumber(f.landline);
      }

      return '';
    }
  }

  private formatNumber(number: string) {
    return number.replace('+44', '0');
  }

  private contactDetailsFormDefList(
    contactDetails: ContactDetailsFormValuesContainer
  ): FormTemplateBuilder[] {
    return [
      {
        name: 'email',
        title: 'Email',
        automation: 'Email',
        requiredMessage: 'Enter your email address',
        patternMessage: 'Enter a valid email address',
        addressGroup: false,
        formGroup: {
          email: [
            contactDetails.formValues.email,
            [
              Validators.required,
              Validators.email,
              Validators.pattern(
                '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
              ),
            ],
          ],
        },
      },
      {
        name: 'mobile',
        title: 'Mobile',
        automation: 'Mobile',
        requiredMessage: 'Enter your mobile number',
        patternMessage: 'Enter a valid mobile number',
        addressGroup: false,
        formGroup: {
          mobile: [
            this.checkNumber(contactDetails.formValues, 'mobile'),
            [
              Validators.required,
              // prettier-ignore
              Validators.pattern(/^(\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/),
            ],
          ],
        },
      },
      {
        name: 'landline',
        title: 'Landline',
        automation: 'Landline',
        requiredMessage: 'Enter your landline number',
        patternMessage: 'Enter a valid landline number',
        addressGroup: false,
        formGroup: {
          landline: [
            this.checkNumber(contactDetails.formValues, 'landline'),
            [
              // prettier-ignore
              // Validators.pattern(/^[0-9+]*$/),
              Validators.pattern(/(\(?\0\)?\s?(1|2|3|7|8)\d{3}|\(?(01|02|03|07|08)\d{3}\)?)\s?\d{3}\s?\d{3}|(\(?\0\)?\s?(1|2|3|5|7|8)\d{2}|\(?(01|02|03|05|07|08)\d{2}\)?)\s?\d{3}\s?\d{4}|(\(?\0\)?\s?(5|9)\d{2}|\(?(05|09)\d{2}\)?)\s?\d{3}\s?\d{3}/),
              Validators.maxLength(13),
              Validators.minLength(11),
            ],
          ],
        },
      },
      {
        name: 'addressLine1',
        title: 'Address line 1',
        automation: 'addressLine1',
        requiredMessage: 'Enter your home address',
        patternMessage: 'Your home address contains restricted characters',
        addressGroup: true,
        formGroup: {
          addressLine1: [
            contactDetails.formValues.addressLine1,
            [
              Validators.required,
              Validators.pattern(
                // prettier-ignore
                '^[0-9A-Za-z-,.&\\/\' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$'
              ),
            ],
          ],
        },
      },
      {
        name: 'addressLine2',
        title: 'Address line 2',
        automation: 'addressLine2',
        requiredMessage: '',
        patternMessage: 'Your home address contains restricted characters',
        addressGroup: true,
        formGroup: {
          addressLine2: [
            contactDetails.formValues.addressLine2,
            [
              Validators.pattern(
                // prettier-ignore
                '^[0-9A-Za-z-,.&\\/\' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$'
              ),
            ],
          ],
        },
      },
      {
        name: 'city',
        title: 'Town/City',
        automation: 'city',
        requiredMessage: 'Enter your town or city',
        patternMessage: 'Your town or city name contains restricted characters',
        addressGroup: true,
        formGroup: {
          city: [
            contactDetails.formValues.city,
            [
              Validators.required,
              Validators.pattern(
                // prettier-ignore
                '^[A-Za-z-,.&\\/\' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$'
              ),
            ],
          ],
        },
      },
      {
        name: 'county',
        title: 'County',
        automation: 'county',
        requiredMessage: '',
        patternMessage: 'Your county contains restricted characters',
        addressGroup: true,
        formGroup: {
          county: [
            contactDetails.formValues.county,
            [
              Validators.pattern(
                // prettier-ignore
                '^[A-Za-z-,.&\\/\' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$'
              ),
            ],
          ],
        },
      },
      {
        name: 'postcode',
        title: 'Postcode',
        automation: 'postcode',
        requiredMessage: 'Enter your postcode',
        patternMessage: 'Enter a valid postcode',
        addressGroup: true,
        formGroup: {
          postcode: [
            contactDetails.formValues.postcode,
            [
              Validators.required,
              Validators.pattern(
                // prettier-ignore
                '[A-Za-z]{1,2}[0-9]{1,2}[A-Za-z]{0,1} ?[0-9][A-Za-z]{2}'
              ),
            ],
          ],
        },
      },
    ];
  }
}
