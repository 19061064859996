import { Injectable } from '@angular/core';
import { Api } from '../interfaces/api.interface';
import {
  Claim,
  ClaimBundleType,
  ServiceOptionTypes,
} from '../interfaces/claim-state.interface';

@Injectable({
  providedIn: 'root',
})
export class ClaimHelperService {
  constructor() {}

  isASV(claim: Claim): boolean {
    return (
      claim?.reflect?.asvOffered === 'true' &&
      claim?.reflect?.claimType === ClaimBundleType.ANNUAL_SERVICE
    );
  }

  isCallbackBooking(
    claim: Claim,
    response: Api.PutServiceOptionResponse
  ): boolean {
    return (
      !this.hasSlots(claim, response) &&
      this.engineerBooking(claim) &&
      this.callbackBrandsAllowed(claim)
    );
  }

  engineerBooking(claim: Claim): boolean {
    return claim.bookedServiceOption === ServiceOptionTypes.Home;
  }

  hasSlots(
    claim: Claim,
    response: Api.PutServiceOptionResponse | null = null
  ): boolean {
    return (
      response?.ExtraAvailability === true ||
      (response?.AvailabilityData?.length as number) > 0 ||
      (claim?.bookingOption?.AvailabilityData?.length as number) > 0
    );
  }

  callbackBrandsAllowed(claim: Claim): boolean {
    const allowedCallbackBrands = [
      'VOKERA',
      'HEATING SERVICES',
      'PROCOMBI',
      'SABRE',
    ];
    return allowedCallbackBrands.includes(
      claim?.reflect?.manufacturer?.toUpperCase() as string
    );
  }
}
