import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customClosedChatDate',
})
export class CustomDatePipe implements PipeTransform {
  constructor(public datePipe: DatePipe) {}

  transform(value: Date | string | number): string {
    if (!value) return '';

    const date = new Date(value);
    return (
      this.datePipe.transform(date, 'dd.MM.yyyy HH:mm', 'Europe/London') ?? ''
    );
  }
}
