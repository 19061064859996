import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
  OnDestroy,
  Input,
} from '@angular/core';
import { ChatContainerComponent } from '../../chat-container/chat-container.component';
import { ChatFacadeService } from '@domgen/smart-fix/data-access-smart-fix';
import { SmartFixAnalyticsService } from '../../../services/smart-fix-analytics/smart-fix-analytics.service';
import { AppConfig, Claim } from '@domgen/dgx-components';
import { CONFIG } from '@domgen/dgx-fe-config';
import { ApiService } from '@domgen/data-access-claims';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { initialPrompt, selectedModel } from '../utils-bedrock';

type BedrockMessages = {
  role: 'user' | 'agent';
  sessionId: string;
  content: {
    message: string;
    type: string;
    timestamp: string;
  };
};

@Component({
  selector: 'smart-fix-bedrock-chat-container',
  templateUrl: './bedrock-chat-container.component.html',
  styleUrls: [
    './bedrock-chat-container.component.scss',
    './../../chat-container/chat-container.component.scss',
  ],
})
export class BedrockChatContainerComponent
  extends ChatContainerComponent
  implements OnInit, OnDestroy
{
  history: Array<BedrockMessages> = [];
  message: string = '';
  sessionId!: string;
  initialMessage: string = '';
  private destroy$ = new Subject<void>();
  @Input() claim!: Claim;

  modelOptions: { label: string; value: string }[] = [
    {
      label: 'Claude 3 Haiku',
      value:
        'arn:aws:bedrock:eu-west-2::foundation-model/anthropic.claude-3-haiku-20240307-v1:0',
    },
    {
      label: 'Claude 3 Sonnet',
      value:
        'arn:aws:bedrock:eu-west-2::foundation-model/anthropic.claude-3-sonnet-20240229-v1:0',
    },
  ];

  initForm?: FormGroup;
  isFadeIn = false;
  isLoading = false;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('messageInput') messageInput!: ElementRef;
  @ViewChild('textArea') textArea!: ElementRef<HTMLTextAreaElement>;
  isListening: boolean = false;
  recognition: any;
  isSpeechRecognized: boolean = false;
  isInputActive: boolean = false;
  enablePromptEditingForSmartFix: boolean = false;

  isFeedback: boolean = false;
  feedback: string = '';
  feedbackRequested: boolean = false;
  onRequestFeedback() {
    this.feedbackRequested = true;
  }
  onSendFeedback(event: string) {
    this.feedback = event;
  }

  showFeedback() {
    this.isFeedback = true;
  }

  exampleString: string = `This is the first paragraph. It's a block of text that stands alone.\n
And this is the second paragraph. It's another block of text that starts on a new line.\n
Sometimes I like to use blocks of text to make things look neat and tidy. It's a nice way to organize information!\n
Other times, though, blocks of text can be useful for creating space between different ideas or thoughts. It's a simple way to visually separate things.\n
I hope this helps! Let me know if you have any other questions.`;

  chatForm = new FormGroup({
    message: new FormControl('', Validators.compose([Validators.required])),
  });

  constructor(
    chatFacade: ChatFacadeService,
    smartFixAnalyticsService: SmartFixAnalyticsService,
    private apiService: ApiService,
    renderer: Renderer2,
    @Inject(CONFIG) protected readonly config: AppConfig
  ) {
    super(chatFacade, smartFixAnalyticsService, config, renderer);
    this.enablePromptEditingForSmartFix =
      this.config.runtimeFeatures.enablePromptEditingForSmartFix;
  }

  ngOnInit(): void {
    this.initialMessage = `Hey there I have an issue with my ${this.claim.reflect?.productType?.toLowerCase()}`;
    if (this.enablePromptEditingForSmartFix) {
      this.initiateForm();
      this.loadModelList();
    } else {
      this.initiateBedrockChat();
    }
  }

  initiateForm() {
    this.initForm = new FormGroup({
      prompt: new FormControl(
        initialPrompt,
        Validators.compose([Validators.required])
      ),
      modelArn: new FormControl(
        selectedModel,
        Validators.compose([Validators.required])
      ),
      initialMessage: new FormControl(this.initialMessage),
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initiateBedrockChat() {
    this.isLoading = true;

    const initialMessage = this.enablePromptEditingForSmartFix
      ? this.initForm?.value.initialMessage
      : this.initialMessage;

    this.apiService
      .initiateBedrockChat(
        initialMessage,
        this.claim.reflect?.productType?.toLowerCase(),
        this.claim.reflect?.planNumber,
        this.initForm?.value.prompt || undefined,
        this.initForm?.value.modelArn || undefined
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.isLoading = false;
        this.history = [];
        this.sessionId = res.sessionId;
        this.history.push({
          role: 'agent',
          sessionId: res.sessionId,
          content: {
            message: res.output.text,
            type: 'text',
            timestamp: new Date().toISOString(),
          },
        });
      });
  }

  loadModelList() {
    this.apiService
      .listBedrockModels()
      .pipe(takeUntil(this.destroy$))
      .subscribe((models) =>
        models.forEach((model) =>
          this.modelOptions.push({
            label: model.modelName,
            value: model.modelArn,
          })
        )
      );
  }

  onSubmit(event: Event) {
    if (this.chatForm.valid && this.chatForm?.value?.message?.trim()) {
      event.preventDefault();
      this.sendBedrockChatMessage(
        this.chatForm?.value?.message ?? '',
        this.sessionId,
        this.initForm?.value?.prompt || undefined,
        this.initForm?.value?.modelArn || undefined
      );
      this.chatForm.reset();
      setTimeout(() => {
        if (this.textArea) {
          this.textArea.nativeElement.style.height = '22px';
        }
      }, 0);
      this.isInputActive = false;
      this.isSpeechRecognized = false;
    }
  }

  initChat() {
    this.initiateBedrockChat();
  }

  closeChat(): void {
    super.closeChat();
    window.location.href = '/';
  }

  sendBedrockChatMessage(
    message: string,
    sessionId: string,
    prompt?: string,
    modelArn?: string
  ) {
    this.history.push({
      role: 'user',
      sessionId: this.sessionId,
      content: {
        message,
        type: 'text',
        timestamp: new Date().toISOString(),
      },
    });

    this.isLoading = true;

    this.apiService
      .sendBedrockChatMessage(sessionId, message, prompt, modelArn)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.sessionId = res.sessionId;
        this.isLoading = false;
        this.history.push({
          role: 'agent',
          sessionId: res.sessionId,
          content: {
            message: res.output.text,
            type: 'text',
            timestamp: new Date().toISOString(),
          },
        });
      });

    this.chatForm.reset();
  }

  onFocusInput() {
    this.isInputActive = true;
  }

  onBlurInput() {
    this.isInputActive = false;
  }

  autoResize(event: Event) {
    this.textArea.nativeElement.style.height = '22px';
    this.textArea.nativeElement.style.height =
      this.textArea.nativeElement.scrollHeight + 'px';
  }
}
