import { ElementRef, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ClaimFacade,
  ClaimHelperService,
  SpecialRoutingService,
} from '@domgen/data-access-claims';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { filter, switchMapTo, take } from 'rxjs/operators';
import { RepairDetailsService } from './repair-details.service';
import { claimActions } from '@domgen/data-access-claims';
import { ClaimTypeSelected } from '@domgen/dgx-components';

import {
  Api,
  Claim,
  ClaimsAnalyticsService,
  ClaimStage,
  ContactDetailsFormValues,
  ContactDetailsFormValuesContainer,
  FormControlsDefinition,
  PartialUpdateClaimPayload,
  ProductDetails,
  ServiceOption,
  PageSection,
  PutAnswer,
} from '@domgen/dgx-components';
import { QAFormCallbackHelper } from './qa-form-callback-helper';

export interface RepairState {
  claim: Claim;
  sectionSchema: PageSection[];
  contactDetails: ContactDetailsFormValuesContainer;
  contactFormValue: ContactDetailsFormValues;
  claimTypeOptions: Api.ClaimType[];
  callbackInWarranty: null | FormControlsDefinition[];
}

@Injectable({
  providedIn: 'root',
})
export class RepairDetailsComponentService {
  vm$ = this._claimFacade.claimLoaded$.pipe(
    filter(Boolean),
    switchMapTo(this._claimFacade.activeClaim$.pipe(take(1))),
    map((claim: Claim) => {
      this.claim = claim;
      this.contactDetails = {
        formValues: {
          email: this.claim.reflect?.email,
          mobile: this.claim.reflect?.mobilePhone,
          landline: this.claim.reflect?.homeTelephone,
          address: '',
          addressLine1: this.claim.reflect?.addressLine1 as string,
          addressLine2: '',
          city: this.claim.reflect?.addressLine2 as string,
          county: this.claim.reflect?.addressLine4 as string,
          postcode: this.claim.reflect?.postCode as string,
        },
      };
      this.contactFormValue = this.contactDetails?.formValues;

      this.pageViewGA();

      return {
        claim: this.claim,
        sectionSchema: this.setPageSectionSchema(claim),
        claimTypeOptions: this.claimTypeOptions,
        contactDetails: this.contactDetails,
        contactFormValue: this.contactFormValue,
        callbackInWarranty: this.getCallbackQA(claim),
      };
    })
  ) as Observable<RepairState>;

  readonly repairOptions$ = this._claimFacade.serviceOptions$;
  readonly product$ = new BehaviorSubject(
    null
  ) as BehaviorSubject<ProductDetails | null>;

  constructor(
    private _claimFacade: ClaimFacade,
    private _repairDetails: RepairDetailsService,
    private claimHelper: ClaimHelperService,
    private callbackHelper: QAFormCallbackHelper,
    private title: Title,
    private analytics: ClaimsAnalyticsService,
    private routing: SpecialRoutingService
  ) {}

  sectionSchemaInitialised = false;
  claimTypeOptions?: Api.ClaimType[];
  sectionSchema?: PageSection[];
  claim?: Claim;
  contactDetails?: ContactDetailsFormValuesContainer;
  contactFormValue?: ContactDetailsFormValues;

  setPageSectionSchema(claim: Claim): PageSection[] {
    if (!this.sectionSchemaInitialised || claim?.getData?.IWCallbackRequired) {
      const init = this._repairDetails.initSectionSchemaAndOptions(claim);
      this.sectionSchema = init.sectionSchema;
      this.title.setTitle(`${init.title} | Domestic & General`);
      this.claimTypeOptions = init.claimTypeOptions;
      this.setHeader(init.title, claim);
      this.sectionSchemaInitialised = true;
    }

    // set completed and active flags
    return this._repairDetails.getSectionSchemaFlags(
      this.sectionSchema as PageSection[],
      claim
    );
  }

  setHeader(title: string, claim: Claim) {
    this.product$.next({
      title,
      brand: claim.reflect?.manufacturer,
      applianceName: claim.reflect?.productType,
      planNumber: claim.reflect?.planNumber,
      modelNumber: this.claimHelper.getModelNumberHeading(claim) as string,
    });
  }

  claimTypeFormSelect(request: ClaimTypeSelected) {
    this._claimFacade.dispatch(
      claimActions.UpdateClaim({
        payload: {
          request: {
            ...request,
            Manufacturer: this.claim?.reflect?.manufacturer,
            ProductType: this.claimHelper.getProductTypeForUpdateClaim(
              this.claim as Claim
            ),
          },
          selectionState: {
            claimStage: ClaimStage.ClaimTypeSelect,
          },
          route: false,
        },
      })
    );
  }

  claimTypeFormCreateClaim(partialPayload: PartialUpdateClaimPayload) {
    this._claimFacade.dispatch(
      claimActions.CreateClaim({ payload: partialPayload })
    );
  }

  qAFormPutAnswer(answer: PutAnswer) {
    this._claimFacade.dispatch(
      claimActions.PutAnswer({
        payload: {
          ClaimID: this.claim?.claimID as string,
          QuestionID: answer.QuestionID,
          AnswerID: answer.AnswerID,
          AnswerValue: answer.AnswerValue,
        },
      })
    );
  }

  contactDetailsSubmitted(contactFormValue: ContactDetailsFormValues) {
    this.contactFormValue = contactFormValue;
  }

  onCompleteContactDetails() {
    const customer = {
      ClaimID: this.claim?.claimID as string,
      CustomerTitle: this.claim?.reflect?.customerTitle as string,
      CustomerFirstName: this.claim?.reflect?.firstName || '', // firstName can be undefined
      CustomerLastName: this.claim?.reflect?.surname as string,
      CustomersEmail: this.contactFormValue?.email as string,
      CustomersMobile: this.contactFormValue?.mobile as string,
      CustomersLandLine: this.contactFormValue?.landline as string,
      CustomersHouseStreetName: `${this.contactFormValue?.addressLine1}${
        this.contactFormValue?.addressLine2
          ? ', ' + this.contactFormValue?.addressLine2
          : ''
      }`,
      CustomersTownCity: this.contactFormValue?.city as string,
      CustomersLocalArea: this.contactFormValue?.county as string,
      CustomersPostCode: this.contactFormValue?.postcode as string,
    };

    if (this.claim.getData.IWCallbackRequired) {
      this._claimFacade.dispatch(
        claimActions.GetCallbackWidget({ payload: customer })
      );
      return;
    }

    this.onCompleteContactDetailsGA();
    this._claimFacade.dispatch(
      claimActions.GetServiceOptions({
        payload: customer,
      })
    );
  }

  onCompleteClaimDetails(selectedServiceOption: ServiceOption) {
    this.onCompleteServiceOptionGA(selectedServiceOption.ServiceOptionLabel);
    this._claimFacade.onCompleteClaimDetails(selectedServiceOption, this.claim);
  }

  onCompleteCallbackQA(e: { [key: string]: string }) {
    this._claimFacade.dispatch(
      claimActions.ContactDetailsCallbackClaim({ payload: e })
    );
  }

  onCompleteServiceOptionGA(label: string) {
    this.analytics.claimEventExtended({
      eventClaims: 'repair-options-selected',
      eventAction: 'RepairOptions-dgx',
      eventLabel: `${label}`, // collects the type of option selected (Drop off, Self send, Courier, Pay & Claim)
      claim: this.claim as Claim,
    });
  }

  goBack() {
    this.routing.routeBack(this.claim as Claim);
  }

  onCompleteContactDetailsGA() {
    this.analytics.claimEventExtended({
      eventClaims: 'contact-details-confirm-repair-options',
      eventAction: 'confirmPersonalDetails-dgx',
      eventLabel: 'detailsConfirm and repair option',
      claim: this.claim as Claim,
    });
  }

  getCallbackQA(claim: Claim) {
    const fields = claim.getData.IWCallbackData?.Fields;
    return fields ? this.callbackHelper.generateQuestionFormData(fields) : null;
  }

  pageViewGA() {
    if (!this.claim?.accepted) {
      this.analytics.pageViewEvent({
        eventClaims: 'what-happened',
        claim: this.claim as Claim,
      });
    }

    if (this.claim?.serviceOptions) {
      this.analytics.pageViewEvent({
        eventClaims: 'repair-options',
        pagename: 'repair-options-dgx',
        claim: this.claim as Claim,
      });
    }

    if (this.claim?.accepted && !this.claim?.serviceOptions) {
      this.analytics.pageViewEvent({
        pagename: 'confirm-details-dgx',
        eventClaims: 'contact-details',
        claim: this.claim,
      });
    }
  }
  scrollToContinue(completeScroll: ElementRef) {
    setTimeout(() => {
      // timeout for user experience
      if (completeScroll && !this.claim?.serviceOption?.ServiceOptionID) {
        completeScroll.nativeElement.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
        });
      }
    }, 300);
  }
}
