<div class="loader" [ngClass]="{ 'loader--with-text': message }">
  <div class="loader__box">
    <div class="loader__box-message" *ngIf="message">
      <p>{{ message }}</p>
    </div>
    <div class="loader__wrapper">
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle class="spinner-bg" cx="50" cy="50" r="45" />
        <circle class="spinner" cx="50" cy="50" r="45" />
      </svg>
    </div>
  </div>
</div>
