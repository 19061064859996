<div class="list__container" *ngIf="vm$ | async as vm">
  <h2 class="typog-h3">
    <img src="./assets/svg/Icon-Pointer.svg" alt="Drop off points" />Nearby
    drop-off points:
  </h2>

  <ul>
    <li
      *ngFor="let provider of vm.dropOff; index as i"
      class="list__item"
      [class.highlighted]="vm.highlighted === i"
      (click)="setHighlighted(i)"
    >
      <claims-dropoff-location
        class="list__info list"
        [provider]="provider"
        [highlighted]="vm.highlighted === i"
        [index]="i"
        [selected]="vm.selected"
      ></claims-dropoff-location>
    </li>
  </ul>
</div>
