import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';

// interface
import { isArray } from 'lodash-es';
import type {
  CalendarDay,
  CalendarSlot,
} from '../../interfaces/calendar.interface';

enum SlotType {
  ALLDAY = 'AllDay',
  SPECIFIC = 'Specific',
}

enum ServiceOptionTypes {
  Manual = 'Manual Referral',
  Home = 'Home Repair',
  Collection = 'Courier',
  SelfSend = 'Self Send',
  DropOff = 'Drop off',
  PayClaim = 'Pay and Claim',
}

@Component({
  selector: 'dg-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnChanges {
  @Input() date?: CalendarDay;
  @Input() serviceOptionType?: string;
  @Output()
  selectedDateTime: EventEmitter<CalendarSlot | null> = new EventEmitter();

  public slotsAvailable = false;
  public slots: CalendarSlot[] | null = null;
  public slotType: string | null = null;

  constructor() {}

  ngOnChanges() {
    if (this.date && this.date.slotsAvailable) {
      this.setTemplate(this.date);
    } else {
      this.slotType = null;
    }
  }

  public setTemplate(date: CalendarDay): void {
    this.slots = isArray(date?.slots)
      ? date.slots
      : [<CalendarSlot>(<unknown>date.slots)];

    this.slotType = null;
    this.slotsAvailable = this.slots?.length > 0;

    this.slotType = this.checkSlotType(this.slots);

    if (this.slotType === SlotType.ALLDAY) {
      this.setSlot(this.slots[0]);
    }

    if (this.slotType === SlotType.SPECIFIC) {
      this.setSlot(null);
    }
  }

  setSlot(slot: CalendarSlot | null): void {
    this.selectedDateTime.emit(slot);
  }

  checkSlotType(slots: CalendarSlot[]): SlotType {
    return slots.every(
      (slot: CalendarSlot) => slot.slotType === SlotType.ALLDAY
    )
      ? SlotType.ALLDAY
      : SlotType.SPECIFIC;
  }

  public onSelectTime(time: CalendarSlot | null) {
    this.selectedDateTime.emit(time);
  }
}
