import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, retry, switchMap, timer } from 'rxjs';
import { ChatService } from '../../services/chat.service';
import {
  SendLexRespondError,
  SendLexRespondLoading,
  SendLexRespondLoadingSuccess,
  StartLexSessionError,
  StartLexSessionLoading,
  StartLexSessionSuccess,
} from './lex-chat.actions';
import { Store } from '@ngrx/store';

@Injectable()
export class LexChatEffects {
  startLexSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StartLexSessionLoading),
      switchMap((action) =>
        this.chatService.startSession(action.payload).pipe(
          retry({
            count: 2,
            delay: (error) => {
              this.store.dispatch(
                StartLexSessionError({ error: error.message })
              );
              return timer(5000);
            },
            resetOnSuccess: true,
          }),
          map((chat) => StartLexSessionSuccess({ payload: chat })),
          catchError((err) => of(StartLexSessionError({ error: err.message })))
        )
      )
    )
  );

  sendLexRespond$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SendLexRespondLoading),
      switchMap((action) =>
        this.chatService
          .sendLexResponse(action.payload.text, action.payload.sessionId)
          .pipe(
            retry({
              count: 2,
              delay: (error) => {
                this.store.dispatch(
                  SendLexRespondError({ error: error.message })
                );
                return timer(5000);
              },
              resetOnSuccess: true,
            }),
            map((chat) => SendLexRespondLoadingSuccess({ payload: chat })),
            catchError((error) =>
              of(SendLexRespondError({ error: error.message }))
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private chatService: ChatService
  ) {}
}
