<div>
  <button
    class="chatbot-toggle-button"
    type="submit"
    id="smart-fix-chat-client-container"
    data-testid="toggle-button"
    (click)="toggleMinimize()"
    (keydown.enter)="toggleMinimize()"
  >
    <dgx-components-icon name="comment" size="lg" class="chatbot-icon">
    </dgx-components-icon>
    <span
      class="badge"
      *ngIf="unreadMessages > 0 && !closed"
      data-testid="unread-messages"
      >{{ unreadMessages }}</span
    >
  </button>

  <div
    class="chatbot-container"
    [ngClass]="{ minimized: minimized, closed: closed }"
    data-testid="chatbot-container"
  >
    <div class="chatbot-scroller" #scrollContainer>
      <section
        *ngIf="errorCount < 3 && errorCount !== 0 && (errorMessage$ | async)"
      >
        <smart-fix-error-dialog />
      </section>
      <smart-fix-chatbot-header
        (closeChatBot)="closeChatBot()"
        (minimizeChatBot)="toggleMinimize()"
        data-testid="chatbot-header"
      ></smart-fix-chatbot-header>
      <div *ngIf="errorCount < 3; else errorScreen">
        <section [class.exit-dialog]="isExitDialogVisible">
          <smart-fix-exit-dialog-component
            *ngIf="isExitDialogVisible"
            [message]="message"
            (cancel)="onEndChat()"
            (continue)="onContinueChat()"
            data-testid="exit-dialog"
          ></smart-fix-exit-dialog-component>
        </section>

        <div class="chatbot-content">
          <smart-fix-lex-history
            *ngIf="lexHistory$ | async"
            [lexHistory]="lexHistory$"
          />

          <div *ngIf="currentLexComponent$ | async as lexSession">
            <ng-container
              *ngIf="isLoading$ | async; else delayedLexMessagesTemp"
            >
              <div class="text--left">
                <img
                  class="loading-chat-animation"
                  src="./assets/images/loading-chat-animation.gif"
                />
              </div>
            </ng-container>

            <ng-template #delayedLexMessagesTemp>
              <div
                *ngFor="let message of delayedLexMessages"
                [@chatBotFadeInAnimation]
                data-testid="chat-component"
              >
                <ng-container [ngSwitch]="message.contentType">
                  <ng-container *ngSwitchCase="'PlainText'">
                    <smart-fix-lex-plain-text
                      [plainTextDescription]="message.content"
                    />
                  </ng-container>

                  <ng-container *ngSwitchCase="'CustomPayload'">
                    <smart-fix-lex-custom-payload [content]="message.content" />
                  </ng-container>

                  <ng-container *ngSwitchCase="'ImageResponseCard'">
                    <ng-container
                      *ngIf="
                        message.imageResponseCard?.imageUrl;
                        else plainMessage
                      "
                    >
                      <div
                        *ngIf="
                          isYoutubeUrl(message.imageResponseCard?.imageUrl);
                          else imageCard
                        "
                      >
                        <smart-fix-video-player
                          [videoUrl]="message.imageResponseCard?.imageUrl"
                          [description]="message.imageResponseCard?.title"
                          data-testid="embedded-video"
                        />
                      </div>
                      <ng-template #imageCard>
                        <div>
                          <smart-fix-lex-response-card
                            [title]="message.imageResponseCard?.title"
                            [imageUrl]="message.imageResponseCard?.imageUrl"
                          ></smart-fix-lex-response-card>
                        </div>
                      </ng-template>
                    </ng-container>
                    <ng-template #plainMessage>
                      <smart-fix-lex-plain-text
                        [plainTextDescription]="
                          message.imageResponseCard?.title
                        "
                      />
                      <smart-fix-lex-plain-text
                        [plainTextDescription]="
                          message.imageResponseCard?.subtitle
                        "
                      />
                    </ng-template>

                    <div
                      class="mt-4"
                      *ngFor="let item of message.imageResponseCard?.buttons"
                    >
                      <div class="mb-4">
                        <dgx-components-button
                          class="pre-wrap-text fix-width"
                          iconAlign="left"
                          [iconName]="getFeedbackIcon(item.text)"
                          variant="secondary"
                          [fill]="true"
                          (click)="
                            sendLexResponse(
                              item.value,
                              lexSession.sessionId,
                              message.imageResponseCard?.title
                            )
                          "
                          data-testid="chat-option-button"
                        >
                          {{ item.text }}
                        </dgx-components-button>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <div class="mb-5">
                  <smart-fix-human-readable-date
                    *ngIf="message.date"
                    [date]="message.date"
                    [alignLeft]="false"
                    data-testid="date"
                  />
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <ng-template #errorScreen>
        <smart-fix-error-fallback
          (continueToRepairBooking)="handleContinueToRepairBooking()"
        />
      </ng-template>
    </div>
  </div>
</div>
