<div
  [ngClass]="{ closed: closed }"
  class="history mb-10"
  *ngFor="let component of history | async"
  data-testid="history-item"
>
  <ng-container [ngSwitch]="component.type">
    <smart-fix-chatbot-disclaimer
      [event]="component"
      *ngSwitchCase="'disclaimer'"
      [closed]="closed"
    ></smart-fix-chatbot-disclaimer>
    <smart-fix-chatbot-welcome-message
      *ngSwitchCase="'welcome-message'"
      [closed]="closed"
      [event]="component"
      data-testid="chatbot-welcome-message"
    />
    <smart-fix-chatbot-response
      *ngSwitchCase="'chat'"
      [closed]="closed"
      [event]="component"
      data-testid="chatbot-response"
    ></smart-fix-chatbot-response>
    <smart-fix-video-player
      *ngSwitchCase="'embeddedVideo'"
      [closed]="closed"
      [videoUrl]="component.details.videoUrl"
      [description]="component.details.description"
      [offset]="component.details.videoOffset"
      data-testid="video-preview"
    />
    <smart-fix-human-readable-date
      *ngIf="component.type !== 'placeholder'"
      [date]="component.date"
      [alignLeft]="component.type === 'chat'"
      data-testid="date"
    />
  </ng-container>
</div>
