import {
  debounceTime,
  tap,
  map,
  filter,
  withLatestFrom,
  take,
  skip,
  takeUntil,
} from 'rxjs/operators';
import { ComponentStore } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { Origin } from '@domgen/dgx-components';
import type { Api } from '@domgen/dgx-components';
import { Claim, ServiceCollectionData } from '@domgen/dgx-components';
import { Observable, OperatorFunction } from 'rxjs';
import { ProductDetails } from '@domgen/dgx-components';
import { ClaimFacade, ClaimHelperService } from '@domgen/data-access-claims';
import { FormCollectionData } from '@domgen/data-access-booking';
import { ContactDetailsFormValues } from '@domgen/dgx-components';
import { BookingAnalyticsService } from '@domgen/dgx-components';

export interface LocalState {
  origin: Origin | undefined;
  pageLoading: boolean | null;
  submitLoading: boolean;
  pageLoaded: boolean | null;
  selected: number | null;
  selectionType: SelectionType | null;
  highlighted: number;
  claim: Claim | null;
  dropOff: Api.ServiceProvider[] | null;
  collectionData: FormCollectionData | null;
  googleStaticMapUrl: string | null;
}

export enum SelectionType {
  MAP = 'map',
  LIST = 'list',
}

@Injectable()
export class DropoffStateService extends ComponentStore<LocalState> {
  readonly submitLoading$ = this.select((state) => state.submitLoading);
  readonly origin$ = this.select((state) => state.origin);

  readonly selected$ = this.select((state) => state.selected);

  readonly selectionType$ = this.select((state) => state.selectionType);

  readonly highlighted$ = this.select((state) => state.highlighted);

  readonly pageLoaded$ = this.select((state) => state.pageLoaded);

  readonly activeClaim$: Observable<Claim> = this._claim.activeClaim$;

  readonly loaded$ = this._claim.claimLoaded$ as Observable<Boolean>;

  readonly getDropOffLocations$ = this._claim.dropoffLocations$ as Observable<
    Api.ServiceProvider[]
  >;

  readonly getOriginLocation$ = this._claim
    .dropOffOrigin$ as Observable<Origin>;

  readonly collectionData$ = this.select((state) => state.collectionData).pipe(
    filter((v) => v !== null)
  );

  readonly getDeliveryAddress$ = this.activeClaim$.pipe(
    take(1),
    map((claim: Claim) => {
      const formValues: ContactDetailsFormValues = {
        address: '',
        addressLine1: claim?.customer?.CustomersHouseStreetName as string,
        addressLine2: '',
        city: claim?.customer?.CustomersTownCity as string,
        county: claim?.customer?.CustomersLocalArea as string,
        postcode: claim?.customer?.CustomersPostCode as string,
      };

      this.setCollectionData({
        DELIVERY_RETURN_ADDRESS: {
          formValues: formValues,
        },
      });

      return true;
    })
  );

  readonly product$: Observable<ProductDetails> = this.activeClaim$.pipe(
    map((claim: Claim) => {
      return {
        brand: claim?.reflect?.manufacturer,
        applianceName: claim?.reflect?.productType,
        planNumber: claim?.reflect?.planNumber,
        modelNumber: this._claimHelper.getModelNumberHeading(claim),
      } as ProductDetails;
    })
  );

  readonly googleStaticMapUrl$ = this.select(
    (state) => state.googleStaticMapUrl
  );

  readonly vm$ = this.select(
    this.select((state) => state),
    this.activeClaim$,
    this.getDropOffLocations$,
    this.getOriginLocation$,
    (state, claim, dropOff, origin) => ({
      ...state,
      claim,
      dropOff,
      origin,
    })
  );

  constructor(
    private _claim: ClaimFacade,
    private _claimHelper: ClaimHelperService,
    private _bookingAnalytics: BookingAnalyticsService
  ) {
    super({
      origin: {} as Origin,
      pageLoading: false,
      submitLoading: false,
      selected: null,
      highlighted: 0,
      claim: null,
      dropOff: null,
      collectionData: null,
      googleStaticMapUrl: null,
      pageLoaded: null,
      selectionType: null,
    });
  }

  // Updaters

  readonly setOrigin = this.updater((state, origin: Origin) => ({
    ...state,
    origin,
  }));

  readonly setSelected = this.updater((state, selected: number | null) => ({
    ...state,
    selected,
  }));

  readonly setSelectedType = this.updater(
    (state, selectionType: SelectionType) => ({
      ...state,
      selectionType,
    })
  );

  readonly setStaticMapUrl = this.updater(
    (state, googleStaticMapUrl: string) => ({
      ...state,
      googleStaticMapUrl,
    })
  );

  readonly setHighlighted = this.updater((state, highlighted: number) => ({
    ...state,
    highlighted,
  }));

  readonly setSubmitLoading = this.updater((state, submitLoading: boolean) => ({
    ...state,
    submitLoading,
  }));

  readonly setPageLoaded = this.updater((state, pageLoaded: boolean) => ({
    ...state,
    pageLoaded,
  }));

  // Effects

  readonly pageLoaded = this.effect(() => {
    return this.pageLoaded$.pipe(
      withLatestFrom(this.activeClaim$),
      filter(([loaded, _]) => !loaded),
      tap(([loaded, claim]: [unknown, Claim]) => {
        this.setPageLoaded(true);
        this._bookingAnalytics.triggerPageView(claim);
      })
    );
  });

  readonly pointHighlight = this.effect(() => {
    return this.highlighted$.pipe(
      skip(1),
      withLatestFrom(this.activeClaim$, this.getDropOffLocations$),
      tap(
        ([highlighted, claim, serviceProviders]: [
          number,
          Claim,
          Api.ServiceProvider[]
        ]) => {
          this._bookingAnalytics.mapInteractionHighlight(
            claim,
            serviceProviders[highlighted]
          );
        }
      )
    );
  });

  readonly pointSelected = this.effect(() => {
    return this.selected$.pipe(
      withLatestFrom(
        this.activeClaim$,
        this.getDropOffLocations$,
        this.selectionType$
      ),
      tap(
        ([selected, claim, serviceProviders, selectionType]: [
          number | null,
          Claim | null,
          Api.ServiceProvider[] | null,
          SelectionType | null
        ]) => {
          if (!!selectionType && !!serviceProviders) {
            this._bookingAnalytics.selectedDropOffPoint(
              claim as Claim,
              serviceProviders[selected as number],
              selectionType
            );
          }
        }
      )
    );
  });

  readonly filterOrigin = this.effect(() => {
    return this.origin$.pipe(
      debounceTime(100),
      filter(
        (origin: Origin | undefined) => !!origin && !!Object.keys(origin).length
      ),
      withLatestFrom(this.activeClaim$),
      tap(([origin, claim]: [Origin | undefined, Claim]) => {
        this._claim.getDropOffLocation(claim, origin as Origin);
        this._bookingAnalytics.updatedOrigin(claim);
      })
    );
  });

  readonly triggerSubmit = this.effect(() => {
    return this.submitLoading$.pipe(
      filter(Boolean),
      withLatestFrom(
        this.activeClaim$,
        this.getDropOffLocations$,
        this.selected$,
        this.collectionData$,
        this.googleStaticMapUrl$,
        this.selectionType$
      ),
      tap(
        ([
          _,
          claim,
          serviceProviders,
          selected,
          collectionData,
          url,
          selectionType,
        ]: [
          boolean,
          Claim,
          Api.ServiceProvider[],
          number,
          FormCollectionData,
          string,
          SelectionType
        ]) => {
          this._bookingAnalytics.submitDropOffBooking(
            claim,
            serviceProviders[selected],
            selectionType
          );
          this._claim.submitDropOffBooking(
            claim,
            url,
            serviceProviders[selected],
            collectionData
          );
        }
      ) as OperatorFunction<
        unknown,
        [
          boolean,
          Claim,
          Api.ServiceProvider[],
          number,
          FormCollectionData,
          string,
          SelectionType
        ]
      >
    );
  });

  readonly setCollectionData = this.updater(
    (state, collectionData: FormCollectionData) => ({
      ...state,
      collectionData: {
        ...state.collectionData,
        ...collectionData,
      },
    })
  );

  onSetCollectionData(
    form: ContactDetailsFormValues,
    type: ServiceCollectionData
  ) {
    this.setCollectionData({
      [type]: { formValues: { ...form } },
    });
  }
}
