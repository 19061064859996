import { Injectable } from '@angular/core';
import { ContactDetails } from '@domgen/common-person-types';
import type { ErrorContent } from '@domgen/dgx-components';
import {
  Api,
  BasicErrorKeys,
  BookingReferenceOnlyType,
  Claim,
  ClaimBundleType,
  CustomerDetails,
  ErrorType,
  ModelQualification,
  ServiceCollectionData,
  ServiceOption,
  ServiceOptionTypes,
} from '@domgen/dgx-components';
import { DebugLoggerService } from '@domgen/dgx-util-general';
import { Store } from '@ngrx/store';
import { endOfWeek, isAfter, startOfWeek } from 'date-fns';
import add from 'date-fns/add';
import format from 'date-fns/format';
import { Observable, throwError } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import * as fromClaims from '../+state/';
import { ApiService } from './api.service';

export enum ProdAttrDropDownTypes {
  Manufacturers = 'Make or Brand',
  ProductTypes = 'Product Type',
}

export enum ClaimQAStateTypes {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

@Injectable({
  providedIn: 'root',
})
export class ClaimEffectHelperService {
  constructor(
    private claimService: ApiService,
    private store: Store<fromClaims.State>,
    private debug: DebugLoggerService
  ) {}

  extractDropDownValues(
    type: ProdAttrDropDownTypes,
    productAttributes: Api.GetProductAttributesResponse
  ): string[] | undefined {
    // get list if exists else undefined
    const dropDownNode = productAttributes.Response.ReqAttributes.find(
      (reqAttributes) =>
        reqAttributes.AttributeLabel === type &&
        Array.isArray(reqAttributes.AllowedValues)
    );
    return dropDownNode ? dropDownNode.AllowedValues : undefined;
  }

  isQuestionValid(question: Api.GetQuestionResponse): boolean {
    return ((question?.AnswerType as string) &&
      (question.AnswerData?.length as number) > 0) as boolean;
  }

  getServiceOptionType(
    serviceOptions: ServiceOption[],
    type: ServiceOptionTypes
  ): ServiceOption {
    return serviceOptions?.find((opt) => {
      return opt.ServiceOptionType?.includes(type as string);
    }) as ServiceOption;
  }

  isModelNumberSearch(getData: Api.GetMandatoryDataResponse) {
    return this.modelQualification(getData) === ModelQualification.Search;
  }

  isAutomaticApplianceId(getData: Api.GetMandatoryDataResponse) {
    return this.modelQualification(getData) === ModelQualification.Auto;
  }

  isFreeTextModelNumber(getData: Api.GetMandatoryDataResponse) {
    return this.modelQualification(getData) === ModelQualification.ManualEntry;
  }

  modelQualification(
    getData: Api.GetMandatoryDataResponse
  ): ModelQualification {
    // see https://domgen.atlassian.net/browse/DGX-1947 for cases

    let scenario: ModelQualification | null = null;

    if (
      // Case A Search
      getData?.OEMModelDataRequired &&
      getData?.BookingOEM !== null &&
      getData?.OEMApplianceData.find(
        (a) => a.DataType === 'ModelNumber' && a.UseGetData === true
      )
    ) {
      scenario = ModelQualification.Search;
    } else if (
      // Case B Auto
      getData?.OEMModelDataRequired &&
      getData?.BookingOEM !== null &&
      getData?.OEMApplianceData.find(
        (a) => a.DataType === 'ModelNumber' && a.UseGetData === false
      )
    ) {
      scenario = ModelQualification.Auto;
    } else if (
      // Case C ManualEntry
      getData?.OEMModelDataRequired === false ||
      (getData?.BookingOEM === null && getData?.OEMApplianceData?.length === 0)
    ) {
      scenario = ModelQualification.ManualEntry;
    }

    if (scenario) {
      this.debug.logOnce('Scenario type:', scenario);
      return scenario;
    }

    throw new Error('Model Number Qualification Case not found');
  }

  mergeRequestUpdateTransaction(
    partialRequest: Partial<Api.UpdateTransactionRequest>
  ): Observable<Api.UpdateTransactionResponse> {
    return this.store.select(fromClaims.getActiveClaim).pipe(
      first(),
      map((claim: Claim | undefined) => ({
        ...claim?.claimSelection?.request,
        ...partialRequest,
        ...this.getAutoClaimType(claim as Claim),
        ...this.getParamFallbackDefaults(
          claim?.claimSelection
            ?.request as Partial<Api.UpdateTransactionRequest>,
          claim as Claim
        ),
      })),
      switchMap((mergedRequest: Partial<Api.UpdateTransactionRequest>) =>
        // check for required parameters before calling UpdateTransaction
        mergedRequest.Manufacturer && mergedRequest.ProductType
          ? this.claimService.updateTransaction(
              mergedRequest as Api.UpdateTransactionRequest
            )
          : throwError(
              new Error('Required parameters not found for Update Transaction')
            )
      )
    );
  }

  getParamFallbackDefaults(
    req: Partial<Api.UpdateTransactionRequest>,
    claim: Claim
  ): Partial<Api.UpdateTransactionRequest> {
    return {
      ...req,
      Manufacturer: req?.Manufacturer
        ? req?.Manufacturer
        : claim?.reflect?.manufacturer,
      ProductType: req?.ProductType
        ? req?.ProductType
        : claim?.reflect?.productType,
    };
  }

  getAutoClaimType(claim: Claim): Partial<Api.UpdateTransactionRequest> {
    let partialRequest: Partial<Api.UpdateTransactionRequest> = {};

    if (
      claim?.reflect?.asvOffered === 'true' &&
      claim?.reflect?.claimType === ClaimBundleType.REPAIR
    ) {
      const cType = claim.getData?.ClaimTypes.find(
        (claimType) => !['ASV'].includes(claimType.ClaimTypeCode)
      );
      if (cType) {
        partialRequest = {
          ClaimTypeID: cType.ClaimTypeID,
        };
      }
    }

    if (
      claim?.reflect?.asvOffered === 'true' &&
      claim?.reflect?.claimType === ClaimBundleType.ANNUAL_SERVICE
    ) {
      const cType = claim.getData?.ClaimTypes.find((claimType) =>
        ['ASV'].includes(claimType.ClaimTypeCode)
      );

      if (cType) {
        partialRequest = {
          ClaimTypeID: cType.ClaimTypeID,
        };
      }
    }

    return partialRequest;
  }

  getProductType(claim: Claim): string {
    return (
      claim?.claimSelection?.request?.ProductType ||
      (claim.reflect?.productType as string)
    );
  }

  getPutServiceOptionPayload(claim: Claim): Api.PutServiceOptionRequest {
    const isCollection =
      claim.bookedServiceOption === ServiceOptionTypes.Collection;
    const isSelfSend =
      claim.bookedServiceOption === ServiceOptionTypes.SelfSend;
    const isDropOff = claim.bookedServiceOption === ServiceOptionTypes.DropOff;

    if (isSelfSend) {
      const { collection_address, delivery_address } =
        this.getServiceOptionRequiredFields(
          claim,
          claim.serviceOption as ServiceOption
        );

      return {
        ClaimID: claim.claimID as string,
        ServiceOptionID: claim?.serviceOption?.ServiceOptionID as string,
        ServiceOptionRequiredFields: {
          ...collection_address,
          ...delivery_address,
        },
      };
    }

    if (isDropOff) {
      return {
        ClaimID: claim.claimID as string,
        ServiceOptionID: claim?.serviceOption?.ServiceOptionID as string,
        ServiceOptionRequiredFields: [
          {
            DELIVERY_RETURN_ADDRESS: claim.dropOffOrigin,
          },
        ],
      };
    }

    if (isCollection) {
      const { collection_address, delivery_address } =
        this.getServiceOptionRequiredFields(
          claim,
          claim.serviceOption as ServiceOption
        );

      let collectionStartDate = null;

      if (this.hasAvailabilityData(claim)) {
        collectionStartDate = this.getAvailabilityStartAndEndDate(claim);
      }

      return {
        ClaimID: claim.claimID as string,
        ServiceOptionID: claim.serviceOption?.ServiceOptionID as string,
        AvailabilityStartDate: collectionStartDate
          ? collectionStartDate.availabilityStartDate
          : '',
        AvailabilityEndDate: collectionStartDate
          ? collectionStartDate.availabilityEndDate
          : '',
        ServiceOptionRequiredFields: {
          ...collection_address,
          ...delivery_address,
        },
      };
    }

    let engineerStartDate = null;

    if (this.hasAvailabilityData(claim)) {
      engineerStartDate = this.getAvailabilityStartAndEndDate(claim);
    }

    return {
      ClaimID: claim.claimID as string,
      ServiceOptionID: claim.serviceOption?.ServiceOptionID as string,
      AvailabilityStartDate: engineerStartDate
        ? engineerStartDate.availabilityStartDate
        : format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
      AvailabilityEndDate: engineerStartDate
        ? engineerStartDate.availabilityEndDate
        : format(endOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
      ServiceOptionRequiredFields: [],
    };
  }

  hasAvailabilityData(claim: Claim): boolean {
    return !!(
      claim.bookingAvailable &&
      claim.bookingOption?.AvailabilityEndDate &&
      claim.bookingOption?.AvailabilityRequestDays
    );
  }

  getServiceOptionRequiredFields(
    claim: Claim,
    serviceOption: ServiceOption
  ): {
    collection_address: Api.CollectionData;
    delivery_address: Api.CollectionData;
  } {
    const COLLECTION_ADDRESS =
      serviceOption?.ServiceOptionRequiredFields?.includes(
        ServiceCollectionData.COLLECTION_ADDRESS
      );
    const DELIVERY_RETURN_ADDRESS =
      serviceOption?.ServiceOptionRequiredFields?.includes(
        ServiceCollectionData.DELIVERY_RETURN_ADDRESS
      );

    const defaults = {
      BuildingName: '',
      HouseStreetName: claim.customer?.CustomersHouseStreetName,
      LocalArea: claim.customer?.CustomersLocalArea,
      TownCity: claim.customer?.CustomersTownCity,
      PostCode: claim.customer?.CustomersPostCode,
    };

    const collection_address = COLLECTION_ADDRESS
      ? {
          COLLECTION_ADDRESS: defaults,
        }
      : {};

    const delivery_address = DELIVERY_RETURN_ADDRESS
      ? {
          DELIVERY_RETURN_ADDRESS: defaults,
        }
      : {};

    return { collection_address, delivery_address };
  }

  getAvailabilityStartAndEndDate(claim: Claim): {
    availabilityStartDate: string;
    availabilityEndDate: string;
  } {
    const availabilityStartDate = add(
      new Date(claim.bookingOption?.AvailabilityEndDate as string),
      {
        days: 1,
      }
    );

    let availabilityEndDate = add(new Date(availabilityStartDate), {
      days: 6,
    });

    const lastAvailabilityEndDate = add(new Date(), {
      days: claim.bookingOption?.AvailabilityMaximumDays,
    });

    if (isAfter(availabilityEndDate, lastAvailabilityEndDate)) {
      availabilityEndDate = lastAvailabilityEndDate;
    }

    return {
      availabilityStartDate: format(availabilityStartDate, 'yyyy-MM-dd'),
      availabilityEndDate: format(availabilityEndDate, 'yyyy-MM-dd'),
    };
  }

  selfSendBooking(claim: Claim) {
    return claim.bookedServiceOption === ServiceOptionTypes.SelfSend;
  }

  dropOffBooking(claim: Claim): boolean {
    return claim.bookedServiceOption === ServiceOptionTypes.DropOff;
  }

  engineerBooking(claim: Claim): boolean {
    return claim.bookedServiceOption === ServiceOptionTypes.Home;
  }

  repairerContact(claim: Claim): boolean {
    return (
      claim.bookedServiceOption === ServiceOptionTypes.Home &&
      this.isBookingReferenceOnly(claim)
    );
  }

  isBookingReferenceOnly(claim: Claim): boolean {
    return (
      claim.serviceOption?.ServiceOptionLabel ===
      BookingReferenceOnlyType.BookingReferenceOnly
    );
  }

  hasSlots(
    claim: Claim,
    response: Api.PutServiceOptionResponse | null = null
  ): boolean {
    return (
      response?.ExtraAvailability === true ||
      (response?.AvailabilityData?.length as number) > 0 ||
      (claim?.bookingOption?.AvailabilityData?.length as number) > 0
    );
  }

  uppercasedKey = (text: string): string =>
    `${text.charAt(0).toUpperCase()}${text.substring(1, text.length)}`;

  upperCaseResponse = <T>(data: any): T => {
    if (data === null || data === undefined) return data;

    const reformattedData: Record<string, unknown> = {};
    Object.keys(data).forEach((key: string) => {
      if (Array.isArray(data[key])) {
        data[key] = data[key].map(this.upperCaseResponse);
      } else if (typeof data[key] === 'object') {
        data[key] = this.upperCaseResponse(data[key]);
      }

      reformattedData[this.uppercasedKey(key)] = data[key];
    });

    return reformattedData as T;
  };

  bookingErrorContent(
    err: ErrorType,
    count: number,
    claim: Claim
  ): ErrorContent {
    const hasSlots = this.hasSlots(claim);

    if (err?.slotUnavailable) {
      return {
        heading: 'Slot unavailable',
        body: 'We’re sorry, someone just took this slot. Please check the updated calendar.',
        cta: 'Look again',
      };
    }

    if (count === 0) {
      return {
        heading: 'Somethings gone wrong',
        body: 'Your request could not be processed.',
        cta: 'Please try again',
      };
    }

    // has a date
    if (count === 1 && hasSlots) {
      return {
        heading: 'Somethings gone wrong',
        body: 'We’re unable to retrieve any more dates, so we’ll pick the first available date for you.',
        cta: 'Show first available date',
      };
    }

    // has no date || user has decided to click again for more.
    if (count === 2 || (count === 1 && !hasSlots)) {
      return {
        heading: 'Somethings gone wrong',
        body: 'We’re unable to retrieve any dates at the moment. Please contact the service provider directly.',
        cta: 'See service provider details',
      };
    }

    return {
      heading: 'Somethings gone wrong',
      body: 'Your request could not be processed.',
      cta: 'Please try again',
    };
  }

  canRetryRequest(attemptCount: number, statusCode: string): boolean {
    const maxAttempts = 2;
    const errorTypes = ['PS006', 'PS007'];
    return attemptCount < maxAttempts && errorTypes.includes(statusCode);
  }

  isWeekEmpty(response: Api.PutServiceOptionResponse): boolean {
    const errorTypes = ['PS001', 'PS002'];
    return (
      errorTypes.includes(response?.StatusCode) &&
      response?.ExtraAvailability === true
    );
  }

  getManualReferralFallback(
    serviceOptions: ServiceOption[]
  ): ServiceOption | undefined {
    return serviceOptions?.find(
      (s: ServiceOption) => s.ServiceOptionType === 'Manual Referral'
    );
  }

  manualReferralAllowed(response: Api.PutServiceOptionResponse): boolean {
    return (
      (response?.ManualReferralAllowed as boolean) &&
      !!response?.ManualReferralServiceOptionID
    );
  }

  getManualReferral(response: Api.PutServiceOptionResponse) {
    return this.manualReferralAllowed(response)
      ? {
          ManualReferralAllowed: response.ManualReferralAllowed,
          ManualReferralServiceOptionID:
            response?.ManualReferralServiceOptionID,
        }
      : {};
  }

  manualReferralAllowedNoSlots(
    claim: Claim,
    response: Api.PutServiceOptionResponse
  ): boolean {
    return (
      this.manualReferralAllowed(response) && !this.hasSlots(claim, response)
    );
  }

  isAnnualServiceRequest(
    claimTypeID: string | undefined,
    claimTypes: Api.ClaimType[],
    claimType: ClaimBundleType
  ): boolean {
    return (
      claimTypes.some(
        (c: Api.ClaimType) =>
          c.ClaimTypeID === claimTypeID && c.ClaimTypeCode === 'ASV'
      ) || claimType === ClaimBundleType.ANNUAL_SERVICE
    );
  }

  getAnnualServicePayload(
    request: Partial<Api.UpdateTransactionRequest>,
    claimTypes: Api.ClaimType[],
    faultData: Api.FaultData | Api.FaultData[]
  ): Partial<Api.UpdateTransactionRequest> {
    const annualClaim = claimTypes.find(
      (c: Api.ClaimType) => c.ClaimTypeCode === 'ASV'
    );

    let r: Partial<Api.UpdateTransactionRequest> = {
      ...request,
      ClaimTypeID: request?.ClaimTypeID
        ? request.ClaimTypeID
        : annualClaim?.ClaimTypeID,
    };

    if (!Array.isArray(faultData)) {
      const annualCodeAnswer = faultData.PossibleAnswers.find(
        (t) => t.FaultLabel.toLowerCase().indexOf('annual') > -1
      );

      if (annualCodeAnswer) {
        r = {
          ...r,
          FaultCategoryID: faultData.FaultCategoryID,
          FaultID: annualCodeAnswer.FaultID,
        };
      }
    }

    return r;
  }

  isRepairWithNoFaultData(faultData: Api.FaultData | Api.FaultData[]): boolean {
    return (<Api.FaultData[]>faultData).length === 0;
  }

  isRepairWithSingleAnswerFaultData(
    faultData: Api.FaultData | Api.FaultData[]
  ): boolean {
    return !Array.isArray(faultData) && faultData.PossibleAnswers.length === 1;
  }

  isSingleClaim(claimTypes: Api.ClaimType[], reflect: Api.Reflect): boolean {
    if (
      reflect.asvOffered === 'true' &&
      reflect?.claimType === ClaimBundleType.REPAIR
    ) {
      return (
        claimTypes.filter((c: Api.ClaimType) => c.ClaimTypeCode !== 'ASV')
          .length === 1
      );
    }

    return claimTypes.length === 1;
  }

  getAutoCreateClaimPayload(
    request: Partial<Api.UpdateTransactionRequest>,
    claimTypes: Api.ClaimType[],
    faultData: Api.FaultData | Api.FaultData[]
  ): Partial<Api.UpdateTransactionRequest> {
    let r: Partial<Api.UpdateTransactionRequest> = request;

    const claimType = claimTypes.filter(
      (c: Api.ClaimType) => c.ClaimTypeCode !== 'ASV'
    )[0];

    // apply claimType
    r = {
      ...r,
      ClaimTypeID: claimType.ClaimTypeID,
    };

    // if we have one faultData Object and only one possible answer
    if (!Array.isArray(faultData) && faultData.PossibleAnswers.length === 1) {
      const faultID = faultData.PossibleAnswers[0].FaultID;

      if (faultID) {
        r = {
          ...r,
          ClaimTypeID: claimType.ClaimTypeID,
          FaultCategoryID: faultData.FaultCategoryID,
          FaultID: faultID,
        };
      }
    }

    return r;
  }

  pncRequired(data: Api.GetMandatoryDataResponse): boolean {
    return (
      data?.OEMApplianceData &&
      data?.OEMApplianceData.some(
        (a) => a.DataType === 'PNCNumber' && a.UseGetData === true
      )
    );
  }

  pncNotEntered(claim: Claim): boolean {
    return !claim?.claimSelection?.selectionState?.PNCNumber;
  }

  isInitialModelNumberValid(data: Api.GetMandatoryDataResponse): boolean {
    if (this.isFreeTextModelNumber(data)) {
      return !!data?.ModelNumber.length;
    }

    return data.UniqueApplianceID !== null ? true : false;
  }

  bookingSlotUnavailable(err: BasicErrorKeys, claim: Claim): boolean {
    const errorTypes = ['RD001'];

    const isAllDayBooking = claim.bookingSelection?.slot === 'All day';

    const extraSlots = (claim.bookingOption?.SlotsByWeek || []).some((week) => {
      if (isAllDayBooking) {
        return week.AvailabilityData.length > 1;
      }

      return week.AvailabilityData.find(
        (data) => (data.Slots as Api.BookingDateSlot[]).length > 1
      );
    });

    const extraSlotsAvailable =
      extraSlots || claim.bookingOption?.ExtraAvailability || false;

    return errorTypes.includes(err?.errorCode as string) && extraSlotsAvailable;
  }

  bookingCompletedError(err: BasicErrorKeys): boolean {
    const errorTypes = ['RD004'];
    return errorTypes.includes(err?.errorCode as string);
  }

  manualBookingErrorFallback(err: BasicErrorKeys): boolean {
    const errorTypes = ['PS007', 'PS008', 'PS011', 'RD013'];
    return !!(
      errorTypes.includes(err.errorCode as string) &&
      err?.ManualReferralAllowed &&
      err?.ManualReferralServiceOptionID
    );
  }

  canUpdateUserDetails(claim: Claim): boolean {
    return claim.reflect?.isRiskOwner === 'false';
  }

  personalDetailsChanged(
    customer: CustomerDetails,
    reflect: Api.Reflect
  ): boolean {
    const homeTelephone = reflect?.homeTelephone?.replace('+44', '0') as string;
    const mobilePhone = reflect?.mobilePhone?.replace('+44', '0') as string;

    const landlineIsMobileNumber =
      /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/.test(homeTelephone);

    const mobileNumberChanged = customer?.CustomersMobile !== mobilePhone;
    const landlineNumberChanged = customer?.CustomersLandLine !== homeTelephone;

    if (landlineIsMobileNumber) {
      return (
        (!!customer?.CustomersLandLine.length && landlineNumberChanged) ||
        mobileNumberChanged
      );
    }

    return mobileNumberChanged || landlineNumberChanged;
  }

  addressDetailsChanged(
    customer: CustomerDetails,
    reflect: Api.Reflect
  ): boolean {
    return (
      customer?.CustomersHouseStreetName !== reflect?.addressLine1 ||
      customer?.CustomersTownCity !== reflect?.addressLine2 ||
      customer?.CustomersLocalArea !== reflect?.addressLine4 ||
      customer?.CustomersPostCode !== reflect?.postCode
    );
  }

  removeEmptyStringKeyValues(obj: ContactDetails): ContactDetails {
    const newObj: ContactDetails = {};
    Object.keys(obj).forEach((prop) => {
      if ((obj as never)[prop] !== '') {
        (newObj as never)[prop] = (obj as never)[prop];
      }
    });
    return newObj;
  }

  isCallbackBooking(
    claim: Claim,
    response: Api.PutServiceOptionResponse
  ): boolean {
    return (
      !this.hasSlots(claim, response) &&
      !this.isReferenceOnlyBooking(claim) &&
      this.engineerBooking(claim) &&
      this.callbackBrandsAllowed(claim)
    );
  }

  isReferenceOnlyBooking(claim: Claim): boolean {
    return this.repairerContact(claim);
  }

  callbackBrandsAllowed(claim: Claim): boolean {
    const allowedCallbackBrands = [
      'VOKERA',
      'HEATING SERVICES',
      'PROCOMBI',
      'SABRE',
    ];
    return allowedCallbackBrands.includes(
      claim?.reflect?.manufacturer?.toUpperCase() as string
    );
  }

  updateCollectionData(req: Api.PutRepairDataRequest): boolean {
    return !!req?.CollectionData
      ? Object.keys(req?.CollectionData).length > 0
      : false;
  }

  getPutRepairDataFallback(claim: Claim): Api.PutRepairDataResponse {
    return {
      Status: 'OK',
      StatusCode: '',
      StatusDebugging: [],
      ClaimID: '',
      ProductGroup: '',
      ProductType: '',
      ClaimReference: '',
      PlanNumber: claim?.reflect?.planNumber as string,
      ManufacturerName: claim?.reflect?.manufacturer as string,
      ProductDescription: claim?.reflect?.productType,
      ModelNumber: claim?.getData?.ModelNumber as string,
      SerialNumber: null,
      FaultCategorySelected: claim?.claimSelection?.selectionState
        ?.faultTypeAnswer?.description as string,
      FaultSelected: claim?.claimSelection?.selectionState?.faultType
        ?.description as string,
      ClaimQuestionsArray: claim?.questions
        ? claim?.questions?.map((q) => {
            return {
              QuestionID: q.question.QuestionID,
              QuestionLabel: q.question.QuestionLabel,
              AnswerID: q.answer?.AnswerID as string,
              AnswerLabel: '',
              AnswerType: q.question.AnswerType,
              AnswerHelp: '',
              AnswerValue: q.answer?.AnswerValue as string,
            };
          })
        : [],
      ServiceOption: claim.bookedServiceOption as string,
      AppointmentDate: claim.bookingSelection?.date as string,
      AppointmentSlot: claim.bookingSelection?.slot as string,
      ExcessAmount: 0,
      AuthorityLimit: '',
      ServiceProviderID: '',
      ServiceProviderCompanyName: '',
      ServiceProviderBuildingName: '',
      ServiceProviderHouseStreetName: '',
      ServiceProviderLocalArea: '',
      ServiceProviderTownCity: '',
      ServiceProviderPostCode: '',
      ServiceProviderTelephone: '',
      ServiceProviderEmail: '',
      ServiceProviderWebsite: '',
      ServiceProviderHours: {},
      ContactName: '',
      ContactAddressType: '',
      ContactBuilding: '',
      ContactHouseStreetName: '',
      ContactLocalArea: '',
      ContactTownCity: '',
      ContactPostCode: '',
      ContactMobile: '',
      ContactEmail: '',
      PaymentData: [
        {
          Type: claim?.excess?.type as string,
          Amount: claim?.excess?.cost as string,
        },
      ],
    };
  }
}
