export interface KeyUpOutput {
  q: string;
  category: string;
  type: string;
}

export interface SelectOutput {
  q: string;
  api: string;
}
