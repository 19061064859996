import { default as localforage } from 'localforage';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  /**
   *
   * @param key
   * @param value
   * @returns {any}
   */
  public setItem<T>(key: string, value: T) {
    return from(localforage.setItem(key, value));
  }

  /**
   *
   * @param key
   * @returns {any}
   */
  public getItem<T>(key: string): Observable<any> {
    return from(localforage.getItem(key));
  }

  /**
   *
   * @param key
   * @returns {any}
   */
  public removeItem(key: string) {
    return from(localforage.removeItem(key));
  }

  /**
   *
   * @param key
   * @returns {any}
   */
  public clear() {
    return from(localforage.clear());
  }
}
