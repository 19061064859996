import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'dg-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
})
export class PrintComponent implements OnInit, OnDestroy {
  state = 'hide';
  isAlive = true;
  isTimerStarted = false;
  isUnoCalled = false;
  delay = 3000;
  timerStarted$ = new BehaviorSubject(false);
  unoCalled$ = new BehaviorSubject(false);
  timer?: ReturnType<typeof setTimeout> | undefined;

  constructor(public el: ElementRef, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.timerStarted$
      .pipe(
        takeWhile(() => this.isAlive), // auto-unsubscribe
        tap((next) => {
          this.isTimerStarted = next;
          if (this.isTimerStarted) {
            if (this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(() => {
              if (this.state !== 'hide') {
                this.state = 'minimised';
              }

              this.cdr.detectChanges();
              this.timerStarted$.next(false);
            }, this.delay);
          }
        })
      )
      .subscribe();

    this.unoCalled$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((unoCalled) => (this.isUnoCalled = unoCalled));
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const componentPosition = this.el.nativeElement.offsetTop;
    const scrollPosition = window.pageYOffset;

    if (scrollPosition >= componentPosition) {
      if (this.state !== 'show') {
        this.runDelay();
      }
      this.state = 'show';
    } else {
      this.state = 'hide';
    }
  }

  runDelay() {
    this.timerStarted$.next(true);
  }

  ngOnDestroy() {
    // auto-unsubscribe
    this.isAlive = false;
  }

  print(event: Event) {
    this.state = 'show';
    event.preventDefault();
    window.focus();
    window.print();
  }
}
