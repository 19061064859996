import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { ChatContainerComponent } from '../../chat-container/chat-container.component';
import {
  AppConfig,
  Claim,
  HistoryMessage,
  LexSession,
} from '@domgen/dgx-components';
import {
  ChatFacadeService,
  LexChatFacadeService,
} from '@domgen/smart-fix/data-access-smart-fix';
import { SmartFixAnalyticsService } from '../../../services/smart-fix-analytics/smart-fix-analytics.service';
import {
  concatMap,
  delay,
  from,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { chatBotFadeInAnimation } from '../../../animations/chatAnimations';
import { CONFIG } from '@domgen/dgx-fe-config';
import { LexChatHelperService } from '../../../services/lex-chat-helper/lex-chat-helper.service';

type LexMessages = LexSession['messages'];

type preFlowQuestionsType =
  | "Please follow the guidance provided by us and refer to the 'warnings' section of your appliance manual for further safety information."
  | 'Please book a repair with a Gas Safe registered engineer.';

@Component({
  selector: 'smart-fix-boiler-chat-container',
  templateUrl: './boiler-chat-container.component.html',
  styleUrls: [
    './boiler-chat-container.component.scss',
    './../../chat-container/chat-container.component.scss',
  ],
  animations: [chatBotFadeInAnimation],
})
export class BoilerChatContainerComponent
  extends ChatContainerComponent
  implements OnDestroy, OnInit, OnDestroy
{
  @Input() claim!: Claim;
  @Output() closedChatNotification = new EventEmitter<'open' | 'closed'>();
  currentLexComponent$!: Observable<LexSession | null | undefined>;
  currentMessages$!: Observable<LexSession['messages'] | null | undefined>;
  delayedLexMessages: LexSession['messages'] = [];
  lexHistory$!: Observable<HistoryMessage[]>;
  isLoading$!: Observable<boolean>;
  errorMessage$!: Observable<string | null>;
  errorCount$: Observable<number>;
  errorCount: number = 0;
  preFlowQuestion!: preFlowQuestionsType;

  private readonly returnToMyAccountMessage = 'End, return to My Account';
  private readonly bookEngineerMessage = 'Book engineer visit';
  private readonly whatTheProblemIs =
    "Let's start. Can you tell us what the problem is?";
  private readonly allFlowQuestions = [
    "Please follow the guidance provided by us and refer to the 'warnings' section of your appliance manual for further safety information.",
    'Please book a repair with a Gas Safe registered engineer.',
  ];
  gAEventCategory!: string;

  private destroy$ = new Subject<void>();
  protected startComponent$!: Observable<LexSession>;
  constructor(
    chatFacade: ChatFacadeService,
    smartFixAnalyticsService: SmartFixAnalyticsService,
    renderer: Renderer2,
    private lexChatFacade: LexChatFacadeService,
    private lexChatHelperService: LexChatHelperService,
    @Inject(CONFIG) protected readonly config: AppConfig
  ) {
    super(chatFacade, smartFixAnalyticsService, config, renderer);
    this.addGlobalClass();
    this.lexHistory$ = this.lexChatFacade.history$;
    this.currentLexComponent$ = this.lexChatFacade.currentLexChatSession$;
    this.currentMessages$ = this.lexChatFacade.currentLexChatMessages$;
    this.isLoading$ = this.lexChatFacade.isLoading$;
    this.errorMessage$ = this.lexChatFacade.errorMessage$;
    this.errorCount$ = this.lexChatFacade.errorCount$;
  }

  async ngOnInit(): Promise<void> {
    this.startLexSession();

    let firstMessage = true;

    this.currentMessages$
      .pipe(
        switchMap((messages: LexMessages | null | undefined) => {
          if (messages != null) {
            return from(messages).pipe(
              concatMap((message) => {
                if (firstMessage) {
                  firstMessage = false;
                  return of({ ...message, date: new Date() });
                } else {
                  return of({ ...message, date: new Date() }).pipe(delay(1000));
                }
              })
            );
          } else {
            return of();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((message) => {
        this.delayedLexMessages = [...this.delayedLexMessages, message];
        if (this.minimized) {
          this.unreadMessages++;
        }
      });

    this.errorCount$
      .pipe(startWith(0))
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.errorCount = val;
      });
  }

  ngOnDestroy(): void {
    this.removeGlobalClass();
    this.destroy$.next();
    this.destroy$.complete();
  }

  matchPreFlowCriteria(question: string, text: string): string[] {
    if (this.allFlowQuestions.includes(question) && !this.gAEventCategory) {
      return [text, 'pre-flow questions', 'Smart-Fix Chatbot - ALL flows'];
    }

    return [text, question, this.gAEventCategory];
  }

  startLexSession() {
    this.delayedLexMessages = [];
    this.lexChatFacade.startLexSession(
      'Lets start with your boiler possible issue....'
    );
  }

  handleContinueToRepairBooking() {
    this.closeChat();
  }

  private setEventCategory(question: string, answer: string) {
    if (question === this.whatTheProblemIs) {
      this.gAEventCategory = 'Smart-Fix Chatbot - ' + answer;
    }
  }

  sendLexResponse(
    text: string | undefined,
    sessionId: string,
    question: string
  ) {
    this.delayedLexMessages = [];
    if (text == null) return;
    this.setEventCategory(question, text);
    this.lexChatFacade.sendLexRespond({ text, sessionId });
    const [eventLabel, eventAction, eventCategory] = this.matchPreFlowCriteria(
      question,
      text
    );
    this.trackFeedbackChoice(eventLabel, eventAction, eventCategory);
    if (text === this.returnToMyAccountMessage) {
      this.closeChat();
      window.location.href = this.config.myAccountUrl;
    }
    if (text === this.bookEngineerMessage) {
      this.closeChat();
    }
  }

  isYoutubeUrl(url: string | undefined): boolean {
    return this.lexChatHelperService.isYoutubeUrl(url);
  }

  getFeedbackIcon(text: string) {
    return this.lexChatHelperService.getFeedbackIcon(text);
  }
}
