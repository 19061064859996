import {
  trigger,
  animate,
  transition,
  style,
  state,
  group,
} from '@angular/animations';

export const fadeInAnimation = trigger('fadeInAnimation', [
  // route 'enter' transition
  transition(':enter', [
    // styles at start of transition
    style({ opacity: 0 }),

    // animation and styles at end of transition
    animate('0.3s', style({ opacity: 1 })),
  ]),
]);

export const showHide = trigger('showHide', [
  state(
    'hidden',
    style({
      height: '0',
    })
  ),
  state(
    'visible',
    style({
      height: '*',
    })
  ),
  transition('visible <=> hidden', animate('{{transitionParams}}')),
]);

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('0ms 200ms', style({ opacity: 1 })),
  ]),
  transition(':leave', [animate('0ms 200ms', style({ opacity: 0 }))]),
]);

export const slideInOut = trigger('slideInOut', [
  state('in', style({ height: '*', opacity: 0 })),
  transition(':leave', [
    style({ height: '*', opacity: 1 }),

    group([
      animate(200, style({ height: 0 })),
      animate('200ms ease-in-out', style({ opacity: '0' })),
    ]),
  ]),
  transition(':enter', [
    style({ height: '0', opacity: 0 }),

    group([
      animate(200, style({ height: '*' })),
      animate('200ms ease-in-out', style({ opacity: '1' })),
    ]),
  ]),
]);
