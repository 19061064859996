import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
} from '@angular/platform-browser';

@Component({
  selector: 'smart-fix-video-preview',
  templateUrl: './video-preview.component.html',
  styleUrls: ['./video-preview.component.scss'],
})
export class VideoPreviewComponent implements OnInit {
  @Input() messageText!: string | undefined;

  ngOnInit() {
    if (this.messageText) {
      const matches = this.messageText.match(
        // eslint-disable-next-line no-useless-escape
        /((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(?:-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)/g
      );
      if (matches?.length) {
        this.videoLinks.push(...matches);
      }
    }
  }

  videoLinks: string[] = [];
}
