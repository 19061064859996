import { Injectable } from '@angular/core';
import {
  UntypedFormControl,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import {
  FormControlsDefinition,
  FormValues,
  FormControlsDefinitionChildKey,
  RadiosDefinition,
} from '../interfaces/form-controls-definition.interface';
import { ValidationError } from '../interfaces/validation-error.interface';
import { ValidationService } from './validation.service';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  FormControls: { [key: string]: AbstractControl } = {};

  constructor(private validationService: ValidationService) {}

  defineformControls(
    questions: FormControlsDefinition[],
    answers: FormValues
  ): { [key: string]: AbstractControl } {
    this.FormControls = {};
    this.create(questions, answers);
    return this.FormControls;
  }

  private create(questions: FormControlsDefinition[], answers: FormValues) {
    questions.forEach((controlsDef) => {
      for (const key in controlsDef) {
        const definition: FormControlsDefinitionChildKey = (
          controlsDef as never
        )[key as string];
        const value =
          answers[
            (definition as FormControlsDefinitionChildKey)?.control as string
          ];

        if (key === 'radios') {
          this.createRadioGroupControl(
            <RadiosDefinition['radios']>definition,
            value as string
          );
        } else {
          this.createFormControl(
            value || value === '0' ? value : null,
            (definition as FormControlsDefinitionChildKey)?.control as string,
            definition['validators'] as ValidationError[]
          );
        }
      }
    });
  }

  private createRadioGroupControl(
    definition: RadiosDefinition['radios'],
    value: string
  ) {
    const selected = definition?.radioGroup.find(
      (elem) => elem.value === value
    );
    this.createFormControl(
      selected ? value : null,
      definition?.control as string,
      definition?.validators as ValidationError[]
    );
  }

  private createFormControl(
    initialValue: any,
    controlName: string,
    validators: ValidationError[]
  ) {
    if (validators) {
      this.FormControls[controlName] = new UntypedFormControl(
        initialValue,
        this.validationService.addControlValidationFn(
          validators
        ) as ValidatorFn[]
      );
      return;
    }
    this.FormControls[controlName] = new UntypedFormControl(initialValue);
  }
}
