import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: string): any {
    const [hour, minutes] = value.split(':');

    let formattedHours = parseInt(hour);
    const formattedMinutes = parseInt(minutes) === 0 ? '' : `:${minutes}`;

    const suffix = formattedHours >= 12 ? 'pm' : 'am';

    if (formattedHours > 12) {
      formattedHours = formattedHours - 12;
    }

    return `${formattedHours}${formattedMinutes}${suffix}`;
  }
}
