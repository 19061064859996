<div class="alert-dialog">
  <button (click)="onClick()">
    <dgx-components-icon name="close"> </dgx-components-icon>
  </button>

  <div class="alert-dialog__content flex">
    <div class="alert-dialog__main">
      <div class="alert-dialog__main-body">
        <div class="heading">
          <h4 class="typog-h4">
            <dgx-components-icon name="circle-exclamation">
            </dgx-components-icon>
            <h4>Something went wrong</h4>
          </h4>
        </div>

        <p class="typog-body timer">
          Please try again now or we’ll retry in <br />
          <span class="typog-body-bold">{{ countdown$ | async }}.</span>
        </p>
        <p class="typog-caption">
          If it’s still not working, please come back later.
        </p>
      </div>
    </div>
    <div class="alert-dialog__action mt-6">
      <dgx-components-button
        type="submit"
        variant="primary"
        (click)="onClick()"
        data-automation="'ConfirmAD'"
      >
        Try again now
      </dgx-components-button>
    </div>
  </div>
</div>
