<div class="instruction-icon-component">
  <dgx-components-icon
    *ngIf="icon"
    [icon]="icon"
    [name]="icon"
    size="md"
    class="mr-1"
  ></dgx-components-icon>
  <p
    class="description"
    [ngClass]="icon ? 'with-icon-description' : 'no-icon-description'"
  >
    {{ description }}
  </p>
</div>
