import { Component, OnInit, Input } from '@angular/core';
import { CalendarSlot } from '../../../interfaces/calendar.interface';

enum ServiceOptionTypes {
  Manual = 'Manual Referral',
  Home = 'Home Repair',
  Collection = 'Courier',
  SelfSend = 'Self Send',
  DropOff = 'Drop off',
  PayClaim = 'Pay and Claim',
}

@Component({
  selector: 'dg-all-day-slot',
  templateUrl: './all-day-slot.component.html',
  styleUrls: ['./all-day-slot.component.scss'],
})
export class AllDaySlotComponent implements OnInit {
  @Input() slots?: CalendarSlot[];
  @Input() serviceOptionType?: string;

  ServiceOptionTypes = ServiceOptionTypes;
  slotType = '';

  ngOnInit() {
    if (this.slots) {
      this.slotType = this.slots[0].slotType.replace('lD', 'l d');
    }
  }
}
