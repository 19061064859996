<dg-booking-current-appointment
  *ngIf="currentAppointment"
  [currentAppointment]="currentAppointment"
>
</dg-booking-current-appointment>
<div class="booking-calendar">
  <dg-booking-calendar-header
    *ngIf="firstDate"
    [firstDate]="firstDate"
    [slotType]="slotType"
    [isRebook]="isRebook"
    (selectFirstDate)="onSelectFirstDate()"
  ></dg-booking-calendar-header>
  <dg-booking-calendar-navigation
    [currentPage]="currentPage"
    [availability]="availability"
    (navigate)="navigate($event)"
    (getMoreDates)="getMoreDates.emit($event)"
  ></dg-booking-calendar-navigation>

  <section
    *ngIf="availability?.bookingOption?.SlotsByWeek?.[currentPage]?.Loading; else ShowValues"
  >
    <dg-loader></dg-loader>
  </section>

  <ng-template #ShowValues>
    <section
      *ngIf="
        currentWeek.length === 0 && (isRebook || (!isRebook && !loading));
        else WeekHasDates
      "
      class="no-availability"
    >
      <p class="typog-body">
        <dgx-components-icon name="info-circle" class="mr-1">
        </dgx-components-icon>
        We're sorry, this week is fully booked. Please choose another.
      </p>
    </section>

    <ng-template #WeekHasDates>
      <dg-booking-calendar-day
        [@fadeInAnimation]
        *ngFor="let day of weeks | async; let i = index"
        [day]="day"
        [slotType]="slotType"
        [isRebook]="isRebook"
        [bookingError]="bookingError"
        [currentAppointment]="currentAppointment"
        [localSelected]="localSelected"
        [gaData]="gaData"
        (daySelected)="onDaySelected($event)"
        (selected)="onDaySlotSelected($event)"
        (setLocalSelected)="localSelected = $event"
      >
      </dg-booking-calendar-day>
    </ng-template>
  </ng-template>
</div>
