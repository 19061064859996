import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'dg-legacy-flip-card',
  templateUrl: './legacy-flip-card.component.html',
  styleUrls: ['./legacy-flip-card.component.scss'],
})
export class LegacyFlipCardComponent implements AfterViewInit {
  @Input() isFlipped = false;
  minHeight?: string;

  @ViewChild('front')
  front?: ElementRef;
  @ViewChild('back')
  back?: ElementRef;

  flipCard() {
    this.isFlipped = !this.isFlipped;
  }

  ngAfterViewInit() {
    const front = this.front?.nativeElement.offsetHeight;
    const back = this.back?.nativeElement.offsetHeight;
    this.minHeight = front >= back ? front : back;
  }
}
