import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { HistoryType } from '@domgen/smart-fix/data-access-smart-fix';
import { fadeInAnimation } from '@domgen/dgx-components';

@Component({
  selector: 'smart-fix-chat-history',
  templateUrl: './chat-history.component.html',
  styleUrls: [],
  animations: [fadeInAnimation],
})
export class ChatHistoryComponent {
  @Input() history!: Observable<HistoryType[]>;
  @Input() closed = false;
}
