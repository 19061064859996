<div *ngIf="countDown" class="error-dialog-container">
  <div class="error-dialog">
    <div class="inner-dialog">
      <dgx-components-icon class="circle-icon" name="circle-exclamation" />
      <div>
        <span class="title">Something went wrong!</span>
        <br />
        We’ll try again in
        <span class="title">{{ countDown }} seconds</span>
      </div>
    </div>
  </div>
</div>
