import { Pipe, PipeTransform } from '@angular/core';
import { differenceInSeconds, format } from 'date-fns';

@Pipe({
  name: 'dateAgo',
  pure: false,
})
export class DateAgoPipe implements PipeTransform {
  transform(value: Date): string {
    const now = new Date();
    const date = new Date(value);

    const secondsDifference = differenceInSeconds(now, date);

    if (secondsDifference < 60) {
      return 'Now';
    } else {
      return format(date, 'HH:mm');
    }
  }
}
