import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ClaimFacade, SpecialRoutingService } from '@domgen/data-access-claims';
import { map, Observable, of, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { AppConfig, Claim, FeatureFlagService } from '@domgen/dgx-components';
import { CONFIG } from '@domgen/dgx-fe-config';

@Injectable({
  providedIn: 'root',
})
export class MMRInClaimsGuard {
  constructor(
    @Inject(CONFIG) private readonly config: AppConfig,

    private readonly featureFlagService: FeatureFlagService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return new Observable((observer) => {
      this.featureFlagService.isFeatureFlagEnabled('MMR_IN_CLAIMS').subscribe({
        next: (val) => {
          if (val) {
            observer.next(true);
            return;
          }
          // // Not allowed on claims, send them back to my account
          window.location.href = `${this.config.plansHomePage}/${route[
            '_routerState'
          ].url.replace(/\/my-plans\//g, '')}`;
        },
      });
    });
  }
}
