<div class="feedback-container">
  <div [@chatBotFadeInAnimationSequence]>
    <div class="smart-fix-text agent sequence-item">
      Did you find this useful?
    </div>
    <div
      *ngIf="!selectedOption; else showSelectedOption"
      class="mb-4 mt-4 sequence-item"
    >
      <ng-container *ngFor="let option of feedbackOptions">
        <dgx-components-button
          class="pre-wrap-text fix-width mb-4 mt-4"
          variant="secondary"
          [fill]="false"
          data-testid="chat-option-button"
          (click)="markOption(option)"
        >
          {{ option.label }}
        </dgx-components-button>
      </ng-container>
    </div>
  </div>

  <ng-template #showSelectedOption>
    <div class="mb-4 mt-4">
      <div [@chatBotFadeInAnimationSequence]>
        <div class="flex justify--end sequence-item">
          <div class="smart-fix-text user">
            {{ selectedOption.label }}
          </div>
        </div>
        <ng-container *ngIf="isResolved; else notResolved">
          <div class="smart-fix-text agent sequence-item">
            We're happy we could help. So we can improve our service in future,
            we'd love to hear more about your experience. How would you describe
            it?
          </div>
        </ng-container>
        <ng-template #notResolved>
          <div class="smart-fix-text agent sequence-item">
            We're sorry to hear that. We recommend getting an engineer out to
            look at your {{ productType }}. Once the chat has ended, we'll take
            you to our repair booking page. But first, how did we do today?
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
  <div *ngIf="feedback" [@chatBotFadeInAnimationSequence]>
    <div class="flex justify--end sequence-item">
      <div class="smart-fix-text user">
        {{ feedback }}
      </div>
    </div>

    <ng-container *ngIf="isResolved; else repairBooking">
      <div class="smart-fix-text agent sequence-item">
        Thank you for your feedback. You can end the chat now and continue to My
        Account.
      </div>

      <dgx-components-button
        class="pre-wrap-text fix-width sequence-item"
        variant="secondary"
        [fill]="true"
        (click)="returnMyAccount()"
        data-testid="chat-option-button"
      >
        {{ myAccountCTALabel }}
      </dgx-components-button>
    </ng-container>
    <ng-template #repairBooking>
      <div class="smart-fix-text agent sequence-item">
        Thank you for your feedback and time. You can end the chat now so that
        you can continue booking your repair.
      </div>

      <dgx-components-button
        class="pre-wrap-text fix-width sequence-item"
        variant="secondary"
        [fill]="false"
        (click)="continueRepairBooking()"
        data-testid="chat-option-button"
      >
        {{ continueRepairCTALabel }}
      </dgx-components-button>
    </ng-template>
  </div>
</div>
