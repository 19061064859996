import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SharedComponentsUiModule,
  FormBuilderModule,
} from '@domgen/dgx-components';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedComponentsUiModule,
    FormBuilderModule,
  ],
  exports: [],
})
export class ClaimSharedModule {}
