import { Component, Input } from '@angular/core';

@Component({
  selector: 'dg-fb-button',
  templateUrl: './button.component.html',
  styles: [
    `
      :host {
        display: inline;
      }
    `,
  ],
})
export class ButtonComponent {
  @Input() classes?: string = '';
  @Input() type?: string = '';
  @Input() control?: string = '';
  @Input() text?: string = '';
  @Input() stretch?: string | boolean;
  @Input() disabled = false;
  @Input() submitted = false;

  constructor() {}
}
