export const floorcareCodes = [
  '3@',
  'AN',
  'C*',
  'C=',
  'CC',
  'CL',
  'DY',
  'FP',
  'IW',
  'N*',
  'OQ',
  'P*',
  'RV',
  'S+',
  'SY',
  'V@',
  'VH',
  'VT',
  'W/',
  'W=',
  'WC',
  'WS',
  'YV',
];
