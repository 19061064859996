<section *ngIf="event" class="disclaimer" [class.closed]="closed">
  <div>
    <p class="header">{{ event.details.header }}</p>
  </div>
  <p class="description">{{ event.details.description }}</p>
  <div *ngIf="event.details.options" class="options-container">
    <div *ngFor="let option of event.details.options">
      <smart-fix-chatbot-disclaimer-option
        [description]="option.description"
        [icon]="option.icon"
        [closed]="closed"
      />
    </div>
  </div>
</section>
