import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dg-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
})
export class SvgComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
