export interface ContactDetailsFormValuesContainer {
  formValues: ContactDetailsFormValues;
}

export interface ContactDetailsFormValues {
  email?: string;
  landline?: string;
  mobile?: string;
  address?: string; // Loquate selected value
  addressLine1: string;
  addressLine2: string;
  city: string;
  county: string;
  postcode: string;
}
