export interface Origin {
  BuildingName?: string;
  CustomersHouseStreetName?: string;
  CustomersTownCity?: string;
  CustomersLocalArea?: string;
  CustomersPostCode?: string;
  Address?: string | null | undefined;
  Lat?: number | null;
  Lng?: number | null;
}
