<google-map
  *ngIf="vm$ | async as vm"
  height="100%"
  width="100%"
  (click)="closeInfo($event)"
  (mousedown)="onMouseDown($event)"
  (ontouchend)="onMouseDown($event)"
  [zoom]="zoom"
  [center]="center"
  [options]="options"
>
  <map-marker
    #markerElem="mapMarker"
    [position]="originMarker?.position"
    [label]="originMarker?.label"
    [title]="originMarker?.title"
    [options]="originalMarkerOptions"
  >
  </map-marker>

  <map-marker
    #markerElem="mapMarker"
    *ngFor="let marker of markers; index as i"
    [position]="marker.position"
    [label]="marker.label"
    [title]="marker.title"
    [options]="{
      icon:
        vm?.selected === i
          ? './assets/svg/Icon-Pointer.svg'
          : './assets/svg/Icon-Pointer-Default.svg'
    }"
    (mapClick)="openInfo(i)"
  >
  </map-marker>

  <map-info-window>
    <div *ngIf="infoContent">
      <claims-dropoff-location
        class="info map"
        [provider]="serviceProviders[highlighted]"
        [highlighted]="highlighted === infoContent.index"
        [index]="infoContent.index"
        [selected]="selected"
        source="map"
      ></claims-dropoff-location>
    </div>
  </map-info-window>
</google-map>
