import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  FormControlsDefinition,
  FormControlsDefinitionChildKey,
  TextareaDefinition,
  PutAnswer,
} from '@domgen/dgx-components';
import { QAFormHelper } from '../../../services/qa-form-helper';

@Component({
  selector: 'claims-question-form-list-input',
  templateUrl: './question-form-list-input.component.html',
  styleUrls: ['./question-form-list-input.component.scss'],
  providers: [QAFormHelper],
})
export class QuestionFormListInputComponent implements OnInit {
  @Input() group;
  @Input() form;
  @Input() questions;

  showValidation = false;

  @Output() answer: EventEmitter<PutAnswer> = new EventEmitter();

  nestedForm: UntypedFormGroup;
  nestedQuestions: FormControlsDefinition[];

  constructor(private qaHelper: QAFormHelper) {}

  ngOnInit() {
    const { nestedQuestion, questionData } = this.getFormData(
      this.group.radios
    );

    if (nestedQuestion.AnswerType !== undefined) {
      this.createForm(nestedQuestion, {
        [`${questionData.answer.AnswerID}`]: questionData.answer.AnswerValue,
      });
    }
  }

  handleChange(group: FormControlsDefinitionChildKey) {
    const { formValue, nestedQuestion } = this.getFormData(group);

    // if no nested question types emit answer
    if (nestedQuestion.AnswerType === undefined) {
      this.answer.emit({
        AnswerValue: nestedQuestion?.AnswerLabel,
        AnswerID: formValue,
        QuestionID: group?.control as string,
      });
    }

    this.createForm(nestedQuestion);
  }

  createForm(nestedQuestion, defaultFormValues = {}) {
    let answer = null;

    if (
      nestedQuestion?.AnswerType === 'DATE' &&
      Object.keys(defaultFormValues).length
    ) {
      answer = {
        AnswerValue: defaultFormValues[nestedQuestion.AnswerID],
      };
    }

    this.nestedQuestions = this.qaHelper.generateQuestionFormData([
      {
        question: nestedQuestion,
        answer: answer,
      },
    ]);

    this.nestedForm = this.qaHelper.createForm(
      this.nestedQuestions,
      defaultFormValues
    );
  }

  getFormData(group: FormControlsDefinitionChildKey) {
    const formValue = this.form.controls[group.control].value;
    const currentQuestionID = group.control;

    const questionData = this.questions.find(
      (q) => q.question.QuestionID === currentQuestionID
    );
    const questionAnswerData = questionData.question.AnswerData.find(
      (v) => v.AnswerID === formValue
    );

    const nestedQuestion = {
      QuestionLabel: questionAnswerData?.AnswerLabel,
      QuestionHelp: questionAnswerData?.AnswerHelp,
      QuestionID: questionAnswerData?.AnswerID,
      ...questionAnswerData,
    };

    if (questionAnswerData?.AnswerType === 'RESPONSE_FIELD') {
      nestedQuestion.AnswerData = [
        { AnswerMaximumCharacters: nestedQuestion.AnswerMaximumCharacters },
      ];

      nestedQuestion.QuestionHelp = nestedQuestion.AnswerHint;
    }

    return { nestedQuestion, formValue, questionData };
  }

  selectRadio(group: FormControlsDefinitionChildKey) {
    const questionID = Object.keys(this.form.value).find(
      (key) => this.form.value[key] === group?.control
    );

    const answerValue = this.nestedForm.value[group?.control as string];

    this.answer.emit({
      AnswerValue: answerValue,
      AnswerID: group?.control as string,
      QuestionID: questionID as string,
    });
  }

  selectDate(answerValue: Date, group: FormControlsDefinitionChildKey) {
    const questionID = Object.keys(this.form.value).find(
      (key) => this.form.value[key] === group?.control
    );

    const question = this.questions.find(
      (q) => q.question.QuestionID === questionID
    );

    if (this.qaHelper.hasDateValueChanged(answerValue, question)) {
      this.answer.emit({
        AnswerValue: answerValue.toString(),
        AnswerID: group.control,
        QuestionID: questionID,
      });
    }
  }

  textAreaSubmit(group: TextareaDefinition['textarea']) {
    const questionID = Object.keys(this.form.value).find(
      (key) => this.form.value[key] === group.control
    );

    const answerValue = this.nestedForm.value[group.control];

    this.answer.emit({
      AnswerValue: answerValue,
      AnswerID: group.control,
      QuestionID: questionID,
    });
  }
}
