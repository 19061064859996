import { Component, Input, OnChanges } from '@angular/core';
import { Api } from '../../interfaces/api.interface';

export interface OpeningHoursSummary {
  day: string;
  value: string;
  group?: number;
  start?: number;
  end?: number;
}

export interface OpeningHoursRow {
  key: string;
  value: string;
}

@Component({
  selector: 'dg-opening-times',
  templateUrl: './opening-times.component.html',
  styleUrls: ['./opening-times.component.scss'],
})
export class OpeningTimesComponent implements OnChanges {
  @Input() openingHours?: Api.ServiceProviderOpeningHours;
  data?: OpeningHoursRow[];
  constructor() {}

  ngOnChanges(): void {
    this.parseOpeningHours(
      this.openingHours as Api.ServiceProviderOpeningHours
    );
  }

  parseOpeningHours(timetable: Api.ServiceProviderOpeningHours) {
    const daysShort = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const daysFull = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];

    let summary = this.formatToArray(timetable);
    summary = this.countMatchingOpeningHours(summary);
    summary = this.groupOpeningHours(summary, daysShort);
    this.data = this.formatData(summary, daysFull, daysShort);
  }

  countMatchingOpeningHours(arr: OpeningHoursSummary[]) {
    let group = 1;
    let last: OpeningHoursSummary;

    return arr.map((v) => {
      if (v.value === last?.value && last?.value !== 'Closed') {
        v.group = group++;
      }
      last = v;
      return v;
    });
  }

  formatToArray(timetable: Api.ServiceProviderOpeningHours) {
    const arr = [];
    for (const day in timetable) {
      if (timetable.hasOwnProperty(day)) {
        arr.push({
          day: day.substring(0, 3),
          value: `${timetable[day]?.OpeningTime.replace(':00', '').replace(
            / /g,
            ''
          )} - ${timetable[day].ClosingTime.replace(':00', '').replace(
            / /g,
            ''
          )}`
            .replace('00:00 - 00:00', 'Closed')
            .replace(/^0+/, ''),
        });
      }
    }

    return arr;
  }

  groupOpeningHours(
    arr: OpeningHoursSummary[],
    days: string[]
  ): OpeningHoursSummary[] {
    // extract unique times
    const unique = arr.filter((v) => !v.group);

    // get start index for each unique opening time
    unique.map((v) => {
      v.start = days.indexOf(v.day.substring(0, 3));
    });

    // get end index for each unique opening time
    unique.map((v, i) => {
      v.end =
        (((unique[i + 1]?.start as number) - 1) as number) ||
        (v.start as number);
    });

    return unique;
  }

  formatData(
    summary: OpeningHoursSummary[],
    daysFull: string[],
    daysShort: string[]
  ): OpeningHoursRow[] {
    const data: OpeningHoursRow[] = [];

    summary.map((v) => {
      return v.value === 'Closed' || v.start === v.end
        ? data.push({ key: daysFull[daysShort.indexOf(v.day)], value: v.value })
        : data.push({
            key: `${v.day} - ${daysShort[v.end as number]}`,
            value: v.value,
          });
    });

    return data as OpeningHoursRow[];
  }
}
