import { Injectable } from '@angular/core';
import { ChatOptionComposite } from '../_shared/chat-option-composite';
import { ChatOptionLeaf } from '../_shared/chat-option-leaf';
import { Option } from '../_shared/chat-types';

@Injectable({
  providedIn: 'root',
})
export class ChatOptionCompositeService {
  createChatOptionComposite(option: Option) {
    return new ChatOptionComposite(option);
  }

  createChatOptionLeaf(option: Option) {
    return new ChatOptionLeaf(option);
  }
}
