import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromChat from './+state/chat.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ChatEffects } from './+state/chat.effects';
import { WASHING_MACHINE } from './assets/washing-machine.token';
import tumbleDryer from './assets/tumble-dryer.json';
import washingMachine from './assets/washing-machine.json';
import washingDryer from './assets/washer-dryer.json';
import { WASHER_DRYER } from './assets/washer-dryer.token';
import { TUMBLE_DRYER } from './assets/tumble-dryer.token';
import * as fromLexChat from './+state/lex-chat/lex-chat.reducer';
import { ChatService } from './services/chat.service';
import { LexChatEffects } from './+state/lex-chat/lex-chat.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromChat.componentFeatureKey, fromChat.chatReducer),
    EffectsModule.forFeature([ChatEffects]),
    StoreModule.forFeature(
      fromLexChat.componentFeatureKey,
      fromLexChat.lexChatReducer
    ),
    EffectsModule.forFeature([LexChatEffects]),
  ],
  providers: [
    { provide: WASHING_MACHINE, useValue: washingMachine },
    { provide: WASHER_DRYER, useValue: washingDryer },
    { provide: TUMBLE_DRYER, useValue: tumbleDryer },
    ChatService,
  ],
  declarations: [],
})
export class SmartFixDataAccessModule {}
