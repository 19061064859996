<div
  class="question flex align--end"
  [ngClass]="{ 'question--active': active, 'question--completed': completed }"
>
  <span class="question-number">{{ number }}</span>
  <p class="question-title" data-testid="claims-section-title">
    {{ title }}
  </p>
  <span class="question-icon" *ngIf="completed">
    <svg class="svg-icon icon-tick-filled">
      <use href="#icon-tick-filled"></use>
    </svg>
  </span>
</div>
