import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromChat from './lex-chat.reducer';

export const getLexChatState = createFeatureSelector<fromChat.State>(
  fromChat.componentFeatureKey
);

export const { selectEntities, selectIds } =
  fromChat.adapter.getSelectors(getLexChatState);

export const getLexHistory = createSelector(
  getLexChatState,
  (state) => state.history
);

export const getLexSessionId = createSelector(
  getLexChatState,
  (state) => state.sessionId
);

export const isLoading = createSelector(
  getLexChatState,
  (state) => state.isLoading
);

export const errorMessage = createSelector(
  getLexChatState,
  (state) => state.error
);

export const errorCount = createSelector(
  getLexChatState,
  (state) => state.errorCount
);

export const currentLexChatSessionMessages = createSelector(
  getLexChatState,
  (state) => state.currentLexChatSession.messages
);

export const currentLexChatSession = createSelector(
  getLexChatState,
  (state) => state.currentLexChatSession
);

export const getChat = createSelector(getLexChatState, (chat) => {
  return chat;
});
