import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NavigationLink } from '@domgen/dgx-fe-common';
import { CONFIG } from '@domgen/dgx-fe-config';
import { AppConfig } from '../../models/config/app-config.type';

@Component({
  selector: 'dg-my-account-banner',
  templateUrl: './my-account-banner.component.html',
  styleUrls: ['./my-account-banner.component.scss'],
})
export class MyAccountBannerComponent implements OnInit, OnChanges {
  @Input() isRepairClaim?: boolean = true;
  @Input() isWhirlpoolBooking = false;
  @Input() pageName = '';
  @Input() planNumber = '';
  @Input() productBrand = '';
  @Input() productType = '';

  get heading(): string {
    if (
      this.isWhirlpoolBooking &&
      this.config.runtimeFeatures.engineerTrackingBanner
    ) {
      return 'View details, change booking and track your engineer';
    }

    return 'View details, change or cancel your booking';
  }

  navigationLink: NavigationLink = {
    href: '',
    label: '',
  };

  productName = '';

  constructor(@Inject(CONFIG) private readonly config: AppConfig) {}

  ngOnInit(): void {
    this.updateNavigationLink();
    this.productName =
      `${this.productBrand.toLowerCase()} ${this.productType.toLowerCase()}`.trim();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isRepairClaim || changes.planNumber) {
      this.updateNavigationLink();
    }
  }

  private updateNavigationLink(): void {
    this.navigationLink = {
      href: `${this.config?.plansHomePage}/${this.planNumber}/claims`,
      label: `Manage my ${this.isRepairClaim ? 'repair' : 'service'}`,
    } as NavigationLink;
  }
}
