import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  FindAddressResponse,
  RetrieveAddressResponse,
} from '../interfaces/address-lookup.interface';

/**
 * Post Code finder service
 * - Has no shared state so can be provided without root
 */
@Injectable()
export class PcaService {
  readonly AppConfig = {
    postcodeServiceKey: 'ZG99-WD62-FP46-DC55',
    postcodeServiceFind:
      'https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws',
    postcodeServiceRetrieve:
      'https://api.addressy.com/Capture/Interactive/Retrieve/v1/json3.ws',
  };

  constructor(private http: HttpClient) {}

  /**
   * find - multiple address lookup requires id to be passed
   * @param input
   * @param id
   * @returns {Observable<any>}
   */
  public pcaFind(
    input: string,
    id: string = ''
  ): Observable<FindAddressResponse> {
    const url = `${this.AppConfig.postcodeServiceFind}?key=${this.AppConfig.postcodeServiceKey}&Text=${input}&Container=${id}&Origin=&IsMiddleware=false&Countries=GB&Limit=&Language=`;
    return this.http.get<FindAddressResponse>(url);
  }

  /**
   * retrieve address
   * @param id
   * @returns {Observable<any>}
   */
  public pcaRetrieve(id: string): Observable<RetrieveAddressResponse> {
    const url = `${this.AppConfig.postcodeServiceRetrieve}?key=${this.AppConfig.postcodeServiceKey}&Id=${id}`;
    return this.http.get<RetrieveAddressResponse>(url);
  }
}
