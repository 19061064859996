<div class="flip-container" [ngClass]="{ flip: isFlipped }">
  <div class="flipper">
    <div class="front">
      <div class="card d-block" #front [style.min-height.px]="minHeight">
        <ng-content select="[front]"></ng-content>
      </div>
    </div>
    <div class="back">
      <div class="card d-block" #back [style.min-height.px]="minHeight">
        <ng-content select="[back]"></ng-content>
      </div>
    </div>
  </div>
</div>
