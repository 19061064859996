import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { ReplaySubject } from 'rxjs';

export interface LoggedInUser {
  sub?: string | null; // User id
  'custom:user_state': string; // is completed, re password etc
  iss: string; // issuer (cognito user pool)
  'cognito:username': string; // email
  aud: string; // api to target with IGNORE
  event_id: string; // IGNORE internal
  token_use: string; // identifies as type of access token
  auth_time: number; // when loggin happened from server time
  'custom:digital_id': any; // digital ID
  name: string; // user name
  exp: number; // cookie expeiry time unix seconds
  iat: number; // issued cookie from this time
  family_name: string; // user surname
  email: string; // email
  'custom:wcs_created_at'?: any; // if present the user is a migrated one else its a new user
}

@Injectable({
  providedIn: 'root',
})
export class LoggedInUserService {
  user$: ReplaySubject<LoggedInUser> = new ReplaySubject<LoggedInUser>(1);
  userData: LoggedInUser | undefined;

  constructor() {
    this.getApplicationUser();
  }

  getApplicationUser(): void {
    const awsIDT = this.getCookieByName('AWS_IDT');
    if (!awsIDT) {
      throw Error('No user');
    }

    const decodedUser = jwt_decode(awsIDT);
    this.userData = decodedUser as LoggedInUser;
    this.user$.next(decodedUser as LoggedInUser);
  }

  getCookieByName(name: string): string | undefined {
    const cookie: Record<string, string> = {};
    document.cookie.split(';').forEach(function (el) {
      const split: string[] = el.split('=');

      cookie[split[0].trim()] = split.slice(1).join('=');
    });
    return cookie[name];
  }
}
