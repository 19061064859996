<div class="exit-dialog-overlay" *ngIf="message">
  <div class="exit-dialog">
    <dgx-components-icon
      name="close"
      size="xl"
      data-testid="close-icon"
      (click)="onContinue()"
      (keydown.enter)="onContinue()"
      class="close-icon"
    >
    </dgx-components-icon>
    <h5 class="message">
      {{ message }}
    </h5>
    <dgx-components-button
      class="exit-dialog-button"
      type="submit"
      data-testid="cancel-button"
      [fill]="true"
      variant="dg-primary-inverted"
      (click)="onCancel()"
      (keydown.enter)="onCancel()"
      >Yes, end chat</dgx-components-button
    >
    <dgx-components-button
      class="exit-dialog-button"
      type="submit"
      data-testid="continue-button"
      [fill]="true"
      variant="dg-primary-inverted"
      (click)="onContinue()"
      (keydown.enter)="onContinue()"
      >No, continue</dgx-components-button
    >
  </div>
</div>
