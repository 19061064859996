<section
  class="video-container"
  data-testid="video-wrapper"
  [class.closed]="closed"
  *ngIf="safeVideoUrl"
>
  <p
    class="description"
    *ngIf="safeDescription"
    data-testid="video-description"
    [innerHtml]="safeDescription"
  ></p>
  <div class="video-wrapper">
    <iframe
      title="preview fix video"
      frameborder="0"
      data-testid="video-iframe"
      allowfullscreen="true"
      [src]="safeVideoUrl"
    ></iframe>
  </div>
</section>
