import { Injectable } from '@angular/core';
import {
  Api,
  DateInputDefinition,
  FormControlsDefinition,
  FormsService,
  InputDefinition,
  TextareaDefinition,
} from '@domgen/dgx-components';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionTypes } from '@domgen/dgx-components';
import { QAFormHelper } from './qa-form-helper';

@Injectable({
  providedIn: 'root',
})
export class QAFormCallbackHelper {
  constructor(
    private formsService: FormsService,
    private fhelper: QAFormHelper
  ) {}

  createForm(questions: FormControlsDefinition[]) {
    return new UntypedFormGroup(
      this.formsService.defineformControls(questions, {})
    );
  }

  generateQuestionFormData(
    questions: Api.IWCallbackDataField[]
  ): FormControlsDefinition[] {
    const inputs = [];

    const filterOutContactDetailsOverlapAndMandatory = questions
      .filter((q) => q?.Identifier !== 'CustomersMobile')
      .filter((value: Api.IWCallbackDataField) => value.Mandatory);

    inputs.push(this.getDatePickerInputData());

    filterOutContactDetailsOverlapAndMandatory.map((q) => {
      if (q.Type === 'Text' && q.MaximumLength > 11) {
        inputs.push(this.getResponseFieldData(q));
      }

      if (q.Type === 'Text' && q.MaximumLength === 11) {
        inputs.push(this.getTextFieldData(q));
      }
    });

    return inputs;
  }

  getTextFieldData(question: Api.IWCallbackDataField): InputDefinition {
    return {
      input: {
        label: { text: question.Label, classes: 'form-label' },
        maxLength: question.MaximumLength,
        control: `${question.Identifier}`,
        DataType: null,
        placeholder: '',
        classes: 'form-control',
        readOnly: false,
        validators: [
          { type: 'required', message: `${question.Label} is required` },
        ],
      },
    };
  }

  getResponseFieldData(question: Api.IWCallbackDataField): TextareaDefinition {
    return {
      textarea: {
        type: QuestionTypes.RESPONSE_FIELD,
        label: {
          text: '',
          classes: '',
        },
        heading: 'Tell us about the fault',
        subheading: `Please give us any information that might help the engineer to fix your product.`,
        validators: [
          {
            type: 'required',
            message: 'Please provide as much information as possible',
          },
        ],
        chrLimit: question.MaximumLength,
        control: `${question.Identifier}`,
        placeholder: '',
        value: '',
        classes: 'form-control',
      },
    };
  }

  getDatePickerInputData(): DateInputDefinition {
    const obj: DateInputDefinition = {
      date: {
        heading: 'When did the fault occur?',
        subheading: `If you can't rememeber the exact date, please give an estimate.`,
        questionID: 'FaultDate',
        control: 'FaultDate',
        calendarDays: this.fhelper.getCalendar(),
        answer: null,
        answerIndex: null,
      },
    };

    return obj;
  }
}
