import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SessionTimeoutService {
  private timer$: Observable<number> | any;
  private timeOutMilliSeconds: number | null = null;

  public expired$: Subject<boolean> = new Subject<boolean>();

  constructor(public router: Router, public route: ActivatedRoute) {}

  public startWatching(timeOutSeconds: number): Observable<any> {
    this.timeOutMilliSeconds = timeOutSeconds * 1000;

    timer(this.timeOutMilliSeconds).subscribe((v) => {
      this.expired$.next(true);
    });

    return this.expired$;
  }

  private startTimer() {
    this.timer$ = timer(
      this.timeOutMilliSeconds as number,
      this.timeOutMilliSeconds as number
    ).subscribe((res) => {
      this.expired$.next(true);
    });
  }

  public stopTimer() {
    this.timer$.unsubscribe();
  }

  handleRedirect(domain: string) {
    const redirectUrl = `${domain}?sessionExpired=true&redirectUrl=${window.location.href}`;
    window.location.href = redirectUrl;
  }
}
