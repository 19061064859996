<div class="grid-row row--padding">
  <div class="xs-12">
    <div class="flex bg--page row--padding-large radio-hint justify--center">
      <div class="md-10 text--center">
        <p>
          <ng-content></ng-content>
        </p>
      </div>
    </div>
  </div>
</div>
