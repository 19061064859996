<div class="chatbot-wrapper">
  <button
    class="chatbot-toggle-button"
    type="submit"
    id="smart-fix-chat-client-container"
    data-testid="toggle-button"
    (click)="toggleMinimize()"
    (keydown.enter)="toggleMinimize()"
  >
    <dgx-components-icon name="comment" size="lg" class="chatbot-icon">
    </dgx-components-icon>
    <span
      class="badge"
      *ngIf="unreadMessages > 0 && !closed"
      data-testid="unread-messages"
      >{{ unreadMessages }}</span
    >
  </button>

  <div
    class="chatbot-container"
    [ngClass]="{ minimized: minimized, closed: closed }"
    data-testid="chatbot-container"
  >
    <form
      *ngIf="enablePromptEditingForSmartFix"
      class="initForm"
      [formGroup]="initForm"
      (submit)="initChat()"
    >
      <textarea
        name="prompt"
        placeholder="Custom initial prompt"
        class="input-wrapper pre-wrap-text"
        formControlName="prompt"
      ></textarea>
      <div class="input-wrapper pre-wrap-text">
        <select
          name="modelArn"
          placeholder="Select model"
          formControlName="modelArn"
          name
        >
          <option
            *ngFor="let option of modelOptions"
            [value]="option.value"
            selected
          >
            {{ option.label }}
          </option>
        </select>
      </div>

      <dgx-form-field formControlName="initialMessage">
        <dgx-form-text-input
          name="initialMessage"
          placeholder="Initial message"
        ></dgx-form-text-input>
      </dgx-form-field>

      <dgx-components-icon
        *ngIf="initForm.valid"
        class="send-prompt-icon"
        name="send"
        [ariaDisabled]="!initForm.valid"
        size="lg"
        type="submit"
        (click)="initChat()"
        (keydown.enter)="initChat()"
      ></dgx-components-icon>
      <br />
      <em>
        Note: Start by tweaking the input of the initial message above. Then
        click the button above to start. Then continue the discussion in the
        chat container.
      </em>

      <br />
      <br />
      <div>
        <p>This is only for lower envs and debugging reasons.</p>

        <br />
        <em>
          In production, the initial message will be sent automatically. The
          reason we have it like this in development environments is for the
          product team to test it and come up with the best approach for the
          initial message and/or tweaking of the prompt.
        </em>
      </div>

      <dgx-components-icon
        *ngIf="initForm?.valid"
        class="send-icon"
        name="send"
        [ariaDisabled]="!initForm?.valid"
        size="lg"
        type="submit"
        (click)="initChat()"
        (keydown.enter)="initChat()"
      ></dgx-components-icon>
    </form>
    <div class="chatbot-scroller" #scrollContainer>
      <smart-fix-bedrock-chat-header
        (minimize)="toggleMinimize()"
        (refresh)="initChat(); isFeedback = false"
        (repairBooking)="closeChatBotWithoutConfirmation()"
        (endChat)="showFeedback()"
      ></smart-fix-bedrock-chat-header>
      <smart-fix-bedrock-chat-welcome-message></smart-fix-bedrock-chat-welcome-message>

      <div class="history-container">
        <div *ngFor="let item of history; let i = index; let isLast = last">
          <div class="history-item" *ngIf="item.role === 'agent'">
            <div *ngIf="!isLoading && isLast">
              <smart-fix-model-response-character-typing
                [content]="item.content.message"
              ></smart-fix-model-response-character-typing>
            </div>

            <smart-fix-lex-plain-text
              *ngIf="!isLast"
              [plainTextDescription]="item.content.message"
            />
          </div>

          <div class="history-item" *ngIf="item.role === 'user'">
            <smart-fix-lex-response [lexResponse]="item.content.message" />
          </div>
        </div>

        <div *ngIf="isLoading" class="text--left loading-chat-container">
          <img
            class="loading-chat-animation"
            src="./assets/images/loading-chat-animation.gif"
          />
        </div>
        <div class="history-item">
          <smart-fix-bedrock-feedback
            [claim]="claim"
            *ngIf="isFeedback"
            (requestFeedback)="onRequestFeedback()"
            (closeChat)="closeChat()"
            [feedback]="feedback"
          ></smart-fix-bedrock-feedback>
        </div>
      </div>

      <form
        class="chatForm"
        *ngIf="chatForm && !isFeedback"
        [formGroup]="chatForm"
        (submit)="onSubmit($event)"
        (keydown.enter)="onSubmit($event)"
      >
        <div class="input-wrapper">
          <div class="text-container">
            <textarea
              #textArea
              name="message"
              placeholder="Ask Smart-Fix"
              class="text-form-input"
              formControlName="message"
              (focus)="onFocusInput()"
              (blur)="onBlurInput()"
              (input)="autoResize($event)"
            ></textarea>
          </div>

          <div class="icon-container">
            <dgx-components-icon
              *ngIf="chatForm.valid && chatForm?.value?.message?.trim()"
              class="input-icon send-icon"
              name="send"
              [ariaDisabled]="!chatForm.valid"
              size="sm"
              type="submit"
              (click)="onSubmit($event)"
              (keydown.enter)="onSubmit($event)"
            ></dgx-components-icon>
          </div>
        </div>
      </form>
      <smart-fix-bedrock-feedback-form
        *ngIf="!feedback && feedbackRequested"
        (sendFeedback)="onSendFeedback($event)"
      ></smart-fix-bedrock-feedback-form>
    </div>
  </div>
</div>
