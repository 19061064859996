import { Api } from '../interfaces';
import {
  Claim,
  ClaimBundleType,
  ClaimJourneyStateType,
} from '../interfaces/claim-state.interface';

const getData: Api.GetMandatoryDataResponse = {
  Status: 'OK',
  StatusCode: 'GM000',
  StatusDebugging: [],
  BookingOEM: 'Hoover Candy',
  ProductType: 'WASHING MACHINE',
  PlanInWarrantyPeriod: false,
  PlanInWaitPeriod: false,
  InWarrantyContactName: null,
  InWarrantyContactTel: null,
  OEMModelDataRequired: true,
  UniqueApplianceID: '128080',
  ModelNumber: 'LBHF130E CE',
  FaultData: {
    FaultCategoryID: 665,
    FaultCategoryName: '',
    FaultCategoryLabel: '',
    FaultCategoryPriority: 0,
    PossibleAnswers: [
      {
        FaultID: '32929',
        FaultCode: '1ZV',
        FaultName: '1ZV - Burning Smell',
        FaultLabel: 'Burning smell',
        Priority: '1',
      },
      {
        FaultID: '32726',
        FaultCode: '1ZL',
        FaultName: '1ZL - Appearance-Damage',
        FaultLabel: 'Comestic damage',
        Priority: '2',
      },
      {
        FaultID: '32719',
        FaultCode: '1ZB',
        FaultName: '1ZB - Does Not Drain',
        FaultLabel: 'Doesnt drain',
        Priority: '3',
      },
      {
        FaultID: '32713',
        FaultCode: '1Z4',
        FaultName: '1Z4 - Does Not Dry',
        FaultLabel: 'Doesnt dry',
        Priority: '4',
      },
      {
        FaultID: '32930',
        FaultCode: '1Z2',
        FaultName: '1Z2 - Does Not Fill',
        FaultLabel: 'Doesnt fill with water',
        Priority: '5',
      },
      {
        FaultID: '32718',
        FaultCode: '1ZA',
        FaultName: '1ZA - Does Not Heat',
        FaultLabel: 'Doesnt heat',
        Priority: '6',
      },
      {
        FaultID: '32931',
        FaultCode: '1Z9',
        FaultName: '1Z9 - Does Not Rinse',
        FaultLabel: 'Doesnt rinse',
        Priority: '7',
      },
      // {
      //   FaultID: '32932',
      //   FaultCode: '1Z6',
      //   FaultName: '1Z6 - Does Not Spin',
      //   FaultLabel: 'Doesnt spin',
      //   Priority: '8',
      // },
      // {
      //   FaultID: '32933',
      //   FaultCode: '1Z8',
      //   FaultName: '1Z8 - Does Not Take,or takes Wrong Detergent',
      //   FaultLabel: 'Doesnt take detergent',
      //   Priority: '9',
      // },
      // {
      //   FaultID: '32934',
      //   FaultCode: '1Z7',
      //   FaultName: '1Z7 - Does Not Wash',
      //   FaultLabel: 'Doesnt wash',
      //   Priority: '10',
      // },
      // {
      //   FaultID: '32935',
      //   FaultCode: '1ZH',
      //   FaultName: '1ZH - Door Faulty',
      //   FaultLabel: 'Faulty door',
      //   Priority: '11',
      // },
      // {
      //   FaultID: '32725',
      //   FaultCode: '1ZJ',
      //   FaultName: '1ZJ - Door Hinge Faulty',
      //   FaultLabel: 'Faulty door hinge',
      //   Priority: '12',
      // },
      // {
      //   FaultID: '32710',
      //   FaultCode: '1XX',
      //   FaultName: '1XX - Inspection Required',
      //   FaultLabel: 'Inspection required',
      //   Priority: '13',
      // },
      // {
      //   FaultID: '32936',
      //   FaultCode: '1ZC',
      //   FaultName: '1ZC - Leaks',
      //   FaultLabel: 'Leaking',
      //   Priority: '14',
      // },
      // {
      //   FaultID: '32721',
      //   FaultCode: '1ZD',
      //   FaultName: '1ZD - Noisy',
      //   FaultLabel: 'Noisy',
      //   Priority: '15',
      // },
      // {
      //   FaultID: '32937',
      //   FaultCode: '1ZE',
      //   FaultName: '1ZE - Overheats',
      //   FaultLabel: 'Overheats',
      //   Priority: '16',
      // },
      // {
      //   FaultID: '32723',
      //   FaultCode: '1ZG',
      //   FaultName: '1ZG - Tears',
      //   FaultLabel: 'Tears clothing',
      //   Priority: '17',
      // },
      // {
      //   FaultID: '32938',
      //   FaultCode: '1Z1',
      //   FaultName: '1Z1 - Appliance Dead',
      //   FaultLabel: 'Wont switch on',
      //   Priority: '18',
      // },
      // {
      //   FaultID: '32930',
      //   FaultCode: '1Z12',
      //   FaultName: 'Annual Service',
      //   FaultLabel: 'Annual Service',
      //   Priority: '19',
      // },
    ],
  },
  OEMApplianceData: [
    {
      FieldIdentifier: 'UniqueApplianceID',
      FieldLabel: 'Model Number',
      FieldType: 'String',
      FieldRegex: null,
      FieldLookupRegex: null,
      FieldValues: [],
      DataCategory: 'OEMAppliances',
      DataType: 'ModelNumber',
      UseGetData: true,
      DisplayPriority: 0,
    },
    {
      FieldIdentifier: 'SerialNumber',
      FieldLabel: 'Serial Number',
      FieldType: 'String',
      FieldRegex: null,
      FieldLookupRegex: null,
      FieldValues: [],
      DataCategory: null,
      DataType: null,
      UseGetData: false,
      DisplayPriority: 0,
    },
  ],
  ClaimTypes: [
    {
      ClaimTypeID: '3',
      ClaimTypeName: 'Breakdown',
      ClaimTypeLabel: 'Breakdown',
      ClaimTypeCode: 'BD',
      ClaimTypeAvailable: true,
    },
    {
      ClaimTypeID: '4',
      ClaimTypeName: 'Damage Caused by Accident',
      ClaimTypeLabel: 'Damage Caused by Accident',
      ClaimTypeCode: 'AD',
      ClaimTypeAvailable: true,
    },
  ],
};

export const claim: Claim = {
  getData: getData,
  reflect: {
    countryCode: 'GB',
    journeyState: ClaimJourneyStateType.START,
    claimType: ClaimBundleType.REPAIR,
    applianceCode: '1C',
    makerCode: 'XXX',
    ttl: '',
    domain: 'https://www.vm.domesticandgeneral.com',
    homeTelephone: '02086612014',
    addressLine1: '12 Paxton Terrace',
    productType: 'Washing Machine',
    addressLine2: 'London',
    companyCode: 'D',
    addressLine3: 'Pimlico',
    mobilePhone: '07707654556',
    addressLine4: 'Pimlico',
    postCode: 'SW1V 3DA',
    initials: 'Mr',
    manufacturer: 'Hoover',
    planNumber: 'VU50017405',
    status: 'N',
    customerTitle: 'Mr',
    surname: 'Malak',
    firstName: 'Morhys',
    email: 'morhys@tester.com',
    workPhone: '1221323',
    user: 'xxx',
    rootURL: 'https://www.domesticandgeneral.com',
    myAccountURL: 'https://www.myaccount.domesticandgeneral.com',
    logoutPath: '/logout',
    personalDetailsPath: '/personaldetails',
    dashboardPath: '/dashboard',
    isRiskOwner: 'false',
  },
  bookingAvailable: false,
};
export const initialState = <unknown>(<unknown>{
  entities: [claim],
  isLoading: false,
  planNumber: 'VU50017405',
});
