import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Api, Availability } from '../../../interfaces';
import { BookingCalendarDateFormats } from '../booking-calendar-constants';

export enum Navigate {
  LEFT = 'left',
  RIGHT = 'right',
}

@Component({
  selector: 'dg-booking-calendar-navigation',
  templateUrl: './booking-calendar-navigation.component.html',
  styleUrls: ['./booking-calendar-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingCalendarNavigationComponent implements OnChanges {
  @Input() currentPage = 0;
  @Input() availability?: Availability;
  @Output() navigate: EventEmitter<'future' | 'past'> = new EventEmitter();
  @Output() getMoreDates: EventEmitter<string> = new EventEmitter();

  bookingCalendarDateFormats = BookingCalendarDateFormats;

  selectedWeek: Api.SlotsByWeek | undefined;

  get numberOfWeeks() {
    return (
      (this.availability?.bookingOption?.SlotsByWeek?.length || 1) - 1 || 0
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.availability || changes.currentPage) {
      this.selectedWeek =
        this.availability?.bookingOption?.SlotsByWeek?.[this.currentPage];
    }
  }

  loadMoreDates(direction: 'future' | 'past' = 'future'): void {
    if (direction === 'past') {
      this.navigate.emit('past');
      this.currentPage = this.currentPage - 1;
      return; // No need to load more data
    }

    const { SlotsByWeek } = this.availability?.bookingOption || {};

    if (SlotsByWeek?.[this.currentPage + 1]) {
      this.navigate.emit(direction);
      return; // We already have loaded in the data for this week
    }

    const currentEndOfWeek = SlotsByWeek?.[this.currentPage]?.WeekEnd;

    if (!currentEndOfWeek) return;

    this.getMoreDates.emit(currentEndOfWeek.toString());
    this.navigate.emit(direction);
  }
}
