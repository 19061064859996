<ng-container [ngSwitch]="showObject(content)">
  <ng-container *ngSwitchCase="'welcome'">
    <div class="description">
      Welcome to Smart-fix!
      <dgx-components-icon ml-5 icon="hand-wave" />
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'checklist-disclaimer'">
    <smart-fix-chatbot-disclaimer
      [event]="disclaimerEvent"
      data-testid="chatbot-disclaimer"
    ></smart-fix-chatbot-disclaimer>
  </ng-container>
</ng-container>
