import { createAction, props } from '@ngrx/store';
import {
  ChatComponent,
  HistoryType,
  productTypeEnums,
} from '../_shared/chat-types';
import { Update } from '@ngrx/entity';

export enum ChatActionTypes {
  UpdateComponent = '[Smart-fix] update component',
  LoadHistory = '[Smart-fix] load history',
  LoadComponent = '[Smart-fix] LoadComponent',
  LoadDecisionTree = '[Smart-fix] LoadDecisionTree',
  LoadDecisionTreeSuccess = '[Smart-fix] LoadDecisionTreeSuccess',
  LoadDecisionTreeFailure = '[Smart-fix] LoadDecisionTreeFailure',
}

export const UpdateRootComponent = createAction(
  '[Smart-fix] Load next component',
  props<{ update: Update<ChatComponent> }>()
);

export const LoadNextComponent = createAction(
  '[Smart-fix] Load next component wip',
  props<{ payload: ChatComponent }>()
);

export const UpdateComponent = createAction(
  '[Smart-fix] update component',
  props<{ payload: ChatComponent }>()
);

export const LoadHistory = createAction(
  '[Smart-fix] Load Decision Tree History',
  props<{ history: Array<string> }>()
);

export const UpdateHistory = createAction(
  '[Smart-fix] Update Decision Tree History',
  props<{ payload: HistoryType }>()
);

export const LoadDecisionTree = createAction(
  ChatActionTypes.LoadDecisionTree,
  props<{ payload: productTypeEnums }>()
);

export const LoadDecisionTreeSuccess = createAction(
  ChatActionTypes.LoadDecisionTreeSuccess,
  props<{ payload: ChatComponent }>()
);

export const LoadDecisionTreeFailure = createAction(
  ChatActionTypes.LoadDecisionTreeFailure,
  props<{ error: string | null }>()
);

export type ChatActions =
  | typeof LoadDecisionTree
  | typeof LoadDecisionTreeSuccess
  | typeof LoadDecisionTreeFailure;
