export interface CardPaymentRedirectRequest {
  companyCode: string;
  schemeCode: string;
  amount: number;
  currency: string;
  description: string;
  name: string;
  email: string;
  transactionSource: string;
  transactionType: string;
  referenceNumber: string;
  mobTel: string;
  address: ExcessAddress;
  billingAddress: ExcessAddress;
  ipAddress: string;
}

export interface ExcessAddress {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  postalCode: string;
  countryCode: string;
}

export interface CardPaymentRedirectResponse {
  clientReferenceNumber: string;
  url: string;
  orderNumber: string;
  status: string;
}

export const CardPaymentRedirectResponse = undefined;
