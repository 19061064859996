import { Injectable } from '@angular/core';
import { Observable, OperatorFunction, of, zip } from 'rxjs';
import { switchMap, map, first, catchError } from 'rxjs/operators';
import { Api, Claim } from '@domgen/dgx-components';
/* NgRx */
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as claimActions from './claim.actions';
import * as fromClaims from './';
import { ApiService } from '../services/api.service';
import { CallbackHelperService } from '../services/callback-helper.service';
import { Store } from '@ngrx/store';

@Injectable()
export class CallbackEffects {
  constructor(
    private actions$: Actions,
    private claimService: ApiService,
    private store: Store<fromClaims.State>,
    private callbackHelperService: CallbackHelperService
  ) {}

  contactDetailsCallbackClaim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.ContactDetailsCallbackClaim),
      switchMap(() => of(claimActions.ContactDetailsCallbackClaimSuccess()))
    )
  );

  getCallbackWidget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.GetCallbackWidget),
      switchMap(() => of(claimActions.GetCallbackWidgetSuccess()))
    )
  );

  completeCallbackBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.CompleteCallbackBooking),
      switchMap((action) =>
        zip(
          this.store
            .select(fromClaims.getActiveClaim)
            .pipe(first()) as Observable<Claim>,
          of(action)
        )
      ) as OperatorFunction<
        unknown,
        [Claim, { type: string; payload: { desiredTime: string } }]
      >,
      switchMap(
        ([claim, action]: [Claim, { payload: { desiredTime: string } }]) => {
          return this.claimService
            .callbackComplete(
              this.callbackHelperService.getCallbackPayload(
                claim,
                action.payload.desiredTime
              )
            )
            .pipe(
              map((response: Api.CallbackResponse) => {
                return claimActions.CompleteCallbackBookingSuccess({
                  payload: {
                    reference: response.CallbackReference,
                    claimConfirmation:
                      this.callbackHelperService.getConfirmationData(
                        claim,
                        response.CallbackReference
                      ),
                  },
                });
              })
            );
        }
      ),
      catchError((error) => {
        return of(claimActions.Error({ payload: error }));
      })
    )
  );
}
