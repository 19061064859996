import { getSuperCategory } from './index';
/* NgRx */
import {
  ActionError,
  CardPaymentRedirectRequest,
  CardPaymentRedirectResponse,
  Origin,
} from '@domgen/dgx-components';

import {
  Api,
  BookingSelection,
  Claim,
  ContactDetailsUpdatedStatus,
  RouteClaimParams,
  ServiceOption,
  UpdateClaimPayload,
} from '@domgen/dgx-components';
import { createAction, props } from '@ngrx/store';

export enum ClaimActionTypes {
  ClaimAccepted = '[Claim] ClaimAccepted',
  ClaimRejected = '[Claim] ClaimRejected',
  ClearCurrentClaim = '[Claim] Clear Current Claim',
  CreateClaim = '[Claim] CreateClaim',
  CreateClaimSuccess = '[Claim] CreateClaim Success',
  AutoCreateClaim = '[Claim] AutoCreateClaim',
  CreateCallbackClaim = '[Claim] CreateCallbackClaim',
  QACallbackClaim = '[Claim] QACallbackClaim',
  ContactDetailsCallbackClaim = '[Claim] ContactDetailsCallbackClaim',
  ContactDetailsCallbackClaimSuccess = '[Claim] ContactDetailsCallbackClaimSuccess',
  GetCallbackWidget = '[Claim] GetCallbackWidget',
  GetCallbackWidgetSuccess = '[Claim] GetCallbackWidgetSuccess',
  CompleteCallbackBooking = '[Claim] CompleteCallbackBooking',
  CompleteCallbackBookingSuccess = '[Claim] CompleteCallbackBookingSuccess',
  CompleteCallbackFail = '[Claim] CompleteCallbackFail',
  Error = '[Claim] Error',
  GetProductAttributesSuccess = '[Claim] GetProductAttributesSuccess',
  GetQuestion = '[Claim] GetQuestion',
  GetQuestionSuccess = '[Claim] GetQuestion Success',
  GetReflectDataSuccess = '[Claim] GetReflectDataSuccess',

  GetReflectDataSuccessV2 = '[Claim] GetReflectDataSuccessV2',
  GetServiceOptions = '[Claim] GetServiceOptions',
  LoadServiceOptions = '[Claim] LoadServiceOptions',
  SelectServiceOption = '[Claim] SelectServiceOption',
  GetSuperCategorySuccess = '[Claim] GetSuperCategorySuccess',
  GetSuperCategorySuccessV2 = '[Claim] GetSuperCategorySuccessV2',
  InitializeCurrentClaim = '[Claim] InitializeCurrentClaim',
  InitializeCurrentClaimV2 = '[Claim] InitializeCurrentClaim v2',
  GetClaimQA = '[Claim] GetClaimQA',
  LoadClaimDataSuccess = '[Claim] LoadClaimData Success',
  RebookClaimDataSuccess = '[Claim] RebookClaimDataSuccess Success',
  PutAnswer = '[Claim] PutAnswer',
  PutRepairData = '[Claim] PutRepairData',
  PutRepairDataSuccess = '[Claim] PutRepairData Success',
  PutNewAppointment = '[Claim] PutNewAppointment',
  PutNewAppointmentSuccess = '[Claim] PutNewAppointment Success',
  RetryPutServiceOption = '[Claim] RetryPutServiceOption',
  DropOffPutServiceOption = '[Claim] DropOffPutServiceOption',
  DropOffPutServiceOptionSuccess = '[Claim] DropOffPutServiceOptionSuccess',
  PutServiceOptionSuccess = '[Claim] PutServiceOption Success',
  GetBookingExtraSlots = '[Claim] GetBookingExtraSlots',
  GetBookingFollowingWeekSlots = '[Claim] GetBookingFollowingWeekSlots',
  SilentGetBookingExtraSlots = '[Claim] SilentGetBookingExtraSlots',
  CancelSilentGetBookingExtraSlots = '[Claim] CancelSilentGetBookingExtraSlots',
  GetBookingExtraSlotsSuccess = '[Claim] GetBookingExtraSlotsSuccess',
  GetBookingExtraSlotsFailed = '[Claim] GetBookingExtraSlotsFailed',
  BookingSlotUnavailable = '[Claim] BookingSlotUnavailable',
  ClearBookingErrors = '[Claim] ClearBookingErrors',
  RouteClaim = '[Claim] RouteClaim',
  RouteSmartFix = '[Claim] RouteSmartFix',
  SeenGasSafetyNotice = '[Claim] SeenGasSafetyNotice',
  SetManufacturers = '[Claim] SetManufacturers',
  SetProductTypes = '[Claim] SetProductTypes',
  StartTransaction = '[Claim] StartTransaction',
  SubmitSelectedServiceOption = '[Claim] SubmitSelectedServiceOption',
  UpdateClaim = '[Claim] UpdateClaim',
  ClaimRebook = '[Claim] ClaimRebook',
  ManualBooking = '[Claim] ManualBooking',
  CollectionBooking = '[Claim] CollectionBooking',
  SelfSendBooking = '[Claim] SelfSendBooking',
  PayAndClaim = '[Claim] PayAndClaim',
  EngineerBooking = '[Claim] EngineerBooking',
  DropOffBooking = '[Claim] DropOffBooking',
  UpdateClaimSuccess = '[Claim] UpdateClaimSuccess',
  UpdateManufacturer = '[Claim] UpdateManufacturer',
  SetOneProductType = '[Claim] SetOneProductType',
  AutoSetProductType = '[Claim] AutoSetProductType',
  RouteForward = '[Claim] RouteForward',
  Loading = '[Claim] Loading',
  Loaded = '[Claim] Loaded',
  UpdateUserPersonalDetails = '[Claim] UpdateUserPersonalDetails',
  UpdateUserPersonalDetailsSuccess = '[Claim] UpdateUserPersonalDetailsSuccess',
  UpdateUserPersonalDetailsFailed = '[Claim] UpdateUserPersonalDetailsFailed',
  UpdateUserAddressDetails = '[Claim] UpdateUserAddressDetails',
  UpdateUserAddressDetailsSuccess = '[Claim] UpdateUserAddressDetailsSuccess',
  UpdateUserAddressDetailsFailed = '[Claim] UpdateUserAddressDetailsFailed',
  ResetBooking = '[Claim] ResetBooking',
  LoadCardPaymentRedirect = '[Claim] LoadCardPaymentRedirect',
  LoadCardPaymentRedirectSuccess = '[Claim] LoadCardPaymentRedirectSuccess',
  LoadCardPaymentRedirectFail = '[Claim] CompletePayment',
  CompletePayment = '[Claim] CompletePayment',
  CompletePaymentSuccess = '[Claim] CompletePaymentSuccess',
  SetPassword = '[Claim] SetPassword',
  SetPasswordSuccess = '[Claim] SetPasswordSuccess',
  SetPasswordFail = '[Claim] SetPasswordFail',
  GetMoreAppointments = '[Claim] GetMoreAppointments',
  CreateNewAppointmentSlot = '[Claim] CreateNewAppointmentSlot',
  GetMoreAppointmentsSuccess = '[Claim] GetMoreAppointmentsSuccess',
  UpdateStartAvailabilityDate = '[Claim] Update start availability',
  RebookDataAlreadyLoaded = '[Claim] Rebook data already loaded',
  NewLoadClaimDataSuccess = '[Claim] NewLoadClaimDataSuccess',
  NewRouteClaim = '[Claim] NewRouteClaim',
}

export const ClearCurrentClaim = createAction(
  ClaimActionTypes.ClearCurrentClaim
);

export const InitializeCurrentClaim = createAction(
  ClaimActionTypes.InitializeCurrentClaim,
  props<{
    payload: {
      param: string;
      newHandoff: boolean;
    };
  }>()
);

export const InitializeCurrentClaimV2 = createAction(
  ClaimActionTypes.InitializeCurrentClaimV2,
  props<{
    payload: {
      param: string;
      newHandoff: boolean;
      resolvesToSmartFix: boolean;
    };
  }>()
);

export interface NewLoadSmartFixClaimDataSuccess
  extends Api.StartBookingResponse {
  resolvesToSmartFix?: boolean;
}

export const NewLoadClaimDataSuccess$ = createAction(
  ClaimActionTypes.NewLoadClaimDataSuccess,
  props<{
    payload: NewLoadSmartFixClaimDataSuccess;
  }>()
);

export const NewRouteClaim$ = createAction(
  ClaimActionTypes.NewRouteClaim,
  props<{
    payload: Api.StartBookingResponse;
  }>()
);

export interface GetReflectDataSuccess {
  reflect: Api.Reflect;
  bundleToken: string;
  newHandoff: boolean;
  resolvesToSmartFix?: boolean;
}

export const GetReflectDataSuccess = createAction(
  ClaimActionTypes.GetReflectDataSuccess,
  props<{
    payload: GetReflectDataSuccess;
  }>()
);

export const GetReflectDataSuccessV2 = createAction(
  ClaimActionTypes.GetReflectDataSuccessV2,
  props<{
    payload: GetReflectDataSuccess;
  }>()
);

export type LoadClaimDataSuccess = Claim & {
  reflect: Api.Reflect;
  resolvesToSmartFix?: boolean;
};

export const LoadClaimDataSuccess = createAction(
  ClaimActionTypes.LoadClaimDataSuccess,
  props<{ payload: LoadClaimDataSuccess }>()
);

export const ClaimRebook = createAction(
  ClaimActionTypes.ClaimRebook,
  props<{ payload: Api.GetMandatoryDataResponse }>()
);

export const GetClaimQA = createAction(
  ClaimActionTypes.GetClaimQA,
  props<{
    payload: {
      getData: Api.GetMandatoryDataResponse;
      superCat: Api.GetSuperCategoryResponse;
    };
  }>()
);

export const CreateNewAppointmentSlot = createAction(
  ClaimActionTypes.CreateNewAppointmentSlot,
  props<{ dateStart: Date }>()
);

export const GetMoreAppointments = createAction(
  ClaimActionTypes.GetMoreAppointments,
  props<{ latestDate: Date }>()
);

export const GetMoreAppointmentsSuccess = createAction(
  ClaimActionTypes.GetMoreAppointmentsSuccess,
  props<{ payload: Api.PutServiceOptionResponse }>()
);

export const RebookClaimDataSuccess = createAction(
  ClaimActionTypes.RebookClaimDataSuccess,
  props<{ payload: Api.PutServiceOptionResponse }>()
);

export const RebookDataAlreadyLoaded = createAction(
  ClaimActionTypes.RebookDataAlreadyLoaded
);
export const CreateClaim = createAction(
  ClaimActionTypes.CreateClaim,
  props<{ payload: UpdateClaimPayload }>()
);

export const AutoCreateClaim = createAction(
  ClaimActionTypes.AutoCreateClaim,
  props<{ payload: UpdateClaimPayload }>()
);

export const CreateCallbackClaim = createAction(
  ClaimActionTypes.CreateCallbackClaim,
  props<{ payload: Api.GetMandatoryDataResponse }>()
);

export const QACallbackClaim = createAction(ClaimActionTypes.QACallbackClaim);

export const ContactDetailsCallbackClaim = createAction(
  ClaimActionTypes.ContactDetailsCallbackClaim,
  props<{ payload: { [key: string]: string } }>()
);

export const ContactDetailsCallbackClaimSuccess = createAction(
  ClaimActionTypes.ContactDetailsCallbackClaimSuccess
);

export const GetCallbackWidget = createAction(
  ClaimActionTypes.GetCallbackWidget,
  props<{ payload: Api.GetServiceOptionRequest }>()
);

export const GetCallbackWidgetSuccess = createAction(
  ClaimActionTypes.GetCallbackWidgetSuccess
);

export const CompleteCallbackBooking = createAction(
  ClaimActionTypes.CompleteCallbackBooking,
  props<{
    payload: {
      desiredTime: string;
    };
  }>()
);

export const CompleteCallbackBookingSuccess = createAction(
  ClaimActionTypes.CompleteCallbackBookingSuccess,
  props<{
    payload: {
      reference: string;
      claimConfirmation?: Api.PutRepairDataResponse;
    };
  }>()
);

export const CreateClaimSuccess = createAction(
  ClaimActionTypes.CreateClaimSuccess,
  props<{
    payload: {
      response: {
        ClaimID: string;
        question: Api.GetQuestionResponse;
      };
    };
  }>()
);

export const GetQuestion = createAction(
  ClaimActionTypes.GetQuestion,
  props<{
    payload: {
      response: Api.PutNewClaimResponse;
      update: UpdateClaimPayload;
    };
  }>()
);

export const GetQuestionSuccess = createAction(
  ClaimActionTypes.GetQuestionSuccess,
  props<{
    payload: {
      request: Api.PutAnswerRequest;
      response: Api.GetQuestionResponse;
    };
  }>()
);

export const PutAnswer = createAction(
  ClaimActionTypes.PutAnswer,
  props<{
    payload: Api.PutAnswerRequest;
  }>()
);

export interface PayloadBooking {
  noBookingDatesFound?: boolean;
  dropOffOrigin?: Origin;
  serviceOptionID?: string;
  attemptCount?: number | null;
}

export const ManualBooking = createAction(
  ClaimActionTypes.ManualBooking,
  props<{
    payload: PayloadBooking;
  }>()
);

export const CollectionBooking = createAction(
  ClaimActionTypes.CollectionBooking
);

export const SelfSendBooking = createAction(ClaimActionTypes.SelfSendBooking);

export const PayAndClaim = createAction(
  ClaimActionTypes.PayAndClaim,
  props<{
    payload: PayloadBooking;
  }>()
);

export const EngineerBooking = createAction(ClaimActionTypes.EngineerBooking);

export const DropOffBooking = createAction(
  ClaimActionTypes.DropOffBooking,
  props<{
    payload: PayloadBooking;
  }>()
);

export const GetBookingFollowingWeekSlots = createAction(
  ClaimActionTypes.GetBookingFollowingWeekSlots,
  props<{
    payload: Api.PutServiceOptionResponse;
  }>()
);

export const Error = createAction(
  ClaimActionTypes.Error,
  props<{
    payload: any;
  }>()
);

export const GetServiceOptions = createAction(
  ClaimActionTypes.GetServiceOptions,
  props<{
    payload: Api.GetServiceOptionRequest;
  }>()
);

export interface CustomerDetailsChanged {
  personalDetailsChanged: boolean;
  addressDetailsChanged: boolean;
}

export const UpdateUserPersonalDetails = createAction(
  ClaimActionTypes.UpdateUserPersonalDetails
);

export const UpdateUserPersonalDetailsSuccess = createAction(
  ClaimActionTypes.UpdateUserPersonalDetailsSuccess,
  props<{
    payload: ContactDetailsUpdatedStatus;
  }>()
);

export const UpdateUserPersonalDetailsFailed = createAction(
  ClaimActionTypes.UpdateUserPersonalDetailsFailed,
  props<{
    payload: ContactDetailsUpdatedStatus;
  }>()
);

export const UpdateUserAddressDetails = createAction(
  ClaimActionTypes.UpdateUserAddressDetails
);

export const UpdateUserAddressDetailsSuccess = createAction(
  ClaimActionTypes.UpdateUserAddressDetailsSuccess,
  props<{
    payload: ContactDetailsUpdatedStatus;
  }>()
);

export const UpdateUserAddressDetailsFailed = createAction(
  ClaimActionTypes.UpdateUserAddressDetailsFailed,
  props<{
    payload: ContactDetailsUpdatedStatus;
  }>()
);

export const LoadServiceOptions = createAction(
  ClaimActionTypes.LoadServiceOptions,
  props<{
    payload: Api.GetServiceOptionResponse;
  }>()
);

export const SelectServiceOption = createAction(
  ClaimActionTypes.SelectServiceOption,
  props<{
    payload: ServiceOption;
  }>()
);

export const PutServiceOptionSuccess = createAction(
  ClaimActionTypes.PutServiceOptionSuccess,
  props<{
    payload: {
      response: Api.PutServiceOptionResponse;
      isServiceProviderCallback?: boolean;
      complete?: boolean;
    };
  }>()
);

export const SubmitSelectedServiceOption = createAction(
  ClaimActionTypes.SubmitSelectedServiceOption,
  props<{
    payload: {
      serviceOptionID?: string;
    };
  }>()
);

export const DropOffPutServiceOption = createAction(
  ClaimActionTypes.DropOffPutServiceOption,
  props<{
    payload: {
      response: Api.PutServiceOptionResponse;
      dropOffOrigin: Origin;
    };
  }>()
);

export const DropOffPutServiceOptionSuccess = createAction(
  ClaimActionTypes.DropOffPutServiceOptionSuccess,
  props<{
    payload: {
      response: Api.PutServiceOptionResponse;
      dropOffOrigin: Origin;
    };
  }>()
);

export interface PutRepairData {
  request: Api.PutRepairDataRequest;
  bookingSelection?: BookingSelection;
  customerDetails: Api.GetServiceOptionRequest | undefined;
  isRebook?: boolean;
}

export interface PutNewAppointmentType {
  claimID: number;
  appointmentSlotIdentifier: string;
  bookingSelection: BookingSelection;
}

export const PutRepairData = createAction(
  ClaimActionTypes.PutRepairData,
  props<{
    payload: PutRepairData;
  }>()
);

export const PutNewAppointmentSuccess = createAction(
  ClaimActionTypes.PutNewAppointmentSuccess,
  props<{
    payload: {
      response: Api.PutRepairDataResponse | null;
      errorResponse?: boolean;
    };
  }>()
);

export const PutNewAppointment = createAction(
  ClaimActionTypes.PutNewAppointment,
  props<{
    payload: PutNewAppointmentType;
  }>()
);

export const PutRepairDataSuccess = createAction(
  ClaimActionTypes.PutRepairDataSuccess,
  props<{
    payload: {
      response: Api.PutRepairDataResponse | null;
      errorResponse?: boolean;
    };
  }>()
);

export const BookingSlotUnavailable = createAction(
  ClaimActionTypes.BookingSlotUnavailable
);

export const GetBookingExtraSlotsFailed = createAction(
  ClaimActionTypes.GetBookingExtraSlotsFailed,
  props<{
    payload: {
      ManualReferralAllowed?: boolean;
      ManualReferralServiceOptionID?: number;
    };
  }>()
);

export const ClaimAccepted = createAction(ClaimActionTypes.ClaimAccepted);

export const ClaimRejected = createAction(
  ClaimActionTypes.ClaimRejected,
  props<{
    payload: { rejectedMessage: string };
  }>()
);

export const UpdateClaim = createAction(
  ClaimActionTypes.UpdateClaim,
  props<{
    payload: UpdateClaimPayload;
  }>()
);

export const UpdateClaimSuccess = createAction(
  ClaimActionTypes.UpdateClaimSuccess,
  props<{
    payload: {
      getData: Api.GetMandatoryDataResponse;
      update: UpdateClaimPayload;
      route: boolean;
    };
  }>()
);

export const UpdateManufacturer = createAction(
  ClaimActionTypes.UpdateManufacturer,
  props<{
    payload: string;
  }>()
);

export const GetProductAttributesSuccess = createAction(
  ClaimActionTypes.GetProductAttributesSuccess
);

export const SetManufacturers = createAction(
  ClaimActionTypes.SetManufacturers,
  props<{
    payload: string[] | undefined;
  }>()
);

export const SetProductTypes = createAction(
  ClaimActionTypes.SetProductTypes,
  props<{
    payload: string[] | undefined;
  }>()
);

export interface GetSuperCategorySuccess {
  isGasAppliance: boolean;
  applianceSuperCategory: string;
}

export const GetSuperCategorySuccess = createAction(
  ClaimActionTypes.GetSuperCategorySuccess,
  props<{
    payload: GetSuperCategorySuccess;
  }>()
);

export const GetSuperCategorySuccessV2 = createAction(
  ClaimActionTypes.GetSuperCategorySuccessV2,
  props<{
    payload: GetSuperCategorySuccess;
  }>()
);

export const StartTransaction = createAction(ClaimActionTypes.StartTransaction);

export const SeenGasSafetyNotice = createAction(
  ClaimActionTypes.SeenGasSafetyNotice
);

export const RouteClaim = createAction(
  ClaimActionTypes.RouteClaim,
  props<{
    payload: RouteClaimParams;
  }>()
);

export const RouteSmartFix = createAction(ClaimActionTypes.RouteSmartFix);

export interface SetOneProductType {
  productType: string;
  startTransaction: boolean;
}

export const SetOneProductType = createAction(
  ClaimActionTypes.SetOneProductType,
  props<{
    payload: SetOneProductType;
  }>()
);

export const AutoSetProductType = createAction(
  ClaimActionTypes.AutoSetProductType,
  props<{
    payload: string;
  }>()
);

export const GetBookingExtraSlots = createAction(
  ClaimActionTypes.GetBookingExtraSlots,
  props<{
    payload?: {
      attemptCount: number | null;
    };
  }>()
);

export const SilentGetBookingExtraSlots = createAction(
  ClaimActionTypes.SilentGetBookingExtraSlots,
  props<{
    payload?: {
      attemptCount: number | null;
    };
  }>()
);

export const CancelSilentGetBookingExtraSlots = createAction(
  ClaimActionTypes.CancelSilentGetBookingExtraSlots
);

export const ClearBookingErrors = createAction(
  ClaimActionTypes.ClearBookingErrors
);

export const GetBookingExtraSlotsSuccess = createAction(
  ClaimActionTypes.GetBookingExtraSlotsSuccess,
  props<{
    payload: Api.PutServiceOptionResponse;
  }>()
);

export const RetryPutServiceOption = createAction(
  ClaimActionTypes.RetryPutServiceOption,
  props<{
    payload: {
      attemptCount: number | null;
    };
  }>()
);

export const RouteForward = createAction(ClaimActionTypes.RouteForward);

export const Loading = createAction(
  ClaimActionTypes.Loading,
  props<{
    payload?: string | null;
  }>()
);

export const Loaded = createAction(ClaimActionTypes.Loaded);

export const ResetBooking = createAction(ClaimActionTypes.ResetBooking);

export const LoadCardPaymentRedirect = createAction(
  ClaimActionTypes.LoadCardPaymentRedirect,
  props<{
    payload: {
      request: CardPaymentRedirectRequest;
      selectedServiceOption: ServiceOption;
    };
  }>()
);

export const LoadCardPaymentRedirectSuccess = createAction(
  ClaimActionTypes.LoadCardPaymentRedirectSuccess,
  props<{ payload: CardPaymentRedirectResponse }>()
);

export const LoadCardPaymentRedirectFail = createAction(
  ClaimActionTypes.LoadCardPaymentRedirectFail,
  props<{ payload: ActionError }>()
);

export const CompletePayment = createAction(
  ClaimActionTypes.CompletePayment,
  props<{ payload: Api.PaymentRequest }>()
);

export const CompletePaymentSuccess = createAction(
  ClaimActionTypes.CompletePaymentSuccess
);

export const SetPassword = createAction(
  ClaimActionTypes.SetPassword,
  props<{
    payload?: {
      password: string;
    };
  }>()
);

export const SetPasswordSuccess = createAction(
  ClaimActionTypes.SetPasswordSuccess
);

export const UpdateStartAvailabilityDate = createAction(
  ClaimActionTypes.UpdateStartAvailabilityDate,
  props<{ newStartDate: string; maximumDays: number }>()
);

export const SetPasswordFail = createAction(ClaimActionTypes.SetPasswordFail);

// Union the valid types
export type ClaimActions =
  | typeof ClearCurrentClaim
  | typeof InitializeCurrentClaim
  | typeof InitializeCurrentClaimV2
  | typeof RebookClaimDataSuccess
  | typeof LoadClaimDataSuccess
  | typeof UpdateManufacturer
  | typeof UpdateClaim
  | typeof UpdateClaimSuccess
  | typeof AutoCreateClaim
  | typeof CreateClaim
  | typeof CreateClaimSuccess
  | typeof GetQuestion
  | typeof GetQuestionSuccess
  | typeof PutAnswer
  | typeof GetServiceOptions
  | typeof LoadServiceOptions
  | typeof SelectServiceOption
  | typeof PutServiceOptionSuccess
  | typeof GetBookingExtraSlots
  | typeof GetBookingExtraSlotsSuccess
  | typeof RetryPutServiceOption
  | typeof ManualBooking
  | typeof CollectionBooking
  | typeof EngineerBooking
  | typeof PutRepairData
  | typeof PutRepairDataSuccess
  | typeof BookingSlotUnavailable
  | typeof GetBookingExtraSlotsFailed
  | typeof ClearBookingErrors
  | typeof ClaimAccepted
  | typeof ClaimRejected
  | typeof GetReflectDataSuccess
  | typeof GetProductAttributesSuccess
  | typeof SeenGasSafetyNotice
  | typeof SetManufacturers
  | typeof SetProductTypes
  | typeof SetOneProductType
  | typeof GetSuperCategorySuccess
  | typeof StartTransaction
  | typeof ClaimRebook
  | typeof GetClaimQA
  | typeof RouteClaim
  | typeof RouteForward
  | typeof Error
  | typeof Loading
  | typeof Loaded
  | typeof ResetBooking
  | typeof CreateCallbackClaim
  | typeof QACallbackClaim
  | typeof ContactDetailsCallbackClaim
  | typeof GetCallbackWidget
  | typeof CompleteCallbackBooking
  | typeof CompleteCallbackBookingSuccess
  | typeof LoadCardPaymentRedirect
  | typeof LoadCardPaymentRedirectSuccess
  | typeof LoadCardPaymentRedirectFail
  | typeof CompletePayment
  | typeof CompletePaymentSuccess
  | typeof SetPassword
  | typeof SetPasswordSuccess
  | typeof SetPasswordFail
  | typeof NewLoadClaimDataSuccess$
  | typeof NewRouteClaim$
  | typeof SubmitSelectedServiceOption;
