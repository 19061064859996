import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromChat from './chat.selectors';
import * as chatActions from './chat.actions';
import {
  ChatComponent,
  HistoryType,
  productTypeEnums,
} from '../_shared/chat-types';
import { Update } from '@ngrx/entity';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatFacadeService {
  currentComponentChildren$: Observable<ChatComponent[] | null | undefined> =
    this._store.pipe(select(fromChat.selectChatCurrentComponentChildren));

  currentComponent$: Observable<ChatComponent | null | undefined> =
    this._store.pipe(select(fromChat.selectChatCurrentComponent));

  history$ = this._store.pipe(select(fromChat.getChatHistory));

  constructor(private _store: Store) {}

  updateHistory(payload: HistoryType) {
    this._store.dispatch(chatActions.UpdateHistory({ payload }));
  }

  loadDecisionTree(payload: productTypeEnums) {
    this._store.dispatch(chatActions.LoadDecisionTree({ payload }));
  }

  updateRootComponent(payload: ChatComponent, previousId: number) {
    const update: Update<ChatComponent> = {
      id: previousId,
      changes: payload,
    };
    this._store.dispatch(chatActions.UpdateRootComponent({ update }));
  }

  loadNextComponent(payload: ChatComponent) {
    this._store.dispatch(chatActions.LoadNextComponent({ payload }));
  }
}
