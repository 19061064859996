import { Component, EventEmitter, Output } from '@angular/core';
import { slideInOut } from '../../animations/animations';

@Component({
  selector: 'dg-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: ['./notification-banner.component.scss'],
  animations: [slideInOut],
})
export class NotificationBannerComponent {
  @Output() clickClose = new EventEmitter<boolean>();
  hide = false;

  close() {
    this.clickClose.emit(true);
    this.hide = !this.hide;
  }
}
