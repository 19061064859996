import { takeUntil } from 'rxjs/operators';
import {
  BaseComponent,
  Claim,
  PaymentType,
  ServiceOption,
  showHide,
  slideInOut,
} from '@domgen/dgx-components';
import { FormDataSchema } from '@domgen/dgx-components';
import { UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { RepairOptionsComponentService } from '@domgen/data-access-repair-details';

export interface ServiceOptionRadio extends ServiceOption {
  label?: { title: string; description: string };
}
@Component({
  selector: 'claims-repair-options',
  templateUrl: './repair-options.component.html',
  styleUrls: ['./repair-options.component.scss'],
  animations: [slideInOut, showHide],
})
export class RepairOptionsComponent extends BaseComponent implements OnInit {
  constructor(private repairOptionsService: RepairOptionsComponentService) {
    super();
  }
  @Output() selected: EventEmitter<ServiceOption> = new EventEmitter();
  @Output() submitted = new EventEmitter();
  @Input() repairOptions: ServiceOptionRadio[] = [];
  @Input() excess: { required: boolean; cost: string; type: PaymentType };
  @Input() claim: Claim;

  isEngineerVisitOnly = false;
  repairOptionData?: FormDataSchema['meta'];
  repairOptionsForm?: UntypedFormGroup;
  showValidation = false;
  hasExcess = false;
  paid = false;

  ngOnInit() {
    ({
      repairOptions: this.repairOptions,
      repairOptionData: this.repairOptionData,
      repairOptionsForm: this.repairOptionsForm,
    } = this.repairOptionsService.modifyRepairOptions(
      this.claim,
      this.repairOptions,
      'flex radio--button radio--with-icon',
      'xs-12 sm-4 mb-6',
      'typog-body-bold',
      'svg-new'
    ));

    this.repairOptionsForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((option) => {
        // Split is to break up value of type_id used to select option with same service types
        const serviceOption = this.repairOptions.filter(
          (o) =>
            option.serviceOption.split('_')[0] === o.ServiceOptionType &&
            option.serviceOption.split('_')[1] === o.ServiceOptionID
        )[0];

        this.selected.emit(serviceOption);
      });

    const selected = this.repairOptions?.find((s) => s.CurrentlySelected);

    const getOptionToSet = (
      option: ServiceOptionRadio = this.repairOptions[0]
    ) => {
      if (!option) return undefined;
      return `${option.ServiceOptionType}_${option.ServiceOptionID}`;
    };

    if (this.repairOptions.length === 1 || selected) {
      this.repairOptionsForm.patchValue({
        serviceOption: getOptionToSet(selected),
      });
    }

    this.isEngineerVisitOnly =
      this.repairOptionsForm.getRawValue().serviceOption === 'Home Repair';
  }

  submit() {
    if (this.repairOptionsForm.valid) {
      this.submitted.emit();
    } else {
      this.showValidation = true;
    }
  }
}
