<div class="dg-notification-banner" [@slideInOut] *ngIf="!hide">
  <div class="container">
    <div class="dg-notification-banner__container">
      <div class="dg-notification-banner__alert">
        <svg class="svg-icon">
          <use xlink:href="#icon-lampbulb"></use>
        </svg>
      </div>
      <div class="dg-notification-banner__content">
        <ng-content></ng-content>
      </div>

      <div class="dg-notification-banner__close">
        <svg class="svg-icon" (click)="close()">
          <use xlink:href="#icon-close"></use>
        </svg>
      </div>
    </div>
  </div>
</div>
