<div class="flex justify--center">
  <div class="xs-10 text--center">
    <h3 class="margin--none">
      {{ slotType }}
    </h3>
    <p class="margin--none">
      <ng-container *ngIf="serviceOptionType === ServiceOptionTypes.Home">
        The engineer will contact you before your repair to confirm a timeslot.
      </ng-container>

      <ng-container *ngIf="serviceOptionType === ServiceOptionTypes.Collection">
        This usually means that your item will be collected between 8am and 6pm.
        You’ll be told of the time slot beforehand.
      </ng-container>
    </p>
  </div>
</div>
