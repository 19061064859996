<div *ngIf="vm$ | async as vm">
  <div *ngIf="!vm.pageLoading" [@fadeInAnimation]>
    <dg-sub-header
      *ngIf="product$ | async as product"
      [product]="product"
      data-testid="action-header"
    ></dg-sub-header>

    <div class="help--page">
      Taking your broken item to a nearby drop-off point is an alternative way
      for us to receive and repair it. Browse the list and choose your preferred
      drop-off point.
    </div>

    <claims-dropoff-container *ngIf="vm.dropOff"></claims-dropoff-container>

    <div class="row--padding row--spacer-top-medium">
      <div class="container">
        <div class="flex justify--center">
          <div class="md-8">
            <dg-contact-details
              *ngIf="showDeliveryAddress$ | async"
              [showCancelCTA]="false"
              heading="Deliver back to this address:"
              placeholder="Address"
              searchLabel="Start typing your address or postcode"
              [contactDetails]="
                (collectionData$ | async)?.DELIVERY_RETURN_ADDRESS
              "
              [claim]="vm.claim"
              (editSave)="isSaved($event)"
              (submitted)="
                saveAddress(
                  $event,
                  ServiceCollectionData.DELIVERY_RETURN_ADDRESS
                )
              "
            >
              <div class="row--spacer-top-medium message--always-visible">
                <p>{{ addressNoticeMessage }}</p>
              </div>
            </dg-contact-details>
          </div>
        </div>
      </div>
    </div>
    <div
      class="grid-row row--padding row--spacer-bottom-large flex justify--center align--center"
      *ngIf="vm.selected !== null"
      [@fadeInAnimation]
    >
      <dgx-components-button
        *ngIf="isReturnAddressValid"
        variant="primary"
        iconName="arrow-right"
        [attr.data-automation]="'bookEngineer'"
        (click)="onSubmit()"
        data-testid="confirm-and-book-btn"
        >{{ submitCtaText }}</dgx-components-button
      >
    </div>
  </div>
</div>
