<form *ngIf="pageItems && form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div
    [ngClass]="{
      'form-group': true,
      'form-group--error': useValidation && form.invalid
    }"
  >
    <h1 class="heading-xl">
      <span *ngIf="pageItems.caption" [class]="pageItems.caption.classes">{{
        pageItems.caption.text
      }}</span>
      {{ pageItems.header }}
    </h1>
    <div
      class="form-group legacy-form-builder"
      *ngFor="let group of pageItems.groups"
    >
      <dg-fb-fieldset
        *ngIf="$any(group).fieldset"
        [classes]="'fieldset'"
        [group]="form"
        [data]="$any(group).fieldset"
        [validate]="useValidation"
      >
      </dg-fb-fieldset>
      <dg-fb-form-elements
        [group]="form"
        [validate]="useValidation"
        [data]="group"
      ></dg-fb-form-elements>
    </div>
  </div>
</form>
