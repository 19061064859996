import { mockRuntimeFeatures } from '@domgen/dgx-fe-config';
import { AppRuntimeFeatures } from '../../models/runtime/app-runtime-features.interface';

export const mockAppRuntimeFeatures: AppRuntimeFeatures = {
  ...mockRuntimeFeatures,
  newBookingFlow: false,
  freeTextSearch: false,
  newStyleRepairOptions: false,
  manageMyRepair: false,
  engineerTrackingBanner: false,
  boilerLexChat: false,
};
