export interface BrandImages {
  [key: string]: BrandImage;
}

export interface BrandImage {
  appliancePlateImage: string;
  brands: string[];
  modelImages: {
    applianceSuperCategory: string;
    defaultImage: string;
    mappings: {
      applianceCode: string;
      image: string;
    }[];
  }[];
  title: string;
  cta: string;
  defaultModelImage?: string;
  frontCardText?: string;
  noModelHeaderSubtitle?: string;
  helplineNumber: string;
  noModelHeaderText: string;
  dontWorryText: string;
  helplineText: string;
  isDefault?: boolean;
}
