import { Component, Input } from '@angular/core';
import { ChatBotEventType } from '@domgen/smart-fix/data-access-smart-fix';

export type Disclaimer = {
  header: string;
  extraInfo: Array<string>;
};

@Component({
  selector: 'smart-fix-chatbot-disclaimer',
  templateUrl: './chatbot-disclaimer.component.html',
  styleUrls: ['./chatbot-disclaimer.component.scss'],
})
export class ChatbotDisclaimerComponent {
  @Input() event!: ChatBotEventType;
  @Input() closed = false;
}
