import { Injectable } from '@angular/core';
import { differenceInMilliseconds } from 'date-fns';
import jwtDecode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';

export interface User {
  sub: string; // User id
  'custom:user_state': string; // is completed, re password etc
  iss: string; // issuer (cognito user pool)
  'cognito:username': string; // email
  aud: string; // api to target with IGNORE
  event_id: string; // IGNORE internal
  token_use: string; // identifies as type of access token
  auth_time: number; // when loggin happened from server time
  'custom:digital_id': string; // digital ID
  name: string; // user name
  exp: number; // cookie expeiry time unix seconds
  iat: number; // issued cookie from this time
  family_name: string; // user surname
  email: string; // email
}

export interface Cookies {
  AWS_ACC: string;
  AWS_IDT: string;
}

@Injectable({
  providedIn: 'root',
})
export class CookieTokenService {
  constructor(private cookieService: CookieService) {}

  getAuthCookies(): Cookies {
    const cookie_AWS_IDT: string = this.cookieService.get('AWS_IDT');
    const cookie_AWS_ACC: string = this.cookieService.get('AWS_ACC');
    return { AWS_ACC: cookie_AWS_ACC, AWS_IDT: cookie_AWS_IDT };
  }

  getCustomerID(): string {
    const cookie_AWS_IDT: any = this.cookieService.get('AWS_IDT');
    if (cookie_AWS_IDT) {
      try {
        const decodedUser: User = jwtDecode(cookie_AWS_IDT);
        if (decodedUser) {
          return decodedUser.sub;
        }
      } catch {
        return 'jwt decode error';
      }
    }
    return 'cookie not found';
  }

  getSessionExpireTime() {
    const cookie_AWS_IDT: any = this.cookieService.get('AWS_IDT');
    if (cookie_AWS_IDT) {
      try {
        const decodedUser: User = jwtDecode(cookie_AWS_IDT);
        if (decodedUser) {
          const expireDate = new Date(decodedUser.exp * 1000);
          const remainingTime = differenceInMilliseconds(
            expireDate,
            new Date()
          );

          return Math.floor(remainingTime);
        }
      } catch {
        return null;
      }
    }

    return null;
  }
}
