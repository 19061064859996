<div class="map-tabs">
  <div class="map-tabs__nav text--center">
    <button
      *ngFor="let tab of tabs"
      class="map-tab"
      [class.map-tab--active]="tab.active"
      (click)="selectTab(tab); $event.stopPropagation()"
    >
      <h4 class="typog-body">{{ tab.title }}</h4>
    </button>
  </div>
  <div class="map-tab__container">
    <ng-content></ng-content>
  </div>
</div>
