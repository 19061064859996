import { Observable, of } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { ChatOptionCompositeService } from './chat-option-composite.service';
import { ChatComponent, productTypeEnums } from '../_shared/chat-types';
import { TUMBLE_DRYER } from '../assets/tumble-dryer.token';
import { WASHER_DRYER } from '../assets/washer-dryer.token';
import { WASHING_MACHINE } from '../assets/washing-machine.token';
import { Api } from '@domgen/dgx-components';
import { ApiService } from '@domgen/data-access-claims';

@Injectable()
export class ChatService {
  constructor(
    private chatOptionCompositeService: ChatOptionCompositeService,
    private apiService: ApiService,
    @Inject(TUMBLE_DRYER) private tumbleDryer: ChatComponent,
    @Inject(WASHER_DRYER) private washingDryer: ChatComponent,
    @Inject(WASHING_MACHINE) private washingMachine: ChatComponent
  ) {}
  loadJSONDecisionTree(payload: productTypeEnums): Observable<ChatComponent> {
    return of(this.createChatCompositionTree(payload));
  }

  filterBasedOnWhiteGood(payload: productTypeEnums): ChatComponent {
    switch (payload) {
      case productTypeEnums.TumbleDryer:
        return this.tumbleDryer;
      case productTypeEnums.WasherDryer:
        return this.washingDryer;
      case productTypeEnums.WashingMachine:
        return this.washingMachine;
    }
    payload satisfies never;
  }

  createChatCompositionTree(payload: productTypeEnums): ChatComponent {
    return this.chatOptionCompositeService.createChatOptionComposite(
      this.filterBasedOnWhiteGood(payload)
    );
  }

  startSession(text: string): Observable<Api.LexStartBoilerSession> {
    return this.apiService.startBoilerSession(text);
  }

  sendLexResponse(text: string, sessionId: string) {
    return this.apiService.sendBoilerResponse(sessionId, text);
  }
}
