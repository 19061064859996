<div class="error-container">
  <div class="error-box">
    <dgx-components-icon class="disappointment-icon" icon="face-frown-slight" />
    <span class="title">Something went wrong</span>
    <p class="description">
      Sorry, we’re having some technical issues. Please continue booking your
      repair.
    </p>
    <dgx-components-button
      class="button"
      (click)="fireContinueToRepairBooking(true)"
    >
      Continue to repair booking
    </dgx-components-button>
  </div>
</div>
