import { RequestAction, RequestSource } from '@domgen/dgx-fe-common';
import { mockBuildConfig } from '@domgen/dgx-fe-config';
import { mockLightstepConfig } from '@domgen/dgx-fe-lightstep';
import { AppBuildConfig } from '../../models/build/app-build-config.interface';
import { mockAppBuildFeatures } from './app-build-features.mock';

export const mockAppBuildConfig: AppBuildConfig = {
  ...mockBuildConfig,
  buildFeatures: mockAppBuildFeatures,
  lightstep: mockLightstepConfig,
  requestAction: RequestAction.OnlineRegistration,
  whiteLabelClient: RequestSource.DomesticAndGeneralUK,
  POSTHOG_KEY: '',
  POSTHOG_HOST: '',
  POSTHOG_ACCOUNT: '',
  POSTHOG_URL: '',
};
