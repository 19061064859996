import { Component, Input } from '@angular/core';
import { PaymentType, slideInOut } from '@domgen/dgx-components';

export enum ToggleState {
  open = 'open',
  closed = 'closed',
}
@Component({
  selector: 'claims-excess-information',
  templateUrl: './excess-information.component.html',
  styleUrls: ['./excess-information.component.scss'],
  animations: [slideInOut],
})
export class ExcessInformationComponent {
  ToggleState = ToggleState;
  @Input() excess: {
    required: boolean;
    cost: string;
    type: PaymentType;
  };
  @Input() newStyleRepairOptions = false;
  PaymentType = PaymentType;
  showMore = false;

  toggle(e) {
    if (this.showMore) {
      this.showMore = e === ToggleState.closed;
    }
  }

  showMoreToggle(e) {
    e.preventDefault();
    this.showMore = true;
  }
}
