import { Component, ElementRef, ViewChild } from '@angular/core';
import { ServiceOption } from '@domgen/dgx-components';
import {
  fadeInAnimation,
  slideInOut,
  PageSectionId,
} from '@domgen/dgx-components';
import { RepairDetailsComponentService } from '../services/repair-details-component.service';

/**
 * Section to display decision tree:
 *
 * Is claim created? (no) (select claim type)
 * = !claim?.created
 *
 * Is claim created not accepted? (yes) (question and answers)
 * = claim?.created && !claim?.accepted
 *
 * Is claim accepted? (contact details)
 * = claim?.accepted
 */

@Component({
  selector: 'claims-repair-details',
  templateUrl: './repair-details.component.html',
  styleUrls: ['./repair-details.component.scss'],
  animations: [fadeInAnimation, slideInOut],
})
export class RepairDetailsComponent {
  @ViewChild('completeScroll') completeScroll?: ElementRef;

  contactFormValid = true;
  repairOptionsValid = false;
  readonly pageSection = PageSectionId; // expose enum to template
  selectedServiceOption?: ServiceOption;
  repairOptions = this.service.repairOptions$;
  bannerText = this.service.bannerText$;

  vm$ = this.service.vm$;

  constructor(public service: RepairDetailsComponentService) {}

  onSelectRepairOption(option: ServiceOption) {
    if (option !== null) {
      this.repairOptionsValid = true;
      this.selectedServiceOption = option;
      this.service.scrollToContinue(this.completeScroll as ElementRef);
    }
  }

  onCompleteClaimDetails() {
    this.service.onCompleteClaimDetails(
      this.selectedServiceOption as ServiceOption
    );
  }
}
