import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { BaseComponent } from '@domgen/dgx-components';
import { CalendarDay } from '@domgen/dgx-components';
import { Observable, timer } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
import { QAFormService, QuestionTypes } from './qa-form.component.service';

@Component({
  selector: 'claims-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss'],
  providers: [QAFormService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionFormComponent
  extends BaseComponent
  implements AfterViewInit
{
  calendarDays: CalendarDay[] = this.qaService.getCalendar();
  showValidation = false;
  open = false;
  date: Date;

  public claim$ = this.qaService.claim$;
  public questions$ = this.qaService.questions$;
  public form$ = this.qaService.form$;
  public showValidation$ = this.qaService
    .showValidation$ as Observable<boolean>;
  public QuestionTypes = QuestionTypes;

  @ViewChildren('controls') controls?: QueryList<ElementRef>;

  constructor(public qaService: QAFormService, private cdr: ChangeDetectorRef) {
    super();
  }

  onOpen() {
    this.open = true;
  }

  ngAfterViewInit() {
    this.questions$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => timer(0)) // wait for dom ready
      )
      .subscribe(() => {
        if (this.controls) {
          const c = this.controls.toArray();
          const q = c[c.length - 1];
          if (q) {
            q.nativeElement.scrollIntoView({ behavior: 'smooth' });
          }
        }
      });

    this.qaService.triggerPageView();
  }
}
