export enum PageSectionId {
  ClaimTypeAndFaultTypeQuestions = 'Claim Type and Fault Type Questions',
  FaultDetailsQandA = 'Fault Details Q&A',
  ContactDetails = 'Contact Details',
  RepairOption = 'Your repair',
  CallbackBookingWidget = 'Callback Booking',
}

export interface PageSection {
  id: PageSectionId;
  title: string;
  completed: boolean;
  active: boolean;
}
