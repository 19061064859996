import { LightstepConfig } from '@domgen/dgx-fe-lightstep';
import { AppConfig } from '../../models/config/app-config.type';

export const lightstepConfigFactory = (
  config: AppConfig
): Partial<LightstepConfig> => {
  return {
    ...config.lightstep,
    serviceName: 'dg-claims-claims',
    serviceVersion: '1.0.0',
    traceHeaderWhitelist: [],
  };
};
