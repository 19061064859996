import { DropoffStateService } from '../../../book-dropoff/book-dropoff-data-access/book-dropoff-state.service';
import { ChangeDetectionStrategy, Component } from '@angular/core';

export interface OpeningHoursSummary {
  day: string;
  value: string;
  group?: number;
  start?: number;
  end?: number;
}

@Component({
  selector: 'claims-dropoff-list',
  templateUrl: './dropoff-list.component.html',
  styleUrls: ['./dropoff-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropoffListComponent {
  constructor(private _state: DropoffStateService) {}
  vm$ = this._state.vm$;

  setHighlighted(index: number) {
    this._state.setHighlighted(index);
  }
}
