import { Injectable } from '@angular/core';
import {
  Api,
  CalendarDay,
  DateInputDefinition,
  FormControlsDefinition,
  FormsService,
  FormValues,
  RadioGroupDefinition,
  RadiosDefinition,
  TextareaDefinition,
} from '@domgen/dgx-components';
import { eachDayOfInterval, subDays } from 'date-fns';
import { findIndex, assign } from 'lodash-es';
import { ClaimQuestion } from '@domgen/dgx-components';
import { UntypedFormGroup } from '@angular/forms';
import { isEqual } from 'date-fns';
import { QuestionTypes } from '@domgen/dgx-components';

@Injectable({
  providedIn: 'root',
})
export class QAFormHelper {
  constructor(private formsService: FormsService) {}

  createForm(questions: FormControlsDefinition[], values: FormValues) {
    return new UntypedFormGroup(
      this.formsService.defineformControls(questions, values)
    );
  }

  getAnsweredValues(questions: ClaimQuestion[]): FormValues {
    return Object.assign(
      {},
      ...questions.reduce((init: FormValues[], current) => {
        if (current.answer !== null) {
          if (
            current.question.AnswerType === 'LIST' &&
            current.answer.AnswerValue
          ) {
            const answer = current.question.AnswerData?.find(
              (a) => a.AnswerID === current.answer?.AnswerID
            );

            init.push({
              [`${current.question.QuestionID}`]: answer?.AnswerID,
            });
          }

          if (current.question.AnswerType === QuestionTypes.RESPONSE_FIELD) {
            init.push({
              [`${current.question.QuestionID}`]: current.answer.AnswerValue,
            });
          }

          if (current.question.AnswerType === QuestionTypes.RADIO_BUTTONS) {
            init.push({
              [`${current.question.QuestionID}`]: current.answer.AnswerID,
            });
          }
        }

        return init;
      }, [])
    ) as FormValues;
  }

  generateQuestionFormData(
    questions: ClaimQuestion[]
  ): FormControlsDefinition[] {
    return questions.map((question: ClaimQuestion) => {
      let obj;

      switch (question.question.AnswerType) {
        case QuestionTypes.DATE_PICKER:
          obj = this.getDatePickerInputData(question);
          break;
        case QuestionTypes.DATE:
          obj = this.getDatePickerInputData(question);
          break;
        case QuestionTypes.RADIO_BUTTONS:
          obj = this.getRadioButtonInputData(question);
          break;
        case QuestionTypes.DROPDOWN:
          obj = this.getRadioDropdownButtonInputData(question);
          break;
        case QuestionTypes.RESPONSE_FIELD:
          obj = this.getResponseFieldData(question);
          break;
        case QuestionTypes.LIST:
          obj = this.getResponseListData(question);
          break;
      }

      return obj;
    }) as FormControlsDefinition[];
  }

  getResponseListData(question: ClaimQuestion): RadiosDefinition {
    return {
      radios: {
        heading: question.question.QuestionLabel,
        subheading: question.question.QuestionHelp,
        type: 'LIST',
        control: `${question.question.QuestionID}`,
        classes: 'radio--with-text-box row d-flex justify-content-center',
        validators: [
          {
            type: 'required',
            message: 'Please select option',
          },
        ],
        elementsPerPage: 6,
        radioGroup: question.question.AnswerData?.map((t) => {
          return {
            value: t.AnswerID,
            text: t.AnswerLabel,
            classes: 'xs-6 md-3',
            hiddenAccessibilityText: '',
          };
        }) as RadioGroupDefinition[],
      },
    };
  }

  getResponseFieldData(question: ClaimQuestion): TextareaDefinition {
    return {
      textarea: {
        type: QuestionTypes.RESPONSE_FIELD,
        label: {
          text: '',
          classes: '',
        },
        heading: question.question.QuestionLabel,
        subheading: question.question.QuestionHelp,
        validators: [
          {
            type: 'required',
            message: 'Please provide as much information as possible',
          },
        ],
        chrLimit: Number(
          (question?.question as Api.GetQuestionResponse)?.AnswerData[0]
            ?.AnswerMaximumCharacters
        ),
        control: `${question.question.QuestionID}`,
        placeholder: '',
        value: '',
        classes: 'form-control',
      },
    };
  }

  getDatePickerInputData(question: ClaimQuestion): DateInputDefinition {
    const obj: DateInputDefinition = {
      date: {
        heading: question.question.QuestionLabel,
        subheading: question.question.QuestionHelp,
        questionID: question.question.QuestionID,
        control: `${question.question.QuestionID}`,
        calendarDays: this.getCalendar(),
        answer: null,
        answerIndex: null,
      },
    };

    assign(obj.date, {
      answer: question.answer === null ? null : question.answer,
      answerIndex:
        question.answer === null
          ? null
          : this.getIndex(
              question.answer.AnswerValue,
              obj?.date?.calendarDays as CalendarDay[]
            ),
    });

    return obj;
  }

  getRadioButtonInputData(question: ClaimQuestion): RadiosDefinition {
    return {
      radios: {
        type: QuestionTypes.RADIO_BUTTONS,
        heading: question.question.QuestionLabel,
        subheading: question.question.QuestionHelp,
        control: `${question.question.QuestionID}`,
        elementsPerPage: 6,
        classes: 'radio--with-text-box row d-flex justify-content-center',
        validators: [
          {
            type: 'required',
            message: 'Please select option',
          },
        ],
        radioGroup: question.question.AnswerData.map((t) => {
          return {
            value: t.AnswerID,
            text: t.AnswerLabel,
            classes: 'xs-6 md-3',
            hiddenAccessibilityText: '',
          };
        }),
      },
    };
  }

  getRadioDropdownButtonInputData(question: ClaimQuestion): RadiosDefinition {
    return {
      radios: {
        type: QuestionTypes.RADIO_BUTTONS,
        heading: question.question.QuestionLabel,
        subheading: question.question.QuestionHelp,
        control: `${question.question.QuestionID}`,
        elementsPerPage: 6,
        classes: 'radio--with-text-box row d-flex justify-content-center',
        validators: [
          {
            type: 'required',
            message: 'Please select option',
          },
        ],
        radioGroup: question.question.AnswerValues?.map((value) => {
          return {
            value: value,
            text: value,
            classes: 'xs-6 md-3',
            hiddenAccessibilityText: '',
          };
        }) as RadioGroupDefinition[],
      },
    };
  }

  getIndex(date: string, calendar: CalendarDay[]): number {
    return findIndex(calendar, (c) => {
      return isEqual(new Date(c.date), new Date(date));
    });
  }

  getCalendar(): CalendarDay[] {
    const days = eachDayOfInterval({
      start: subDays(new Date(), 30),
      end: new Date(),
    });

    const calendarDays = days.map((date) => {
      return {
        date: date,
        slotsAvailable: true,
      };
    });

    return calendarDays;
  }

  hasDateValueChanged(d: Date, question: ClaimQuestion): boolean {
    if (question.answer !== null) {
      const oldDate = new Date(question.answer.AnswerValue);
      const newDate = new Date(d);

      return !isEqual(oldDate, newDate);
    }

    return true;
  }
}
