import { Component, HostListener, Input } from '@angular/core';
import { BookingCalendarDateFormats } from '../booking-calendar-constants';
import { Api } from './../../../interfaces/api.interface';

@Component({
  selector: 'dg-booking-current-appointment',
  templateUrl: './booking-current-appointment.html',
  styleUrls: ['./booking-current-appointment.scss'],
})
export class BookingCurrentAppointmentComponent {
  @Input() currentAppointment!: Api.CurrentAppointment;

  showTooltip = false;
  bookingCalendarDateFormats = BookingCalendarDateFormats;

  @HostListener('document:click', ['$event'])
  globalClick(event: MouseEvent): void {
    const isClickOutsideOfToggle =
      (event.target as HTMLElement)?.closest('.show-tooltip-button') === null;

    if (isClickOutsideOfToggle && this.showTooltip) {
      this.showTooltip = false;
    }
  }

  get isMobile() {
    return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
}
