import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QAFormCallbackHelper } from '@domgen/data-access-repair-details';
import { FormControlsDefinition, CalendarDay } from '@domgen/dgx-components';

@Component({
  selector: 'claims-call-back-question-form',
  templateUrl: './call-back-question-form.component.html',
  styleUrls: ['./call-back-question-form.component.scss'],
})
export class CallBackQuestionFormComponent implements OnInit {
  @Input() callbackInWarranty?: FormControlsDefinition[];
  @Output() qaSubmitted: EventEmitter<{ [key: string]: string }> =
    new EventEmitter();
  form?: UntypedFormGroup;
  showTimePickerError = false;
  validate = false;
  open = false;

  constructor(private _qaCallbackHelper: QAFormCallbackHelper) {}

  ngOnInit() {
    this.form = this._qaCallbackHelper.createForm(this.callbackInWarranty);
    this.scrollToPosition('repairCallbackDate');
  }

  scrollToPosition(el: string) {
    setTimeout(() => {
      const region = document.getElementById(el);
      if (region) {
        region.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300);
  }

  selectDate(date: Date | null) {
    this.form?.get('FaultDate')?.patchValue(date);
    this.scrollToPosition('repairCallbackText');
  }

  submitForm() {
    if (this.form?.invalid || this.form?.value.FaultDate === null) {
      this.validate = true;
      this.form.markAllAsTouched();
      return;
    }

    this.qaSubmitted.emit({
      FaultDate: this.form?.value.FaultDate.toString(),
      Description: this.form?.value.Description,
    });
  }

  onOpen() {
    this.open = true;
  }
}
