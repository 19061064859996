<div class="grid-row booking">
  <div class="xs-12 navigation">
    <div class="navigation__cta flex align--center">
      <dgx-components-button
        class="navigation__cta-left"
        (clicked)="loadMoreDates('past')"
        [class.navigation__cta-right--disabled]="currentPage === 0"
        [disabled]="currentPage === 0"
        iconName="chevron-left"
        [fill]="false"
        variant="secondary"
      >
      </dgx-components-button>
      <div class="navigation__current-week flex flex-column align--center">
        <h3 class="text--center">Week</h3>
        <h2 class="margin--none typog-h2">
          <ng-container
            *ngIf="
              (selectedWeek?.WeekStart
                | date : bookingCalendarDateFormats.ShortMonth) ===
                (selectedWeek?.WeekEnd
                  | date : bookingCalendarDateFormats.ShortMonth);
              else ShowBothMonths
            "
          >
            {{
              selectedWeek?.WeekStart | date : bookingCalendarDateFormats.Date
            }}
            -
            {{
              selectedWeek?.WeekEnd
                | date : bookingCalendarDateFormats.DateShortMonth
            }}
          </ng-container>

          <ng-template #ShowBothMonths>
            {{
              selectedWeek?.WeekStart
                | date : bookingCalendarDateFormats.DateShortMonth
            }}
            -
            {{
              selectedWeek?.WeekEnd
                | date : bookingCalendarDateFormats.DateShortMonth
            }}
          </ng-template>
        </h2>
      </div>

      <dgx-components-button
        class="navigation__cta-right"
        [class.navigation__cta-right--disabled]="
          currentPage === numberOfWeeks &&
          !availability?.bookingOption?.ExtraAvailability
        "
        (clicked)="loadMoreDates()"
        [disabled]="
          (currentPage === numberOfWeeks &&
            !availability?.bookingOption?.ExtraAvailability) ||
          !!selectedWeek?.Loading
        "
        [fill]="false"
        iconName="chevron-right"
        variant="secondary"
      >
      </dgx-components-button>
    </div>
  </div>
</div>
