import { DropoffStateService } from '../../../book-dropoff/book-dropoff-data-access/book-dropoff-state.service';
import { takeUntil, map, take } from 'rxjs/operators';
import {
  PcaService,
  fadeInAnimation,
  slideInOut,
  AddressItemDetail,
  AddressItem,
} from '@domgen/dgx-components';
import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';
import { BaseComponent } from '@domgen/dgx-components';
import { OriginStateService } from './dropoff-origin-state.service';
import { Origin } from '@domgen/dgx-components';

@Component({
  selector: 'claims-dropoff-origin',
  templateUrl: './dropoff-origin.component.html',
  styleUrls: ['./dropoff-origin.component.scss'],
  providers: [OriginStateService],
  animations: [slideInOut, fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropoffOriginComponent extends BaseComponent implements OnInit {
  @Input() claim: any; // TODO extend model to include dropOffOrigin
  @Input() origin?: Origin;
  edit = false;
  storedAddress?: Origin;
  selected = 0;
  vm$ = this._originState.vm$;

  @ViewChild('scrollList')
  scrollList?: ElementRef;
  @ViewChild('addressInput') addressInput?: ElementRef;
  @ViewChild('addressSearch') addressSearch?: ElementRef;
  addressSearchForm = this._originState.addressSearchForm;

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    const element = event.target as HTMLElement;
    const isFocus = !!element.closest('.edit__focusarea');
    const isCtaFocus = !!element.closest('.cta__focusarea');
    if (!isFocus && !isCtaFocus) {
      this.toggleEdit(false);
    }
  }

  constructor(
    private readonly _originState: OriginStateService,
    private readonly _state: DropoffStateService,
    private readonly pca: PcaService
  ) {
    super();
  }

  ngOnInit() {
    this.storedAddress = {
      BuildingName: this.claim?.dropOffOrigin?.BuildingName,
      CustomersHouseStreetName:
        this.claim?.dropOffOrigin?.CustomersHouseStreetName,
      CustomersTownCity: this.claim?.dropOffOrigin?.CustomersTownCity,
      CustomersLocalArea: this.claim?.dropOffOrigin?.CustomersLocalArea,
      CustomersPostCode: this.claim?.dropOffOrigin?.CustomersPostCode,
    };
    this.origin = { ...this.storedAddress } as Origin;
  }

  cancel() {
    this.toggleEdit(false);
    this.addressSearchForm.patchValue({
      input: '',
    });
    this._originState.setAddressList([]);
  }

  toggleEdit(state: boolean) {
    this._originState.toggleEdit(state);
    const target = this.addressInput?.nativeElement;
    if (target) {
      if (state) {
        target.focus();
        target.click();
        if (this.isMobile) {
          const search = this.addressSearch?.nativeElement;
          if (search) {
            search.scrollIntoView({ behavior: 'smooth' });
          }
        }
      } else {
        target.blur();
      }
    }
  }

  onKeydown(event: Event, index: number, addressList: AddressItem[]) {
    if ((event as KeyboardEvent).key === 'Enter') {
      this.handleAddressClick(addressList[index]);
    } else if (
      (event as KeyboardEvent).key === 'ArrowDown' &&
      this.selected < addressList.length - 1
    ) {
      this.selected++;
      (this.scrollList as ElementRef).nativeElement.scrollTop =
        this.selected * 65 - 30;
    } else if (
      (event as KeyboardEvent).key === 'ArrowUp' &&
      this.selected > 0
    ) {
      this.selected--;
      (this.scrollList as ElementRef).nativeElement.scrollTop =
        this.selected * 65;
    }
  }

  get isMobile() {
    return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  setOrigin() {
    this.toggleEdit(false);
    this._state.setOrigin(this.origin as Origin);
  }

  handleAddressClick(addressLookup: AddressItem) {
    this.selected = 0;
    if (addressLookup?.Type === 'Address') {
      this._originState.setAddressList([]);
      this.pca
        .pcaRetrieve(addressLookup.Id)
        .pipe(
          takeUntil(this.destroy$),
          take(1),
          map(({ Items }) => Items[0])
        )
        .subscribe((res: AddressItemDetail) => {
          this.origin = {
            BuildingName: `${res.Company ? res.Company : ''}`,
            CustomersHouseStreetName: `${res.Line1}${
              res.Line2 ? ', ' + res.Line2 : ''
            }`
              .replace(/(\r\n|\n|\r)/gm, '')
              .replace(/^\s+|\s+$/g, ''),
            CustomersTownCity: res.City,
            CustomersLocalArea: res.Province,
            CustomersPostCode: res.PostalCode,
          } as Origin;

          this.setOrigin();
          this.addressSearchForm.patchValue({
            input: '',
          });
        });
    } else {
      this.pca
        .pcaFind(addressLookup.Text, addressLookup.Id)
        .pipe(takeUntil(this.destroy$), take(1))
        .subscribe(({ Items }) => {
          this._originState.setAddressList([...Items]);
          (this.scrollList as ElementRef).nativeElement.scrollTop = 0;
        });
    }
  }

  handleExpandable(address: AddressItem): boolean {
    return !!(address.Type === 'Address' || address.Next === 'Retrieve');
  }
}
