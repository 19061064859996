import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ClaimFacade, SpecialRoutingService } from '@domgen/data-access-claims';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { Claim } from '@domgen/dgx-components';

@Injectable({
  providedIn: 'root',
})
export class PaymentGuard {
  constructor(private cf: ClaimFacade, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.checkClaim().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkClaim(): Observable<boolean> {
    return this.cf.activeClaim$.pipe(
      switchMap((claim: Claim) => {
        if (claim?.excess.required) {
          return of(true);
        } else {
          this.router.navigate(['repair-details'], {
            replaceUrl: true,
          });
          return throwError(false);
        }
      })
    );
  }
}
