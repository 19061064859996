import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  Validator,
  ValidatorFn,
} from '@angular/forms';

@Directive({
  selector: '[dgFbForbiddenName]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ForbiddenValidatorDirective,
      multi: true,
    },
  ],
})
export class ForbiddenValidatorDirective implements Validator {
  @Input('dgFbForbiddenName') hiddenName?: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.hiddenName
      ? forbiddenNameValidator(this.hiddenName)(control)
      : null;
  }
}

export function forbiddenNameValidator(forbiddenName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden =
      control.value.toLowerCase() === forbiddenName.toLowerCase()
        ? true
        : false;
    return forbidden ? { forbiddenName: { value: control.value } } : null;
  };
}
