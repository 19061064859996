<div
  *ngIf="group && item"
  [formGroup]="group"
  class="form-input legacy-form-builder"
  [ngClass]="{
    'is-invalid':
      !$any(group?.controls)[item.control].valid &&
      $any(group?.controls)[item.control]?.touched,
    'is-valid':
      $any(group?.controls)[item.control]?.valid &&
      $any(group?.controls)[item.control]?.touched
  }"
>
  <dg-fb-label
    *ngIf="label"
    [label]="label.text"
    [class]="label.classes"
    [forElement]="labelFor"
    >{{ label.text }}
  </dg-fb-label>
  <dg-fb-hint *ngIf="hint">
    {{ hint.text }}
  </dg-fb-hint>
  <div class="form-control">
    <dgx-components-icon name="search" size="xl"> </dgx-components-icon>
    <input
      [class]="item?.classes"
      [formControlName]="$any(item?.control)"
      [attr.data-automation]="item?.control"
      [placeholder]="item?.placeholder"
      [id]="item?.control"
      [name]="item?.control"
      [maxlength]="maxLength"
      type="text"
      (keydown)="typeAhead($event)"
      (keyup)="inputKeyUp()"
      (blur)="handleOnBlur()"
      (focus)="handleOnFocus()"
      autocomplete="off"
    />
    <ul
      *ngIf="filtered.length > 0 && lookupMatch === false && !hideDropdown"
      class="autocomplete-results"
    >
      <li
        *ngFor="let result of filtered; index as i"
        [innerHTML]="result.value"
        [ngClass]="{
          focus: result.focused,
          nomatch: result.nomatch
        }"
        (mousedown)="selectResult(i)"
        (mouseenter)="typeAhead($event, i)"
      ></li>
    </ul>
    <i class="icon validation-icon icon-tick-circle"></i>
    <i class="icon validation-icon icon-error-circle"></i>
    <dgx-components-icon
      *ngIf="$any(group?.controls)[this.item.control].value?.length > 0"
      name="close"
      size="xl"
      (mousedown)="clearInput()"
    >
    </dgx-components-icon>
    <ng-content></ng-content>
  </div>
  <ng-container
    *ngIf="validators && $any(group?.controls)[item.control]?.touched"
  >
    <dg-fb-validation-error-formcontrol
      [group]="group"
      [validators]="validators"
      [control]="item.control"
    ></dg-fb-validation-error-formcontrol>
  </ng-container>
</div>
