<div
  *ngIf="group && control"
  [formGroup]="group"
  class="form-input legacy-form-builder"
  [ngClass]="{
    'is-invalid':
      !group.controls[control].valid && group.controls[control]?.touched,
    'is-valid':
      group.controls[control]?.valid && group.controls[control]?.touched
  }"
>
  <dg-fb-label
    *ngIf="label && label?.text"
    [label]="label?.text"
    [class]="label?.classes"
    [forElement]="labelFor"
    >{{ label?.text }}
  </dg-fb-label>
  <dg-fb-hint *ngIf="hint" [hint]="hint">
    {{ hint.text }}
  </dg-fb-hint>
  <div class="form-control">
    <textarea
      [class]="classes"
      [rows]="rows"
      [id]="labelFor"
      [attr.aria-label]="labelFor"
      [formControlName]="control"
      [placeholder]="placeholder"
      (blur)="onBlur()"
      [attr.maxlength]="chrLimit"
      [attr.data-automation]="control"
    ></textarea>
    <i class="icon validation-icon icon-tick-circle"></i>
    <i class="icon validation-icon icon-error-circle"></i>
    <p *ngIf="chrLimit" class="character-counter">
      {{ theLength }} characters left
    </p>
  </div>
  <ng-container *ngIf="validators && showValidation">
    <dg-fb-validation-error-formcontrol
      [group]="group"
      [refresh$]="refreshValidation$"
      [validators]="validators"
      [control]="control"
    ></dg-fb-validation-error-formcontrol>
  </ng-container>
</div>
