import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'smart-fix-bedrock-chat-header',
  templateUrl: './bedrock-chat-header.component.html',
  styleUrls: ['./bedrock-chat-header.component.scss'],
})
export class BedrockChatHeaderComponent {
  isOpen: boolean = false;
  @Output() minimize = new EventEmitter<void>();
  @Output() endChat = new EventEmitter<void>();
  @Output() refresh = new EventEmitter<void>();
  @Output() repairBooking = new EventEmitter<void>();

  onMinimize() {
    this.minimize.emit();
    this.isOpen = false;
  }

  onRefresh() {
    this.refresh.emit();
    this.isOpen = false;
  }

  onEndChat() {
    this.endChat.emit();
    this.isOpen = false;
  }

  onRepairBooking() {
    this.repairBooking.emit();
    this.isOpen = false;
  }

  toggleMenu() {
    this.isOpen = !this.isOpen;
  }
}
