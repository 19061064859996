export interface LexSession {
  $metadata: Metadata;
  interpretations: Interpretation[];
  messages: Message[];
  sessionId: string;
  sessionState: SessionState;
}

interface SessionState {
  dialogAction: DialogAction;
  intent: Intent;
  originatingRequestId: string;
  sessionAttributes: {};
}

interface DialogAction {
  slotToElicit: string;
  type: string;
}

type lexContentType = 'PlainText' | 'ImageResponseCard' | 'CustomPayload';

export interface Message {
  content?: string;
  contentType: string;
  imageResponseCard?: ImageResponseCard;
  date?: Date;
}

interface ImageResponseCard {
  buttons?: Button[];
  subtitle?: string;
  imageUrl?: string;
  title: string;
}

interface Button {
  text: string;
  value: string;
}

interface Interpretation {
  intent: Intent;
  interpretationSource: string;
  nluConfidence?: NluConfidence;
}

interface NluConfidence {
  score: number;
}

interface Intent {
  confirmationState?: string;
  name: string;
  slots: {};
  state?: string;
}

interface Metadata {
  httpStatusCode: number;
  requestId: string;
  attempts: number;
  totalRetryDelay: number;
}

export interface HistoryMessage extends Message {
  response: string;
  date: Date;
}
