<form
  class="chatForm"
  [formGroup]="chatForm"
  (submit)="onSubmit()"
  (keydown.enter)="onSubmit()"
>
  <div class="input-wrapper">
    <div class="text-container">
      <textarea
        #textArea
        name="message"
        placeholder="Start typing"
        class="text-form-input"
        formControlName="message"
        (focus)="onFocusInput()"
        (blur)="onBlurInput()"
        (input)="autoResize($event)"
      ></textarea>
    </div>

    <div class="icon-container">
      <dgx-components-icon
        *ngIf="chatForm.valid"
        class="input-icon send-icon"
        name="send"
        [ariaDisabled]="!chatForm.valid"
        size="lg"
        type="submit"
        (click)="onSubmit()"
        (keydown.enter)="onSubmit()"
        data-testid="submit-button"
      ></dgx-components-icon>
    </div>
  </div>
</form>
