<div class="grid-row" [attr.id]="'RepairOptionSection'">
  <div class="xs-12">
    <h2 class="typog-h3">Thanks, we've approved your request</h2>
    <p>
      We've analysed your fault and think you'll get the fastest fix with a
      repair callback.
    </p>
  </div>
</div>

<div class="grid-row">
  <div class="xs-12 lg-6 mt-6 mb-6">
    <div id="widget-location">
      <dg-loader message="Loading calendar…"></dg-loader>
    </div>
  </div>
  <div class="xs-12 lg-6 mt-6 mb-6">
    <h4>How a repair callback works</h4>

    <ul>
      <li class="mb-4">
        <svg class="svg-icon icon-tick">
          <use xlink:href="#icon-tick-outlined"></use>
        </svg>
        <p>
          First, <span class="typog-body-bold">book a good time</span> to be
          called by the service provider.
        </p>
      </li>
      <li class="mb-4">
        <svg class="svg-icon icon-tick">
          <use xlink:href="#icon-tick-outlined"></use>
        </svg>
        <p>
          The caller will give you
          <span class="typog-body-bold">real-time guidance</span> to help solve
          the fault.
        </p>
      </li>
      <li class="mb-4">
        <svg class="svg-icon icon-tick">
          <use xlink:href="#icon-tick-outlined"></use>
        </svg>
        <p>
          <span class="typog-body-bold">If the fault persists</span>, the caller
          will book you an engineer visit there and then.
        </p>
      </li>
    </ul>
  </div>
</div>
