import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Api, Claim, FormDataSchema } from '@domgen/dgx-components';
import { ComponentStore } from '@ngrx/component-store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, withLatestFrom, take } from 'rxjs/operators';
import { RepairDetailsComponentService } from '../../../services/repair-details-component.service';
import {
  ClaimFaultTypeFormHelperService,
  FormTypes,
} from './claim-fault-type-form-helper.service';
import { ApiService } from '@domgen/data-access-claims';
export interface SelectionSummary {
  id: string;
  formName: string;
  title: string;
  description: string;
  faultName?: string;
}

export enum SummaryHeading {
  CLAIMTYPEFORM = 'Cause of fault',
  FAULTAREAFORM = 'Broken part',
  FAULTTYPEFORM = 'Fault description',
}

export interface FreeTextSearchState {
  run: boolean;
  running: boolean;
  noMatches: boolean;
  fallBackToOldSelect: boolean;
  results: Api.FaultSearchResponse[];
}

export interface ClaimFaultTypeState {
  formData?: FormDataSchema['meta'];
  form?: UntypedFormGroup;
  selectionSummary?: SelectionSummary[];
  planInWarranty?: boolean;
  planInWaitPeriod?: boolean;
  claim?: Claim;
  showContinueCTA: boolean;
  submitClaim?: boolean;
  freeTextSearchState: FreeTextSearchState;
}

const initialState: ClaimFaultTypeState = {
  showContinueCTA: false,
  submitClaim: false,
  selectionSummary: [],
  freeTextSearchState: {
    run: false,
    running: false,
    noMatches: false,
    fallBackToOldSelect: false,
    results: [],
  },
};

@Injectable({
  providedIn: 'root',
})
export class ClaimFaultTypeStore extends ComponentStore<ClaimFaultTypeState> {
  faultData = new BehaviorSubject<Api.FaultData | undefined>(undefined);
  faultData$: Observable<Api.FaultData | undefined> =
    this.faultData.asObservable();
  constructor(
    private _fh: ClaimFaultTypeFormHelperService,
    public service: RepairDetailsComponentService,
    private apiService: ApiService
  ) {
    super(initialState);
  }

  readonly $claim = this.select((state) => state.claim);
  readonly $selectionSummary = this.select((state) => state.selectionSummary);
  readonly $submitClaim = this.select((state) => state.submitClaim);

  readonly vm$ = this.select(
    this.select((state) => state),
    (state) => ({
      ...state,
    })
  );

  /* set form and summary */
  readonly claim = this.effect(($claim: Observable<Claim>) => {
    return $claim.pipe(
      filter(Boolean),
      map((claim: Claim) => {
        this.setForm(claim);
        this.setSelectionSummary(claim);
      })
    );
  })(this.$claim);

  /* submit claim */
  readonly submitClaim$ = this.effect(($submitClaim: Observable<boolean>) => {
    return $submitClaim.pipe(
      filter(Boolean),
      withLatestFrom(this.$claim, this.$selectionSummary),
      map(([_, claim, selectionSummary]) => {
        this.service.claimTypeFormCreateClaim(
          this._fh.getPayload(claim, selectionSummary)
        );

        const faultDescription = selectionSummary.find(
          (f) => f.formName === FormTypes.FAULTTYPEFORM
        );

        const faultArea = selectionSummary.find(
          (f) => f.formName === FormTypes.FAULTAREAFORM
        );

        this.service.triggerFaultResponseGA(
          this._fh.isUsingFreeTextSearch(claim),
          faultArea?.description,
          faultDescription.description
        );
      })
    );
  })(this.$submitClaim);

  readonly setClaim = this.updater((state, claim: Claim) => ({
    ...state,
    claim,
  }));

  readonly submitClaim = this.updater((state, submitClaim: boolean) => ({
    ...state,
    submitClaim,
  }));

  readonly showContinueCTA = this.updater(
    (state, showContinueCTA: boolean) => ({
      ...state,
      showContinueCTA,
    })
  );

  readonly setGetData = this.updater(
    (state, getData: Api.GetMandatoryDataResponse) => ({
      ...state,
      getData,
    })
  );

  readonly updateForm = this.updater(
    (
      state,
      f: {
        formData: FormDataSchema['meta'];
        form: UntypedFormGroup;
      }
    ) => ({
      ...state,
      ...f,
    })
  );

  readonly resetFreeTextSearchState = this.updater((state) => {
    return {
      ...state,
      freeTextSearchState: {
        running: false,
        run: false,
        noMatches: false,
        fallBackToOldSelect:
          state.freeTextSearchState?.fallBackToOldSelect || false,
        results: [],
      },
    };
  });

  readonly updateFreeTextSearchState = this.updater(
    (state, newState: Partial<FreeTextSearchState>) => {
      return {
        ...state,
        freeTextSearchState: {
          ...state.freeTextSearchState,
          ...newState,
        },
      };
    }
  );

  readonly updateSelectionSummary = this.updater(
    (state, selectionSummary: SelectionSummary[]) => ({
      ...state,
      selectionSummary,
    })
  );

  setForm(claim: Claim) {
    const formData = this._fh.getFormFaultDefaults(claim);
    const form = this._fh.createForm(formData.groups);

    this.updateForm({
      formData: formData,
      form: form,
    });
  }

  searchResults(searchTerm: string = '', noMatchesCallback: () => void): void {
    this.updateFreeTextSearchState({
      run: true,
    });

    if (searchTerm === '') return;

    this.updateFreeTextSearchState({
      running: true,
    });

    this.vm$.pipe(take(1)).subscribe({
      next: (value) => {
        const lineId = value.claim.getData.OEMProductGroupCode.replace(
          /[^0-9]/g,
          ''
        );

        const request = {
          lineId,
          description: searchTerm,
        };

        this.apiService
          .searchFault(request, value.claim.getData.FaultData)
          .subscribe({
            next: (val) => {
              this.updateFreeTextSearchState({
                noMatches: val.length === 0,
                results: val,
                running: false,
              });

              if (val.length === 0) {
                noMatchesCallback();
              }
            },
            error: () => {
              this._fh.flagFallback();
              this.updateFreeTextSearchState({
                running: false,
                noMatches: true,
                fallBackToOldSelect: true,
              });
              noMatchesCallback();
            },
          });
      },
    });
  }

  setSelectionSummary(claim: Claim) {
    const claimSelected = claim.getData.ClaimTypes.find(
      (ct) => ct?.ClaimTypeID === claim?.claimSelection?.request?.ClaimTypeID
    );

    if (claimSelected) {
      const sel = this._fh.getTextIcon(
        claimSelected.ClaimTypeName,
        claimSelected.ClaimTypeCode
      );
      this.updateSelectionSummary([
        {
          formName: FormTypes.CLAIMTYPEFORM,
          id: claimSelected.ClaimTypeID,
          title: SummaryHeading.CLAIMTYPEFORM,
          description: sel.text,
        },
      ]);
    }
  }

  formTypeChange(
    form: UntypedFormGroup,
    selectionSummary: SelectionSummary[],
    claim: Claim
  ) {
    if (form.controls[FormTypes.FAULTAREAFORM]) {
      this.service.triggerFaultFormViewGA('fault-area');
      const faultArea = (claim?.getData?.FaultData as Api.FaultData[])?.find(
        (f: Api.FaultData) => {
          return f?.FaultCategoryID === form?.value?.faultArea;
        }
      );
      const faultAreaData = faultArea;
      this.faultData.next(faultAreaData);

      const hiddenClaimTypes = this._fh.isClaimTypeHidden(claim);

      const claimTypeData = this._fh.getFaultTypeData(
        faultArea,
        false,
        hiddenClaimTypes
      );

      this.updateForm({
        formData: claimTypeData,
        form: this._fh.createForm(claimTypeData.groups),
      });

      this.updateSelectionSummary([
        ...selectionSummary,
        {
          formName: FormTypes.FAULTAREAFORM,
          id: form.value.faultArea,
          title: SummaryHeading.FAULTAREAFORM,
          description: faultArea.FaultCategoryLabel,
        },
      ]);
    }

    if (form.controls[FormTypes.FAULTTYPEFORM]) {
      this.service.triggerFaultFormViewGA('type-of-fault');
      let faultDescription;

      const faultAreaExists = selectionSummary.find(
        (s) => s.formName === FormTypes.FAULTAREAFORM
      );

      if (faultAreaExists) {
        faultDescription = (claim?.getData?.FaultData as Api.FaultData[])
          .find((d) => String(d.FaultCategoryID) === String(faultAreaExists.id))
          ?.PossibleAnswers?.find(
            (s) => s.FaultID === form.value.faultType
          ).FaultLabel;
      } else {
        const isSingleFaultCatagory =
          Array.isArray(claim.getData.FaultData) &&
          claim.getData.FaultData.length === 1;

        if (isSingleFaultCatagory) {
          faultDescription = (
            claim?.getData?.FaultData as Api.FaultData[]
          )[0].PossibleAnswers.find(
            (a) => a.FaultID === form.value.faultType
          )?.FaultLabel;
        } else {
          faultDescription = (
            claim?.getData?.FaultData as Api.FaultData
          ).PossibleAnswers.find(
            (a) => a.FaultID === form.value.faultType
          )?.FaultLabel;
        }
      }

      this.updateForm({
        formData: null,
        form: null,
      });

      this.updateSelectionSummary([
        ...selectionSummary,
        {
          id: form.value.faultType,
          formName: FormTypes.FAULTTYPEFORM,
          title: SummaryHeading.FAULTTYPEFORM,
          description: faultDescription,
        },
      ]);

      this.showContinueCTA(true);
    }
  }

  resetForm(
    selectionSummary: SelectionSummary[],
    formName: string,
    claim: Claim,
    freeTextSearchForm = false
  ) {
    if (freeTextSearchForm) {
      this.resetFreeTextSearchState();
    }

    const isSingleFaultCatagory =
      Array.isArray(claim.getData.FaultData) &&
      claim.getData.FaultData.length === 1;

    if (formName === FormTypes.CLAIMTYPEFORM) {
      this.updateSelectionSummary([]);
      const claimTypeData = this._fh.getClaimTypeData(claim);

      this.updateForm({
        formData: claimTypeData,
        form: this._fh.createForm(claimTypeData.groups),
      });
    }

    if (formName === FormTypes.FAULTTYPEFORM && freeTextSearchForm) {
      this.updateSelectionSummary([selectionSummary[0]]);
      const formData = this._fh.getFormFaultDefaults(claim);
      const form = this._fh.createForm(formData.groups);

      this.updateForm({
        formData: formData,
        form: form,
      });
      this.showContinueCTA(false);

      return;
    }

    if (formName === FormTypes.FAULTTYPEFORM && isSingleFaultCatagory) {
      this.updateSelectionSummary([
        ...selectionSummary.filter(
          (s) => s.formName === FormTypes.CLAIMTYPEFORM
        ),
      ]);

      const formData = this._fh.getFormFaultDefaults(claim);
      const form = this._fh.createForm(formData.groups);

      this.updateForm({
        formData: formData,
        form: form,
      });
    }

    if (formName === FormTypes.FAULTTYPEFORM && !isSingleFaultCatagory) {
      this.updateSelectionSummary([
        ...selectionSummary.filter(
          (s) => s.formName !== FormTypes.FAULTTYPEFORM
        ),
      ]);

      let claimTypeData;

      const faultAreaID = selectionSummary.find(
        (s) => s.formName === FormTypes.FAULTAREAFORM
      )?.id;

      if (faultAreaID) {
        const faultArea = (claim?.getData?.FaultData as Api.FaultData[])?.find(
          (f: Api.FaultData) => {
            return String(f?.FaultCategoryID) === String(faultAreaID);
          }
        );

        claimTypeData = this._fh.getFaultTypeData(faultArea);
      } else {
        claimTypeData = this._fh.getFaultAreaTypeData(claim);
      }

      this.updateForm({
        formData: claimTypeData,
        form: this._fh.createForm(claimTypeData.groups),
      });
    }

    if (formName === FormTypes.FAULTAREAFORM) {
      if (freeTextSearchForm) {
        this.updateSelectionSummary([selectionSummary[0]]);
      } else {
        this.updateSelectionSummary([
          ...selectionSummary.filter(
            (s) => s.formName === FormTypes.CLAIMTYPEFORM
          ),
        ]);
      }

      const claimTypeData = this._fh.getFaultAreaTypeData(claim);

      this.updateForm({
        formData: claimTypeData,
        form: this._fh.createForm(claimTypeData.groups),
      });
    }

    this.service.onResetClaimSelectGA(formName);
    this.showContinueCTA(false);
  }
}
