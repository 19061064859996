import { Injectable } from '@angular/core';
import { DgxIconName } from '@domgen/dgx-fe-components/icon';

@Injectable({
  providedIn: 'root',
})
export class LexChatHelperService {
  returnToMyAccountMessage = 'End, return to My Account';
  wasItUsefulMessage = 'It was useful';

  isYoutubeUrl(url: string | undefined): boolean {
    if (url == null) return false;
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;
    return youtubeRegex.test(url);
  }

  getFeedbackIcon(text: string) {
    if (this.detectFeedbackKeywords(text)) {
      return text === this.wasItUsefulMessage
        ? 'thumbs-up'
        : ('thumbs-down' as DgxIconName);
    } else {
      return undefined;
    }
  }

  detectFeedbackKeywords(str: string): boolean {
    return str.toLowerCase().includes('useful');
  }
}
