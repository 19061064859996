export declare enum AddressItemType {
  Address = 'Address',
}
export declare enum AddressType {
  Residential = 'Residential',
  Commercial = 'Commercial',
}
export interface AddressItem {
  Id: string;
  Type: AddressItemType | string;
  Next: string;
  Text: string;
  Highlight: string;
  Description: string;
  [index: string]: string;
}
export interface AddressItemDetail {
  Id: string;
  Company: string;
  County: string;
  Line1: string;
  Line2: string;
  City: string;
  Province: string;
  PostalCode: string;
  Type: string;
}
export interface FindAddressResponse {
  Items: AddressItem[];
}
export interface RetrieveAddressResponse {
  Items: AddressItemDetail[];
}
export interface Address {
  line1: string;
  line2?: string;
  city: string;
  county: string;
  postcode: string;
  type: string;
}
