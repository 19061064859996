import { CalendarDay } from '../../../interfaces/calendar.interface';
import { Hint } from '../components/hint/hint.component';
import { FormBuilderLabel } from './formData.interface';
import { ValidationError } from './validation-error.interface';

export interface FormDataSchema {
  meta: {
    header?: string;
    description?: string;
    groups: FormControlsDefinition[];
    caption?: { classes: string; text: string };
    freeTextSearch?: boolean;
  };
  formValues?: FormValues;
}

export interface FormValues {
  [key: string]: string | Date;
}

export type FormControlsDefinition = InputDefinition &
  RadiosDefinition &
  DateInputDefinition &
  TextareaDefinition &
  SelectDefinition &
  AutocompleteDefinition;

export type FormControlsDefinitionChildKey =
  | InputDefinition['input']
  | RadiosDefinition['radios']
  | DateInputDefinition['date']
  | TextareaDefinition['textarea']
  | SelectDefinition['select']
  | AutocompleteDefinition['autocomplete'];

export interface InputDefinition {
  input?: {
    label: FormBuilderLabel;
    validators?: ValidationError[];
    control: string;
    placeholder: string;
    classes: string;
    maxLength?: number | null;
    DataType?: string | null;
    hint?: Hint;
    tooltip?: string;
    PasswordStrengthChecker?: boolean;
    useShowPassword?: boolean;
    readOnly?: boolean;
  };
}

export interface RadiosDefinition {
  radios?: {
    heading?: string;
    subheading?: string;
    type?: string;
    label?: FormBuilderLabel;
    control: string;
    classes: string;
    validators: ValidationError[];
    radioGroup: RadioGroupDefinition[];
    elementsPerPage: number;
    textarea?: TextareaDefinition['textarea'];
  };
}

export interface RadioGroupDefinition {
  value: string | number;
  text: string;
  classes: string;
  icon?: string;
  hiddenAccessibilityText: string;
  list?: {
    classes: string;
    text: string;
  };
  svgClasses?: string;
  hint?: Hint;
}

export interface DateInputDefinition {
  date?: {
    heading: string;
    subheading: string;
    questionID: string;
    control: string;
    validators?: ValidationError[];
    calendarDays: CalendarDay[];
    answer: {
      AnswerID: string;
      AnswerValue: string;
    } | null;
    answerIndex: number | null;
  };
}

export interface TextareaDefinition {
  textarea?: {
    heading: string;
    subheading: string;
    type: string;
    label: FormBuilderLabel;
    validators: ValidationError[];
    chrLimit: number;
    control: string;
    placeholder: string;
    value: string;
    classes: string;
  };
}

// TODO: gather a real example of the structure of this object
export interface SelectDefinition {
  select?: {
    heading: string;
    subheading: string;
    type: string;
    label: string;
    validators: ValidationError[];
    hint: string;
    control: string;
  };
}

export interface AutocompleteDefinition {
  autocomplete?: {
    label?: FormBuilderLabel;
    maxLength?: number | null;
    control: string;
    DataType: 'ModelNumber' | 'ModelNumber' | 'PNCNumber' | string;
    placeholder: string;
    classes: string;
    readOnly: boolean;
    validators: ValidationError[];
    DataCategory: 'OEMAppliances' | null | string;
    hint?: Hint;
    startSearchFromCharacter?: number;
  };
}
