<div class="modal">
  <div class="alert-dialog">
    <button (click)="onClick()">
      <dgx-components-icon name="close"> </dgx-components-icon>
    </button>

    <div class="alert-dialog__content flex">
      <div class="alert-dialog__main">
        <div class="alert-dialog__main-body">
          <div class="heading">
            <h4 class="typog-h4">
              <dgx-components-icon name="circle-exclamation">
              </dgx-components-icon>

              {{ content?.heading }}
            </h4>
          </div>

          <p class="typog-body">{{ content?.body }}</p>
        </div>
      </div>
      <div class="alert-dialog__action mt-6">
        <dgx-components-button
          type="submit"
          variant="primary"
          (click)="onClick()"
          data-automation="'ConfirmAD'"
        >
          {{ content?.cta }}
        </dgx-components-button>
      </div>
    </div>
  </div>
</div>
