import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'smart-fix-chatbot-header',
  templateUrl: './chatbot-header.component.html',
  styleUrls: ['./chatbot-header.component.scss'],
})
export class ChatbotHeaderComponent {
  @Output() minimizeChatBot: EventEmitter<boolean> = new EventEmitter();
  @Output() closeChatBot: EventEmitter<boolean> = new EventEmitter();

  triggerMinimizeChatBot() {
    this.minimizeChatBot.emit(true);
  }

  triggerCloseChatBot() {
    this.closeChatBot.emit(true);
  }
}
