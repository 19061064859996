import { Injectable } from '@angular/core';
import {
  Api,
  FormControlsDefinition,
  FormControlsDefinitionChildKey,
} from '@domgen/dgx-components';
import { Store } from '@ngrx/store';
import { filter, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import * as fromClaims from '@domgen/data-access-claims';
import { claimActions } from '@domgen/data-access-claims';
import { Claim, ClaimQuestion } from '@domgen/dgx-components';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { ClaimsAnalyticsService, PutAnswer } from '@domgen/dgx-components';
import { QAFormHelper } from '../../../services/qa-form-helper';

export enum QuestionTypes {
  RESPONSE_FIELD = 'RESPONSE_FIELD',
  DATE_PICKER = 'DATE_PICKER',
  DATE = 'DATE',
  RADIO_BUTTONS = 'RADIO_BUTTONS',
  DROPDOWN = 'DROPDOWN',
  LIST = 'LIST',
}

@Injectable({
  providedIn: 'root',
})
export class QAFormService {
  public claim?: Claim;
  public form?: UntypedFormGroup;
  public showValidation$ = new Subject();

  questions: FormControlsDefinition[] = [];

  readonly claim$ = this.store.select(fromClaims.getLoaded).pipe(
    filter(Boolean),
    switchMap(
      () =>
        this.store
          .select(fromClaims.getActiveClaim)
          .pipe(take(1)) as Observable<Claim | undefined>
    ),
    switchMap((c: Claim | undefined) => {
      this.claim = c;
      return of(c);
    })
  );

  readonly questions$ = this.claim$.pipe(
    switchMap((c: Claim | undefined) => {
      const formData = this.qaHelper.generateQuestionFormData(
        c?.questions as ClaimQuestion[]
      ) as FormControlsDefinition[];

      if (!this.questions.length) {
        formData.map((q) => {
          this.questions.push(q);
        });
      } else if (this.questions.length < formData.length) {
        const index = this.questions.length;
        this.questions.push(formData[index]);
      } else if (this.questions.length > formData.length) {
        this.questions = formData;
      }

      return of(this.questions);
    })
  );

  readonly form$ = this.questions$.pipe(
    withLatestFrom(this.claim$ as Observable<Claim>),
    switchMap(([questions, claim]: [FormControlsDefinition[], Claim]) => {
      this.form = this.qaHelper.createForm(
        questions,
        this.qaHelper.getAnsweredValues(claim.questions as ClaimQuestion[])
      );

      return of(this.form);
    })
  );
  constructor(
    private store: Store<fromClaims.State>,
    private qaHelper: QAFormHelper,
    private _analytics: ClaimsAnalyticsService
  ) {}

  putDateAnswer(d: Date, group: FormControlsDefinitionChildKey) {
    const question = this.claim?.questions?.find((q) => {
      return group?.control === q.question.QuestionID;
    }) as ClaimQuestion;

    if (this.qaHelper.hasDateValueChanged(d, question)) {
      const date = this.claim?.questions?.find((q) => {
        return group?.control === q.question.QuestionID;
      });

      const answer = {
        AnswerValue: d.toString(),
        AnswerID: date?.question.AnswerData[0].AnswerID,
        QuestionID: date?.question.QuestionID,
      } as Api.PutAnswerRequest;

      this.store.dispatch(
        claimActions.PutAnswer({
          payload: {
            ...answer,
            ClaimID: this.claim?.claimID as string,
          },
        })
      );

      this._analytics.claimEventExtended({
        eventClaims: 'when-date',
        eventAction: 'dateStoppedWorking-dgx',
        eventLabel: `${d.toString()}`,
        claim: this.claim as Claim,
      });
    }
  }

  textAreaSubmit(
    form: UntypedFormGroup,
    group: FormControlsDefinitionChildKey
  ) {
    const hasValueChanged = this.hasValueChanged(form, group);
    const control = form?.controls[group?.control as string];
    if (control?.invalid) {
      control.markAsTouched();
      control.updateValueAndValidity();
      this.showValidation$.next(true);
      return;
    }

    if (hasValueChanged) {
      this.showValidation$.next(false);
      const { answerID, answerValue } = this.getAnswerValue(form, group);
      this.putAnswer({
        AnswerValue: answerValue,
        AnswerID: answerID,
        QuestionID: group?.control as string,
      });
    }
  }

  putAnswer(answer: PutAnswer) {
    this.store.dispatch(
      claimActions.PutAnswer({
        payload: {
          ...answer,
          ClaimID: this.claim?.claimID as string,
        },
      })
    );

    const repairASV = this._analytics.isASVinRepairJourney(this.claim as Claim);

    this._analytics.claimEventExtended({
      eventClaims: repairASV
        ? 'fault-details'
        : this._analytics.isASV(this.claim as Claim)
        ? 'questions-before-booking-asv'
        : 'when-cause',
      eventAction: 'causeOfDamage-dgx',
      eventLabel: answer.AnswerValue,
      claim: this.claim as Claim,
    });
  }

  triggerPageView() {
    const repairASV = this._analytics.isASVinRepairJourney(this.claim as Claim);
    this._analytics.pageViewEvent({
      eventClaims: repairASV
        ? 'fault-details'
        : this._analytics.isASV(this.claim as Claim)
        ? 'questions-before-booking-asv'
        : 'when-cause',
      claim: this.claim as Claim,
    });
  }

  getAnswerValue(
    form: UntypedFormGroup,
    group: FormControlsDefinitionChildKey
  ): {
    question: ClaimQuestion;
    answerID: string;
    answerValue: string;
  } {
    const question = this.claim?.questions?.find((q: ClaimQuestion) => {
      return q.question.QuestionID === group?.control;
    }) as ClaimQuestion;

    const answerID = question?.question.AnswerData[0].AnswerID;
    const answerValue = form?.controls[group?.control as string].value;

    return {
      question,
      answerID,
      answerValue,
    };
  }

  hasValueChanged(
    form: UntypedFormGroup,
    group: FormControlsDefinitionChildKey
  ): boolean {
    const { question, answerID, answerValue } = this.getAnswerValue(
      form,
      group
    );

    const answerChanged = !(
      question?.answer?.AnswerID === answerID &&
      question?.answer?.AnswerValue === answerValue
    );

    return answerChanged;
  }

  getCalendar() {
    return this.qaHelper.getCalendar();
  }
}
