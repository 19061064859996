import { Component, Inject, OnInit } from '@angular/core';
import { ChatClientService } from '@domgen/data-access-claims';
import { AppConfig, BaseComponent } from '@domgen/dgx-components';
import { NavigationLink } from '@domgen/dgx-fe-common';
import { Picture } from '@domgen/dgx-fe-components/media';
import { Observable, map, take, takeUntil } from 'rxjs';
import { ClaimsAppComponentService } from './claims-app-component.service';
import { CONFIG } from '@domgen/dgx-fe-config';
import { LoggedInUserService } from '@domgen/dgx-components';

@Component({
  selector: 'claims-app-root',
  templateUrl: './claims-app.component.html',
  styleUrls: ['./claims-app.component.scss'],
})
export class ClaimsAppComponent extends BaseComponent implements OnInit {
  loadingState$: Observable<{
    isLoading: boolean;
    message: string | null;
  }> = this.service.loadingState$;

  user$ = this.loggedInUser.user$;

  headerLinks: NavigationLink[];

  clientLogo: Picture = {
    sourceSet: {},
    alt: 'Domestic & General Logo',
    aspectRatio: '16:9',
    src: 'assets/images/domestic-and-general.png',
  };

  constructor(
    @Inject(CONFIG) private readonly config: AppConfig,
    public readonly service: ClaimsAppComponentService,
    private readonly chatClient: ChatClientService,
    private readonly loggedInUser: LoggedInUserService
  ) {
    super();
    this.chatClient.routeListener().subscribe();
  }

  ngOnInit(): void {
    this.user$.subscribe((user) => {
      if (user) {
        this.headerLinks = [
          {
            label: 'Dashboard',
            href: this.config.plansHomePage,
          },
          {
            label: 'Your details',
            href: this.config.personalDetailsPage,
          },
          {
            label: 'Your preferences',
            href: this.config.myPreferencesPage,
          },
          {
            label: 'Logout',
            routerPath: '/logout',
            icon: 'arrow-right-from-bracket',
          },
        ];
      } else {
        this.headerLinks = [];
      }
    });
  }
}
