import { Injectable } from '@angular/core';
import { ClaimFacade } from '@domgen/data-access-claims';
import { ClaimHelperService } from '@domgen/data-access-claims';
import {
  Claim,
  ClaimSelectionState,
  CustomWindow,
} from '@domgen/dgx-components';
import { claimActions } from '@domgen/data-access-claims';

declare let window: CustomWindow;

@Injectable({
  providedIn: 'root',
})
export class RepairDetailsCallbackService {
  constructor(
    private claimFacade: ClaimFacade,
    private _claimHelper: ClaimHelperService
  ) {}

  callbackQA: { [key: string]: string };
  claim: Claim;
  selectionState: ClaimSelectionState;

  initialize(claim: Claim) {
    this.callbackQA = claim.callbackQA;
    this.claim = claim;
    this.selectionState = this.claim.claimSelection.selectionState;
    this.loadExternalScript(
      'https://apps.mypurecloud.com/widgets/9.0/cxbus.min.js'
    ).onload = () => {
      this.loadConfig();
      this.configureWidgets();
    };
  }

  loadExternalScript(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = true;
    script.defer = true;
    document.getElementsByTagName('head')[0].appendChild(script);
    return script;
  }

  loadConfig() {
    window._genesys = window._genesys || {};

    window._genesys.widgets = {
      // https://all.docs.genesys.com/WID/Current/SDK/App-combined#config
      main: {
        debug: false,
        theme: 'light',
        lang: 'en',
        mobileMode: false,
        customStylesheetID: 'genesys_widgets_custom',
        preload: ['callback'],
        i18n: {
          en: {
            callback: {
              CallbackTitle: 'Book a callback',
              ConfirmButtonText: 'Confirm booking',
              AriaConfirmButtonText: 'Confirm booking',
              CallbackQuestion:
                'Please confirm your booking, or select the date if you want to change it.',
              CallbackPlaceholderCalendar:
                'Please confirm your booking, or select the date if you want to change it.',
              CallbackConfirmDescription: 'Thanks for your request',
              CallbackNumberDescription:
                'We’re directing you to the confirmation page.',
            },
          },
        },
      },
      onReady() {
        console.log('onReady');
      },
      stats: {
        ewt: {
          dataURL:
            'https://gapi-euc4.genesyscloud.com/engagement/v3/estimated-wait-time',
          apikey: 'zvO9AQwWwh14jE1zsGM5FaE7ooZh2di4a57OTrLW',
          apiVersion: 'v3',
          mode: '',
        },
      },
      channelselector: {
        ewtRefreshInterval: 10,
        channels: [
          {
            enable: true,
            clickCommand: 'Callback.open',
            readyEvent: 'Callback.ready',
            displayName: 'Receive a Call',
            i18n: 'CallbackTitle',
            icon: 'call-incoming',
            html: '',
            ewt: {
              display: true,
              queue: 'test_VQ_UK_AO_WCB',
              availabilityThresholdMin: 300,
              availabilityThresholdMax: 3600,
              hideChannelWhenThresholdMax: false,
            },
          },
        ],
      },
      callback: {
        dataURL: 'https://gapi-euc4.genesyscloud.com/engagement/v3/callbacks',
        apiVersion: 'v3',
        callDirection: 'USERTERMINATED',
        immediateCallback: false,
        scheduledCallback: true,
        serviceName: 'test_VQ_UK_AO_WCB',
        ewt: {
          display: true,
          queue: 'test_VQ_UK_AO_WCB',
          threshold: 65,
          refreshInterval: 10,
          immediateCallback: {
            thresholdMin: 20,
            thresholdMax: 120,
          },
        },
        countryCodes: true,
        apikey: 'zvO9AQwWwh14jE1zsGM5FaE7ooZh2di4a57OTrLW',
      },
    };
  }

  configureWidgets() {
    const widgetBaseUrl = 'https://apps.mypurecloud.ie/widgets/9.0/';
    window.CXBus.configure({
      debug: true,
      pluginsPath: widgetBaseUrl + 'plugins/',
    });
    window.CXBus.loadPlugin('widgets-core');
    this.setupPlugin();
  }

  setupPlugin() {
    window._genesys.widgets.onReady = (QuickBus) => {
      const plugin = QuickBus.registerPlugin('DG');
      plugin.subscribe('Callback.ready', () => {
        this.openCallback();
      });
      plugin.subscribe('Calendar.generated', () => {
        window._genesys.widgets.bus.command('Callback.hideOverlay');
      });
      plugin.subscribe('Calendar.selectedDateTime', () => {
        this.showCallbackItems();
      });
      plugin.subscribe('CallbackService.availabilityError', (e) => {
        this.handleWidgetError(e);
      });
      plugin.subscribe('Callback.completed', (e) => {
        const desiredTime = e.data.form._desired_time;
        this.claimFacade.dispatch(
          claimActions.CompleteCallbackBooking({
            payload: {
              desiredTime: new Date(desiredTime).toString(),
            },
          })
        );
      });
      plugin.subscribe('CallbackService.scheduleError', (e) => {
        this.handleWidgetError(e);
      });
    };
  }

  openCallback() {
    window._genesys.widgets.bus
      .command('Callback.open', {
        form: {
          autoSubmit: false,
          desiredTime: 'now',
          firstname: this.callbackQA.CustomerFirstName,
          lastname: this.callbackQA.CustomerLastName,
          subject: `${this.selectionState?.faultType?.description}, ${this.selectionState?.faultTypeAnswer?.description}`, // fault description
          phonenumber: this.claim.customer.CustomersMobile.replace(
            /^0+/,
            '+44'
          ),
        },
      })
      .done(() => {
        document
          .getElementById('cx_form_callback_firstname')
          .setAttribute('disabled', 'true');
        document
          .getElementById('cx_form_callback_lastname')
          .setAttribute('disabled', 'true');
        document
          .getElementById('cx_form_callback_subject')
          .setAttribute('disabled', 'true');
        document
          .getElementById('cx_form_callback_phone_number')
          .setAttribute('disabled', 'true');
        this.hideCallbackItems();
        this.openCalendar();
      })
      .fail((e) => {
        this.handleWidgetError(e);
      });
  }

  openCalendar() {
    document.getElementById('cx_form_schedule_time').click();
    const target = document.getElementById('widget-location');
    const container = document.querySelector("[cx-dock-id='Callback']");
    container.remove();
    target.appendChild(container);
    setTimeout(() => {
      target.style.opacity = 'block';
      document
        .getElementsByClassName('cx-widget')[0]
        .classList.add('cx-widget--show');
    }, 1000);
  }

  hideDock() {
    document
      .getElementsByClassName('cx-dock')[0]
      .classList.add('cx-hide-component');
  }
  hideCallbackItems() {
    // document.getElementById('cx-schedule').style['display'] = 'none';
    // document.getElementById('cx-callback-confirm').style['display'] = 'none';
  }

  showCallbackItems() {
    // document.getElementById('cx-schedule').style['display'] = '';
    // document.getElementById('cx-callback-confirm').style['display'] = '';
  }

  handleWidgetError(e) {
    this.claimFacade.dispatch(claimActions.Error({ payload: e }));
  }
}
