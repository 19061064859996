import { Injectable, Inject } from '@angular/core';
import { CustomWindow } from '../interfaces/custom-window.interface';
import { GAClientConfig } from '../interfaces';
import { GA_CLIENT } from '../tokens';

// tslint:disable-next-line:interface-over-type-literal
export type GtmData = { [key: string]: any };

declare let window: CustomWindow;

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  constructor(@Inject(GA_CLIENT) protected gaClient: GAClientConfig) {
    // Make sure the dataLayer property exists on the window object,
    // or we will not be able to communicate with GTM.
    /* istanbul ignore if */
    if (!Object.hasOwnProperty.call(window, 'dataLayer')) {
      throw new Error('`window.dataLayer` is not defined.');
    }
  }

  public push(data: GtmData) {
    window['dataLayer'].push(data);
  }

  public trigger(event: string, data?: GtmData) {
    this.push({ event, clientName: this.gaClient.name, ...data });
  }
}
