import { Component, Input } from '@angular/core';

@Component({
  selector: 'smart-fix-lex-custom-payload',
  templateUrl: './lex-custom-payload.component.html',
  styleUrls: [
    './lex-custom-payload.component.scss',
    './../../chatbot-welcome-message/chatbot-welcome-message.component.scss',
    './../../chatbot-disclaimer/chatbot-disclaimer.component.scss',
  ],
})
export class LexCustomPayloadComponent {
  @Input() content!: string | undefined;

  disclaimerEvent = {
    type: '',
    details: {
      header:
        'Before we begin, please take a moment to read the following points when attempting a Smart-fix:',
      delay: 1200,
      options: [
        {
          icon: 'circle-check',
          description:
            'Don’t try it if you don’t feel you can do so safely, confidently or effectively',
        },
        {
          icon: 'circle-check',
          description:
            'Make sure to follow the instructions we provide exactly',
        },
      ],
    },
  };

  showObject(object1: string | undefined) {
    if (object1 == null) return;
    const welcome = object1.replace(/"/g, '');
    return welcome;
  }
}
