<dg-booking-calendar-confirmation
  *ngIf="showConfirmationModal"
  [day]="day"
  [slotSelected]="slotSelected"
  [slotType]="slotTypes.SPECIFIC"
  [currentAppointment]="currentAppointment"
  [isRebooking]="isRebook"
  [gaData]="gaData"
  (confirmBooking)="handleBookingConfirmation($event)"
></dg-booking-calendar-confirmation>

<form *ngIf="form" [formGroup]="form" (change)="selectSlot()">
  <h5 class="typog-h5 mb-3">Time slots available</h5>
  <div [@fadeInAnimation]>
    <dg-fb-radiobutton
      *ngFor="let group of formData?.groups"
      [elementsPerPage]="group.radios?.elementsPerPage"
      [group]="form"
      [align]="'justify--left'"
      [classes]="'dg-radios-light ' + group.radios?.classes"
      [idPrefix]="group.radios?.control"
      [control]="group.radios?.control"
      [items]="group.radios?.radioGroup"
      [includeResetOption]="true"
      (resetFormValue)="clearSlot()"
    >
    </dg-fb-radiobutton>
  </div>
</form>

<section class="engineer-arrival mt-6 mb-8 flex flex-column">
  <p class="label typog-body-bold mb-1">When will the engineer come?</p>
  <p class="typog-body">
    They'll contact you with their arrival time as soon as possible.
  </p>
</section>

<div class="slot-confirm" [@fadeInAnimation]>
  <dgx-components-button
    (clicked)="showConfirmationModal = true"
    data-automation="'ConfirmAD'"
    [disabled]="form?.value?.slotIdentifier === null"
    [fill]="true"
    type="submit"
    variant="primary"
  >
    Book
  </dgx-components-button>

  <dgx-components-button
    class="mt-3"
    (clicked)="seeOtherDates()"
    data-automation="'SeeOtherDates'"
    [fill]="true"
    variant="secondary"
  >
    See other dates
  </dgx-components-button>
</div>
