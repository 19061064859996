import { Component, Output, EventEmitter, Input } from '@angular/core';
import type { ErrorContent } from '../../interfaces/error-page.interface';

@Component({
  selector: 'dg-modal-dialog-alert',
  templateUrl: './modal-dialog-alert.component.html',
  styleUrls: ['./modal-dialog-alert.component.scss'],
})
export class ModalDialogAlertComponent {
  @Input() content?: ErrorContent;
  @Output() closed: EventEmitter<boolean> = new EventEmitter();

  onClick() {
    this.closed.emit();
  }
}
