import { Component, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'claims-map-tab',
  templateUrl: './map-tab.component.html',
  styleUrls: ['./map-tab.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0ms 200ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [style({ opacity: 0 })]),
    ]),
  ],
})
export class MapTabComponent {
  /**
   * Receives the title
   */
  @Input() title?: string;

  /**
   * Default active state false
   */
  @Input() active = false;
}
