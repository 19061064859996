import { slideInOut } from '@domgen/dgx-components';
import {
  DropoffStateService,
  SelectionType,
} from '../../../book-dropoff/book-dropoff-data-access/book-dropoff-state.service';
import { Component, Input } from '@angular/core';
import { Api } from '@domgen/dgx-components';

@Component({
  selector: 'claims-dropoff-location',
  templateUrl: './dropoff-location.component.html',
  styleUrls: ['./dropoff-location.component.scss'],
  animations: [slideInOut],
})
export class DropoffLocationComponent {
  constructor(private _state: DropoffStateService) {}
  vm$ = this._state.vm$;
  @Input() provider?: Api.ServiceProvider;
  @Input() highlighted = false;
  @Input() selected?: number | null;
  @Input() index = 0;
  @Input() source: 'map' | 'list' = 'list';

  setSelected(index: number, source: string) {
    this._state.setSelectedType(
      source === SelectionType.MAP ? SelectionType.MAP : SelectionType.LIST
    );
    this._state.setSelected(index);
  }
}
