import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Hint } from '../hint/hint.component';
import { ValidationError } from '../../interfaces/validation-error.interface';
import { InputDefinition } from '../../interfaces/form-controls-definition.interface';
import { FormBuilderLabel } from '../../interfaces/formData.interface';

@UntilDestroy()
@Component({
  selector: 'dg-fb-input',
  templateUrl: './input.component.html',
})
export class InputComponent implements OnInit {
  @Input() group?: UntypedFormGroup;
  @Input() maxlength?: number | null;
  @Input() item?: InputDefinition['input'];
  @Input() label?: FormBuilderLabel;
  @Input() hint?: Hint;
  @Input() labelFor?: string;
  @Input() showValidation = false;
  @Input() validators?: ValidationError[];
  @Input() tooltip?: {
    text: string;
    classes: string;
  };
  passwordValue = '';
  passwordError = false;
  refreshValidation$ = new Subject();

  constructor() {}

  ngOnInit(): void {
    if (this.item?.PasswordStrengthChecker) {
      this.group?.controls[this.labelFor as string].valueChanges
        .pipe(untilDestroyed(this))
        .subscribe((v: string) => {
          this.passwordError = this.group?.controls[this.labelFor as string]
            ?.valid as boolean;
          this.passwordValue = v;
        });
    }

    /**
     * TODO: Remove this code when it's proved it was never needed.
     * Commented out because it was causing immediate touched state
     * on key up in pristine state = bad UX; shouldn't happen until
     * after first blur which is default on a form control
    this.group
      .get(this.item.control)
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        if (
          this.group.get(this.item.control).invalid ||
          this.group.get(this.item.control).touched ||
          this.group.get(this.item.control).dirty
        ) {
          this.showValidation = true;
          this.group.get(this.item.control).markAsTouched();
        } else {
          this.showValidation = false;
        }
      });
      */
  }

  refreshValidationMessages() {
    this.showValidation = true;
    this.refreshValidation$.next(true);
  }
}
