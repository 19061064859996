<div class="grid-row">
  <div class="xs-8 no-padding">
    <h3 data-testid="confirm-return-address">{{ heading }}</h3>
  </div>
  <div class="xs-4 no-padding text--right" *ngIf="!isEdit" [@fadeInAnimation]>
    <a
      class="link link-underline middle-h2"
      (click)="edit()"
      data-automation="editAddress"
      >Edit</a
    >
  </div>
  <div
    class="xs-4 no-padding text--right"
    *ngIf="isEdit && !showCancelCTA && initFormDataValid"
    [@fadeInAnimation]
  >
    <a
      class="link link-underline middle-h2"
      (click)="cancel()"
      data-automation="editAddress"
      >Cancel</a
    >
  </div>
</div>

<hr class="divider-fat" />

<ng-content select=".message--always-visible"></ng-content>

<ng-container *ngIf="!isEdit; else editForm">
  <div class="row--padding" [@fadeInAnimation]>
    <ng-container *ngFor="let input of formTemplateBuilder">
      <div
        class="no-padding row--spacer-bottom-medium"
        *ngIf="!input.addressGroup && (addressForm?.value)[input.name]"
      >
        <h4 class="typog-body-bold margin--none">{{ input.title }}</h4>
        <p
          [dgxQmSanitise]="
            ['Email', 'Mobile', 'Landline'].includes(input.title)
              ? 'block'
              : 'encrypt'
          "
        >
          {{ (addressForm?.value)[input.name] }}
        </p>
      </div>
    </ng-container>

    <div class="no-padding row--spacer-bottom-medium">
      <h4 *ngIf="showCancelCTA" class="font--bold margin--none">
        {{ searchLabel }}
      </h4>
      <p>
        <span class="xs--block" [dgxQmSanitise]="'block'"
          >{{ contactDetails?.formValues?.addressLine1 }}
          {{ contactDetails?.formValues?.addressLine2 }}</span
        >
        <span class="xs--block" [dgxQmSanitise]="'block'">{{
          contactDetails?.formValues?.county
        }}</span>
        <span
          *ngIf="contactDetails?.formValues?.city"
          class="xs--block"
          [dgxQmSanitise]="'block'"
          >{{ contactDetails?.formValues?.city }}, UK</span
        >
        <span
          *ngIf="!contactDetails?.formValues?.city"
          class="xs--block"
          [dgxQmSanitise]="'block'"
          >UK</span
        >
        <span class="xs--block" [dgxQmSanitise]="'block'">{{
          contactDetails?.formValues?.postcode?.toUpperCase()
        }}</span>
      </p>
    </div>
  </div>
</ng-container>

<ng-template #editForm>
  <div
    class="row--spacer-top-medium"
    #editContactDetailsForm
    [@fadeInAnimation]
  >
    <form [formGroup]="$any(addressForm)">
      <div class="edit-contact-details-form">
        <ng-container *ngFor="let input of formTemplateBuilder">
          <div
            [@fadeInAnimation]
            *ngIf="
              (input.addressGroup && isManualAddress) || !input.addressGroup
            "
          >
            <div
              class="row--spacer-bottom-medium"
              *ngIf="input.name === 'addressLine1'"
            >
              <a
                class="link link-underline"
                (click)="toggleAddress()"
                data-automation="searchAdressEnter"
                >Search address</a
              >
            </div>
            <div
              *ngIf="$any(f)[input.name]?.touched && $any(f)[input.name].errors"
              #error
            ></div>
            <div
              class="form-input no-padding legacy-form-builder"
              [ngClass]="{
                'is-invalid':
                  !$any(f)[input.name].valid && $any(f)[input.name]?.touched,
                'is-valid':
                  $any(f)[input.name]?.valid && $any(f)[input.name]?.touched
              }"
            >
              <label class="form-label">{{ input.title }}</label>
              <div
                class="form-control"
                [dgxQmSanitise]="
                  ['Email', 'Mobile', 'Landline'].includes(input.title)
                    ? 'block'
                    : 'encrypt'
                "
              >
                <input
                  (input)="formatCase(input.name, $any(f)[input.name])"
                  type="text"
                  [formControlName]="input.name"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': $any(f)[input.name].errors }"
                  [placeholder]="input.title"
                  [attr.data-automation]="input.automation"
                />
                <i class="icon validation-icon icon-tick-circle"></i>
                <i class="icon validation-icon icon-error-circle"></i>
              </div>
              <ng-container
                *ngIf="
                  $any(f)[input.name]?.touched && $any(f)[input.name].errors
                "
                class="invalid-feedback"
              >
                <span
                  class="form-input-feedback"
                  *ngIf="$any(f)[input.name].errors.required"
                  >{{ input.requiredMessage }}</span
                >
                <span
                  class="form-input-feedback"
                  *ngIf="$any(f)[input.name].errors.pattern"
                  >{{ input.patternMessage }}</span
                >
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!isManualAddress">
          <!--TODO Replace address field below to Capture+ same as on old website -->
          <div
            class="form-input no-padding legacy-form-builder"
            [@fadeInAnimation]
          >
            <label class="form-label">{{ searchLabel }}</label>
            <div class="form-control">
              <input
                #contactAddressInput
                type="text"
                class="form-control"
                [dgxQmSanitise]="'block'"
                formControlName="address"
                placeholder="{{ placeholder }}"
                data-automation="addressLookup"
                autocomplete="chrome-off"
                (keydown)="onKeydown($event, selected, addressList)"
              />

              <ul
                class="address-look-up"
                [@slideInOut]
                tabindex="0"
                #scrollList
                *ngIf="addressList.length > 0"
                (keydown)="onKeydown($event, selected, addressList)"
              >
                <li
                  *ngFor="let address of addressList; index as i"
                  (click)="handleAddressClick(address)"
                  (keydown)="onKeydown($event, selected, addressList)"
                  [class.active]="i === selected"
                  [dgxQmSanitise]="'block'"
                >
                  {{ address.Text }}, {{ address.Description }}
                  <span
                    class="glyphicon glyphicon-chevron-right"
                    [ngClass]="{
                      hidden: handleExpandable(address)
                    }"
                    aria-hidden="true"
                  ></span>
                </li>
              </ul>
            </div>
          </div>

          <div class="row--spacer-bottom-medium">
            <a
              class="link link-underline md-8 margin--none"
              (click)="toggleAddress()"
              data-automation="searchAdressEnter"
              >Enter address manually</a
            >
          </div>
        </ng-container>

        <ng-content select=".message--visible-edit-mode"></ng-content>

        <div class="row--spacer-bottom-medium">
          <dgx-components-button
            type="submit"
            variant="primary"
            iconName="arrow-right"
            (click)="submit()"
            >Save changes</dgx-components-button
          >
        </div>
      </div>
    </form>
  </div>
</ng-template>
