<div *ngIf="claim$ | async as claim">
  <div *ngIf="form$ | async as form">
    <form [formGroup]="form" class="legacy-form-builder">
      <div *ngFor="let group of questions$ | async">
        <div
          *ngIf="group?.date"
          #controls
          [ngClass]="{ 'p-calendar--open': open }"
        >
          <dg-information-notice
            [title]="group?.date?.heading"
            [description]="group.date?.subheading"
            data-testid="information-notice"
          >
          </dg-information-notice>
          <div
            class="form-input legacy-form-builder"
            [ngClass]="{
              'is-invalid':
                form.controls[group.date?.control]?.invalid &&
                form.controls[group.date?.control]?.touched,
              'is-valid': form.controls[group.date?.control]?.valid
            }"
          >
            <p class="typog-body-large-bold form-label">Fault date</p>
            <div class="form-control form-control--calendar">
              <i class="icon validation-icon icon-tick-circle"></i>
              <i class="icon validation-icon icon-error-circle"></i>
              <p-calendar
                class="form-control form-control--calendar"
                inputId="{{ group.date?.control }}"
                [showIcon]="true"
                dateFormat="dd/mm/yy"
                dataType="string"
                icon="icon icon-calendar"
                placeholder="DD/MM/YYYY"
                [showWeek]="true"
                [readonlyInput]="true"
                [showOtherMonths]="false"
                [selectOtherMonths]="false"
                [minDate]="calendarDays[0].date"
                [maxDate]="calendarDays[calendarDays.length - 1].date"
                [required]="true"
                [formControlName]="group.date?.control"
                (onSelect)="qaService.putDateAnswer($event, group.date)"
                (onClose)="open = false"
                (onShow)="onOpen()"
                data-id="fault-calendar"
              >
              </p-calendar>
            </div>
            <ng-container
              *ngIf="
                form.controls[group.date?.control]?.invalid &&
                form.controls[group.date?.control]?.touched
              "
            >
              <dg-fb-validation-error-formcontrol
                [group]="form"
                [validators]="group.date?.validators"
                [control]="group.date?.control"
              ></dg-fb-validation-error-formcontrol>
            </ng-container>
          </div>
        </div>

        <div
          *ngIf="group?.textarea?.type === QuestionTypes.RESPONSE_FIELD"
          #controls
          class="question"
        >
          <div class="form-label">
            <p class="typog-body-large-bold">{{ group.textarea?.heading }}</p>
            <p>{{ group.textarea?.subheading }}</p>
          </div>

          <dg-fb-textarea
            [classes]="'dg-textarea'"
            [validators]="group.textarea?.validators"
            [chrLimit]="group.textarea?.chrLimit"
            [group]="form"
            [showValidation]="showValidation$ | async"
            [control]="group.textarea?.control"
            [placeholder]="group.textarea?.placeholder"
            [label]="group.textarea?.label"
            [labelFor]="group.textarea?.control"
            data-id="additional-description"
          >
          </dg-fb-textarea>

          <dgx-components-button
            type="submit"
            variant="primary"
            [ngClass]="{
              'btn--disabled': form.controls[group.textarea?.control].invalid
            }"
            iconName="arrow-right"
            (click)="qaService.textAreaSubmit(form, group.textarea)"
            [attr.data-automation]="'Continue' + group.textarea?.control"
            data-id="proceed-button"
          >
            OK, let’s continue
          </dgx-components-button>
        </div>

        <div>
          <div
            #controls
            *ngIf="
              group?.radios?.type === QuestionTypes.LIST ||
              group?.radios?.type === QuestionTypes.RADIO_BUTTONS
            "
            class="question"
          >
            <claims-question-form-list-input
              [group]="group"
              [questions]="claim.questions"
              [form]="form$ | async"
              (answer)="qaService.putAnswer($event)"
            ></claims-question-form-list-input>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
