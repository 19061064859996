import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'smart-fix-bedrock-feedback-form',
  templateUrl: './bedrock-feedback-form.component.html',
  styleUrls: ['./bedrock-feedback-form.component.scss'],
})
export class BedrockFeedbackFormComponent {
  @Output() sendFeedback = new EventEmitter<string>();
  @ViewChild('textArea') textArea!: ElementRef<HTMLTextAreaElement>;
  isInputActive: boolean = false;

  chatForm = new FormGroup({
    message: new FormControl('', Validators.compose([Validators.required])),
  });

  onSubmit() {
    if (this.chatForm.valid && this.chatForm?.value?.message) {
      this.sendFeedback.emit(this.chatForm?.value?.message);
      this.chatForm.reset();
      setTimeout(() => {
        if (this.textArea) {
          this.textArea.nativeElement.style.height = '22px';
        }
      }, 0);
      this.isInputActive = false;
    }
  }

  onFocusInput() {
    this.isInputActive = true;
  }

  onBlurInput() {
    this.isInputActive = false;
  }

  autoResize(event: Event) {
    this.textArea.nativeElement.style.height = '22px';
    this.textArea.nativeElement.style.height =
      this.textArea.nativeElement.scrollHeight + 'px';
  }
}
