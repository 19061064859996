<form *ngIf="group" [formGroup]="form">
  <div class="form-label">
    <p class="typog-body-large-bold">{{ group.radios.heading }}</p>
    <p *ngIf="group.radios.subheading">{{ group.radios.subheading }}</p>
  </div>

  <dg-fb-radiobutton
    [group]="form"
    [align]="'justify--left'"
    [classes]="'dg-radios ' + group.radios.classes"
    [idPrefix]="group.radios.control"
    [control]="group.radios.control"
    [items]="group.radios.radioGroup"
    [showValidation]="showValidation"
    [validators]="group.radios.validators"
    [elementsPerPage]="6"
    (change)="handleChange(group.radios)"
  >
  </dg-fb-radiobutton>
</form>
<form *ngIf="nestedForm" [formGroup]="nestedForm" class="mt-4">
  <div *ngFor="let group of nestedQuestions">
    <div *ngIf="group?.radios">
      <div class="form-label">
        <p class="typog-body-large-bold">{{ group.radios.heading }}</p>
        <p *ngIf="group.radios.subheading">{{ group.radios.subheading }}</p>
      </div>

      <dg-fb-radiobutton
        [group]="nestedForm"
        [align]="'justify--left'"
        [classes]="'dg-radios ' + group.radios.classes"
        [idPrefix]="group.radios.control"
        [control]="group.radios.control"
        [items]="group.radios.radioGroup"
        [validators]="group.radios.validators"
        (change)="selectRadio(group.radios)"
        [elementsPerPage]="6"
      >
      </dg-fb-radiobutton>
    </div>

    <div *ngIf="group?.date" [attr.id]="group.date?.control">
      <div class="form-label">
        <p class="typog-body-large-bold">{{ group.date.subheading }}</p>
      </div>
      <p-calendar
        class="form-control form-control--calendar"
        inputId="{{ group.date?.control }}"
        [showIcon]="true"
        dateFormat="dd/mm/yy"
        dataType="string"
        icon="icon icon-calendar"
        placeholder="DD/MM/YYYY"
        [showWeek]="true"
        [showOtherMonths]="true"
        [selectOtherMonths]="true"
        [required]="true"
        data-id="fault-calendar"
        [maxDate]="
                group?.date?.calendarDays?.[group?.date?.calendarDays?.length - 1]
                  .date
              "
        [formControlName]="group.date?.control"
        (onSelect)="selectDate($event, group.date)"
      >
      </p-calendar>
    </div>

    <div *ngIf="group?.textarea">
      <div class="form-label">
        <p class="typog-body-large-bold">
          {{ group.textarea.subheading }}
        </p>
      </div>

      <dg-fb-textarea
        [classes]="'dg-textarea'"
        [validators]="group.textarea.validators"
        [chrLimit]="group.textarea.chrLimit"
        [group]="nestedForm"
        [showValidation]="showValidation"
        [control]="group.textarea.control"
        [placeholder]="group.textarea.placeholder"
        [label]="group.textarea.label"
        [labelFor]="group.textarea.control"
        data-id="additional-description"
      >
      </dg-fb-textarea>

      <dgx-components-button
        type="submit"
        variant="primary"
        iconName="arrow-right"
        (click)="textAreaSubmit(group.textarea)"
        [attr.data-automation]="'Continue' + group.textarea.control"
        data-id="proceed-button"
      >
        OK, let’s continue
      </dgx-components-button>
    </div>
  </div>
</form>
