import { Component, Input } from '@angular/core';

@Component({
  selector: 'dg-fb-label',
  templateUrl: './label.component.html',
})
export class LabelComponent {
  @Input() idPrefix = 'lb';
  @Input() name = 'lb';
  @Input() forElement?: string;
  @Input() label?: string;

  constructor() {}
}
